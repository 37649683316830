import { generateLink, UserLink } from '../../../../utils';
import { RoleCategory } from '../../../../constants/projectUsers';
import React, { FC } from 'react';
import { IBreadcrumbsElement } from '../../../../interfaces/user/breadcrumbs';
import { BreadcrumbsCustom, Separator } from './Breadcrumbs.styled';
import { Link } from 'react-router-dom';
import useBreakpoint, { Breakpoint } from '../../../../hooks/useBreakpoint';


interface IProps {
  breadcrumbs?: IBreadcrumbsElement[] | null;
}

export const Breadcrumbs: FC<IProps> = ({ breadcrumbs }) => {
  const breakpoint = useBreakpoint();
  return (
    <BreadcrumbsCustom>
      {breadcrumbs && breakpoint >= Breakpoint.SMALL &&
        <div>
          {breadcrumbs && breadcrumbs.length > 0 &&
            <>
              <Link to={generateLink(RoleCategory.USER, UserLink.HOME)}>
                RPM
              </Link><Separator>/</Separator>
              {breadcrumbs.map((element, index) => (
                <span key={element.name}>
                      {
                        element.link ?
                          <Link to={generateLink(RoleCategory.USER, element.link)}>
                            {element.name}
                          </Link>
                          :
                          <span>
                            {element.name}
                          </span>
                      }
                  {breadcrumbs?.length !== (index + 1) && <Separator>/</Separator>}
                    </span>
              ))}
            </>
          }
        </div>
      }
    </BreadcrumbsCustom>
  );
};