import React, { FC } from 'react';
import { Input } from "../../../../shared/form/input/Input";
import { Grid } from "@mui/material";
import { Button } from '../../../../shared/button/Button'
import { ErrorMessage } from "../../../../shared/form/errorMessage/ErrorMesage";
import { ReactComponent as ArrowRight } from './../../../../../assets/icon/arrowRight.svg'
import { formData, IFormDataChangePassword, } from "./changePasswordFormData";
import { useChangePasswordViewingForm } from "./useChangePasswordViewingForm.vm";
import { RegisterViewingFormContainer } from "./ChangePasswordViewingForm.styled";
import theme from "../../../../../theme/theme";

interface IProps {
  isRegister?: boolean;
}

export const ChangePasswordViewingForm: FC<IProps> = ({isRegister}) => {
  const {
    formik,
    message,
    confirmForm,
    isButtonActive
  } = useChangePasswordViewingForm(isRegister)

  return <RegisterViewingFormContainer isButtonActive={isButtonActive} onSubmit={formik.handleSubmit}>
    <Grid container justifyContent={'space-between'}>
      {formData.map((el: IFormDataChangePassword) =>
        (
          <Input key={el.name} label={el.label} name={el.name} type={el.type} value={formik.values[el.name]}
                 color={'white'}
                 onChange={formik.handleChange}
                 placeholder={el.placeholder}
                 isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
                 textError={formik.errors[el.name]}
          />
        )
      )}
    </Grid>
    {formik.submitCount > 0 && <ErrorMessage errors={formik.errors} errorServer={false}
                                             message={message}/>}
    <Button text={'nadaj hasło i zaloguj'} clickHandler={confirmForm}
            backgroundColor={isButtonActive ? theme.colors.dark1 : theme.colors.blue2}
            color={isButtonActive ? 'white' : theme.colors.darkBlue}
            size={'small'} type={'submit'}
            maxWidth={'315px'} icon={<ArrowRight/>}/>
  </RegisterViewingFormContainer>
}

