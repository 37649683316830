import { HeaderHistory } from './headerHistory/HeaderHistory';
import { Incidents } from './incidents/Incidents';
import { useCaseStore } from '../../../../../store/hooks';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { ResonansAssessment } from './resonansAssessment/ResonansAssessment';
import { Container, SectionTitle } from './TreatmentHistory.styled';
import { IImages } from '../ViewCase.helper';

export const TreatmentHistory: FC<IImages> = observer(({ imageHandler }) => {
  const caseStore = useCaseStore();
  const { viewCaseNeuro } = caseStore;

  return (
    <Container>
      <SectionTitle>Historia leczenia:</SectionTitle>
      {viewCaseNeuro?.drugs.map((el, idx) => <div key={el.id}><HeaderHistory index={idx} drug={el} />
        <Incidents incidents={el.incidents} />
        <ResonansAssessment imageHandler={imageHandler} drug={el} />
      </div>)
      }
    </Container>
  );
});

