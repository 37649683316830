import * as yup from "yup";
import { useProgressBarNeuroStore } from "../../../../../../store/hooks";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Stages } from "../../progressBar/ProgressBar.helper";
import { useDateSetter } from "../../../../../shared/form/date/useDateSetter";

import { useDraftCaseApi } from "../../../../../../apiHooks/useDraftCaseNeuroApi";
import { useParams } from "react-router-dom";
import { ISecondStageNeuro } from "../../../../../../interfaces/user/draftCaseNeuro/secoundStage";
import { setAllFieldsTouched } from '../../../../../../utils/setAllFieldsTouched';


export const validationSchema = yup.object().shape({
  date: yup
    .string()
    .nullable()
    .required(),
  age: yup
    .number()
    .min(1)
    .max(120)
    .required(),
  isFluidTesting: yup
    .boolean()
    .required(),
  projectileActivityDate: yup
    .string()
    .nullable(),
  projectileActivityDescription: yup
    .string()
    .required(),
  projectileActivityTreatment: yup
    .string()
    .required(),
  projectileActivityAssessment: yup
    .string()
    .required(),
  resonanceActivityDescription: yup
    .string()
    .required()
})

export const useSecondStage = () => {
  const {id} = useParams();
  const {postImageSecondStage, deleteImageSecondStage} = useDraftCaseApi()
  const progressBarStore = useProgressBarNeuroStore();
  const {secondStage, isClicked} = progressBarStore;

  const formik = useFormik<ISecondStageNeuro>({
    enableReinitialize: true,
    initialValues: {
      date: secondStage ? secondStage.date : '',
      age: secondStage ? secondStage.age : '',
      isFluidTesting: secondStage ? secondStage.isFluidTesting : null,
      projectileActivityDate: secondStage ? secondStage.projectileActivityDate : null,
      projectileActivityTreatment: secondStage ? secondStage.projectileActivityTreatment : '',
      projectileActivityDescription: secondStage ? secondStage.projectileActivityDescription : '',
      projectileActivityAssessment: secondStage ? secondStage.projectileActivityAssessment : '',
      resonanceActivityDescription: secondStage ? secondStage.resonanceActivityDescription : '',
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  })

  const {
    dateElements: projectileActivityDateDateElements
  } = useDateSetter();
  const {
    dateElements: dateDateElements
  } = useDateSetter();

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      progressBarStore.setMaxEditStage(Stages.SECOND);
    } else {
      const secondStageConvert: ISecondStageNeuro = {
        date: formik.values.date,
        age: formik.values.age,
        isFluidTesting: formik.values.isFluidTesting,
        projectileActivityDate: formik.values.projectileActivityDate !== '' ? formik.values.projectileActivityDate : null,
        projectileActivityTreatment: formik.values.projectileActivityTreatment,
        projectileActivityDescription: formik.values.projectileActivityDescription,
        projectileActivityAssessment: formik.values.projectileActivityAssessment,
        resonanceActivityDescription: formik.values.resonanceActivityDescription,
        diagnosisImages: secondStage ? secondStage.diagnosisImages : []
      }
      progressBarStore.setSecondStage(secondStageConvert);
      progressBarStore.setMaxEditStage(Stages.THIRD);
    }
  }, [dateDateElements, formik.errors, formik.submitCount, formik.values.age, formik.values.date, formik.values.isFluidTesting, formik.values.projectileActivityAssessment, formik.values.projectileActivityDate, formik.values.projectileActivityDescription, formik.values.projectileActivityTreatment, formik.values.resonanceActivityDescription, progressBarStore, projectileActivityDateDateElements, secondStage])

  const addImage = async (file: File | null) => {
    if (file && id) {
      await postImageSecondStage(+id, file);
    }
  }

  const removeImage = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteImageSecondStage(+id, idFile);
    }
  }

  const {validateForm, setFieldTouched} = formik;

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
      progressBarStore.setClicked(false);
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values, progressBarStore])



  return {
    formik,
    addImage,
    removeImage,
    images: secondStage && secondStage.diagnosisImages ? secondStage.diagnosisImages : []
  }
}
