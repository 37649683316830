import {FC} from "react";
import {ReactComponent as DoubleArrow} from "../../../../../../assets/icon/doubleArrow.svg";
import {Direction} from "./ArrrowButton.helper";
import {ArrowButtonContainer, ArrowIconContainer, ArrowText} from "./ArrowButton.styled";

interface IProps {
  text: string;
  direction: Direction;
  onClick: () => void;
  isValid: boolean;
  isMobile: boolean;
  tooltipNext?: string;
}

export const ArrowButton: FC<IProps> = ({
                                          text,
                                          direction,
                                          onClick,
                                          isValid,
                                          isMobile,
                                          tooltipNext = 'Proszę uzupełnić dane przed przejściem do kolejnego kroku'
                                        }) => {
  return (
    <ArrowButtonContainer isMobile={isMobile} type={"submit"} isValid={isValid} onClick={onClick} direction={direction}>
      {direction === 'left' &&
          <ArrowIconContainer isValid={isValid} direction={direction}><DoubleArrow/></ArrowIconContainer>}
      <ArrowText>{text}</ArrowText>
      {direction === 'right' &&
          <ArrowIconContainer title={!isValid ? tooltipNext : ''} isValid={isValid}
                              direction={direction}><DoubleArrow/></ArrowIconContainer>}
    </ArrowButtonContainer>)
}

