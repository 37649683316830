import styled from "styled-components";

export const Header = styled.h1`
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.01em;
  margin-bottom: 20px;

`
export const InputContainer = styled.div`
  width: 375px;
  margin-left: 20px;
`
export const Container = styled.div`
  width: 100%;
  padding: 50px 60px 0 60px;
`
export const CheckboxPermissionsContainer = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto;
`

export const FormButtonsContainer = styled.div`
  width: 390px;
  margin: auto;
`