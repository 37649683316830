import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const Title = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.turquoise2};
`

export const Container = styled.div`
  padding: 36px 40px;
  margin-top: 34px;
  margin-bottom: 30px;
  background: #F3FAF7;
  border-radius: 28px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-top: 30px;
    padding: 32px 16px;
  }
`

export const BlackTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.dark1};
  text-transform: uppercase;

  & span {
    font-weight: 400;
  }
`

export const Content = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 8px;
  color: ${theme.colors.dark1};

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 16px;
    line-height: 22px;
  }
`