import {useState} from "react";

export const useFileStateUpload = () => {
  const [file, setFile] = useState<File[]>([]);

  const handleFileChange = (file: File | null) => {
    if (file) {
      setFile([file]);
    }
  };

  const handleRemoveFile = () => {
    setFile([])
  }

  return {
    file, handleFileChange, handleRemoveFile
  }
}