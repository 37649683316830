import { UserLayout } from "../../../../../shared/user/layout/UserLayout";
import { useSummaryFormInvoiceVm } from "./useSummaryFormInvoice.vm";
import { Header, ImgContainer, StyledInfo, Subheader, Text } from './SummaryFormInvoice.styled'
import { ReactComponent as Settlement } from "../../../../../../assets/icon/settlement.svg";
import { ReactComponent as Pen } from "../../../../../../assets/icon/edit.svg";
import React from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Button } from "../../../../../shared/button/Button";
import theme from "../../../../../../theme/theme";
import { generateLink, UserLink } from "../../../../../../utils";
import { RoleCategory } from "../../../../../../constants/projectUsers";
import { useNavigate } from "react-router-dom";

export const SummaryFormInvoice = observer(() => {
  const {breadcrumbs, contractData} = useSummaryFormInvoiceVm()
  const navigate = useNavigate()
  return (
    <UserLayout breadcrumbs={breadcrumbs} isProfileContainer={true} isDoctorVisible={true}>
      <Grid container display={'flex'}>
        <Grid item xs={12} alignSelf={'flex-start'}>
          <Header><Settlement/> Twoje dane rozliczeniowe:</Header>
        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'column'} alignSelf={'flex-start'}
              alignItems={'flex-start'}>
          <Subheader><ImgContainer onClick={() => {
            navigate(generateLink(RoleCategory.USER, UserLink.PROFILE_SELECT_FORM));
          }}><Pen/></ImgContainer>Wybrana
            forma rozliczenia:&nbsp;<span>faktura</span></Subheader>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Subheader><span>Dane firmy:</span></Subheader>
              <Text><strong>Nazwa firmy</strong></Text>
              <Text>{contractData?.title || '-'}</Text>
              <Text><strong>Ulica</strong></Text>
              <Text>{contractData?.street || '-'}</Text>
              <Text><strong>Kod pocztowy i miasto</strong></Text>
              <Text>{`${contractData?.zip}, ${contractData?.city}` || '-'}</Text>
              <Text><strong>Numer NIP</strong></Text>
              <Text>{contractData?.nip || '-'}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Subheader><span>Osoba kontaktowa:</span></Subheader>
              <Text><strong>Imię i nazwisko</strong></Text>
              <Text>{contractData?.fullName || '-'}</Text>
              <Text><strong>Numer kontaktowy</strong></Text>
              <Text>{contractData?.phone || '-'}</Text>
              <Text><strong>Numer rachunku bankowego</strong></Text>
              <Text>{contractData?.bank || '-'}</Text>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Text><strong>Tytuł faktury oraz kwotę </strong>znajdziesz w sekcji <strong>"ROZLICZ"</strong></Text>
            </Grid>
            <Grid item xs={12} sm={6} mt={2} pr={{xs: 0, sm: 2}}>
              <StyledInfo>
                <h4>Dane do wystawienia faktury:</h4>
                <p>Softwebo Sp. z o.o.</p>
                <p>ul. Franciszka kniaźnina 4A</p>
                <p>01-607 Warszawa</p>
                <p>NIP: 5272653664</p>
              </StyledInfo>
            </Grid>
            <Grid item xs={12} sm={6} mt={2} display={'flex'} alignItems={'center'} flexDirection={'column'}
                  justifyContent={'center'} gap={3}>
              <Button text={'rozlicz'} size={'small'} maxWidth={'244px'} width={'100%'} fontSize={'12px'}
                      color={theme.colors.beige}
                      backgroundColor={theme.colors.red}
                      clickHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.MY_ACCEPTED_CASES))}/>
              <Button text={'twoje faktury i umowy'} size={'small'} maxWidth={'244px'} width={'100%'} fontSize={'12px'}
                      color={theme.colors.beige}
                      backgroundColor={theme.colors.blue3}
                      clickHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.PROFILE_FORM_DOCUMENTS))}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </UserLayout>
  )
})