import React, {FC} from "react";
import {SwitchIcon, SwitchIconContainer} from "./GenderIcon.styled";

interface IProps {
  children: JSX.Element;
}

export const GenderIcon: FC<IProps> = ({children}) => {
  return <SwitchIconContainer><SwitchIcon>{children}</SwitchIcon></SwitchIconContainer>
}
