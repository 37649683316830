import * as yup from 'yup'
import { useFormik } from 'formik';
import { useAuthApi } from "../../../../../../apiHooks";
import { useRegisterForm } from "../../form/useRegisterForm.vm";
import { useNavigate, useParams } from "react-router-dom";
import { GeneralLink, generateLink } from "../../../../../../utils";
import { RoleCategory } from "../../../../../../constants/projectUsers";
import { ValidationMessages } from '../../../../../../constants/validationMessages';
import { usePoiStore } from "../../../../../../store/hooks";
import { IRegisterPublish } from "../../../../../../interfaces/common/register";
import { validatePWZ } from "../../../../../../utils/validatePWZ";

const containLetterE = (string: string) => /^((?!(0))[0-9^e]{7})$/g.test(string)
export const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(ValidationMessages.EMPTY_FIELD),
  affiliation: yup
    .string()
    .required(ValidationMessages.EMPTY_FIELD),
  pwz: yup
    .string()
    .test('withoutE', 'Numer PWZ powinien składać się z 7 cyfr.', (value) => containLetterE(value ?? ''))
    .test('validatePWZ', ValidationMessages.INVALID_PWZ, (value?: string | number) => validatePWZ(value?.toString() ?? ''))
    .required(ValidationMessages.EMPTY_FIELD),
  phone: yup
    .string()
    .matches(/^(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}$/u, 'Nieprawidłowy format numeru telefonu.')
    .required(ValidationMessages.EMPTY_FIELD),
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
  repeatPassword: yup
    .string()
    .test('passwords-match', ValidationMessages.INVALID_PASSWORD, function (value) {
      return this.parent.password === value
    })
    .required(ValidationMessages.EMPTY_FIELD),
  termsAndConditions: yup.boolean().oneOf([true])
})

export const useRegisterPublishForm = () => {
  const {registerPublishUser} = useAuthApi()
  const poiStore = usePoiStore()
  const navigate = useNavigate()
  const params = useParams()
  const initialValue: IRegisterPublish = {
    affiliation: '',
    fullName: '',
    pwz: '',
    phone: '',
    password: '',
    repeatPassword: ''
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!isErrorCheckbox) {
        setMessage('')
        let data = {...values, repeatPassword: undefined, pwz: Number.parseInt(formik.values.pwz)};

        const res = await registerPublishUser(params.token, data)
        if (res) {
          setMessage("")
          navigate(generateLink(RoleCategory.GUEST, GeneralLink.LOGIN))
        } else {
          if (poiStore.errorMessage === 'validation failed') {
            setMessage(ValidationMessages.INVALID_PWZ)
          } else {
            setMessage(ValidationMessages.INVALID_LINK)
          }
        }
      }
    }
  })

  const {
    isChecked,
    setChecked,
    isErrorCheckbox,
    message,
    confirmForm,
    isButtonActive,
    setMessage
  } = useRegisterForm(formik)

  return {formik, isChecked, setChecked, isErrorCheckbox, message, confirmForm, isButtonActive}
}
  
  

