import { UserLayout } from "../../../../../shared/user/layout/UserLayout";
import { useInvoiceFormVm } from "./useInvoiceForm.vm";
import {
  BoldText,
  ConfirmationTextModal,
  ContractConfirmationContainer,
  ContractTitle,
  FlexDiv,
  Header, ListElement,
  Paragraph,
  RedCircle,
  StyledFileContainer,
  Subheader
} from './InvoiceForm.styled';
import { Grid } from '@mui/material'
import { Input } from "../../../../../shared/form/input/Input";
import theme from "../../../../../../theme/theme";
import React from "react";
import { Fieldset } from "../../../../../shared/form/fieldset/FieldsetContainer";
import { Checkbox } from "../../../../../shared/form/checkbox/Checkbox";
import { ReactComponent as RedPdf } from '../../../../../../assets/icon/redPdf.svg'
import { ReactComponent as Download } from '../../../../../../assets/icon/greenDownload.svg'
import { ReactComponent as Settlement } from "../../../../../../assets/icon/settlement.svg";
import { MultipleFileUpload } from "../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload";
import { FileSource } from "../../../../../../interfaces/user/profile/profile";
import { ButtonConfirm } from "../shared/ButtonConfirm";
import { observer } from "mobx-react-lite";
import { Modal } from "../../../../../shared/modal/Modal";
import { ContentModal } from "../../../../../shared/modal/ContentModal";
import { MoneyIcon } from "../../../../../shared/modal/modalIcons/MoneyIcon";
import { Button } from "../../../../../shared/button/Button";
import { useNavigate } from "react-router-dom";
import { generateLink, UserLink } from "../../../../../../utils";
import { RoleCategory } from "../../../../../../constants/projectUsers";

export const InvoiceForm = observer(() => {
  const {
    breadcrumbs,
    formik,
    setCheckbox,
    isChecked,
    isErrorCheckbox,
    confirmForm,
    isFileBoxOpen,
    getPdf,
    file,
    handleFileChange,
    handleRemoveFile,
    sendFile,
    isModalOpened,
    closeModal
  } = useInvoiceFormVm()
  const navigate = useNavigate()

  return (
    <UserLayout breadcrumbs={breadcrumbs}>
      <Header>Uzupełnij dane rozliczeniowe do faktur</Header>
      <Subheader>- umowa o współpracy</Subheader>
      <Grid container margin={'auto'}>
        <Grid container justifyContent={'space-between'} maxWidth={'1280px'} margin={'0 auto'}>
          <Grid item xs={12} sm={5} mt={1}>
            <Input
              disabled={isFileBoxOpen}
              isRequired={true}
              label={'Nazwa firmy'}
              name={"title"}
              type={"text"}
              color={theme.colors.dark1}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={"Wpisz nazwę firmy..."}
              isError={formik.touched.title && Boolean(formik.errors.title)}
            />
            <Input
              disabled={isFileBoxOpen}
              isRequired={true}
              label={'Ulica'}
              name={"street"}
              type={"text"}
              color={theme.colors.dark1}
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={"Wpisz nazwę ulicy..."}
              isError={formik.touched.street && Boolean(formik.errors.street)}
            />
            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
              <Grid item xs={12} sm={4}>
                <Input
                  disabled={isFileBoxOpen}
                  isRequired={true}
                  label={'Kod pocztowy'}
                  name={"zip"}
                  type={"text"}
                  color={theme.colors.dark1}
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"__-___"}
                  isError={formik.touched.zip && Boolean(formik.errors.zip)}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Input
                  disabled={isFileBoxOpen}
                  isRequired={true}
                  label={'Miejscowość'}
                  name={"city"}
                  type={"text"}
                  color={theme.colors.dark1}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Wpisz miejscowość..."}
                  isError={formik.touched.city && Boolean(formik.errors.city)}
                />
              </Grid>
              <Input
                disabled={isFileBoxOpen}
                isRequired={true}
                label={'Numer NIP'}
                name={"nip"}
                type={"text"}
                color={theme.colors.dark1}
                value={formik.values.nip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"Wpisz numer NIP..."}
                isError={formik.touched.nip && Boolean(formik.errors.nip)}
              />
              <Input
                disabled={isFileBoxOpen}
                isRequired={true}
                label={'Numer REGON'}
                name={"regon"}
                type={"text"}
                color={theme.colors.dark1}
                value={formik.values.regon}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"Wpisz numer REGON..."}
                isError={formik.touched.regon && Boolean(formik.errors.regon)}
              />
              <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'space-between'}>
                <ContractTitle href={require('./../../../../../../files/umowab2b.pdf')} target={'_blank'}
                               rel="noreferrer"><span><RedPdf/>Umowa-wzór.pdf </span><Download/></ContractTitle>

              </Grid>
              <Checkbox
                disabled={isFileBoxOpen}
                id={'policy'}
                textColor={isErrorCheckbox ? theme.colors.red : theme.colors.dark1}
                label={'Zapoznałem się z umową o współpracy. Zapoznanie się z umową <strong>nie jest</strong> równoznaczne z jej akceptacją i podpisem. <span>*</span>'}
                checked={isChecked}
                isError={isErrorCheckbox}
                onChange={setCheckbox}/>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} mt={1}>
            <Input
              disabled={isFileBoxOpen}
              isRequired={true}
              label={'Numer rachunku bankowego'}
              name={"bank"}
              type={"text"}
              color={theme.colors.dark1}
              value={formik.values.bank}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={"Wpisz numer rachunku..."}
              isError={formik.touched.bank && Boolean(formik.errors.bank)}
            />
            <Fieldset color={theme.colors.turquoise2} label={'OSOBA KONTAKTOWA:'} size={'medium'}>
              <>
                <Input
                  disabled={isFileBoxOpen}
                  isRequired={true}
                  label={'Imię i nazwisko'}
                  name={"fullName"}
                  type={"text"}
                  color={theme.colors.dark1}
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Wpisz imię i nazwisko..."}
                  isError={formik.touched.fullName && Boolean(formik.errors.fullName)}
                />
                <Input
                  disabled={isFileBoxOpen}
                  isRequired={true}
                  label={'Numer kontaktowy'}
                  name={"phone"}
                  type={"text"}
                  color={theme.colors.dark1}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Wpisz numer telefonu..."}
                  isError={formik.touched.phone && Boolean(formik.errors.phone)}
                />
              </>
            </Fieldset>
            <Paragraph>Na podstawie tych danych zostanie wystawiona umowa o współpracy w formię PDF. Należy ją podpisać
              (podpis elektroniczny) i odesłać z powrotem po przez serwis RPM. W ciągu 48h od przesłania podpisanej
              umowy
              będziesz miał możliwość wystawienia pierwszej faktury. Podpisana umowa o współpracy oraz dostarczona
              faktura
              w rozliczeniu jest podstawą do wypłacenia wynagrodzenia za OPISY PRZYPADKÓW.</Paragraph>
          </Grid>
        </Grid>
        {!isFileBoxOpen && <ButtonConfirm clickHandler={confirmForm} text={'zapisz'}/>}
        {isFileBoxOpen && <>
          <Grid item xs={12}>
            <StyledFileContainer>
              <RedCircle><Settlement/></RedCircle>
              <BoldText>Nowa umowa została utworzona</BoldText>
              <p>Do utworzenia umowy wykorzystane zostały dane rozliczeniowe do faktury.</p>
              <ul>
                <ListElement>
                  <h3><b>1. Pobierz umowę:</b></h3>
                  <FlexDiv onClick={getPdf}><ContractTitle><RedPdf/>Umowa-{formik.values.title}.pdf</ContractTitle>
                    <Download/>
                  </FlexDiv>
                </ListElement>
                <ListElement>
                  <h3><b>2. Sprawdź i podpisz umowę.</b></h3>
                  <p>Umowę możesz wydrukować i podpisać tradycyjnie lub w sposób elektroniczny</p>
                </ListElement>
                <ListElement>
                  <h3><b>3. Załącz pełną treść podpisanej umowy:</b></h3>
                  <p>W przypadku załączenia niewłaściwego pliku (np. skan tylko ostatniej strony umowy) zostanie on odrzucony.</p>
                </ListElement>
              </ul>

              <MultipleFileUpload
                images={file}
                removeFile={handleRemoveFile}
                addFile={handleFileChange}
                maxFileSize={16}
                extensions={['pdf']}
                fileType='application/pdf'
                fileSource={FileSource.STATE}
              />
            </StyledFileContainer>
          </Grid>
          <ButtonConfirm clickHandler={sendFile} text={'wyślij'} bcgColor={theme.colors.red}/>
        </>}
        {isModalOpened &&
          <Modal clickHandler={() => closeModal()}>
            <ContentModal
              scrolled={true}
              iconType={<MoneyIcon background={theme.colors.turquoise2}/>}
              text={<ConfirmationTextModal>
                <ContractConfirmationContainer>
                  <p><b>Gratulacje!</b></p>
                  <p>Twoja umowa została poprawnie przekazana do działu rozliczeń.
                    <b> W przeciągu 48h otrzymasz</b> email z podpisaną umową oraz informacją o możliwości
                    wystawienia faktur za opisy przypadków. Jeżeli chcesz poprawić swoje dane lub zmienić
                    formę rozliczenia przejdź do "Dane rozliczeniowe"</p>
                  <Button text={"Twoje dane rozliczeniowe"} width={'244px'}
                          backgroundColor={theme.colors.dark1}
                          size={'tiny'}
                          color={'white'}
                          clickHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.PROFILE_FORM_INVOICE_SUMMARY))}/>
                </ContractConfirmationContainer>
              </ConfirmationTextModal>}
            />

          </Modal>
        }
      </Grid>
    </UserLayout>
  )
})