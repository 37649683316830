import { useModal } from '../../modal/useModal.vm';
import { ModalType } from '../../../../constants/modalType';

export const useFooter = () => {
  const {handleModal, isModalOpened, closeModal} = useModal();

  const sideEffectHandler = () => {
    handleModal(ModalType.VIEW_MODAL)
  }

  return {
    sideEffectHandler,
    isModalOpened,
    closeModal
  }
}