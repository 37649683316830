import { observer } from "mobx-react-lite";
import React from "react";
import { Grid } from "@mui/material";
import {
  DoctorImg,
  Img,
  ImgLayer,
  NavigationContainer,
  NavigationContent,
  UserLayoutContainer
} from './Page404Layer.styled'
import { NavigationMenu } from "../../user/navigation/navigationMenu/NavigationMenu";
import { Footer } from "../../user/footer/Footer";
import logo from './../../../../assets/img/logo.png'

interface IProps {
  children: JSX.Element;
  backgroundImg: string
  handler?: () => void
}

export const Page404Layer: React.FC<IProps> = observer(({children, backgroundImg, handler}) => {
  return (
    <UserLayoutContainer
      container
      direction='row'
      flexWrap='wrap'>
      <NavigationContainer>
        <NavigationMenu/>
        <NavigationContent>
          <ImgLayer onClick={handler}><Img
            src={logo}/>
          </ImgLayer>
        </NavigationContent>
      </NavigationContainer>
   
        <Grid container
              maxWidth={'1200px'}
              display={'flex'}
              margin={'0 auto'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'calc(100vh - 186px)'}
              mt={2}
        >
          <Grid item xs={12} sm={7} display={'flex'} alignItems={'center'} flexDirection={'column'} p={4}>
            {children}
          </Grid>
          <Grid item xs={12} sm={5} alignSelf={'flex-end'}>
            <DoctorImg src={backgroundImg}/>
          </Grid>
        </Grid>
  
      <Footer/>
    </UserLayoutContainer>);

});
