import { useCallback } from 'react';

import { useApiCall } from './useApiCall';
import {IArea, IAreaCommon} from '../interfaces/admin/areas/areas';
import { IResponse } from '../interfaces/common/response';
import { useAreasStore } from '../store/hooks';

export const useAreasApi = () => {
    const {api} = useApiCall();
  
    const areasStore = useAreasStore();

    const getAreas = useCallback(
      async (isLoading: boolean = true) => {
        const res: IResponse<IArea> = await api.apiInstance.get('areas', isLoading);
        if (res) {
          areasStore.setAreas(res.items);
          return res;
        }
  
      },
      [api.apiInstance, areasStore]
    );

    const deleteArea = useCallback(
      async (id: number) => {
          const res = await api.apiInstance.delete(`area/${id}`);
          if (res) {
              const resAreas = await getAreas();
              return !!resAreas;
          }

      },
      [api.apiInstance, getAreas]
    );

    const getArea = useCallback(
      async (id: number) => {
        const res: IArea = await api.apiInstance.get(`area/${id}`);
        areasStore.setArea(res);
        return !!res;
      },
      [api.apiInstance, areasStore]
    );

    const postArea = useCallback(
      async (data: IAreaCommon) => {
        const res: IResponse<IArea> = await api.apiInstance.post('area', data);
        return !!res;
      },
      [api.apiInstance]
    );

    const putArea = useCallback(
      async (id: number, data: IAreaCommon) => {
        const res: IResponse<IArea> = await api.apiInstance.put(`area/${id}`, data);
        return !!res;
      },
      [api.apiInstance]
    );

  return {
        getAreas, deleteArea, getArea, postArea, putArea
  }

}