import {IStageNeuro} from '../../../../../interfaces/user/draftCaseNeuro/progressBar';
import {FirstStage} from '../stages/firstStage/FirstStage';
import {SecondStage} from '../stages/secondStage/SecondStage';
import {ThirdStage} from '../stages/thirdStage/ThirdStage';
import {FourthStage} from '../stages/fourthStage/FourthStage';
import {FifthStage} from '../stages/fifthStage/FifthStage';
import {SixthStage} from '../stages/sixthStage/SixthStage';

/*
    stagesSequence object:
        if (access = false)
            stage = null
        else
            stage = Stages.Stage
 */

export enum Stages {
  FIRST = 1, SECOND, THIRD, FOURTH, FIFTH, SIXTH
}

export const stages: IStageNeuro[] = [
  {
    stage: Stages.FIRST,
    component: <FirstStage/>,
    stagesSequence: [{access: false, stage: null}, {access: true, stage: Stages.SECOND}],
    name: 'Dane pacjenta',
    isStepper: true,
  },
  {
    stage: Stages.SECOND,
    component: <SecondStage/>,
    stagesSequence: [{access: true, stage: Stages.FIRST}, {access: true, stage: Stages.THIRD}],
    name: 'Rozpoznanie SM',
    isStepper: true,
  },
  {
    stage: Stages.THIRD,
    component: <ThirdStage/>,
    message: '(nie dotyczy pacjentów treatment naive)',
    stagesSequence: [{access: true, stage: Stages.SECOND}, {access: true, stage: Stages.FOURTH}],
    name: 'Historia leczenia',
    isStepper: true,
  },
  {
    stage: Stages.FOURTH,
    component: <FourthStage/>,
    stagesSequence: [{access: true, stage: Stages.THIRD}, {access: true, stage: Stages.FIFTH}],
    name: 'Kwalifikacja do leczenia okrelizumabem',
    isStepper: true,
  },
  {
    stage: Stages.FIFTH,
    component: <FifthStage/>,
    stagesSequence: [{access: true, stage: Stages.FOURTH}, {access: false, stage: null}],
    isStepper: false,
  },
  {
    stage: Stages.FIFTH,
    component: <FifthStage/>,
    stagesSequence: [{access: true, stage: Stages.FOURTH}, {access: false, stage: null}],
    isStepper: false,
  },
  {
    stage: Stages.SIXTH,
    component: <SixthStage/>,
    stagesSequence: [{access: false, stage: null}, {access: false, stage: null}],
    isStepper: false,
  }
]

export const MAX_STAGE = stages.length;
