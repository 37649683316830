import { observer } from 'mobx-react-lite';
import { Grid } from '@mui/material';
import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import { MuiPagination } from '../../../../shared/pagination/MuiPagination';
import { useReckoningsVm } from './useReckonings.vm';

export const StatReckonings = observer(() => {
  const {
    cellsSwitcher,
    headCells,
    tableDataRows,
    isStatisticsTableLoaded,
    currentReckoningsPage,
    setReckoningsCurrentPage,
    reckoningsPageCount,
    generateActions,
    sortOrderByHandler,
    sortOrderHandler
  } = useReckoningsVm();

  return (
    <Grid justifyContent={'space-between'} display={'flex'} flexDirection={'column'} height={'100%'}>
      <CustomTable
        sortOrderByHandler={sortOrderByHandler} sortOrderHandler={sortOrderHandler}
        actions={generateActions()}
        isAccordion={true}
        cellsSwitcher={cellsSwitcher}
        customTableConfig={{ rowsPerPage: 20 }}
        tableDataRows={tableDataRows}
        isDataDownloaded={isStatisticsTableLoaded}
        headCells={headCells}
      />
      {isStatisticsTableLoaded &&
        <MuiPagination
          style={{ alignSelf: 'center', marginBottom: '10px', marginTop: '16px' }}
          count={reckoningsPageCount}
          page={currentReckoningsPage}
          onChange={(e, value: number) => {
            setReckoningsCurrentPage(value);
          }} />
      }
    </Grid>
  );
});