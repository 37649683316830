import { IDoctorThirdStageLung } from '../../../../../../interfaces/user/draftCaseLung/thirdStage';
import React from 'react';
import { Container, BlackTitle, Title, Content, FlexContainerSecond } from './AccordionForm.styled';
import { dateStringify } from '../../../../../../utils';
import { ViewCheckbox } from '../../sharedComponents/checkbox/ViewCheckbox';
import { newObjectWithoutNull, objectWithTitle } from './AccordionForm.helper';
import { Grid } from '@mui/material';

interface IProps {
  doctor: IDoctorThirdStageLung;
}

export const AccordionForm: React.FC<IProps> = ({ doctor }) => {

  const arrayForFirstForm = objectWithTitle(newObjectWithoutNull(doctor, 'first', doctor.type === 'family'), 'first');
  const arrayForSecondForm = objectWithTitle(newObjectWithoutNull(doctor, 'second', doctor.type === 'family'), 'second');

  return (
    <Container>
      {doctor.date && <><Title>Data wizyty</Title>
        <Content>{dateStringify(doctor.date).substring(3)}</Content></>}

      <FlexContainerSecond>
        {arrayForFirstForm.length > 0 && arrayForFirstForm.map((el: any) => {
          const key = Object.keys(el)[0];

          if (el[key].isCommissioned === null) {
            return null;
          } else {
            return (
              <Grid item xs={12} md={6}>
                <Title>{el[key].title}</Title>
                <BlackTitle>Czy zostało zlecone?</BlackTitle>
                <ViewCheckbox>{el[key].isCommissioned ? 'tak' : 'nie'}</ViewCheckbox>
                {el[key].isCommissioned && <><BlackTitle>Czy zostało wykonane?</BlackTitle>
                  <ViewCheckbox>{el[key].isDone ? 'tak' : 'nie'}</ViewCheckbox>
                  {el[key].isDone && <>
                    <BlackTitle>Liczba dni od zlecenia do otrzymania wyniku</BlackTitle>
                    <Content>{el[key].amount}</Content>
                  </>}
                </>}
              </Grid>
            );
          }
        })}
      </FlexContainerSecond>
      <> {arrayForSecondForm.length > 0 && arrayForSecondForm.map((el: any) => {
        const key = Object.keys(el)[0];

        if (el[key].isCommissioned === null) {
          return null;
        } else {
          return (
            <Grid item xs={12}>
              <Title>{el[key].title}</Title>
              <BlackTitle>Czy zostało zlecone?</BlackTitle>
              <ViewCheckbox>{el[key].isCommissioned ? 'tak' : 'nie'}</ViewCheckbox>
              {el[key].isCommissioned && <><BlackTitle>Czy zostało wykonane?</BlackTitle>
                <ViewCheckbox>{el[key].done}</ViewCheckbox>
                {el[key].done === 'tak' && <>
                  <BlackTitle>Liczba dni od zlecenia do otrzymania wyniku</BlackTitle>
                  <Content>{el[key].amount}</Content>
                </>}
              </>}
            </Grid>
          );
        }
      })}</>

    </Container>
  );
};