import {RoleCategory, RoleFunction} from '../constants/projectUsers';

export const checkRoleCategory = (roleFunction: RoleFunction) => {
  const isAdmin = [RoleFunction.ROLE_ADMIN, RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_BOOKKEEPER, RoleFunction.ROLE_BOOKKEPPING].includes(roleFunction);
  return isAdmin ? RoleCategory.ADMIN : RoleCategory.USER;
}

export const checkPermission = (roleFunction: RoleFunction, rolesPermission: RoleFunction[]) => {
  return rolesPermission.includes(roleFunction);
}



