import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { useAddUser } from './useAddUser';
import { Modal } from '../../../shared/modal/Modal';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { UserForm } from '../../../shared/admin/userForm/UserForm';
import { AdminLink, generateLink } from '../../../../utils';
import { RoleCategory } from '../../../../constants/projectUsers';
import { TextModal } from './AddUser.styled';

export const AddUser = () => {
  const {
    actionHandler,
    isModalOpened,
    closeModal
  } = useAddUser();

  return <AdminLayout centralize={true}>
    <UserForm link={generateLink(RoleCategory.ADMIN, AdminLink.USERS)} actionHandler={actionHandler} />
    {isModalOpened && <Modal clickHandler={() => closeModal()}>
      <ConfirmationModal
        text={
          <TextModal><p>Użytkownik został <b>dodany poprawnie.</b></p>
            <p><b>Automatycznie link aktywacyjny </b> zostanie wysłany aby użytkownik mógł się
              zarejestrować.
              (Dotyczy użytkownika publikującego, administratora oraz rozliczającego)</p>
          </TextModal>}
      /></Modal>}
  </AdminLayout>;
};


