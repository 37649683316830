import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import {
  IChipsFilters,
  IFilters,
  IStatistics,
  MonthlyCases
} from '../interfaces/admin/statistics/statistics';
import { SelectChangeEvent } from '@mui/material';
import { IOption } from '../interfaces/common/form/select';
import { yearArrayGenerate } from '../components/shared/admin/filter/filter.helper';

import {
  filterByAcceptStatus,
  filterByArea,
  filterByCurrentPage,
  filterByReckoningStatus,
  filterByReckoningType,
  filterByYear
} from '../utils/searchHelpers';

import { acceptStatusMap } from '../components/pages/admin/statistics/Statistics.helper';
import { searchByFilter } from '../components/shared/customTables/utils/searchByFilter';
import { shortMonthsArray } from '../utils';
import { TableSortOrder } from '../interfaces/common/table';
import { stableSort } from '../components/shared/customTables/utils/stableSort';
import { getComparator } from '../components/shared/customTables/utils/getComparior';


const initialFiltersValue = {
  area: [],
  year: [],
  reckoningType: [],
  acceptStatus: [],
  reckoningStatus: []
};

export class StatisticsStore {
  order: TableSortOrder = TableSortOrder.Ascending;
  orderBy: string = '';
  statistics: IStatistics | null = null;
  isStatisticsTableLoaded = false;
  searchValue: string = '';
  statFilters: IFilters = initialFiltersValue;
  isFiltersOpen: boolean = true;
  userCardPerPage = 11;
  currentUserPage = 1;

  casesCardPerPage = 11;
  currentCasesPage = 1;

  reckoningsCardPerPage = 11;
  currentReckoningsPage = 1;

  root: RootStore;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }

  setOrder(order: TableSortOrder) {
    this.order = order;
  }

  setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  setStatistics(statistics: IStatistics) {
    this.statistics = statistics;
    this.isStatisticsTableLoaded = true;
  }

  resetStatisticsTable() {
    this.isStatisticsTableLoaded = false;
  }

  setSearchValue(searchValue: string) {
    this.searchValue = searchValue;
    this.resetUserCurrentPage();
    this.resetCasesCurrentPage();
    this.resetReckoningsCurrentPage();
  }

  handleChangeFilters(event: SelectChangeEvent<number[]>, key: keyof IFilters, options: IOption[]) {
    const selectedValues = event.target.value as number[];
    this.setSelectedFilter(selectedValues, key);
  };

  setSelectedFilter(value: string[] | number[], key: keyof IFilters) {
    this.statFilters = { ...this.statFilters, [key]: value };
    this.resetUserCurrentPage();
    this.resetCasesCurrentPage();
    this.resetReckoningsCurrentPage();
  }

  get preparedAreas() {
    return (this.root.areasStore.areas && this.root.areasStore.areas.map(el => ({
      id: el.id,
      title: el.fullName
    }))) || [];
  }

  get preparedAreasForChart() {
    return (this.root.areasStore.areas && this.root.areasStore.areas.map(el => ({
      dataKey: el.fullName,
      label: el.fullName
    }))) || [];
  }

  get prepareTypeOfReckonings() {
    return [
      {
        id: 1,
        title: 'UoD'
      }, {
        id: 2, title: 'Fv'
      }
    ];
  };

  get prepareAcceptStatus() {
    return [
      {
        id: 1,
        title: 'Szkic'
      }, {
        id: 2, title: 'Do edycji'
      }, {
        id: 3,
        title: 'Oczekujący'
      }, {
        id: 4,
        title: 'Zaakceptowany Publiczny'
      }, {
        id: 5,
        title: 'Zaakceptowany Niepubliczny'
      }

    ];
  }

  get prepareReckoningStatus() {
    return [
      {
        id: 1,
        title: 'Opłacone'
      }, {
        id: 2,
        title: 'Nieopłacone'
      }
    ];
  }


  get chipsFromFilters(): IChipsFilters {
    return {
      area: this.statFilters?.area?.map(area => ({
        id: area,
        title: this.preparedAreas?.find(el => el.id === area)?.title || ''
      })),
      year: this.statFilters?.year?.map(year => ({
        id: year,
        title: yearArrayGenerate()?.find(el => el.id === year)?.title || ''
      })),
      reckoningType: this.statFilters?.reckoningType?.map(type => ({
        id: type,
        title: this.prepareTypeOfReckonings?.find(el => el.id === type)?.title || ''
      })),
      acceptStatus: this.statFilters?.acceptStatus?.map(type => ({
        id: type,
        title: this.prepareAcceptStatus?.find(el => el.id === type)?.title || ''
      })),
      reckoningStatus: this.statFilters?.reckoningStatus?.map(type => ({
        id: type,
        title: this.prepareReckoningStatus?.find(el => el.id === type)?.title || ''
      }))
    };
  }

  get filtersToExcel() {
    return {
      areas: this.statFilters.area,
      years: this.statFilters.year,
      reckoningTypes: this.statFilters.reckoningType,
      acceptStatuses: this.statFilters.acceptStatus,
      reckoningStatuses: this.statFilters.reckoningStatus
    };
  }

  get amountOfChips() {
    return Object.values(this.chipsFromFilters).filter(arr => arr !== undefined).reduce((sum, arr) => arr ? sum + (arr && arr?.length) : sum, 0);
  }

  setFilterOpen(value: boolean) {
    this.isFiltersOpen = value;
  }

  resetAllChips() {
    this.statFilters = initialFiltersValue;
  }

  deleteChip(value: number, key: string) {
    this.statFilters = {
      ...this.statFilters,
      [key]: this.statFilters && this.statFilters[key as keyof IFilters]?.filter((el: number) => el !== value)
    };
  }

  get filteredUsers() {
    const selectedAreas = this.chipsFromFilters.area?.filter(el => el.title !== undefined && typeof el.title === 'string')
      .map(el => el.title as string) || [];
    return selectedAreas && this.statistics?.users?.filter(filterByArea(selectedAreas));
  }

  get selectedYears() {
    return this.chipsFromFilters.year?.filter(el => el.title !== undefined).map(el => el.title as number) || [];
  }

  get selectedAreas() {
    return this.chipsFromFilters.area?.filter(el => el.title !== undefined && typeof el.title === 'string').map(el => el.title as string) || [];
  }

  get selectedReckoningType() {
    return this.chipsFromFilters.reckoningType?.filter(el => el.title !== undefined && typeof el.title === 'string').map(el => el.title as string) || [];
  }

  get selectedReckoningStatus() {
    return this.chipsFromFilters.reckoningStatus?.filter(el => el.title !== undefined && typeof el.title === 'string').map(el => el.title as string) || [];
  }

  get selectedAcceptStatus() {
    return this.chipsFromFilters.acceptStatus?.filter(el => el.title !== undefined && typeof el.title === 'string').map(el => el.title as string).map(sentence => acceptStatusMap.get(sentence) || sentence) || [];
  }

  get filteredSituationsForUsers() {
    return this.statistics?.situations?.map(el => ({
      ...el,
      areas: el.area.fullName
    }))?.filter(filterByArea(this.selectedAreas))
      .filter(filterByAcceptStatus(this.selectedAcceptStatus))
      .filter(filterByReckoningType(this.selectedReckoningType));
  }

  get publishedUsersDataTable() {
    const filteredUserCases = this.filteredUsers?.filter(searchByFilter(this.searchValue, ['fullName', 'keeper', 'email']))?.map(user => {
      const userSituations = this.filteredSituationsForUsers?.filter(situation => situation.createdBy === user.id);
      const totalSituations = userSituations && userSituations.length;
      const isPublic = userSituations?.filter(situation => situation.isPublic).length;
      const isUnPublic = userSituations?.filter(situation => situation.isPublic === false).length;

      const statusCounts = userSituations?.reduce((acc: Record<string, number>, situation) => {
        acc[situation.status] = (acc[situation.status] || 0) + 1;
        return acc;
      }, {} as Record<string, number>);
      return {
        ...user,
        situations: userSituations,
        allCases: totalSituations || 0,
        sentCases: statusCounts?.SENT || 0,
        draftCases: statusCounts?.DRAFT || 0,
        rejectedCases: statusCounts?.REJECT || 0,
        publicCases: isPublic,
        unpublicCases: isUnPublic
      };
    });
    if (this.selectedReckoningType.length > 0 || this.selectedAcceptStatus.length > 0) {
      return filteredUserCases?.filter(user => !!user.allCases);
    } else {
      return filteredUserCases;
    }

  }


  get userPageCount() {
    return Math.ceil(
      this.publishedUsersDataTable && this.publishedUsersDataTable.length !== 0 ? this.publishedUsersDataTable?.length / this.userCardPerPage : 1
    );
  }

  get casesPageCount() {
    return Math.ceil(
      this.casesDataTable && this.casesDataTable.length !== 0 ? this.casesDataTable?.length / this.casesCardPerPage : 1
    );
  }

  get reckoningsPageCount() {
    return Math.ceil(
      this.reckoningDataTable && this.reckoningDataTable.length !== 0 ? this.reckoningDataTable?.length / this.reckoningsCardPerPage : 1
    );
  }

  resetUserCurrentPage = () => (this.currentUserPage = 1);

  resetCasesCurrentPage = () => (this.currentCasesPage = 1);

  resetReckoningsCurrentPage = () => (this.currentReckoningsPage = 1);

  setUserCurrentPage = (value: number) => {
    this.currentUserPage = value;
  };

  setCasesCurrentPage = (value: number) => {
    this.currentCasesPage = value;
  };

  setReckoningsCurrentPage = (value: number) => {
    this.currentReckoningsPage = value;
  };

  get sortedPublishedUsersDataTable() {
    const preparedArray = this.publishedUsersDataTable?.map(({ situations, ...rest }) => ({
      ...rest,
      keeper: rest.keeper || '',
      areas: rest.areas.map(area => area.fullName).join(', ')
    }));

    if (preparedArray) {
      return stableSort(preparedArray, getComparator(this.order, this.orderBy));
    } else {
      return preparedArray;
    }
  }

  get publishedUsersDataTableByCurrentPage() {
    return this.sortedPublishedUsersDataTable?.filter(filterByCurrentPage(this.currentUserPage, this.userCardPerPage));
  }

  get casesDataTableByCurrentPage() {
    return this.sortedCasesDataTable?.filter(filterByCurrentPage(this.currentCasesPage, this.casesCardPerPage));
  }

  get sortedReckoningsDataTable() {
    const preparedArray = this.reckoningDataTable?.map(({ cases, ...rest }) => ({
      ...rest,
      areas: typeof rest.areas === 'object' ? rest.areas.join(',') : rest.areas
    }));

    if (preparedArray) {
      return stableSort(preparedArray, getComparator(this.order, this.orderBy));
    } else {
      return preparedArray;
    }
  }

  get reckoningsDataTableByCurrentPage() {
    return this.sortedReckoningsDataTable?.filter(filterByCurrentPage(this.currentReckoningsPage, this.reckoningsCardPerPage));
  }

  get sortedCasesDataTable() {
    const preparedArray = this.casesDataTable?.map(({ area, isPublic, type, ...rest }) => ({
      ...rest,
      paidAt: rest.paidAt || '',
      isPay: rest.isPay || false
    }));

    if (preparedArray) {
      return stableSort(preparedArray, getComparator(this.order, this.orderBy));
    } else {
      return preparedArray;
    }
  }

  get casesDataTable() {
    return this.filteredSituationsForUsers?.map(el => ({
      ...el,
      year: new Date(el.createdAt).getFullYear() || 0,
      views: el.views.length || 0,
      areas: el.area.fullName || '',
      author: this.statistics?.users.find(user => el.createdBy === user.id)?.fullName || ''
    })).filter(filterByYear(this.selectedYears)).filter(filterByReckoningStatus(this.selectedReckoningStatus)).filter(filterByAcceptStatus(this.selectedAcceptStatus)).filter(searchByFilter(this.searchValue, ['customId', 'author', 'areas', 'title']));
  }


  get reckoningDataTable() {
    return this.statistics?.contracts.map((contract, index) => ({
      ...contract,
      id: contract.type + index,
      author: this.statistics?.users.find(el => el.id === contract.createdBy)?.fullName || '',
      areas: this.statistics?.users.filter(filterByArea(this.selectedAreas))?.find(el => el.id === contract.createdBy)?.areas?.map(item => item.fullName) || '',
      cases: contract.situations.map(el => ({
        customId: el,
        title: this.statistics?.situations?.find(situation => situation.customId === el)?.title || ''
      })),
      amount: contract.situations.map(el => ({
        ...this.statistics?.situations?.find(situation => situation.customId === el)
      })).reduce((acc, currentValue) => {
        const amount = currentValue?.amount ?? 0;
        return acc + amount;
      }, 0)
    })).filter(filterByArea(this.selectedAreas)).filter(filterByReckoningType(this.selectedReckoningType)).filter(searchByFilter(this.searchValue, ['title', 'author']));
  }

//general
  get filteredCasesByStatus() {
    const filteredCases = this.statistics?.situations?.map(el => ({
      ...el,
      areas: el.area.fullName
    }))?.filter(filterByAcceptStatus(this.selectedAcceptStatus))
      .filter(filterByArea(this.selectedAreas));

    const statusCounts = filteredCases?.reduce((acc: Record<string, number>, situation) => {
      acc[situation.status] = (acc[situation.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const isPublicCounts = filteredCases?.filter(el => el.isPublic)?.filter(filterByYear(this.selectedYears, false, true)).reduce((acc: Record<string, number>, situation) => {
      acc[situation.status] = (acc[situation.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const isUnPublicCounts = filteredCases?.filter(el => el.isPublic === false)?.filter(filterByYear(this.selectedYears, false, true)).reduce((acc: Record<string, number>, situation) => {
      acc[situation.status] = (acc[situation.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return [
      {
        data1: 'Szkice',
        data2: this.selectedYears?.length > 0 ? '-' : statusCounts?.DRAFT || 0
      },
      {
        data1: 'Oczekujące',
        data2: this.selectedYears?.length > 0 ? '-' : statusCounts?.SENT || 0
      },
      {
        data1: 'Odrzucone',
        data2: this.selectedYears?.length > 0 ? '-' : statusCounts?.REJECT || 0
      },
      {
        data1: 'Zaakceptowane',
        data2: this.selectedYears?.length > 0 ? Number(isPublicCounts?.ACCEPT ?? 0) + Number(isUnPublicCounts?.ACCEPT ?? 0) : statusCounts?.ACCEPT || 0
      }, {
        data1: 'Publiczne',
        data2: isPublicCounts?.ACCEPT ?? 0
      },
      {
        data1: 'Niepubliczne',
        data2: isUnPublicCounts?.ACCEPT ?? 0
      },
      {
        data1: 'Suma',
        data2: this.selectedYears?.length > 0 ? Number(isPublicCounts?.ACCEPT ?? 0) + Number(isUnPublicCounts?.ACCEPT ?? 0) : filteredCases?.length
      }
    ];
  }

  get filteredUsersByRole() {
    const roleCounts = this.filteredUsers?.filter(filterByYear(this.selectedYears)).reduce((acc: Record<string, number>, situation) => {
      acc[situation.role] = (acc[situation.role] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return [
      {
        data1: 'Admin główny',
        data2: roleCounts?.Administrator || 0
      },
      {
        data1: 'Admin akceptujący',
        data2: roleCounts?.['Admin Merytoryczny (Akceptujący)'] || 0

      },
      {
        data1: 'Admin rozliczający',
        data2: roleCounts?.['Księgowy (Rozliczający)'] || 0
      },
      {
        data1: 'Księgowość',
        data2: roleCounts?.['Księgowość'] || 0
      }, {
        data1: 'Oglądający',
        data2: roleCounts?.['HCP (Uczestnik)'] || 0
      },
      {
        data1: 'Publikujący',
        data2: roleCounts?.['HCP (Publikujący)'] || 0
      },
      {
        data1: 'Suma',
        data2: this.filteredUsers?.filter(filterByYear(this.selectedYears))?.length
      }
    ];
  }

  get filteredCasesByPayStatus() {
    const filteredCases = this.statistics?.situations?.map(el => ({
      ...el,
      areas: el.area.fullName
    }))?.filter(filterByArea(this.selectedAreas))
      .filter(filterByReckoningType(this.selectedReckoningType));

    const statusCounts = filteredCases?.filter(el => el.isPay).filter(filterByYear(this.selectedYears, true))?.length;
    const statusCountsUnpaid = filteredCases?.filter(el => el.isPay === false).length;

    return [
      {
        data1: 'Opłacone',
        data2: statusCounts || 0
      },
      {
        data1: 'Nieopłacone',
        data2: statusCountsUnpaid || 0
      }];
  }

  get filteredCasesByAmount() {
    const filteredCases = this.statistics?.situations?.map(el => ({
      ...el,
      areas: el.area.fullName
    }))?.filter(filterByArea(this.selectedAreas))
      .filter(filterByYear(this.selectedYears, true))
      .filter(filterByReckoningType(this.selectedReckoningType));

    return filteredCases?.filter(el => el.isPay).reduce((acc, situation) => {
      return acc + (situation.amount || 0);
    }, 0);
  }

  get filteredCasesByMonth() {

    const filteredCases = this.statistics?.situations?.map(el => ({
      ...el,
      areas: el.area.fullName
    }))?.filter(filterByArea(this.selectedAreas))
      .filter(filterByYear(this.selectedYears));


    const monthlyData: MonthlyCases[] = shortMonthsArray.map(month => {
      const newObject: MonthlyCases = { month };

      this.preparedAreasForChart.forEach(keyObj => {
        newObject[keyObj.dataKey] = 0;
      });

      return newObject;
    });

    filteredCases?.forEach(caseItem => {
      const date = new Date(caseItem.createdAt);
      const monthIndex = date.getMonth();
      const area = caseItem.areas;

      if (!monthlyData[monthIndex][area]) {
        monthlyData[monthIndex][area] = 0;
      }

      monthlyData[monthIndex][area] = (monthlyData[monthIndex][area] as number) + 1;
    });

    return monthlyData;
  }

  get filteredUsersByRoleChart() {

    const filteredUsersForChart = this.filteredUsers?.filter(filterByYear(this.selectedYears)).filter(el => el.role === 'HCP (Uczestnik)' || el.role === 'HCP (Publikujący)');
    const monthlyData: MonthlyCases[] = shortMonthsArray.map(month => {
      const newObj: MonthlyCases = { month };

      this.preparedRolesForChart.forEach(keyObj => {
        newObj[keyObj.dataKey] = 0;
      });

      return newObj;
    });

    filteredUsersForChart?.forEach(user => {
      const date = user.createdAt && new Date(user.createdAt);

      if (date) {
        const monthIndex = date.getMonth();
        const role = user.role;

        if (!monthlyData[monthIndex][role]) {
          monthlyData[monthIndex][role] = 0;
        }
        monthlyData[monthIndex][role] = (monthlyData[monthIndex][role] as number) + 1;
      }

    });
    return monthlyData;
  }

  get preparedRolesForChart() {
    return Array.from(new Set(this.statistics?.users.filter(el => el.role === 'HCP (Uczestnik)' || el.role === 'HCP (Publikujący)').map(el => el.role))).map(el => ({
      label: el,
      dataKey: el
    }));
  }


  get amountOfViewsOfCasesChart() {
    const filteredCases = this.statistics?.situations?.map(el => ({
      ...el,
      areas: el.area.fullName
    }))?.filter(filterByArea(this.selectedAreas));

    // Inicjalizacja tablicy na 12 miesięcy, zainicjowanej zerami
    const monthlyViewsData = shortMonthsArray.map(month => ({
      month,
      data: 0
    }));

    filteredCases?.forEach(caseItem => {
      caseItem.views.filter(filterByYear(this.selectedYears)).forEach(view => {
        const date = new Date(view.createdAt);
        const monthIndex = date.getMonth();

        monthlyViewsData[monthIndex].data += 1;
      });
    });

    return monthlyViewsData.map(el => el.data);
  }
}


