import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { IAction, IHeadCell } from '../../../../../interfaces/common/table';
import theme from '../../../../../theme/theme';
import React, { useEffect, useState } from 'react';
import { useContractApi } from '../../../../../apiHooks';
import { useContractStore } from '../../../../../store/hooks';
import { StatusText, StatusTextOther } from '../../../../shared/admin/status/Status.styled';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { convertDate } from '../../../../shared/customTables/utils/convertDate';
import { EditIcon } from '../../../../shared/modal/modalIcons/EditIcon';
import { useModal } from '../../../../shared/modal/useModal.vm';
import { ModalType } from '../../../../../constants/modalType';
import { useContracts } from '../useContracts.vm';

export const useDocuments = () => {
  const contractStore = useContractStore();
  const { documents, isDocumentsLoaded } = contractStore;
  const { getDocuments } = useContractApi();
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    if (!isDocumentsLoaded) {
      if (documents === null) {
        void getDocuments();
      } else {
        void getDocuments(false);
      }
    }
  }, [documents, getDocuments, isDocumentsLoaded]);

  const {
    filteredContracts,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years
  } = useContracts(documents);

  useEffect(() => {
    return () => {
      contractStore.resetDocuments();
    };
  }, [contractStore]);

  const editDocument = (id: number | string) => {
    handleModal(ModalType.EDIT_MODAL);
    setSelectedItem(id as string);
  };

  const generateStatus = (value: string, type?: string) => {

    switch (value) {
      case 'PAY':
        return <StatusText width={'100%'} margin={'0'}
                           color={theme.colors.turquoise2}><span>opłacone</span></StatusText>;
      case 'WAIT':
        return <StatusTextOther width={'100%'} margin={'0'}
                                color={theme.colors.turquoise2}><span>{type === 'Faktura' ? 'do zapłaty' : 'do akceptacji'}</span></StatusTextOther>;
      case 'ACCEPT':
        return <StatusText width={'100%'} margin={'0'}
                           color={theme.colors.turquoise2}><span>zaakceptowane</span></StatusText>;
    }
  };

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: 'edit',
        actionHandler: editDocument,
        background: theme.colors.red,
        actionElement: <EditIcon />,
        width: 52
      }
    ];

    return actions;
  };

  const headCells: IHeadCell[] = [
    { id: 'originalName', numeric: false, label: 'Nazwa dokumentu' },
    { id: 'type', numeric: false, label: 'Rodzaj' },
    { id: 'fullName', numeric: false, label: 'Publikujący' },
    { id: 'datePay', numeric: false, label: 'Data zapłaty' },
    { id: 'areas', numeric: false, label: 'Obszar terapeutyczny' },
    { id: 'createdAt', numeric: false, label: '' },
    { id: 'formatedDate', numeric: false, label: '' },
    { id: 'status', numeric: false, label: 'Status' },
    { id: 'isVisible', numeric: false, label: '' }
  ];

  const tableDataRows = createTableRows(filteredContracts ?? [], headCells, false);

  const cellsSwitcher = (keyName: string, value: string, onClick?: () => void, id?: number, type?: string) => {
    const specialValue = keyName === 'datePay' && value !== '-' ? convertDate(value?.toString()) : '-';
    switch (keyName) {
      case 'id':
      case 'formatedDate':
      case 'createdAt':
      case 'isVisible':
        return null;
      case 'datePay':
        return <TableCellData>{specialValue}</TableCellData>;
      case 'status':
        return value ? <TableCellData sx={{ padding: 0 }}>{generateStatus(value, type)}</TableCellData> : null;
      default:
        return <TableCellData>{value?.toString() ?? ''}</TableCellData>;
    }
  };


  const openModalGenerateReport = () => {
    handleModal(ModalType.GENERATE_REPORT);
  };

  return {
    companyContractsIsNotNull: documents !== null,
    cellsSwitcher,
    generateActions,
    tableDataRows,
    headCells,
    openModalGenerateReport,
    isModalOpened,
    closeModal,
    selectedItem,
    modalType,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years
  };

};