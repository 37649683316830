import { observer } from 'mobx-react-lite';
import React from 'react';
import { UserLayout } from '../../../../shared/user/layout/UserLayout';
import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import { MuiPagination } from '../../../../shared/pagination/MuiPagination';
import { useMyAcceptedCases } from './useMyAcceptedCase.vm';
import { Modal } from '../../../../shared/modal/Modal';
import { MyReckoningNotAccepted } from '../modals/myReckoningNotAccepted/MyReckoningNotAccepted';
import { ModalType } from '../../../../../constants/modalType';
import { MyReckoningSelection } from '../modals/myReckoningSelection/MyReckoningSelection';
import { MyReckoningDocumentPlacement } from '../modals/myReconingDocumentPlacement/MyReckoningDocumentPlacement';
import { MyReckoningConfirmation } from '../modals/myReckoningConfirmation/MyReckoningConfirmation';
import { Header, MyReckoningContainer } from '../MyReckoning.styled';
import { Button } from '../../../../shared/button/Button';
import theme from '../../../../../theme/theme';
import { ToReckoningButtonContainer } from './MyAcceptedCases.styled';
import { Grid } from '@mui/material';
import { Select } from '../../../../shared/form/select/Select';

export const MyAcceptedCases = observer(() => {
  const {
    isSingleMode,
    reckoningAcceptedCasesIsNotNull,
    ids,
    confirmationHandler,
    documentPlacementHandler,
    isModalOpened,
    closeModal,
    modalType,
    pageCount,
    currentPage,
    setCurrentPage,
    breadcrumbs,
    actions,
    cases,
    headCells,
    cellsSwitcher,
    contractType,
    cleanReckoning,
    toReckoningButtonActive,
    reckoningHandler,
    selectOptions,
    selectedValue,
    changeAreaHandler
  } = useMyAcceptedCases();

  return (<UserLayout breadcrumbs={breadcrumbs}>
    <MyReckoningContainer>
      <Grid width={'100%'} display={'flex'} flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            gap={{ xs: 0, md: 2 }} mt={2}>
        <Header>Obszar terapeutyczny </Header>
        {selectOptions &&
          <Select
            width={'245px'}
            selectValue={selectedValue}
            isDefaultValue={false}
            elementName={'area-filter'}
            setValue={(id: number) => changeAreaHandler(id)}
            optionsArray={selectOptions} />
        }
      </Grid>
      {reckoningAcceptedCasesIsNotNull && <> <CustomTable actions={actions}
                                                          cellsSwitcher={cellsSwitcher}
                                                          tableDataRows={cases}
                                                          isDataDownloaded={true}
                                                          headCells={headCells} />
        <ToReckoningButtonContainer container>
          <Grid md={3} xs={0} item />
          <Grid md={5} xs={12} item display={'flex'} justifyContent={'center'}>
            <MuiPagination
              count={pageCount}
              page={currentPage}
              onChange={(e, value: number) => {
                setCurrentPage(value);
                cleanReckoning();
              }} />
          </Grid>
          {!isSingleMode &&
            <Grid md={4} mb={2} xs={12} item display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }}>
              <Button
                clickHandler={() => toReckoningButtonActive && reckoningHandler()}
                disabled={!toReckoningButtonActive}
                width={'230px'}
                size={'tiny'}
                backgroundColor={toReckoningButtonActive ? theme.colors.red : theme.colors.gray3}
                color={'white'}
                text={'Rozlicz zaznaczone'}></Button>
            </Grid>}

        </ToReckoningButtonContainer>
      </>}
      {isModalOpened &&
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.MY_RECKONING_NOT_ACCEPTED_MODAL &&
            <MyReckoningNotAccepted />
          }
          {modalType === ModalType.MY_RECKONING_SELECTION_MODAL &&
            <MyReckoningSelection documentPlacementHandler={documentPlacementHandler} closeModal={closeModal}
                                  type={contractType} />
          }
          {modalType === ModalType.MY_RECKONING_DOCUMENT_PLACEMENT_MODAL &&
            <MyReckoningDocumentPlacement confirmationHandler={confirmationHandler} ids={ids}
                                          selectedValue={selectedValue} cleanAfterReckoningHandler={cleanReckoning}
                                          type={contractType} />
          }
          {modalType === ModalType.MY_RECKONING_CONFIRMATION_MODAL &&
            <MyReckoningConfirmation closeModal={closeModal} type={contractType} />
          }
        </Modal>}
    </MyReckoningContainer>
  </UserLayout>);
});