import React, {FC} from "react";
import {IImage} from "../../../../interfaces/user/image";
import {Modal} from "../Modal";
import {ImageMain} from "./Lightbox.styled";


interface IProps {
  images: IImage[];
  closeModal: () => void;
  index: number
}

export const LightBox: FC<IProps> = ({images, closeModal, index}) => {
  return (
    <Modal lightbox={true} clickHandler={() => closeModal()}>
      <ImageMain src={images[index].link}/>
    </Modal>
  )
}
