import {useProgressBarNeuroStore} from "../../../../../../store/hooks";
import {stages} from "../../progressBar/ProgressBar.helper";
import theme from "../../../../../../theme/theme";


export const useStepper = () => {
  const progressBarStore = useProgressBarNeuroStore();
  const {currentStage} = progressBarStore;

  const stagesFilter = stages.filter((s) => s.isStepper);
  const stagesStepper = stagesFilter.map(stage => ({
    ...stage,
    status: stage.stage === currentStage ? 'active' : (stage.stage > currentStage ? 'prev' : 'next'),
    color: stage.stage === currentStage ? theme.colors.blue3 : (stage.stage > currentStage ? theme.colors.gray6 : theme.colors.turquoise2)
  }));

  const currentStepText = `${progressBarStore.currentStage > stagesFilter.length ? stagesFilter.length : progressBarStore.currentStage}/${stagesFilter.length}`;

  return {currentStepText, stagesFilter, stagesStepper}
}