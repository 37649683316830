import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { useDraftCaseNeuro } from '../draftCaseNeuro/useDraftCaseNeuro';
import { HeaderCase } from './headerCase/HeaderCase';
import { observer } from 'mobx-react-lite';
import { MiddlePart } from './middlePart/MiddlePart';
import { TreatmentHistory } from './treatmentHistory/TreatmentHistory';
import { Classification } from './classification/Classification';
import { useViewCase } from './useViewCase.vm';
import { Button } from '../../../shared/button/Button';
import pdfIcon from '../../../../assets/icon/pdfIcon.svg';
import theme from '../../../../theme/theme';
import { LightBox } from '../../../shared/modal/lightbox/Lightbox';
import { StyledButton, StyledLayout } from './ViewCase.styled';

export const ViewCaseNeuro = observer(() => {
  const { imageHandler, index, images, isModalOpened, closeModal } = useDraftCaseNeuro();
  const { breadcrumbs, caseInfo, casePdfHandler } = useViewCase();
  return (
    <StyledLayout>
      <UserLayout breadcrumbs={breadcrumbs}>
        {caseInfo &&
          <><HeaderCase casePdfHandler={casePdfHandler} />
            <MiddlePart imageHandler={imageHandler} />
            <TreatmentHistory imageHandler={imageHandler} />
            <Classification imageHandler={imageHandler} />
            {isModalOpened && <LightBox index={index} closeModal={closeModal} images={images} />}
            <StyledButton> <Button clickHandler={casePdfHandler} text={'pobierz jako pdf'} size={'small'}
                                   icon={pdfIcon}
                                   backgroundColor={theme.colors.red}
                                   color={theme.colors.beige} width={'206px'} /></StyledButton>
          </>}
      </UserLayout>
    </StyledLayout>
  );
});

