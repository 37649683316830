import {Card, ImageContainer, Img, Paragraph} from './ImageBox.styled'
import React from "react";
import {IImage} from "../../../../../../interfaces/user/image";
import {IImages} from "../../ViewCase.helper";

interface IProps extends IImages {
  images?: IImage[]
}

export const ImageBox: React.FC<IProps> = ({images, imageHandler}) => {
  return (
    images?.length !== 0 ? <ImageContainer>
      {images?.map((el, index) => <Card key={el.id}>
        <Img src={el.link} alt="lightbox-main" onClick={() => imageHandler && imageHandler(index, images)}/>
        <Paragraph>{el.originalName}</Paragraph>
      </Card>)}
    </ImageContainer> : <></>)
}