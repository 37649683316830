import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useProgressBarLungStore} from '../../../../store/hooks';
import {IBreadcrumbsElement} from '../../../../interfaces/user/breadcrumbs';
import {UserLink} from '../../../../utils';
import {useModal} from '../../../shared/modal/useModal.vm';
import {ModalType} from '../../../../constants/modalType';
import {IImage} from '../../../../interfaces/user/image';

export const useDraftCaseLung = (isReset?: boolean) => {
  const [afterReset, setAfterReset] = useState(false);
  const [index, setIndex] = useState(-1)
  const [images, setImages] = useState<IImage[]>([])
  const {isModalOpened, closeModal, handleModal} = useModal()

  const {id} = useParams();
  const progressBarStore = useProgressBarLungStore();

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.MY_CASES,
      name: "Moje opisy przypadków"
    },
    {
      name: "Dodawanie nowego opisu przypadku"
    }];

  useEffect(() => {
    if (isReset && !afterReset) {
      setAfterReset(true)
      progressBarStore.resetProgressBar();
      progressBarStore.setIsLoaded(true);
    }
  }, [id, isReset, progressBarStore, afterReset])

  const imageHandler = (index: number, images: IImage[]) => {
    handleModal(ModalType.LIGHTBOX)
    setIndex(index);
    setImages(images);
  }

  return {breadcrumbs, imageHandler, index, isModalOpened, closeModal, images, comment: progressBarStore.comment};
}