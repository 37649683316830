import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const ParagraphSixthStage = styled.p`
  color: ${theme.colors.dark1};
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  max-width: 565px;
  margin-top: 15px;
  margin-bottom: 46px;
  text-align: center;
`

export const StyledImage = styled.div<{ isDraft: boolean }>`
  background: ${props => props.isDraft ? theme.colors.blue3 : theme.colors.turquoise2};
  width: 94px;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  & svg {
    width: 49px;
    height: 45px;
  }
`

export const ContainerSixthStage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px auto auto auto;
  height: 100%;

`