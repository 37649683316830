import { useCallback } from 'react';
import { useAuthStore, useCasesStore } from '../store/hooks';
import { ICase, ICaseCard, ICases } from '../interfaces/admin/cases/cases';
import { useApiCall } from './useApiCall';
import { IAreaObject } from '../interfaces/user/case';
import { sortCasesByDate } from '../utils';
import { createEndpoints } from '../utils/createEndpoints';
import { RoleFunction } from '../constants/projectUsers';

export const useCasesApi = () => {
  const { api } = useApiCall();
  const casesStore = useCasesStore();
  const { areas, roleFunction } = useAuthStore();

  const getCases = useCallback(
    async (isLoading: boolean = true) => {

      const userAreas = areas ? areas.map(el => el.shortName) : [];
      const areaObject: IAreaObject = {
        lung: 'lung/situations',
        neuro: 'situations'
      };

      const createdEndpoints = createEndpoints(userAreas, areaObject);

      const areasEndpoints = createdEndpoints
        .map(el => api.apiInstance.get(areaObject[el as keyof IAreaObject], isLoading));

      await Promise.all(areasEndpoints).then(dataArray => {
        const allCases: ICase[] = [];
        dataArray && dataArray.length > 0 && dataArray.forEach((data, index) => {
          if (data) {
            const areaCases = data?.items.map((el: ICaseCard) => ({
              ...el,
              type: userAreas[index]
            }));

            allCases.push(...areaCases);
          }
        });
        const sortedCases = allCases && allCases.length > 0 ? allCases.sort((a: ICase, b: ICase) => {
          const c = new Date(a.sentToAcceptAt);
          const d = new Date(b.sentToAcceptAt);
          return d.getTime() - c.getTime();
        }) : [];
        casesStore.setCases(sortedCases);
      });

    },

    [api.apiInstance, casesStore, areas]
  );

  const acceptCase = useCallback(
    async (id: number, isPublic: boolean, type: string) => {

      const endpointUrl: IAreaObject = {
        lung: `lung/situation/${id}/accept`,
        neuro: `situation/${id}/accept`
      };

      if (endpointUrl[type as keyof IAreaObject]) {
        const res: ICases = await api.apiInstance.put(endpointUrl[type as keyof IAreaObject], { isPublic });

        casesStore.setCases(res.items);
        return !!res;
      }
    },
    [api.apiInstance, casesStore]
  );


  const rejectCase = useCallback(
    async (id: number, comment: string, type: string) => {

      const endpointUrl: IAreaObject = {
        lung: `lung/situation/${id}/reject`,
        neuro: `situation/${id}/reject`
      };
      if (endpointUrl[type as keyof IAreaObject]) {
        const res: ICases = await api.apiInstance.put(endpointUrl[type as keyof IAreaObject], { comment });
        casesStore.setCases(res.items);
        return !!res;
      }
    },
    [api.apiInstance, casesStore]
  );

  const getListOfMyCases = useCallback(
    async (isLoading: boolean = true) => {
      const userAreas = areas ? areas.map(el => el.shortName) : [];

      const areaObject: IAreaObject = {
        lung: 'lung/situations/my',
        neuro: 'situations/my'
      };

      const createdEndpoints = createEndpoints(userAreas, areaObject);

      const areasEndpoints = createdEndpoints
        .map(el => api.apiInstance.get(areaObject[el as keyof IAreaObject], isLoading));

      await Promise.all(areasEndpoints).then(dataArray => {
        const allCases: ICaseCard[] = [];
        dataArray.forEach((data, index) => {

          if (data) {
            const areaCases = data?.items.map((el: ICaseCard) => ({
              ...el,
              type: userAreas[index]
            }));
            allCases.push(...areaCases);
          }
        });
        const sortedCases = sortCasesByDate(allCases);
        casesStore.setListOfMyCases(sortedCases);
      });
    },
    [api.apiInstance, casesStore, areas]
  );

  const getListOfOtherCases = useCallback(
    async (isLoading: boolean = true, limit?: number) => {
      const isPublishingOrParticipant = roleFunction === RoleFunction.ROLE_PUBLISHING || roleFunction === RoleFunction.ROLE_PARTICIPANT;

      if (limit) {
        const res = await api.apiInstance.get(limit ? `situations/public?limit=${limit}` : 'situations/public', isLoading);

        if (res) {
          if (limit) {
            const allCases: ICaseCard[] = (res.items as any[]).map((data) => {
              return {
                ...data,
                type: data.area
              };
            });

            casesStore.setListOfLast6Cases(allCases);
          } else {
            casesStore.setListOfOtherCases(res.items);
          }
        }
      } else if (isPublishingOrParticipant) {
        const userAreas = areas ? areas.map(el => el.shortName) : [];

        const areaObject: IAreaObject = {
          lung: 'lung/situations/public',
          neuro: 'situations/public'
        };

        const createdEndpoints = createEndpoints(userAreas, areaObject);

        const areasEndpoints = createdEndpoints
          .map(el => api.apiInstance.get(areaObject[el as keyof IAreaObject], isLoading));

        await Promise.all(areasEndpoints).then(dataArray => {
          const allCases: ICaseCard[] = [];
          dataArray.forEach((data, index) => {
            if (data) {
              const areaCases = data?.items.map((el: ICaseCard) => ({
                ...el,
                type: userAreas[index]
              }));
              allCases.push(areaCases);
            }
          });
          const newAllCases = sortCasesByDate(allCases.flat());
          casesStore.setListOfOtherCases(newAllCases);
        });

      }
    },
    [api.apiInstance, casesStore, areas, roleFunction]
  );


  return {
    getCases,
    acceptCase,
    rejectCase,
    getListOfMyCases,
    getListOfOtherCases
  };
};