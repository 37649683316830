import React from 'react'
import { Grid } from "@mui/material";
import { RegisterInfo } from "../../registerInfo/RegisterInfo";
import { ChildrenContainer, Container, Image } from "./RegisterLayoutPublish.styled";
import { Footer } from "../../../../shared/user/footer/Footer";
import { NavigationMenu } from "../../../../shared/user/navigation/navigationMenu/NavigationMenu";

interface IProps {
  children: JSX.Element
}

export const RegisterLayoutPublish: React.FC<IProps> = ({children}) => {
  return (
    <Container>
      <Grid container>
        <NavigationMenu/>
        <Grid item xs={12} sm={6} order={{xs: 2, sm: 1}}
        ><Image><ChildrenContainer>{children}</ChildrenContainer></Image></Grid>
        <Grid item xs={12} sm={6} order={{xs: 1, sm: 2}} alignSelf={'center'} pt={{xs: '72px'}}><RegisterInfo/></Grid>
      </Grid>
      <Footer/>
    </Container>
  )
}
