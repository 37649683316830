import {useRootStore} from '../RootStateContext';

export const useStatisticsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.statisticsStore) {
    throw new Error('statistics store should be defined');
  }

  return rootStore.statisticsStore;
};
