import styled from "styled-components";
import theme from "../../../theme/theme";

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${theme.colors.dark1};
  padding-top: 8px;

  & a {
    color: ${theme.colors.dark1};
    font-weight: 700;
  }
`