import styled from 'styled-components';
import theme from '../../../../theme/theme';

export const FooterComponent = styled.footer`
  width: 100%;
  margin-top: auto;
  flex-grow: 2;
  line-height: 40px;
  background: rgba(135, 193, 243, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.darkBlue};
  padding: 0 33px;
  gap: 8px;

  & a {
    color: ${theme.colors.darkBlue};
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
  }

  & svg {
    height: auto !important;
    margin-top: 0 !important;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
    padding: 8px 16px;
    line-height: unset;
    gap: 0;

    div > button {
      margin-top: 16px;
    }
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${theme.breakpoints.small}px) {

  }
`;