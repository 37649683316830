import { IBreadcrumbsElement } from "../../../../../../interfaces/user/breadcrumbs";
import { UserLink } from "../../../../../../utils";
import { useFormik } from "formik";
import * as yup from "yup";
import { MouseEvent, useEffect, useState } from 'react'
import { useContractApi } from "../../../../../../apiHooks";
import { useContractStore } from "../../../../../../store/hooks";
import { useFileStateUpload } from "../../../../../shared/uploadFiles/fileUpload/useFileStateUpload";
import { useModal } from "../../../../../shared/modal/useModal.vm";
import { IContractData } from "../../../../../../interfaces/user/contract/contract";
import { validatePolish } from 'validate-polish';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  street: yup
    .string()
    .required(),
  zip: yup
    .string()
    .required()
    .matches(/^[0-9]{2}-[0-9]{3}/),
  city: yup
    .string()
    .required(),
  nip: yup
    .string()
    .matches(/^[0-9]{10}$/)
    .required(),
  regon: yup
    .string()
    .matches(/^[0-9]{9}$/)
    .required(),
  bank: yup
    .string()
    .matches(/[0-9]{26}/)
    .required(),
  fullName: yup
    .string()
    .required(),
  phone: yup
    .string()
    .matches(/^(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}$/u)
    .required()
});

export const useInvoiceFormVm = () => {
  const [isChecked, setChecked] = useState(false)
  const [isFileBoxOpen, setFileBoxOpen] = useState(false)
  const {getCompanyContractData, editContractData, getContractPdf, sendContractPdf} = useContractApi()
  const contractStore = useContractStore()
  const {contractData} = contractStore
  const {file, handleFileChange, handleRemoveFile} = useFileStateUpload();
  const {handleModal, isModalOpened, closeModal} = useModal();
  const setCheckbox = () => {
    setChecked(!isChecked)
  }

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.PROFILE,
      name: "Profil użytkownika"
    },
    {
      link: UserLink.PROFILE_SELECT_FORM,
      name: "Dane rozliczeniowe"
    },
    {
      name: "Forma rozliczenia faktura"
    }
  ];


  const formik = useFormik<IContractData>({
    enableReinitialize: true,
    initialValues: {
      title: '',
      street: '',
      zip: '',
      city: '',
      nip: '',
      regon: '',
      bank: '',
      fullName: '',
      phone: ''
    },
    validate: (values) => {
      const errors: Partial<IContractData> = {};
      if (values.nip && !validatePolish.nip(values.nip)) {
        errors.nip = 'Nieprawidłowy NIP';
      }
      if (values.regon && !validatePolish.regon(values.regon)) {
        errors.regon = 'Nieprawidłowy REGON';
      }
      return errors;
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!isErrorCheckbox) {
        await editContractData(values)
        setFileBoxOpen(true)
      }
    }
  })

  useEffect(() => {
    void getCompanyContractData()
  }, [getCompanyContractData])

  const {setFieldValue} = formik

  useEffect(() => {
    if (contractData) {
      setFieldValue('title', contractData.title)
      setFieldValue('street', contractData.street)
      setFieldValue('zip', contractData.zip)
      setFieldValue('city', contractData.city)
      setFieldValue('nip', contractData.nip)
      setFieldValue('regon', contractData.regon)
      setFieldValue('bank', contractData.bank)
      setFieldValue('fullName', contractData.fullName)
      setFieldValue('phone', contractData.phone)
    }
  }, [contractData, setFieldValue])

  const isErrorCheckbox = formik.submitCount > 0 && !isChecked
  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  const getPdf = async () => {
    const res = await getContractPdf()
    const blob = new Blob([res], {type: 'application/pdf'});
    const url = URL.createObjectURL(blob);
    window.open(url);
  }

  const sendFile = async () => {
    if (file[0]) {
      const res = await sendContractPdf(file[0])
      if (res) {
        handleModal()
      }
    }
  }

  return {
    breadcrumbs,
    formik,
    isChecked,
    setCheckbox,
    confirmForm,
    isErrorCheckbox,
    isFileBoxOpen,
    getPdf,
    file,
    handleFileChange,
    handleRemoveFile,
    sendFile,
    isModalOpened,
    closeModal
  }
}