import { StoreIDs } from '../../../../../config/storeIds';
import {AreaKey} from '../../../../../interfaces/admin/cases/cases';

export const mapKeyToId = (key: AreaKey) => {

  switch(key){
    case 'lung': return StoreIDs.AREA_LUNG; 
    case 'neuro': return StoreIDs.AREA_NEURO; 
  }

}

export const mapIdToKey = (id: number) => {
  switch(id){
    case StoreIDs.AREA_LUNG: return 'lung';
    case StoreIDs.AREA_NEURO: return 'neuro';
  }
  return 'lung';
}