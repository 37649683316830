import {ReactComponent as CheckboxCheck} from '../../../../../../assets/icon/checkboxCheck.svg';
import {FC} from "react";
import {ConnectorContainer} from "./Connector.styled";

interface IProps {
  color: string;
}

export const Connector: FC<IProps> = ({color}) => {
  return <ConnectorContainer color={color}><CheckboxCheck/></ConnectorContainer>
}
