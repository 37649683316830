import {Connector} from "../connector/Connector";
import {observer} from "mobx-react-lite";
import {useStepper} from "./useStepper";
import {CurrentStep, PathStep, PathStepContainer, Step, StepLabel, StepperContainer} from "./Stepper.styled";

export const StepperCustom = observer(() => {
  const {currentStepText, stagesFilter, stagesStepper} = useStepper()
  return (
    <StepperContainer>
      <CurrentStep>{currentStepText}</CurrentStep>
      {stagesStepper.map((stage, index) => (
        <PathStepContainer key={stage.stage}>
          <Step>
            <Connector color={stage.color}/>
            <StepLabel color={stage.color}>{stage.name}</StepLabel>
          </Step>
          {index !== (stagesFilter.length - 1) && <PathStep color={stage.color}/>}
        </PathStepContainer>
      ))}
    </StepperContainer>
  )
});
