import { observer } from 'mobx-react-lite';
import { Grid } from '@mui/material';
import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import { MuiPagination } from '../../../../shared/pagination/MuiPagination';
import { useCasesVm } from './useCases.vm';


export const StaticticCases = observer(() => {
  const {
    cellsSwitcher,
    headCells,
    tableDataRows,
    isStatisticsTableLoaded,
    casesPageCount,
    currentCasesPage,
    setCasesCurrentPage,
    sortOrderByHandler,
    sortOrderHandler
  } = useCasesVm();
  return (
    <Grid justifyContent={'space-between'} display={'flex'} flexDirection={'column'} height={'100%'}>
      <CustomTable
        sortOrderByHandler={sortOrderByHandler} sortOrderHandler={sortOrderHandler}
        cellsSwitcher={cellsSwitcher}
        customTableConfig={{ rowsPerPage: 20 }}
        tableDataRows={tableDataRows}
        isDataDownloaded={isStatisticsTableLoaded}
        headCells={headCells}
      />
      {isStatisticsTableLoaded &&
        <MuiPagination
          style={{ alignSelf: 'center', marginBottom: '10px', marginTop: '16px' }}
          count={casesPageCount}
          page={currentCasesPage}
          onChange={(e, value: number) => {
            setCasesCurrentPage(value);
          }} />
      }
    </Grid>
  );
});