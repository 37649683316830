import { IArea } from '../interfaces/admin/areas/areas';
import { IAreas } from '../interfaces/admin/user/userLoginResponse';

export const generateOptionsArea = (isOwnArea: boolean, areas: IArea[] | null, areasUser: IAreas[] | null) => {
  if (areas && areasUser) {
    if (isOwnArea) {
      return areasUser.length > 1 ? areas.filter(area => areasUser.some(areaUser => areaUser.id === area.id)).map(el => ({
        id: el.id,
        title: el.fullName
      })) : null;
    }
    return areas.map(el => ({ id: el.id, title: el.fullName }));
  }

  return null;
};