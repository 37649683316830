import { Modal } from "../../../../shared/modal/Modal";

import React, { FC } from "react";
import {
  ConfirmationTextModal,
  TextDocumentContainer
} from "../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled";
import { ContentModal } from "../../../../shared/modal/ContentModal";
import { observer } from "mobx-react-lite";

interface IProps {
  closeModal: () => void;
  handleNo: () => void;
  handleYes: () => void;
  text: string,
}

export const AcceptDocumentModal: FC<IProps> = observer(({
                                                           closeModal,
                                                           handleYes, handleNo,
                                                           text
                                                         }) => {


  return (<Modal clickHandler={() => closeModal()} minHeight={'250px'}>
    <ContentModal
      width={'250px'}
      text={<ConfirmationTextModal>
        <TextDocumentContainer>
          <p>Czy jesteś pewny, że chcesz <b>zaakceptować</b> {text} tego użytkownika?</p>
        </TextDocumentContainer>
      </ConfirmationTextModal>}
      handleYes={() => handleYes()}
      handleNo={() => handleNo()}
    />
  </Modal>)
})