import { UserLayout } from "../../../../../shared/user/layout/UserLayout";
import {
  ConfirmationTextModal,
  ContractConfirmationContainer,
  ContractTitle,
  FieldSetParagraph,
  FlexContainer,
  Header,
  Paragraph,
  Subheader
} from './ContractForm.styled'
import React from "react";
import { useContractFormVm } from "./useContractForm.vm";
import { Grid } from "@mui/material";
import theme from "../../../../../../theme/theme";
import { Input } from "../../../../../shared/form/input/Input";
import { Fieldset } from "../../../../../shared/form/fieldset/FieldsetContainer";
import { DateInput } from "../../../../../shared/form/date/Date";
import { Checkbox } from "../../../../../shared/form/checkbox/Checkbox";
import { ButtonConfirm } from "../shared/ButtonConfirm";
import { observer } from "mobx-react-lite";
import { Modal } from "../../../../../shared/modal/Modal";
import { ContentModal } from "../../../../../shared/modal/ContentModal";
import { MoneyIcon } from "../../../../../shared/modal/modalIcons/MoneyIcon";
import { Button } from "../../../../../shared/button/Button";
import { generateLink, UserLink } from "../../../../../../utils";
import { RoleCategory } from "../../../../../../constants/projectUsers";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RedPdf } from "../../../../../../assets/icon/redPdf.svg";
import { ReactComponent as Download } from "../../../../../../assets/icon/greenDownload.svg";

export const ContractForm = observer(() => {
  const {breadcrumbs, formik, confirmForm, handleCheckboxChange, isModalOpened, closeModal} = useContractFormVm()
  const navigate = useNavigate()
  return (
    <UserLayout breadcrumbs={breadcrumbs}>
      <Header>Uzupełnij dane do umowy o dzieło</Header>
      <Subheader>Przekazywane dane są wykorzystywane tylko i wyłącznie na potrzeby rozliczeń przypadków w serwisie
        RPM</Subheader>
      <Grid container margin={'auto'}>
        <Grid container justifyContent={'space-between'} maxWidth={'1280px'} margin={'0 auto'}>
          <Grid item xs={12}>
            <Fieldset color={theme.colors.turquoise2} label={'DANE OSOBOWE'} size={'medium'}>
              <Grid container display={'flex'}>
                <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}}>
                  <Input
                    isRequired={true}
                    label={'Nazwisko i imiona'}
                    name={"fullName"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz nazwisko i imiona..."}
                    isError={formik.touched.fullName && Boolean(formik.errors.fullName)}
                  />
                  <Input isRequired={true}
                         label={'Nazwisko rodowe'}
                         name={"familyName"}
                         type={"text"}
                         color={theme.colors.dark1}
                         value={formik.values.familyName}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         placeholder={"Wpisz nazwisko rodowe..."}
                         isError={formik.touched.familyName && Boolean(formik.errors.familyName)}
                  />
                  <DateInput
                    openTo={'year'}
                    inputFormat={'dd.mm.yyyy'}
                    views={['year', 'month', 'day']}
                    isRequired={true}
                    maxWidth={'100%'}
                    label={'Data urodzenia'}
                    name={"dateOfBirth"}
                    color={theme.colors.dark1}
                    value={formik.values.dateOfBirth}
                    onChange={(e) => formik.setFieldValue('dateOfBirth', e)}
                    onBlur={() => formik.setFieldTouched('dateOfBirth', true)}
                    isError={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}}>
                  <Input
                    isRequired={true}
                    label={'Imię matki'}
                    name={"motherFirstName"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.motherFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz imię matki..."}
                    isError={formik.touched.motherFirstName && Boolean(formik.errors.motherFirstName)}
                  />
                  <Input
                    isRequired={true}
                    label={'Imię ojca'}
                    name={"fatherFirstName"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.fatherFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz imię ojca..."}
                    isError={formik.touched.fatherFirstName && Boolean(formik.errors.fatherFirstName)}
                  />
                  <Input
                    isRequired={true}
                    label={'Obywatelstwo'}
                    name={"citizenship"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.citizenship}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz obywatelstwo..."}
                    isError={formik.touched.citizenship && Boolean(formik.errors.citizenship)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    isRequired={true}
                    label={'Numer ewidencyjny PESEL'}
                    name={"pesel"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.pesel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz numer PESEL..."}
                    isError={formik.touched.pesel && Boolean(formik.errors.pesel)}
                  />
                  <Input
                    isRequired={true}
                    label={'Numer rachunku bankowego'}
                    name={"bank"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.bank}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz numer rachunku..."}
                    isError={formik.touched.bank && Boolean(formik.errors.bank)}
                  />
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Fieldset color={theme.colors.turquoise2} label={'ADRES ZAMELDOWANIA'} size={'medium'}>
              <>
                <FieldSetParagraph>Adres, który zostanie ujety na rocznej deklaracji podatkowej PIT</FieldSetParagraph>
                <Grid container display={'flex'}>
                  <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}}>
                    <Input isRequired={true}
                           label={'Ulica, Nr domu, Nr lokalu'}
                           name={"checkinAddressStreet"}
                           type={"text"}
                           color={theme.colors.dark1}
                           value={formik.values.checkinAddressStreet}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           placeholder={"Wpisz ulicę, nr domu, nr lokalu..."}
                           isError={formik.touched.checkinAddressStreet && Boolean(formik.errors.checkinAddressStreet)}
                    />
                    <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                      <Grid item xs={12} sm={4.5}>
                        <Input isRequired={true}
                               label={'Kod pocztowy'}
                               name={"checkinAddressZip"}
                               type={"text"}
                               color={theme.colors.dark1}
                               value={formik.values.checkinAddressZip}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               placeholder={"Wpisz kod pocztowy..."}
                               isError={formik.touched.checkinAddressZip && Boolean(formik.errors.checkinAddressZip)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Input isRequired={true}
                               label={'Miejscowość'}
                               name={"checkinAddressCity"}
                               type={"text"}
                               color={theme.colors.dark1}
                               value={formik.values.checkinAddressCity}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               placeholder={"Wpisz miejscowość..."}
                               isError={formik.touched.checkinAddressCity && Boolean(formik.errors.checkinAddressCity)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}} flexDirection={'column'} display={'flex'}
                        justifyContent={'space-between'}>
                    <Input isRequired={true}
                           label={'Gmina'}
                           name={"checkinAddressCommunity"}
                           type={"text"}
                           color={theme.colors.dark1}
                           value={formik.values.checkinAddressCommunity}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           placeholder={"Wpisz gminę..."}
                           isError={formik.touched.checkinAddressCommunity && Boolean(formik.errors.checkinAddressCommunity)}
                    />
                    <Input isRequired={true}
                           label={'Powiat'}
                           name={"checkinAddressCounty"}
                           type={"text"}
                           color={theme.colors.dark1}
                           value={formik.values.checkinAddressCounty}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           placeholder={"Wpisz powiat..."}
                           isError={formik.touched.checkinAddressCounty && Boolean(formik.errors.checkinAddressCounty)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Input
                      isRequired={true}
                      label={'Województwo'}
                      name={"checkinAddressVoivodeship"}
                      type={"text"}
                      color={theme.colors.dark1}
                      value={formik.values.checkinAddressVoivodeship}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Wpisz województwo..."}
                      isError={formik.touched.checkinAddressVoivodeship && Boolean(formik.errors.checkinAddressVoivodeship)}
                    />
                  </Grid>
                  <Checkbox
                    id={'isNotEqualAddresses'}
                    name={'isNotEqualAddresses'}
                    textColor={theme.colors.dark1}
                    label={'Dane dotyczące adresu zameldowania są takie same jak adres korespondencyjny.'}
                    checked={formik.values.isNotEqualAddresses}
                    onChange={handleCheckboxChange}
                  />
                </Grid>
              </>
            </Fieldset>
          </Grid>
          {!formik.values.isNotEqualAddresses && <Grid item xs={12} mt={2}>
            <Fieldset color={theme.colors.turquoise2} label={'ADRES KORESPONDENCYJNY:'} size={'medium'}>
              <Grid container display={'flex'}>
                <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}}>
                  <Input isRequired={true}
                         label={'Ulica, Nr domu, Nr lokalu'}
                         name={"mailingAddressStreet"}
                         type={"text"}
                         color={theme.colors.dark1}
                         value={formik.values.mailingAddressStreet}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         placeholder={"Wpisz ulicę, nr domu, nr lokalu..."}
                         isError={formik.touched.mailingAddressStreet && Boolean(formik.errors.mailingAddressStreet)}
                  />
                  <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Grid item xs={12} sm={4.5}>
                      <Input isRequired={true}
                             label={'Kod pocztowy'}
                             name={"mailingAddressZip"}
                             type={"text"}
                             color={theme.colors.dark1}
                             value={formik.values.mailingAddressZip}
                             onChange={formik.handleChange}
                             onBlur={formik.handleBlur}
                             placeholder={"Wpisz kod pocztowy..."}
                             isError={formik.touched.mailingAddressZip && Boolean(formik.errors.mailingAddressZip)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Input isRequired={true}
                             label={'Miejscowość'}
                             name={"mailingAddressCity"}
                             type={"text"}
                             color={theme.colors.dark1}
                             value={formik.values.mailingAddressCity}
                             onChange={formik.handleChange}
                             onBlur={formik.handleBlur}
                             placeholder={"Wpisz miejscowość..."}
                             isError={formik.touched.mailingAddressCity && Boolean(formik.errors.mailingAddressCity)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}} flexDirection={'column'} display={'flex'}
                      justifyContent={'space-between'}>
                  <Input isRequired={true}
                         label={'Gmina'}
                         name={"mailingAddressCommunity"}
                         type={"text"}
                         color={theme.colors.dark1}
                         value={formik.values.mailingAddressCommunity}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         placeholder={"Wpisz gminę..."}
                         isError={formik.touched.mailingAddressCommunity && Boolean(formik.errors.mailingAddressCommunity)}
                  />
                  <Input isRequired={true}
                         label={'Powiat'}
                         name={"mailingAddressCounty"}
                         type={"text"}
                         color={theme.colors.dark1}
                         value={formik.values.mailingAddressCounty}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         placeholder={"Wpisz powiat..."}
                         isError={formik.touched.mailingAddressCounty && Boolean(formik.errors.mailingAddressCounty)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input isRequired={true}
                         label={'Województwo'}
                         name={"mailingAddressVoivodeship"}
                         type={"text"}
                         color={theme.colors.dark1}
                         value={formik.values.mailingAddressVoivodeship}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         placeholder={"Wpisz województwo..."}
                         isError={formik.touched.mailingAddressVoivodeship && Boolean(formik.errors.mailingAddressVoivodeship)}
                  />
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>}
          <Grid item xs={12} mt={2}>
            <Fieldset color={theme.colors.turquoise2} label={'DANE URZĘDOWE'} size={'medium'}>
              <Grid container display={'flex'} alignItems={'flex-end'}>
                <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}}>
                  <Input
                    isRequired={true}
                    label={'Nazwa urzędu skarbowego'}
                    name={"officeTitle"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.officeTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz nazwę urzędu skarbowego..."}
                    isError={formik.touched.officeTitle && Boolean(formik.errors.officeTitle)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}}>
                  <Input
                    isRequired={true}
                    label={'Ulica, Nr domu, Nr lokalu'}
                    name={"street"}
                    type={"text"}
                    color={theme.colors.dark1}
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Wpisz ulicę, nr domu, nr lokalu urzędu skarbowego..."}
                    isError={formik.touched.street && Boolean(formik.errors.street)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} pr={{xs: 0, sm: 2}}>
                  <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Grid item xs={12} sm={4.5}>
                      <Input
                        isRequired={true}
                        label={'Kod pocztowy'}
                        name={"zip"}
                        type={"text"}
                        color={theme.colors.dark1}
                        value={formik.values.zip}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={"Wpisz kod pocztowy..."}
                        isError={formik.touched.zip && Boolean(formik.errors.zip)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Input
                        isRequired={true}
                        label={'Miejscowość'}
                        name={"city"}
                        type={"text"}
                        color={theme.colors.dark1}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={"Wpisz miejscowość..."}
                        isError={formik.touched.city && Boolean(formik.errors.city)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
          <Paragraph>Na podstawie tych danych będą wystawiane umowy o dzieło w formie PDF. Pamiętaj, że każdą
            umowę trzeba podpisać i odesłać z powrotem poprzez serwis RPM. Na podstawie każdej umowy o dzieło
            w ciągu 30 dni zostanie wypłacone wynagrodzenie.</Paragraph>
          <Grid mt={2} mb={1} item xs={12} display={'flex'} justifyContent={'space-between'}
                alignItems={'space-between'}>
            <ContractTitle href={require('./../../../../../../files/UoDz.pdf')} target={'_blank'}
                           rel="noreferrer"><span><RedPdf/>Umowa-wzór.pdf</span> <Download/></ContractTitle>

          </Grid>
        </Grid>
        <ButtonConfirm clickHandler={confirmForm} text={'zapisz'}/>
        {isModalOpened &&
          <Modal clickHandler={() => closeModal()}>
            <ContentModal
              scrolled={true}
              iconType={<MoneyIcon background={theme.colors.turquoise2}/>}
              text={<ConfirmationTextModal>
                <ContractConfirmationContainer>
                  <p><b>Gratulacje!</b></p>
                  <p>Gratulacje! Na podstawie uzupełnionych danych, od tej pory umowy o dzieło będą uzupełniać się
                    automatycznie podczas rozliczania każdego opisu przypadku. Pamiętaj, aby każdą umowę podpisać
                    i odesłać z powrotem poprzez serwis RPM. Jeśli masz już zaakceptowane opisy przypadków,
                    przejdź do rozliczeń (ROZLICZ). </p>
                  <FlexContainer>
                    <Button text={"Twoje dane rozliczeniowe"} width={'244px'}
                            backgroundColor={theme.colors.dark1}
                            size={'tiny'}
                            color={theme.colors.beige}
                            clickHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.PROFILE_FORM_CONTRACT_SUMMARY))}/>
                    <Button text={"Rozlicz"} width={'244px'}
                            backgroundColor={theme.colors.red}
                            size={'tiny'}
                            color={theme.colors.beige}
                            clickHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.MY_RECKONING))}/>
                  </FlexContainer>
                </ContractConfirmationContainer>
              </ConfirmationTextModal>}
            />
          </Modal>
        }
      </Grid>
    </UserLayout>
  )
})