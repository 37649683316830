import { useAuthStore, useDraftCaseStore, useProgressBarLungStore } from '../../../../../../store/hooks';
import { AddCaseActionStatus } from '../../../../../../constants/addCaseStatus';

export const useSixthStage = () => {
  const progressBarStore = useProgressBarLungStore();
  const { areas } = useAuthStore();
  const draftCaseStore = useDraftCaseStore();
  const { abbreviationLung } = draftCaseStore;
  const isDraft = progressBarStore.typeOfCase === 'draft';
  const areaStatus = areas && areas?.find(el => el.shortName === abbreviationLung)?.addCaseActionStatus;

  const isBlocked = areaStatus === AddCaseActionStatus.CLOSE_TO_BLOCKED;

  return { isDraft, isBlocked };
};