import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Select as MultiSelect, MenuItem } from '@mui/material';

import { useUserForm } from './useUserForm';
import { IUserForm } from '../../../../interfaces/admin/user/userForm';
import { FormButtons } from '../../form/formButton/FormButtons';
import { Select } from '../../form/select/Select';
import { formData } from '../../../pages/admin/addUser/addUserData';
import { Input } from '../../form/input/Input';
import { ErrorMessage } from '../../form/errorMessage/ErrorMesage';
import { Container } from './UserForm.styled';
import { FormControlMultiSelect, Label, RedSpan } from '../../form/select/Select.styled';
import theme from '../../../../theme/theme';

interface IProps {
  isEdit?: boolean;
  link: string;
  userForm?: IUserForm | null;
  actionHandler: (userForm: IUserForm) => Promise<string | null>;
  handleNo?: () => void;
}

export const UserForm: FC<IProps> = observer(({ isEdit, link, userForm, actionHandler, handleNo }) => {

  const {
    formik,
    selectRolesOptions,
    selectAreasOptions,
    isButtonActive,
    confirmForm,
    message,
    selectRolesOption,
    selectAreasOption,
    showAreasWhenPusblishingOrViewingUser,
    showKeeperWhenLungArea,
    showSituationsLimitUsers
  } = useUserForm(actionHandler, userForm);

  return (
    <Container>
      <Select defaultValue={'Wybierz rolę'} isRequired label={'Rola'} selectValue={+formik.values.roleId}
              elementName={'role'}
              setValue={selectRolesOption}
              optionsArray={selectRolesOptions} isError={formik.touched.roleId && Boolean(formik.errors.roleId)} />

      {showAreasWhenPusblishingOrViewingUser && <FormControlMultiSelect>
        <Label>
          Obszar terapeutyczny<RedSpan>*</RedSpan>
        </Label>
        <MultiSelect
          multiple
          value={formik.values.areaIds}
          placeholder='kks'
          error={formik.touched.areaIds && Boolean(formik.errors.areaIds)}
          onChange={(e) => selectAreasOption(String(e.target.value))}
        >
          {selectAreasOptions.map((element) => (
            <MenuItem
              key={element.id}
              value={element.id}
            >
              {element.title}
            </MenuItem>
          ))}
        </MultiSelect>
      </FormControlMultiSelect>}

      {showKeeperWhenLungArea && <Input color={theme.colors.dark1}
                                        isRequired={true}
                                        isUpperCase={true}
                                        label='Email opiekuna'
                                        name='keeper'
                                        type='text'
                                        value={formik.values.keeper ?? ''}
                                        onChange={formik.handleChange}
                                        placeholder='Wpisz email opiekuna'
                                        isError={formik.touched.keeper && Boolean(formik.errors.keeper)} />}
      {formData.map(el =>
        <Input color={theme.colors.dark1}
               isRequired={el.isRequired}
               key={el.name}
               label={el.label}
               name={el.name}
               type={el.type}
               value={formik.values[el.name] ?? ''}
               onChange={formik.handleChange}
               placeholder={el.placeholder}
               isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
        />
      )}
      {showSituationsLimitUsers && <Input color={theme.colors.dark1}
                                          isRequired={false}
                                          key='situationsLimit'
                                          label='LIMIT OPISÓW PRZYPADKÓW'
                                          name='situationsLimit'
                                          type='number'
                                          value={formik.values.situationsLimit ?? ''}
                                          onChange={formik.handleChange}
                                          placeholder='Wpisz...'
                                          isError={formik.touched.situationsLimit && Boolean(formik.errors.situationsLimit)}
      />}
      {formik.submitCount > 0 &&
        <ErrorMessage errors={formik.errors} errorServer={false} message={message} />
      }
      <FormButtons isButtonActive={isButtonActive} confirmForm={confirmForm}
                   link={link} onClickCancel={handleNo}
                   buttonText={isEdit ? 'zapisz' : 'zapisz i dodaj'} />
    </Container>
  );
});
