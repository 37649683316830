import { useHomeApi } from '../../../../../apiHooks';
import { useEffect, useState } from 'react';
import {
  useAuthStore, useDraftCaseStore,
  useHomeStore,
  useProgressBarLungStore,
  useProgressBarNeuroStore
} from '../../../../../store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateAreaLink, generateLink, UserLink } from '../../../../../utils';
import { RoleCategory } from '../../../../../constants/projectUsers';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import { useModal } from '../../../../shared/modal/useModal.vm';
import { AddCaseActionStatus } from '../../../../../constants/addCaseStatus';
import { getPathType } from '../../../../../utils/switchArea';

export const usePublishUserHome = () => {
  const navigate = useNavigate();
  const { handleModal, isModalOpened, closeModal } = useModal();
  const { getListOfLastCases } = useHomeApi();
  const homeStore = useHomeStore();
  const progressBarNeuroStore = useProgressBarNeuroStore();
  const progressBarLungStore = useProgressBarLungStore();
  const { listOfLastCases, isListOfLastCasesLoaded } = homeStore;
  const breakpoint = useBreakpoint();
  const authStore = useAuthStore();
  const { fullName, affiliation, areas } = authStore;
  const [selectValue, setSelectedValue] = useState(-1);
  const [isError, setError] = useState(false);
  const location = useLocation();
  const type = getPathType(location.pathname);
  const draftCaseStore = useDraftCaseStore();
  ;
  const addCase = () => {
    if (areas)
      if (areas.length === 1) {
        const [mainArea] = areas;
        const { shortName } = mainArea;
        const newLink = generateAreaLink(shortName, 'add');
        if (newLink) {
          navigate(generateLink(RoleCategory.USER, newLink));
          if (type === 'lung') {
            progressBarLungStore.resetProgressBar();
          } else if (type === 'neuro') {
            progressBarNeuroStore.resetProgressBar();
          }
        }
      } else {
        handleModal();
      }
  };

  const selectedArea = areas?.find(el => el.id === selectValue)?.shortName;
  const selectedAreaStatus = areas?.find(el => el.id === selectValue)?.addCaseActionStatus;

  const addCaseFurther = () => {
    if (areas) {
      if (selectedArea) {
        const newLink = generateAreaLink(selectedArea, 'add');
        if (newLink) {
          navigate(generateLink(RoleCategory.USER, newLink));
          if (type === 'lung') {
            progressBarLungStore.resetProgressBar();
          } else if (type === 'neuro') {
            progressBarNeuroStore.resetProgressBar();
          }
          draftCaseStore.resetDraftCaseLung();
          draftCaseStore.resetDraftCaseNeuro();

          closeModal();
        }
      }
    }
  };

  useEffect(() => {
    if (selectedAreaStatus === AddCaseActionStatus.BLOCKED) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectValue, selectedAreaStatus]);

  const showMyReckoning = () => {
    navigate(generateLink(RoleCategory.USER, UserLink.MY_RECKONING));
  };

  useEffect(() => {
    if (!isListOfLastCasesLoaded) {
      if (listOfLastCases === null) {
        void getListOfLastCases();
      } else {
        void getListOfLastCases(false);
      }
    }
  }, [listOfLastCases, getListOfLastCases, isListOfLastCasesLoaded]);

  useEffect(() => {
    return () => {
      homeStore.resetListOfLastCases();
    };
  }, [homeStore]);

  const selectOptions = areas && areas.map(el => ({
    id: el.id,
    name: el.fullName
  }));

  const isNeuroAffiliation = areas?.length === 1 && areas[0].fullName === 'Neurologia';

  return {
    showMyReckoning,
    handleModal,
    selectOptions,
    isModalOpened,
    addCaseFurther,
    closeModal,
    addCase,
    selectValue, setSelectedValue,
    listOfLastCases,
    fullName,
    affiliation,
    breakpoint,
    isError,
    isNeuroAffiliation
  };
};