import styled from 'styled-components';
import theme from '../../../../theme/theme';

export const Item = styled.div<{ $isError?: boolean, $isUppercase?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  font-size: 20px;

  & input {
    margin-top: 0;
    cursor: pointer;
    font-size: revert;
    height: 24px;
  }

  input[type='radio']:after {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 3px;
    left: -1px;
    position: relative;
    background-color: ${theme.colors.beige};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid ${props => props.$isError ? theme.colors.red : theme.colors.blue2};
  }

  input[type='radio']:checked:after {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 3px;
    left: -1px;
    position: relative;
    background-color: ${theme.colors.dark1};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid ${theme.colors.dark1};
  }

  & label {
    color: ${props => props.$isError ? theme.colors.red : theme.colors.dark1};
    font-size: 12px;
    text-transform: ${props => props.$isUppercase ? 'uppercase' : 'capitalize'};
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-right: 16px;
    cursor: pointer;
    padding-left: 9px;
  }
`;