import { Type } from '../../../../interfaces/common/form/formData';
import styled from 'styled-components';
import theme from '../../../../theme/theme';

interface IInputContainerProps {
  isRequired?: boolean;
  type: Type;
  width?: string;
  isUpperCase?: boolean;
}

interface IInputElement {
  isError: boolean;
  width?: string;
  maxWidth?: string;
  height?: string;
  margin?: string;
}

export const InputContainer = styled.div<IInputContainerProps>`
  width: ${props => props.width ?? '100%'};

  p {
    padding-left: 10px;
    color: #D32F2F;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    margin-top: -14px;
    margin-bottom: 2px;
  }
`;
export const InputElement = styled.input<IInputElement>`
  color: ${theme.colors.dark1};
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '2.25rem'};
  border: 2px solid ${theme.colors.gray5};
  border-radius: 28px;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 22px;
  border: ${props => props.isError && '2px solid #DF1414 '};
  ${props => props.maxWidth && `max-width: ${props.maxWidth}`};
  -moz-appearance: textfield;
  margin: ${props => props.margin ? props.margin : '0 0 16px 0'};

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextareaElement = styled.textarea<IInputElement>`
  height: ${props => props.height ?? '10rem'};
  border: 2px solid ${theme.colors.gray5};
  width: ${props => props.width ?? 'unset'};
  border-radius: 28px;
  font-size: 16px;
  padding: 8px 22px;
  line-height: 22px;
  resize: none;
  ${props => props.maxWidth && `max-width: ${props.maxWidth}`};
  border: ${props => props.isError && '2px solid #DF1414 '};
`;

export const PasswordIcon = styled.img`
  width: 22px;
  position: absolute;
  right: 20px;
  bottom: 25px;
`;
