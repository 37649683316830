import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const StyledContainer = styled.div`
  max-width: 1012px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 0 16px;
  }
`

export const Title = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  margin-bottom: 21px;

  color: ${theme.colors.turquoise2};
`

export const SectionTitle = styled.h1`
  font-weight: 800;
  font-size: 36px;
  margin-top: 48px;
  line-height: 120%;
  text-transform: uppercase;
  color: ${theme.colors.dark1};
  margin-bottom: 32px;
  @media (max-width: ${theme.breakpoints.small}px) {
    & span {
      font-size: 24px;
      line-height: 28px;

    }
`

export const BlackTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.dark1};
  text-transform: uppercase;

  & span {
    font-weight: 400;
  }
`

export const Content = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 8px;
  color: ${theme.colors.dark1};

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 16px;
    line-height: 22px;
  }

`