import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const TitleBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.dark1};
  color: ${theme.colors.beige};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
`