import {ContentModal} from "../../../../../shared/modal/ContentModal";
import {
  ConfirmationTextModal
} from "../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled";
import React from "react";
import {MoneyIcon} from "../../../../../shared/modal/modalIcons/MoneyIcon";


export const MyReckoningNotAccepted = () => {
  return <ContentModal
    iconType={<MoneyIcon/>}
    text={<ConfirmationTextModal>Jeszcze nie możesz rozliczyć się za zaakceptowane opisy przypadków. <b>Twoja umowa jest
      w trakcie weryfikacji.
      W przeciągu 48h</b> otrzymasz email z podpisaną umową oraz informacją o aktywacji rozliczeń i zostanie odblokowana
      możliwość rozliczenia opisów przypadków. </ConfirmationTextModal>}
  />
}