import styled from 'styled-components';
import theme from '../../../../theme/theme';
import { ILabelFieldsetContainer } from '../Form.helper';


export const LabelContainer = styled.label<ILabelFieldsetContainer>`
  color: ${props => props.color};
  font-size: ${props => props.size === 'small' ? '16px' : props.size === 'superSmall' ? '12px' : '20px'};
  line-height: ${props => props.size === 'small' ? '30px' : '130%'};
  font-weight: ${props => props.size === 'small' || props.size === 'superSmall' ? '400' : '700'};
  display: flex;
  flex-direction: column;
  position: relative;
  ${props => props.isUpperCase && 'text-transform: uppercase;'}
  text-align: ${props => props.textAlign};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: ${props => props.size === 'small' ? '16px' : props.size === 'superSmall' ? '12px' : '20px'};
    line-height: 120%;
    margin-top: 6px;
  }
`;

export const LabelInsideContainer = styled.div`
  margin-bottom: 4px;
  margin-left: 16px;

  & div {
    margin-left: 0;
  }
`;
export const RedSpan = styled.span`
  color: ${theme.colors.red};
`;
