import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { IAllUsers } from '../interfaces/admin/user/allUsers';
import { IUserForm } from '../interfaces/admin/user/userForm';
import { sortPublishingUsers, sortUsers } from '../utils';
import { IPublishingUser } from '../interfaces/admin/user/publishingUser';

export class UsersStore {
  allUsers: IAllUsers[] | null = null;
  publishingUsers: IPublishingUser[] | null = null;
  isAllUsersLoaded: boolean = false;
  isPublishingUsersLoaded: boolean = false;
  userEdit: IUserForm | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setAllUsers(allUsers: IAllUsers[]) {
    this.allUsers = sortUsers(allUsers) as IAllUsers[];
    this.isAllUsersLoaded = true;
  }

  get allUsersList() {
    return this.allUsers;
  }

  setUserEdit(user: IUserForm, id: number) {
    const userEditElement = { ...user };
    userEditElement.id = id;
    this.userEdit = userEditElement;
  }

  clearUserEdit() {
    this.userEdit = null;
  }

  resetAllUsers() {
    this.isAllUsersLoaded = false;
  }

  setPublishingUsers(publishingUsers: IPublishingUser[]) {
    this.publishingUsers = sortPublishingUsers(publishingUsers) as IPublishingUser[];
    this.isPublishingUsersLoaded = true;
  }

  get publishingUsersList() {
    return this.publishingUsers;
  }


  resetPublishingUsers() {
    this.isPublishingUsersLoaded = false;
  }


}
