import styled from 'styled-components';
import theme from '../../../../../theme/theme';

interface IColor {
  color?: string;
}

export const ColorSpan = styled.span<IColor>`
  color: ${props => props.color ? props.color : theme.colors.blue3};
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const ImgContainer = styled.div<IColor>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => props.color ? props.color : theme.colors.blue3};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;

  & path {
    fill: white;
  }
`;

export const CaseInfo = styled.div`
  @media (max-width: ${theme.breakpoints.small}px) {
    order: 2;
    margin-top: 36px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

export const RightSide = styled.div`
  & button {
    margin-left: auto;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    order: 3;
    & button {
      margin: 10px auto 0;
    }
  }
`;

export const Container = styled.div`
  margin: 0 16px;
`;

export const StyledContainer = styled.div`
  max-width: 1280px;
  margin: 30px auto 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
  }

`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: 43px;
  line-height: 120%;
  text-transform: uppercase;
  color: ${theme.colors.dark1};
  position: relative;
  @media (max-width: ${theme.breakpoints.small}px) {
    order: 1;
    font-size: 32px;
  }
`;
export const DataMiddle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  & span {
    font-weight: 400;
  }
`;

export const IdCase = styled.div`
  font-weight: 700;
  padding-top: 20px;
  font-size: 20px;
  line-height: 27px;
  text-align: left;

  & span {
    font-weight: 400;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    padding-top: 6px;
  }

`;
