import {useFileUpload} from "./fileUpload/useFileUpload";
import {DropZone} from "./dropZone/DropZone";
import theme from '../../../theme/theme'
import {Grid} from "@mui/material";
import React, {FC} from "react";
import {ReactComponent as Plus} from '../../../assets/icon/plus.svg'
import {IImage} from "../../../interfaces/user/image";
import {
  ContainerFiles,
  Label,
  ParagraphImage,
  SpanText,
  StyledBin,
  StyledPlus,
  StyledPlusBig
} from "./FileUploadLayout.styled";
import {joinExtensionsLabel} from "./FileUploadLoader.helper";
import {FileSource} from "../../../interfaces/user/profile/profile";

interface IProps {
  images: IImage[] | File[];
  addFile: (file: File | null) => Promise<void> | void;
  removeFile?: (id: number | string) => Promise<void> | void;
  extensions?: string[];
  maxFileSize?: number;
  fileType?: string
  fileSource?: FileSource
}

export const FileUploadLayout: FC<IProps> = ({
                                               fileSource = FileSource.SERVER,
                                               images,
                                               addFile,
                                               removeFile,
                                               fileType = 'image',
                                               extensions = ['jpg', 'jpeg', 'png', 'gif'],
                                               maxFileSize = 6,
                                             }): JSX.Element => {
  const {
    onDragStateChange,
    onFileDrop,
    setIsDropActive,
    isFileTooBig,
    isFileNotSupported
  } = useFileUpload(addFile, maxFileSize, fileType);

  const insideInput = (images: IImage[] | File[]) => {
    const isFileFromServer = fileSource === FileSource.SERVER;

    if (isFileFromServer) {
      const imagesServer = images as IImage[];
      return (
        imagesServer.map((image, idx) =>
          <Grid key={image.originalName ? image.originalName + idx : Number(image.name) + idx} item display={'flex'}
                pb={1}
                alignItems={'center'}>
            <StyledBin onClick={() => removeFile && removeFile(image.id ?? -1)} fill={'red'}/>
            <ParagraphImage>{image.originalName || image.name}</ParagraphImage>
          </Grid>)
      )
    }
    const imagesState = images as File[];
    return (
      imagesState.map((image, idx) =>
        <Grid key={image.name + idx} item display={'flex'} pb={1} alignItems={'center'}>
          <StyledBin onClick={() => removeFile && removeFile(-1)} fill={'red'}/>
          <ParagraphImage>{image.name}</ParagraphImage>
        </Grid>)
    )
  }

  return (
    <DropZone
      onDragStateChange={onDragStateChange}
      onFileDrop={onFileDrop}>
      <ContainerFiles container overflow={'auto'}>

        {images.length > 0 ?
          <Grid item xs={11}>
            {insideInput(images)}
          </Grid>
          : <>

            <Grid item xs={12} md={10} display={'flex'} flexDirection={'column'}
                  justifyContent={images.length === 0 ? 'center' : 'flex-start'} overflow={'hidden'}
                  alignItems={'center'}>
              <Grid display={'flex'} alignItems={'center'}>
                <StyledPlus>
                  <Plus/>
                </StyledPlus>
                <Label
                  htmlFor="addFileButton">Przeciągnij lub wybierz z folderu z komputera
                  <input
                    id="addFileButton"
                    type="file"
                    accept={extensions.join(', ')}
                    style={{display: 'none'}}
                    onChange={(event) => {
                      event.preventDefault();
                      if (event.target.files) {
                        onFileDrop(event.target.files[0]);
                        setIsDropActive(true);
                      }
                    }}
                  />
                </Label>
              </Grid>
              <Grid display={'flex'} alignItems={'center'}>
                <SpanText
                  color={isFileNotSupported ? theme.colors.red : theme.colors.gray4}>{joinExtensionsLabel(extensions)}</SpanText>
                <SpanText
                  color={isFileTooBig ? theme.colors.red : theme.colors.gray4}>&nbsp;{` (max. ${maxFileSize}MB)`}</SpanText>
              </Grid>
            </Grid>
          </>}
      </ContainerFiles>
      <Label
        isCenter={true}
        htmlFor="addFileButton1">
        <StyledPlusBig>
          <input
            id="addFileButton1"
            type="file"
            accept={extensions.join(', ')}
            style={{display: 'none'}}
            onChange={(event) => {
              event.preventDefault();
              if (event.target.files) {
                onFileDrop(event.target.files[0]);
                setIsDropActive(true);
              }
            }}
          />
          <Plus/>
        </StyledPlusBig>
      </Label>
    </DropZone>);
}
