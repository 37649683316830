export enum RoleCategory {
  GUEST, USER, ADMIN
}

export enum RoleFunction {
  ROLE_GUEST = 'ROLE_GUEST',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_PUBLISHING = 'ROLE_PUBLISHING',
  ROLE_PARTICIPANT = 'ROLE_PARTICIPANT',
  ROLE_ACCEPTING = 'ROLE_ACCEPTING',
  ROLE_BOOKKEEPER = 'ROLE_BOOKKEEPER',
  ROLE_BOOKKEPPING = 'ROLE_BOOKKEPPING'
}
