import styled from 'styled-components';
import { Breadcrumbs } from '@mui/material';
import theme from '../../../../theme/theme';

export const BreadcrumbsCustom = styled(Breadcrumbs)`
  width: 100%;
  margin: 32px auto 10px auto !important;
  max-width: calc(1280px + 70px);
  position: relative;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: ${theme.colors.gray6};
  padding: 0 35px;

  & a {
    color: ${theme.colors.gray6};
    text-decoration: none;
  }
`;

export const Separator = styled.span`
  margin: 0 12px;
`;