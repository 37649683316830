import { useApiCall } from './useApiCall';
import { useUsersStore } from '../store/hooks';
import { useCallback } from 'react';
import { IUserLoginResponse } from '../interfaces/admin/user/userLoginResponse';
import { IUserForm } from '../interfaces/admin/user/userForm';
import { IResponse } from '../interfaces/common/response';
import { IAllUsers, IAllUsersResponse } from '../interfaces/admin/user/allUsers';
import { IPublishingUserResponse } from '../interfaces/admin/user/publishingUser';

export const useUsersApi = () => {
  const { api } = useApiCall();
  const usersStore = useUsersStore();

  const addUser = useCallback(
    async (userForm: IUserForm) => {
      const res: IUserLoginResponse = await api.apiInstance.post('user', userForm);
      return !!res;
    },
    [api.apiInstance]
  );

  const getAllUsers = useCallback(async (isLoading: boolean = true) => {
    const res: IResponse<IAllUsersResponse> = await api.apiInstance.get('users', isLoading);
    if (res) {
      const newItems: IAllUsers[] = res.items.map(el => ({
        ...el,
        role: el.role.title,
        roleId: el.role.id,
        areas: el.areas.map(area => area.fullName).join(', ')
      }));
      usersStore.setAllUsers(newItems);
    }
  }, [api.apiInstance, usersStore]);


  const getPublishingUsers = useCallback(async (isLoading: boolean = true) => {
    const res: IResponse<IPublishingUserResponse> = await api.apiInstance.get('users/publishing', isLoading);
    if (res) {
      const newItems = res.items.map(el => ({ ...el, areas: el.areas.map(el => el.fullName).join(', ') }));
      usersStore.setPublishingUsers(newItems);
    }
  }, [api.apiInstance, usersStore]);


  const resendActivateEmail = useCallback(async (id: number) => {
    const res = await api.apiInstance.post(`/user/${id}/resend-activate-email`, {});
    return !!res;
  }, [api.apiInstance]);

  const deleteUser = useCallback(async (id: number) => {
    const res = await api.apiInstance.delete(`/user/${id}`);
    return !!res;
  }, [api.apiInstance]);

  const editUser = useCallback(async (userForm: IUserForm) => {
    if (userForm.id) {
      const res = await api.apiInstance.put(`/user/${userForm.id}`, userForm);
      return !!res;
    }
  }, [api.apiInstance]);

  const getUser = useCallback(async (id: number) => {
    const res: IUserForm = await api.apiInstance.get(`/user/${id}`);
    if (res) {
      usersStore.setUserEdit(res, id);
    }
    return !!res;
  }, [api.apiInstance, usersStore]);


  return {
    getUser,
    deleteUser,
    editUser,
    resendActivateEmail,
    addUser,
    getAllUsers,
    getPublishingUsers
  };
};