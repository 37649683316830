import * as yup from 'yup';

import {IFormData} from '../../../../../interfaces/common/form/formData';
import {IArea, IAreaCommon} from '../../../../../interfaces/admin/areas/areas';

export interface IFormDataAddArea extends IFormData {
  name: keyof IArea;
}

export const formData: IFormDataAddArea[] = [
  {
    name: 'fullName',
    label: 'NAZWA OBSZARU TERAPEUTYCZNEGO',
    type: 'text',
    placeholder: 'Wpisz nazwę',
    isRequired: true
  },
  {
    name: 'shortName',
    label: 'NAZWA SKRÓCONA',
    type: 'text',
    placeholder: 'Wpisz nazwę',
    isRequired: true
  },
  {
    name: 'situationsLimit',
    label: 'LIMIT OPISÓW PRZYPADKÓW',
    type: 'number',
    placeholder: 'Wpisz limit',
    isRequired: true
  }
]

export const initialValue : IAreaCommon = {
  shortName: '',
  fullName: '',
  situationsLimit: '',
}

export const validationSchema = yup.object().shape({
  fullName: yup
  .string()
  .required(),
  shortName: yup
  .string()
  .required(),
  situationsLimit: yup
  .number()
  .required(),
})
