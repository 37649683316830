import styled from 'styled-components';
import theme from '../../../../theme/theme';
import { Grid } from '@mui/material';

interface ISummaryElement {
  color?: string;
}

export const Header = styled.h1`
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 30px;
    line-height: 120%;
  }

  & div {
    margin-bottom: 0px;
  }
`;

export const MyReckoningContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;

  & tr {
    font-size: 10px;
    line-height: 14px;
  }

  @media (max-width: ${theme.breakpoints.large}px) {
    padding-bottom: 30px;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    min-height: unset;
    padding-bottom: 0;
    overflow: hidden;
    gap: 0;
  }
`;
export const SummaryContainer = styled.div`
  margin: 80px 30px 20px 0;
  font-weight: 300;
  font-size: 20px;
  line-height: 160%;

  @media (max-width: ${theme.breakpoints.large}px) {
    margin: 40px 0 20px 0;
  }
`;
export const SummaryElement = styled.span<ISummaryElement>`
  color: ${props => props.color ?? theme.colors.dark1};
  font-weight: bold;
`;

export const ButtonsContainer = styled.div`
  height: 110px;
  position: relative;
  display: flex;
  align-items: end;
  gap: 16px;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${theme.breakpoints.medium}px) {
    flex-direction: column;
    height: auto;
    gap: 20px;
    align-items: center;
    justify-content: end;

    & button {
      width: 320px;
    }
  }
`;

export const NoReckoningInfo = styled.span`
  width: 310px;
  display: block;
  position: absolute;
  top: 0;

  @media (max-width: ${theme.breakpoints.medium}px) {
    position: relative;
    width: 100%;
  }
`;

export const ListContainer = styled.div`
  position: relative;

  & button {
    top: 5px;
    right: 0;
    position: absolute;
    text-align: left;
    justify-content: left;
    padding-left: 25px;
  }

  & svg {
    height: 100%;
    right: 10px;
  }

  @media (max-width: ${theme.breakpoints.large}px) {
    height: auto;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    & button {
      top: 0;
      right: 0;
      position: relative;
      margin: 20px auto 0 auto;
    }
  }
`;

export const ListHeader = styled.h2`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: ${theme.breakpoints.large}px) {
    margin-top: 20px;
  }
`;
export const ListSubHeader = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
`;
export const GridCaseCard = styled(Grid)`
  padding-right: 8px;

  @media (max-width: ${theme.breakpoints.medium}px) {
    padding-right: 0;
    padding-top: 8px;
  }
`;
export const NoData = styled.div`
  height: calc(100% - 64px);
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  @media (max-width: ${theme.breakpoints.large}px) {
    width: 100%;
    height: 100px;
  }
`;
