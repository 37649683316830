import {Grid} from "@mui/material";
import {Header, ImgContainer, Subheader, Text} from './SummaryFormContract.styled'
import {dateStringify, generateLink, UserLink} from "../../../../../../utils";
import {RoleCategory} from "../../../../../../constants/projectUsers";
import {Button} from "../../../../../shared/button/Button";
import theme from "../../../../../../theme/theme";
import {UserLayout} from "../../../../../shared/user/layout/UserLayout";
import React from "react";
import {useSummaryFormContract} from './useSummaryFormContract.vm'
import {ReactComponent as Pen} from './../../../../../../assets/icon/edit.svg'
import {ReactComponent as Settlement} from './../../../../../../assets/icon/settlement.svg'
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

export const SummaryFormContract = observer(() => {
  const {breadcrumbs, userContractData} = useSummaryFormContract()
  const navigate = useNavigate()
  return (
    <UserLayout breadcrumbs={breadcrumbs}>
      <Grid container display={'flex'} alignItems={'center'}>
        <Grid item xs={12} alignSelf={'flex-start'}>
          <Header><Settlement/> Twoje dane rozliczeniowe:</Header>
        </Grid>
        <Grid item xs={12}>
          <Subheader>
            <ImgContainer onClick={() => {
              navigate(generateLink(RoleCategory.USER, UserLink.PROFILE_SELECT_FORM));
            }}>
              <Pen/>
            </ImgContainer>
            Wybrana forma rozliczenia:&nbsp;<span>umowa o dzieło</span></Subheader>
        </Grid>
        <Grid xs={12} item display={{xs: 'block', sm: 'flex'}} height={'100%'}>
          <Grid container display={'flex'}>
            <Grid item xs={12} sm={6}>
              <Subheader><span>Dane osobowe:</span></Subheader>
              <Text><strong>Nazwisko i imiona</strong></Text>
              <Text>{userContractData?.fullName}</Text>
              <Text><strong>Nazwisko rodowe</strong></Text>
              <Text>{userContractData?.familyName}</Text>
              <Text><strong>Data urodzenia</strong></Text>
              <Text>{userContractData?.dateOfBirth && dateStringify(userContractData?.dateOfBirth, true)}</Text>
              <Text><strong>Obywatelstwo</strong></Text>
              <Text>{userContractData?.citizenship}</Text>
              <Text><strong>Imię ojca</strong></Text>
              <Text>{userContractData?.fatherFirstName}</Text>
              <Text><strong>Imię matki</strong></Text>
              <Text>{userContractData?.motherFirstName}</Text>
              <Text><strong>Numer ewidencyjny PESEL</strong></Text>
              <Text>{userContractData?.pesel}</Text>
              <Text><strong>Rachunek bankowy</strong></Text>
              <Text>{userContractData?.bank}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Subheader><span>Adres zameldowania:</span></Subheader>
              <Text><strong>Ulica</strong></Text>
              <Text>{userContractData?.checkinAddressStreet}</Text>
              <Text>{userContractData?.checkinAddressZip} {userContractData?.checkinAddressCity}</Text>
              <Text><strong>Gmina</strong></Text>
              <Text>{userContractData?.checkinAddressCommunity}</Text>
              <Text><strong>Powiat</strong></Text>
              <Text>{userContractData?.checkinAddressCounty}</Text>
              <Text><strong>Województwo</strong></Text>
              <Text>{userContractData?.checkinAddressVoivodeship}</Text>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} display={{xs: 'block', sm: 'flex'}} width={'100%'}>
              <Grid item xs={12}>
                <Subheader><span>Adres korespondencyjny:</span></Subheader>
                <Text><strong>Ulica</strong></Text>
                <Text>{userContractData?.mailingAddressStreet}</Text>
                <Text>{userContractData?.mailingAddressZip} {userContractData?.mailingAddressCity}</Text>
                <Text><strong>Gmina</strong></Text>
                <Text>{userContractData?.mailingAddressCommunity}</Text>
                <Text><strong>Powiat</strong></Text>
                <Text>{userContractData?.mailingAddressCounty}</Text>
                <Text><strong>Województwo</strong></Text>
                <Text>{userContractData?.mailingAddressVoivodeship}</Text>
              </Grid>
              <Grid item xs={12}>
                <Subheader><span>Dane właściwego urzędu skarbowego:</span></Subheader>
                <Text><strong>Nazwa</strong></Text>
                <Text>{userContractData?.officeTitle}</Text>
                <Text><strong>Ulica</strong></Text>
                <Text>{userContractData?.street}</Text>
                <Text>{userContractData?.zip} {userContractData?.city}</Text>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} display={'flex'} alignSelf={'flex-end'} flexDirection={{xs: 'column', sm: 'row'}}
                  alignItems={'center'} justifyContent={'center'}>
              <Grid item xs={12} width={'100%'} m={{xs: '16px auto 0', sm: 0}}>
                <Button text={'rozlicz'} size={'small'} maxWidth={'244px'} width={'100%'} fontSize={'12px'}
                        color={theme.colors.beige}
                        margin={'0 auto'}
                        backgroundColor={theme.colors.red}
                        clickHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.MY_ACCEPTED_CASES))}/>
              </Grid>
              <Grid item xs={12} m={{xs: '16px auto 0', sm: 0}} width={'100%'}>
                <Button text={'twoje faktury i umowy'} size={'small'} maxWidth={'244px'} width={'100%'}
                        fontSize={'12px'}
                        color={theme.colors.beige}
                        margin={'0 auto'}
                        backgroundColor={theme.colors.blue3}
                        clickHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.PROFILE_FORM_DOCUMENTS))}/>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </UserLayout>
  )
})