import React, {MouseEvent} from 'react'
import {Grid} from "@mui/material";
import {FormikValues} from 'formik'
import {IFormData} from "../registerPublishUser/form/registerFormData";
import {Input} from "../../../../shared/form/input/Input";
import {Checkbox} from "../../../../shared/form/checkbox/Checkbox";
import {ErrorMessage} from "../../../../shared/form/errorMessage/ErrorMesage";
import {Button} from "../../../../shared/button/Button";
import {ReactComponent as ArrowRight} from "../../../../../assets/icon/arrowRight.svg";
import {RegisterFormContainer, Title} from "./RegisterForm.styled";

interface IProps {
  formik: FormikValues,
  isChecked: boolean;
  isErrorCheckbox: boolean
  setChecked: (checked: boolean) => void
  confirmForm: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  isButtonActive: boolean,
  message: string
  titleColor: string
  labelColor?: string
  buttonTextColor?: string
  buttonBcgColor?: string
  formData: any
}

export const RegisterForm: React.FC<IProps> = ({
                                                 formik,
                                                 isChecked,
                                                 isErrorCheckbox,
                                                 setChecked,
                                                 confirmForm,
                                                 isButtonActive,
                                                 message,
                                                 titleColor,
                                                 labelColor,
                                                 buttonBcgColor,
                                                 buttonTextColor,
                                                 formData

                                               }) => {
  return (
    <RegisterFormContainer onSubmit={formik.handleSubmit} buttonTextColor={buttonTextColor}
                           isButtonActive={isButtonActive}>
      <Title titleColor={titleColor}>Rejestracja</Title>
      <Grid container justifyContent={'space-between'}>
        {formData.map((el: IFormData) =>
          el.name === 'affiliation' || el.name === 'pwz' ?
            (
              <Grid item xs={6} pr={el.name === 'affiliation' ? 2 : 0} key={el.name}>
                <Input color={labelColor} label={el.label} name={el.name} type={el.type}
                       value={formik.values[el.name]}
                       onChange={formik.handleChange}
                       placeholder={el.placeholder}
                       textError={formik.errors[el.name]}
                       isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
                />
              </Grid>
            ) : (
              <Input color={labelColor} key={el.name} label={el.label} name={el.name} type={el.type}
                     value={formik.values[el.name]}
                     onChange={formik.handleChange}
                     placeholder={el.placeholder}
                     textError={formik.errors[el.name]}
                     isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
              />
            )
        )}
      </Grid>
      <Checkbox id={'rules'}
                textColor={isErrorCheckbox ? '#DF1414' : labelColor}
                label={'Przeczytałem/am i akceptuję <strong>regulamin</strong> i <strong> politykę prywatności</strong>'}
                checked={isChecked}
                isError={isErrorCheckbox}
                onChange={setChecked}/>
      {formik.submitCount > 0 && <ErrorMessage errors={formik.errors} errorServer={false}
                                               message={message}/>}
      <Button text={'zarejestruj się'} clickHandler={confirmForm}
              backgroundColor={isButtonActive ? '#333333' : buttonBcgColor}
              color={isButtonActive ? '#F9FCFF' : buttonTextColor}
              size={'small'} type={'submit'}
              maxWidth={'188px'} icon={<ArrowRight/>}/>
    </RegisterFormContainer>
  )
}
