import {MoneyIcon} from "../../../../../shared/modal/modalIcons/MoneyIcon";

import {
  ConfirmationTextModal
} from "../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled";

import React, {FC} from 'react';
import {ContentModal} from "../../../../../shared/modal/ContentModal";
import {InvoiceContent} from "./invoiceContent/InvoiceContent";
import {ContractContent} from "./contractContent/ContractContent";
import {MultipleFileUpload} from "../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload";
import {FileSource} from "../../../../../../interfaces/user/profile/profile";
import {Button} from "../../../../../shared/button/Button";
import {ReactComponent as ArrowDouble} from "../../../../../../assets/icon/arrowDouble.svg";
import theme from "../../../../../../theme/theme";
import {ButtonContainer} from "./MyReckoningDocumentPlacement.styled";
import {ContractType} from "../../../../../../interfaces/admin/contract/contract";
import {useMyReckoningDocumentPlacement} from './useMyReckoningDocumentPlacement';
import { StoreIDs } from '../../../../../../config/storeIds';
import { mapIdToKey } from '../../myAcceptedCases/MyAcceptedCases.helper';

interface IProps {
  ids: number[] | null;
  type: ContractType;
  selectedValue: StoreIDs.AREA_LUNG | StoreIDs.AREA_NEURO
  confirmationHandler: () => void;
  cleanAfterReckoningHandler: () => void;
}

export const MyReckoningDocumentPlacement: FC<IProps> = ({ids, type, selectedValue, confirmationHandler, cleanAfterReckoningHandler}) => {
  const {
    file,
    contractTitle,
    handleRemoveFile,
    handleFileChange,
    sendFileHandler,
  } = useMyReckoningDocumentPlacement(ids, selectedValue, confirmationHandler, cleanAfterReckoningHandler);

  return <ContentModal
    iconType={<MoneyIcon/>}
    text={<ConfirmationTextModal>
      {
        type !== 'none' &&
        <>
          {
            type === 'company' ? <InvoiceContent ids={ids}/> :
              <ContractContent contactName={contractTitle} ids={ids} area={mapIdToKey(selectedValue)}/>
          }
        </>
      }

      <MultipleFileUpload images={file} removeFile={handleRemoveFile}
                          addFile={handleFileChange}
                          maxFileSize={16}
                          extensions={['pdf']}
                          fileType='application/pdf'
                          fileSource={FileSource.STATE}
                          size={'small'}
      />
      <ButtonContainer>
        <Button text={"Dalej"} size={'small'} icon={<ArrowDouble/>} backgroundColor={theme.colors.dark1} color={'white'}
                width={'100px'} clickHandler={sendFileHandler}/>
      </ButtonContainer>
    </ConfirmationTextModal>}
  />
}