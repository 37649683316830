import {useApiCall} from "./useApiCall";
import {useCallback} from "react";
import {INewIncident} from "../interfaces/user/draftCaseNeuro/thirdStage";
import {IIncident} from "../interfaces/user/draftCaseNeuro/draft";

export const useIncidentApi = () => {
  const {api} = useApiCall();

  const postIncident = useCallback(
    async (id: number, drugId: number, incident: INewIncident) => {
      const res = await api.apiInstance.post(`situation/${id}/drug/${drugId}/incident`, incident);
      return !!res;
    },
    [api.apiInstance]
  );

  const putIncident = useCallback(
    async (id: number, drugId: number, incidentId: number, incident: IIncident) => {
      const res = await api.apiInstance.put(`situation/${id}/drug/${drugId}/incident/${incidentId}`, incident);
      return !!res;
    },
    [api.apiInstance]
  );

  return {
    postIncident,
    putIncident
  }
}