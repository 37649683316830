import { useApiCall } from "./useApiCall";
import { useCallback } from "react";
import { INewDrug } from "../interfaces/user/draftCaseNeuro/thirdStage";

export const useDrugApi = () => {
  const {api} = useApiCall();

  const postDrug = useCallback(
    async (id: number, drug: INewDrug) => {
      const res = await api.apiInstance.post(`situation/${id}/drug`, drug);
      return !!res;
    },
    [api.apiInstance]
  );

  const putDrug = useCallback(
    async (id: number, drugId: number, drug: INewDrug) => {
      const res = await api.apiInstance.put(`situation/${id}/drug/${drugId}`, drug);
      return !!res;
    },
    [api.apiInstance]
  );

  const postImageDrug = useCallback(
    async (id: number, drugId: number, image: File) => {
      const form = new FormData();
      form.append('image', image);
      const res = await api.apiInstance.post(`situation/${id}/drug/${drugId}/image`, form);
      return !!res;
    },
    [api.apiInstance]
  );


  const deleteImageDrug = useCallback(
    async (id: number, drugId: number, idImage: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/drug/${drugId}/image/${idImage}`);
      return !!res;
    },
    [api.apiInstance]
  );

  const deleteDrug = useCallback(
    async (id: number, drugId: number) => {
      const res = await api.apiInstance.delete(`situation/${id}/drug/${drugId}`);
      return !!res;
    },
    [api.apiInstance]
  );


  return {
    deleteDrug,
    postDrug,
    putDrug,
    postImageDrug,
    deleteImageDrug
  }
}