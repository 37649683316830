import {RegisterLayoutPublish} from "../layout/registerLayoutPublish/RegisterLayoutPublish";
import {ChangePasswordViewingForm} from "./form/ChangePasswordViewingForm";
import {FC} from 'react';

interface IProps {
  isRegister?: boolean;
}

export const ChangePassword: FC<IProps> = ({isRegister}) => {
  return <RegisterLayoutPublish><ChangePasswordViewingForm isRegister={isRegister}/></RegisterLayoutPublish>
}