import { MouseEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { ValidationMessages } from '../../../../constants/validationMessages';
import { useModal } from '../../modal/useModal.vm';
import { IUserForm } from '../../../../interfaces/admin/user/userForm';
import { useRolesApi } from '../../../../apiHooks/useRolesApi';
import { useRolesStore } from '../../../../store/hooks/useRolesStore';
import { RegexRules } from '../../../../utils';
import { useAreasApi } from '../../../../apiHooks/useAreasApi';
import { useAreasStore } from '../../../../store/hooks';
import { IOption } from '../../../../interfaces/common/form/select';
import { StoreIDs } from '../../../../config/storeIds';

class UserFormConfig {
  static SHOW_AREAS_WHEN_ROLE_IDS = [StoreIDs.ROLE_PUBLISHING, StoreIDs.ROLE_PARTICIPANT, StoreIDs.ROLE_VIEWING];
  static SHOW_KEEPER_WHEN_AREA_ID = StoreIDs.AREA_LUNG;
}

const validationSchema = yup.object().shape({
  roleId: yup
    .number()
    .required(),
  areaIds: yup
    .array()
    .when('roleId', {
      is: (value: number) => UserFormConfig.SHOW_AREAS_WHEN_ROLE_IDS.find(singleValue => singleValue === value),
      then: (schema) => schema.min(1)
    }),
  keeper: yup
    .string()
    .nullable()
    .when(['areaIds', 'roleId'], {
      is: (value: string[], role: number) => value.find(singleValue => +singleValue === UserFormConfig.SHOW_KEEPER_WHEN_AREA_ID && role === 2),
      then: (schema) => schema.matches(RegexRules.EMAIL).required()
    }),
  fullName: yup
    .string()
    .required(),
  email: yup
    .string()
    .matches(RegexRules.EMAIL)
    .required()
});

export const useUserForm = (actionHandler: (userForm: IUserForm) => Promise<string | null>, userForm?: IUserForm | null) => {
  const rolesApi = useRolesApi();
  const rolesStore = useRolesStore();
  const { getRoles } = rolesApi;
  const { roles, isRolesLoaded } = rolesStore;
  const areasStore = useAreasStore();
  const { areas } = areasStore;
  const { getAreas } = useAreasApi();
  const { handleModal, isModalOpened } = useModal();
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [message, setMessage] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      roleId: userForm ? userForm.roleId : '',
      fullName: userForm ? userForm.fullName : '',
      email: userForm ? userForm.email : '',
      phone: userForm ? userForm.phone : '',
      areaIds: userForm ? userForm.areaIds.map(id => String(id)) : [],
      keeper: userForm ? userForm.keeper : '',
      situationsLimit: userForm ? userForm.situationsLimit : ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const userFormNew: IUserForm = {
        id: userForm ? userForm.id : -1,
        roleId: +values.roleId,
        fullName: values.fullName,
        email: values.email,
        phone: values.phone?.toString() ?? null,
        areaIds: values.areaIds.map(id => +id),
        keeper: values.keeper ?? null,
        situationsLimit: !!values.situationsLimit ? +values.situationsLimit : null
      };
      const message = await actionHandler(userFormNew);
      if (message === null && userForm) {
        values.fullName = userForm.fullName;
        values.email = userForm.email;
        values.phone = userForm.phone;
        values.situationsLimit = userForm.situationsLimit ?? null;
      } else if (message !== null) {
        setMessage(message);
      }

      if (!userForm) {
        formik.resetForm();
      }
    }
  });

  const createErrorMessage = useCallback(() => {
    if (Object.keys(formik.errors).length > 0 || formik.values.roleId === -1) {
      return ValidationMessages.INVALID_OR_EMPTY_FORM;
    }

    return '';
  }, [formik.errors, formik.values.roleId]);

  useEffect(() => {
    if (formik.submitCount !== 0) {
      setMessage(createErrorMessage());
      const isValid = Object.keys(formik.errors).length === 0 && formik.values.roleId !== -1;
      setIsButtonActive(isValid);
    } else {
      const isValid = !!formik.values.email && !!formik.values.fullName && formik.values.roleId !== -1;
      setIsButtonActive(isValid);
    }
  }, [createErrorMessage, formik.errors, formik.submitCount, formik.values.roleId, formik.values.email, formik.values.fullName]);

  useEffect(() => {
    void getAreas();
  }, [getAreas]);

  useEffect(() => {
    if (!isRolesLoaded) {
      void getRoles(false);
    }

  }, [isRolesLoaded, getRoles]);

  useEffect(() => {
    return () => rolesStore.clearRoles();
  }, [rolesStore]);


  const selectRolesOption = (value: number | string) => {
    const convertValue = typeof value === 'string' ? Number.parseInt(value) : value;

    if (convertValue) {
      formik.setFieldValue('roleId', convertValue);
      if (!UserFormConfig.SHOW_AREAS_WHEN_ROLE_IDS.find(singleValue => singleValue === convertValue)) {
        formik.setFieldValue('areaIds', []);
      }
      if (StoreIDs.ROLE_PARTICIPANT === convertValue) {
        formik.setFieldValue('keeper', '');
      }
    }
  };

  const selectAreasOption = (value: string) => {
    const generateValue = () => {
      const valueToArray = value.split(',');
      const newValue: string[] = [];

      if (valueToArray.find(element => element === '0') && areas) {
        return areas.map(area => String(area.id));
      }

      valueToArray.forEach(element => {
        if (valueToArray.filter(elementNested => element === elementNested).length === 1) {
          newValue.push(element);
        }
      });

      if (!newValue.includes(String(UserFormConfig.SHOW_KEEPER_WHEN_AREA_ID))) {
        formik.setFieldValue('keeper', '');
      }

      return newValue;
    };
    formik.setFieldValue('areaIds', generateValue());
  };

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const selectRolesOptions: IOption[] = roles ? roles.map(role => ({ title: role.title, id: role.id })) : [];
  const selectAreasOptions: IOption[] = areas ? [{
    title: 'Wszystko',
    id: 0
  }, ...areas.map(area => ({ title: area.fullName, id: area.id }))] : [];

  const showAreasWhenPusblishingOrViewingUser = UserFormConfig.SHOW_AREAS_WHEN_ROLE_IDS.find(singleValue => singleValue === formik.values.roleId);
  const showKeeperWhenLungArea = formik.values.areaIds.find(area => +area === UserFormConfig.SHOW_KEEPER_WHEN_AREA_ID) && formik.values.roleId === StoreIDs.ROLE_PUBLISHING;
  const showSituationsLimitUsers = formik.values.roleId === StoreIDs.ROLE_PUBLISHING || formik.values.roleId === StoreIDs.ROLE_VIEWING;

  return {
    formik,
    selectRolesOptions,
    selectAreasOptions,
    isButtonActive,
    confirmForm,
    message,
    selectRolesOption,
    selectAreasOption,
    handleModal,
    isModalOpened,
    areas,
    showAreasWhenPusblishingOrViewingUser,
    showKeeperWhenLungArea,
    showSituationsLimitUsers
  };
};