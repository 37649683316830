import {
  IDoctorFieldSetsVariantSecond,
  IDoctorThirdStageLung
} from '../../../../../../interfaces/user/draftCaseLung/thirdStage';

export const isAllNull = (obiekt: { [key: string]: any }) => {
  for (let key in obiekt) {
    if (typeof obiekt[key] === 'object' && obiekt[key] !== null) {
      let subObj = obiekt[key];
      for (let subKey in subObj) {
        if (subObj[subKey] !== null) {
          return false;
        }
      }
    }
  }
  return true;
};

const firstDataArray: [(keyof IDoctorThirdStageLung), string][] = [
  ['rtgKlp', 'RTG KLP'], ['tkKlp', 'TK KLP'], ['tkAbdomen', 'TK JAMY BRZUSZNEJ'], ['tkPelvis', 'TK MIEDNICY'], ['mri', 'MRI GŁOWY'], ['petCt', 'PET CT'],
  ['biopsy', 'BRONCHOSKOPIA (BIOPSJA)'], ['ebus', 'EBUS/EUS']
];

export const secondDataArray: [(keyof IDoctorFieldSetsVariantSecond), string][] = [
  ['histPath', 'BADANIE HIS-PAT'], ['egfr', 'EGFR'], ['alk', 'ALK'], ['ros', 'ROS1']
];

const firstDataFamilyArray: [(keyof IDoctorThirdStageLung), string][] = [
  ['rtgKlp', 'RTG KLP'], ['tkKlp', 'TK KLP']
];


export const newObjectWithoutNull = (doctor: IDoctorThirdStageLung, type: 'first' | 'second', isFamily: boolean) => {

  const properArray = type === 'first' ? (isFamily ? firstDataFamilyArray : firstDataArray) : (isFamily ? [] : secondDataArray);
  const dataFirst = Object.keys(doctor).filter(key => properArray.find(el => el[0] === key));
  if (Array.isArray(dataFirst)) {
    return dataFirst.map((el) => ({ [el]: doctor[el as keyof IDoctorThirdStageLung] })).filter(el => !isAllNull(el));
  } else {
    return [];
  }
};

export const objectWithTitle = <T>(data: { [key: string]: T }[], type: 'first' | 'second') => {
  const properArray = type === 'first' ? firstDataArray : secondDataArray;
  return data.map((item, index) => {
    const itemKey = Object.keys(item)[0];
    const newFirstArray = properArray.find(el => el[0] === itemKey);

    if (newFirstArray) {
      const [key, title] = newFirstArray;
      return { [key]: { ...item[key], title } };
    } else {
      return undefined;
    }
  });
};

