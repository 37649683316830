import React from "react";
import {BlackTitle, Container, Content, Title} from './ResonansAssessment.styled'
import {observer} from 'mobx-react-lite'
import {IDrug} from "../../../../../../interfaces/user/draftCaseNeuro/draft";
import {ImageBox} from '../../sharedComponents/imageBox/ImageBox'
import {Grid} from "@mui/material";
import {GridContainer} from "../../sharedComponents/gridContainer/GridContainer";
import {IImages} from "../../ViewCase.helper";

interface IProps extends IImages {
  drug: IDrug
}

export const ResonansAssessment: React.FC<IProps> = observer(({drug, imageHandler}) => {
  return (
    <>
      <Container>
        <Title>Ocena aktywności rezonansowej podczas leczenia danym lekiem:</Title>
        {drug?.images?.length !== 0 && <ImageBox imageHandler={imageHandler} images={drug.images}/>}
        <BlackTitle>Opis MRI (np: liczba i lokalizacja nowych zmian T1 i/lub nowych i powiększających się zmian T2/FLAIR
          wzmacniających się po kontraście):</BlackTitle>
        <Content>{drug.descriptionChanges ?? "-"}</Content>
      </Container>
      <Grid container padding={{xs: `0 16px`}}>
        {drug.descriptionChanges && <GridContainer textData={'data zakończenia leczenia danym lekiem'}
                                                   textContent={'opis działań niepożądanych'}
                                                   content={drug.descriptionReactions!}
                                                   date={drug.dateEnd!}/>}
        {drug.comments && <Grid mt={3}>
            <BlackTitle>inne uwagi:</BlackTitle>
            <Content>{drug.comments}</Content>
        </Grid>}
      </Grid>
    </>
  )
})
