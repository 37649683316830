import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { ICaseCard } from '../interfaces/admin/cases/cases';

export class HomeStore {
  listOfLastCases: ICaseCard[] = [];
  isListOfLastCasesLoaded: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setListOfLastCases(listOfLastCases: ICaseCard[]) {
    this.listOfLastCases = listOfLastCases;
    this.isListOfLastCasesLoaded = true;
  }

  resetListOfLastCases() {
    this.isListOfLastCasesLoaded = false;
  }

}
