import * as yup from 'yup';
import { useFormik } from 'formik';
import { MouseEvent, useEffect, useState } from 'react';
import { ValidationMessages } from '../../../../../constants/validationMessages';
import { useAuthApi } from '../../../../../apiHooks';
import { useNavigate } from 'react-router-dom';
import { GeneralLink, generateLink, RegexRules } from '../../../../../utils';
import { RoleCategory } from '../../../../../constants/projectUsers';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(RegexRules.EMAIL, ValidationMessages.INVALID_EMAIL)
    .required(ValidationMessages.EMPTY_FIELD),
})

export const useRetrievePasswordViewingForm = () => {
  const {resetPassword} = useAuthApi()
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await resetPassword(values.email)
      if (res) {
        formik.resetForm();
        navigate(generateLink(RoleCategory.GUEST, GeneralLink.LOGIN));
      } else {
        setMessage(ValidationMessages.INVALID_EMAIL)
      }

    }
  })

  const [message, setMessage] = useState('')
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

  useEffect(() => {
    if (formik.submitCount !== 0) {
      const isValid = Object.keys(formik.errors).length === 0;
      setIsButtonActive(isValid);
    }
  }, [formik.errors, formik.submitCount])

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  }

  return {formik, message, confirmForm, isButtonActive}
}
  
  

