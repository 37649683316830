import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSixthStage } from './useSixthStage';
import { ReactComponent as Success } from '../../../../../../assets/icon/success.svg';
import { ContainerSixthStage, ParagraphSixthStage, StyledImage } from './SixthStage.styled';

export const SixthStage = observer(() => {
  const { isDraft, isBlocked } = useSixthStage();

  return (
    <ContainerSixthStage>
      <StyledImage isDraft={isDraft}><Success /></StyledImage>
      {isDraft ?
        <ParagraphSixthStage>Szkic opisu przypadku został poprawnie zapisany.<br />
          Możesz zobaczyć ten opis <br /> w zakładce MOJE OPISY PRZYPADKÓW {'>'} SZKICE
        </ParagraphSixthStage>
        :
        <ParagraphSixthStage>Opis przypadku został wysłany do akceptacji.<br />
          Po zaakceptowaniu będzie widoczny dla innych użytkowników serwisu. <br />
          Zarówno "zaakceptowany" jak i "do edycji" opis będzie dla Ciebie widoczny w zakładce MOJE OPISY PRZYPADKÓW.
        </ParagraphSixthStage>
      }
      {isBlocked &&
        <ParagraphSixthStage>
          <b>Limit opisów przypadków dla tego konta został wyczerpany. Prosimy pamiętać o dokonaniu rozliczeń.
            Serdecznie dziękujemy za udział w projekcie.</b>
        </ParagraphSixthStage>
      }
    </ContainerSixthStage>);
});
