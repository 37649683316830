import {useEffect, useState} from 'react';
import { IBreadcrumbsElement } from "../../../../../interfaces/user/breadcrumbs";
import { UserLink } from "../../../../../utils";
import { useContractApi } from "../../../../../apiHooks";
import { useContractStore } from "../../../../../store/hooks";

export type TabName = 'Umowy o współpracy' | 'Faktury' | 'Umowy o dzieło'

export const useDocuments = () => {
  const [tab, setTab] = useState<TabName>('Umowy o współpracy');
  const {getContractDocuments} = useContractApi()
  const contractStore = useContractStore()

  const {contractDocuments} = contractStore
  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.PROFILE,
      name: "Profil użytkownika"
    },
    {
      name: "Umowy i faktury"
    }
  ];

  const handleChangeTab = (value: TabName) => {
    setTab(value);
  }

  useEffect(() => {
    void getContractDocuments()
  }, [getContractDocuments])

  return {
    tab,
    handleChangeTab,
    breadcrumbs,
    contractDocuments
  }
}
