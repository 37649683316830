import styled from 'styled-components';
import theme from '../../../../../theme/theme';

export const CaseContainer = styled.div`
  display: flex;
  gap: 10px;
  min-height: 180px;
  height: 100%;
  margin-right: 10px;
  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`;

export const NoCases = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: auto;
`;

export const Paragraph = styled.p`
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`;

export const ThinParagraph = styled.p`
  color: ${theme.colors.dark1};
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 41px;
  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`;

export const BoldParagraph = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 32px 0;
  gap: 25px;


  & svg {
    background: red;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    justify-content: center;
    z-index: 10;
    position: relative;
    margin: 18px 0 21px;
    & button:last-child {
      display: none;
    }
  }
`;

export const Header = styled.h1`
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  color: ${theme.colors.dark1};
  margin-bottom: 51px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 16px;
    line-height: 32px;

    & span {
      display: inline-block;
      font-size: 28px;
      font-weight: 800;
    }
  }
`;

export const DoctorImg = styled.img<{ margin?: string }>`
  display: block;
  width: 100%;
  height: auto;
  max-width: 537px;
  margin: ${props => props.margin};
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 auto;
  }
`;

export const StyledContainer = styled.div`
  @media (max-width: ${theme.breakpoints.small}px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 16px 0;
  }
`;

export const StyledNavigation = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  bottom: 0;
  gap: 10px;
  width: 100%;

  & a {
    width: 100%;
    max-width: 244px;
  }
`;
