import { IHeadCell, ITableData } from '../../../../interfaces/common/table';

import { ICasesTable } from '../../../../interfaces/admin/statistics/statistics';

export const createTableRows = (data: object[], headCells: IHeadCell[], isCheckBox: boolean, generateCasesTable?: (cases: ICasesTable[], id: string) => JSX.Element): ITableData[] => {
  const tableRows: ITableData[] = [];
  data && data.length > 0 && data.forEach(row => {
    const tableRow: ITableData = {};

    headCells.forEach(({ id }) => {
      const key = id as keyof typeof row;
      if (key === 'actions') return;
      if (row[key] === null || row[key] === '') {
        tableRow[key] = '-';
      } else {
        tableRow[key] = row[key];
      }

    });

    if (row.hasOwnProperty('uId')) {
      tableRow['id'] = row['uId' as keyof typeof row];
    } else {
      tableRow['id'] = row['id' as keyof typeof row];
    }

    //accordion
    if (generateCasesTable && row.hasOwnProperty('cases')) {
      tableRow['cases'] = generateCasesTable(tableRow['cases'] as ICasesTable[], tableRow['id']);
    }


    tableRows.push(tableRow);
  });

  return tableRows;
};