import { useEffect, useState } from 'react';
import { IHeadCell } from '../../../../interfaces/common/table';
import { createTableRows } from '../../../shared/customTables/utils/createTableRows';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/icon/message.svg';
import theme from '../../../../theme/theme';
import { ActionButtonStyled } from '../../../shared/customTables/button/ActionButton.styled';
import { checkIsActive } from '../../../shared/customTables/utils/actionsHelper';
import { useModal } from '../../../shared/modal/useModal.vm';
import { ModalType } from '../../../../constants/modalType';
import { useUsersApi } from '../../../../apiHooks';
import { useAuthStore, useUsersStore } from '../../../../store/hooks';
import { IUserForm } from '../../../../interfaces/admin/user/userForm';
import { RoleFunction } from '../../../../constants/projectUsers';
import { checkPermission } from '../../../../utils';
import { TableCellData } from '../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { useUsersFilters } from './useUsersFilters.vm';

export const useAllUsers = () => {
  const { roleFunction } = useAuthStore();
  const [actionId, setActionId] = useState<number | null>(null);
  const { getAllUsers, resendActivateEmail, deleteUser, editUser, getUser } = useUsersApi();
  const usersStore = useUsersStore();
  const { allUsersList, userEdit, isAllUsersLoaded } = usersStore;
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();
  const [openFilters, setOpenFilters] = useState(true);
  const {
    filteredUsers,
    optionsAreas,
    selectedAreas,
    handleChange,
    optionsRoles,
    selectedUsers,
    handleChangeRole,
    chipsAreas,
    chipsRoles,
    handleReset,
    handleDeleteRole,
    handleDeleteArea
  } = useUsersFilters(allUsersList, checkPermission(roleFunction, [RoleFunction.ROLE_ACCEPTING]));

  useEffect(() => {
    if (!isAllUsersLoaded) {
      if (allUsersList === null) {
        void getAllUsers();
      } else {
        void getAllUsers(false);
      }
    }
  }, [isAllUsersLoaded, getAllUsers, allUsersList]);

  useEffect(() => {
    return () => usersStore.resetAllUsers();
  }, [usersStore]);

  const handleOpenFilters = () => {
    setOpenFilters(!openFilters);
  };

  const deleteHandler = (id: number | string) => {
    handleModal(ModalType.DELETE_MODAL);
    setActionId(+id);
  };

  const editModalHandler = async (id: number | string) => {
    const user = await getUser(+id);
    if (user) {
      handleModal(ModalType.EDIT_MODAL);
    }
  };

  const editHandler = async (userForm: IUserForm) => {
    const isEdit = await editUser(userForm);
    if (isEdit) {
      await handleModal(ModalType.EDIT_CONFIRMATION_MODAL);
      setActionId(null);
      usersStore.clearUserEdit();
      await getAllUsers();
    }
    return null;
  };

  const deleteHandlerYes = async () => {
    if (allUsersList && actionId) {
      const isDelete = await deleteUser(actionId);
      if (isDelete) {
        await getAllUsers();
        setActionId(null);
        handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
      }
    }
  };

  const sendLinkHandler = (id: number | string) => {
    if (allUsersList) {
      const user = allUsersList.find(user => user.id === +id);
      if (user && !user.active) {
        void resendActivateEmail(+id);
      }
    }
  };

  const generateSendButton = (id: number) => {
    if (checkIsActive(id, allUsersList ?? [])) {
      return <ActionButtonStyled>Wyślij link <MessageIcon /></ActionButtonStyled>;
    }
    return <div>Aktywny</div>;
  };

  const generateBackground = (id: number) => {
    if (checkIsActive(id, allUsersList ?? [])) {
      return theme.colors.turquoise2;
    }
    return theme.colors.gray4;
  };

  const generateIsDisabled = (id: number) => {
    return !checkIsActive(id, allUsersList ?? []) ?? true;
  };

  const generateActions = () => {
    if (roleFunction && checkPermission(roleFunction, [RoleFunction.ROLE_ADMIN])) {
      return [
        {
          key: 'send',
          actionHandler: sendLinkHandler,
          background: generateBackground,
          actionElement: generateSendButton,
          width: 138,
          isDisabled: generateIsDisabled
        },
        {
          key: 'edit',
          actionHandler: editModalHandler,
          background: theme.colors.darkBlue,
          actionElement: <EditIcon />,
          width: 52
        },
        {
          key: 'delete',
          actionHandler: deleteHandler,
          background: theme.colors.blue4,
          actionElement: <DeleteIcon />,
          width: 52
        }
      ];
    }
    return [];
  };

  const headCells: IHeadCell[] = [
    { id: 'role', numeric: false, label: 'Rola' },
    { id: 'fullName', numeric: false, label: 'Imię i nazwisko' },
    { id: 'phone', numeric: false, label: 'Telefon' },
    { id: 'email', numeric: false, label: 'e-mail' },
    { id: 'areas', numeric: false, label: 'obszar terapeutyczny' }
  ];

  const cellsSwitcher = (keyName: string, value: string) => {
    switch (keyName) {
      case 'id':
      case 'isActive':
        return <TableCellData>{Boolean(value) ? 'Zarejestrowany' : 'Niezarejestrowany'}</TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const tableDataRows = createTableRows(filteredUsers ?? [], headCells, false);
  const amountOfChips = chipsAreas.length + chipsRoles.length;

  return {
    publishingUsersListIsNotNull: allUsersList !== null,
    count: allUsersList ? allUsersList.length : 0,
    userEdit,
    headCells,
    generateActions,
    tableDataRows,
    modalType,
    isModalOpened,
    editHandler,
    deleteHandlerYes,
    closeModal,
    optionsAreas,
    selectedAreas,
    handleChange,
    cellsSwitcher,
    optionsRoles,
    selectedUsers,
    handleChangeRole,
    chipsAreas,
    chipsRoles,
    openFilters,
    handleOpenFilters,
    amountOfChips,
    handleReset,
    handleDeleteRole,
    handleDeleteArea
  };
};
