import { IFormData } from '../../../../interfaces/common/form/formData';

type Name = 'fullName' | 'email' | 'phone' | 'situationsLimit';

export interface IFormDataAddUser extends IFormData {
  name: Name;
}

export const formData: IFormDataAddUser[] = [
  {
    name: 'fullName',
    label: 'IMIĘ I NAZWISKO',
    type: 'text',
    placeholder: 'Wpisz imię i nazwisko',
    isRequired: true
  },
  {
    name: 'email',
    label: 'EMAIL',
    type: 'text',
    placeholder: 'Wpisz email',
    isRequired: true
  }
];
