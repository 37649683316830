import { Grid } from '@mui/material';
import {
  BoldParagraph,
  CaseContainer,
  DoctorImg,
  Header,
  NoCases,
  StyledContainer,
  StyledNavigation,
  ThinParagraph
} from './publishUserHome.styled';
import { CaseCard } from '../../../../shared/user/cards/CaseCard';
import doctorHospitalBcg from '../../../../../assets/img/doctorHospitalBcg.png';
import { Breakpoint } from '../../../../../hooks/useBreakpoint';
import { NavigationButtons } from '../../../../shared/user/navigation/navigationButton/NavigationButtons';
import React from 'react';
import { usePublishUserHome } from './usePublishUserHome.vm';
import { observer } from 'mobx-react-lite';

export const PublishUserHome = observer(() => {
  const {
    listOfLastCases,
    fullName,
    affiliation,
    breakpoint,
    isNeuroAffiliation
  } = usePublishUserHome();

  return (
    <>
      <Grid item xs={12} sm={7}>
        <Header>Miło Cię widzieć, <br />
          <span>{isNeuroAffiliation ? affiliation : ''} {fullName}</span></Header>
        <BoldParagraph>Twoje opisy przypadków cieszą się dużym powodzeniem.</BoldParagraph>
        <ThinParagraph> Zobacz co się wydarzyło pod twoją nieobecność:</ThinParagraph>
        <CaseContainer>
          {listOfLastCases.length !== 0 ?
            listOfLastCases.map(({
                                   status,
                                   id,
                                   customId,
                                   patient,
                                   title,
                                   updatedAt,
                                   type
                                 }) => (
              <CaseCard type={type} key={id} id={id} status={status} customId={customId} patient={patient}
                        updatedAt={updatedAt}
                        title={title} maxWidth={'33%'} />
            )) : <NoCases>Nie masz jeszcze żadnych dodanych opisów przypadków.</NoCases>}
        </CaseContainer>
      </Grid>
      <Grid item xs={12} sm={5} alignSelf={'flex-end'}>
        <StyledContainer>
          <DoctorImg src={doctorHospitalBcg} />
          {breakpoint < Breakpoint.SMALL && <StyledNavigation><NavigationButtons /></StyledNavigation>}
        </StyledContainer>
      </Grid>
    </>
  );
});
