const theme = {
  colors: {
    blue1: '#5BAFF9',
    blue2: '#87C1F3',
    blue3: '#0376D9',
    blue4: '#3285C7',
    blue5: '#E5E5E5',
    lightBlue: '#D9EDFF',
    darkBlue: '#033D71',
    turquoise1: '#41BBA4',
    turquoise2: '#1D96A1',
    turquoise3: '#F3FAF7',
    dark1: '#333333',
    gray3: '#CECECE',
    gray4: '#BDBDBD',
    gray5: '#E0E0E0',
    gray6: '#828282',
    red: '#DF1414',
    yellow: '#FDBE00',
    beige: '#F9FCFF',
    white: '#FFFFFF'
  },
  breakpoints: {
    extraLarge: 1535,
    large: 1199,
    medium: 899,
    small: 767,
    cell: 599
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif'
  }
};

export default theme;