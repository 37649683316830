import React from 'react';
import { CloseIcon } from './modalIcons/CloseIcon';
import { Button } from '../button/Button'

import theme from "../../../theme/theme";
import { ButtonsArea, ContentModalContainer } from "./Modal.styled";

interface IProps {
  text?: JSX.Element;
  iconType?: string | JSX.Element;
  handleYes?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  handleNo?: () => void;
  children?: React.ReactNode;
  scrolled?: boolean;
  contentAfter?: JSX.Element;
  width?: string
}

export const ContentModal: React.FC<IProps> = ({
                                                 children,
                                                 text,
                                                 iconType,
                                                 handleYes,
                                                 handleNo,
                                                 scrolled,
                                                 contentAfter,
                                                 width
                                               }) => {
  const component = iconType ?? <CloseIcon clickHandler={handleNo}/>;

  return (
    <ContentModalContainer scrolled={scrolled} width={width}>
      <>
        {iconType && component}
        {text}
        {children}
        {handleYes && handleNo && (
          <ButtonsArea>
            <Button width={'95px'} size={'small'} text={'tak'} backgroundColor={theme.colors.turquoise2}
                    maxWidth={'95px'}
                    color={theme.colors.beige}
                    clickHandler={handleYes}/>
            <Button width={'95px'} size={'small'} text={'nie'} backgroundColor={theme.colors.red} maxWidth={'95px'}
                    color={theme.colors.beige}
                    clickHandler={handleNo}/>
          </ButtonsArea>
        )}
        {contentAfter}
      </>
    </ContentModalContainer>
  );
};
