import {Grid} from "@mui/material";

import React from "react";
import {observer} from "mobx-react-lite";
import {useFirstStage} from "./useFirstStage.vm";
import {Input} from "../../../../../shared/form/input/Input";
import {GridPadding} from "./FirstStage.styled";
import {SwitchGender} from "./switchGender/SwitchGender";

export const FirstStage = observer(() => {
  const {formik} = useFirstStage();

  return (<Grid container justifyContent={'space-between'}>
    <Grid item xs={12}>
      <Input isRequired={true}
             label={"Tytuł opisu przypadku"}
             isUpperCase={true}
             name={"title"}
             type={"text"}
             value={formik.values.title}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             placeholder={"Wpisz tytuł opisu przypadku..."}
             isError={formik.touched.title && Boolean(formik.errors.title)}
      />
    </Grid>

    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Input isRequired={true}
                 label={"Inicjały pacjenta"}
                 isUpperCase={true}
                 name={"initials"}
                 maxLength={2}
                 type={"text"}
                 value={formik.values.initials}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={"Wpisz pierwsze litery imienia i nazwiska..."}
                 isError={formik.touched.initials && Boolean(formik.errors.initials)}
          />
        </Grid>
        <GridPadding item xs={12} md={4}>
          <Input isRequired={false}
                 label={"Rok urodzenia"}
                 isUpperCase={true}
                 name={"yearOfBirth"}
                 maxLength={4}
                 type={"text"}
                 value={formik.values.yearOfBirth}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={"yyyy"}
                 isError={formik.touched.yearOfBirth && Boolean(formik.errors.yearOfBirth)}
          />
        </GridPadding>
        <GridPadding item xs={12} md={2}>
          <Input
            isRequired={false}
            label={"Płeć"}
            name={'gender'}
            isError={formik.touched.gender && Boolean(formik.errors.gender)}
            component={
              <SwitchGender
                name={'gender'}
                onChange={formik.handleChange}
                value={formik.values.gender}

              />
            }
          />
        </GridPadding>
      </Grid>
    </Grid>
  </Grid>)
});



