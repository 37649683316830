import styled from 'styled-components';

export interface IConnector {
  color: string;
}

export const ConnectorContainer = styled.div<IConnector>`
  background: ${props => props.color};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;

  svg {
    margin: auto;
    width: 12px;
  }
`;