import { useCaseStore } from '../../../../../store/hooks';
import { Container, FlexContainer, SectionTitle, Title, BlackTitle, Content } from './Interview.styled';
import React from 'react';
import { ViewCheckbox } from '../sharedComponents/checkbox/ViewCheckbox';
import { dateStringify } from '../../../../../utils';

export const Interview = () => {
  const caseStore = useCaseStore();
  const { viewCaseLung } = caseStore;
  return (
    <Container>
      {viewCaseLung && viewCaseLung.interview && <>
        <SectionTitle>Wywiad:</SectionTitle>
        <Title>Palenie tytoniu</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.smockingTobacco.isActive ? 'tak' : viewCaseLung.interview.smockingTobacco.isActive === false ? 'nie' : ''}</ViewCheckbox>
        {viewCaseLung.interview.smockingTobacco.isActive && <FlexContainer>
          <div>
            <BlackTitle>Liczba lat</BlackTitle>
            <Content>{viewCaseLung.interview.smockingTobacco.years}</Content>
          </div>
          <div>
            <BlackTitle>Liczba paczek dziennie</BlackTitle>
            <Content>{viewCaseLung.interview.smockingTobacco.amount}</Content>
          </div>
          <div>
            <BlackTitle>Liczba paczkolat</BlackTitle>
            <Content>{viewCaseLung.interview.smockingTobacco.years * viewCaseLung.interview.smockingTobacco.amount}</Content>
          </div>
        </FlexContainer>}
        <Title>Kaszel</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.cough.isActive ? 'tak' : viewCaseLung.interview.cough.isActive === false ? 'nie' : ''}</ViewCheckbox>
        {viewCaseLung.interview.cough.isActive && <>
          <BlackTitle>Czy kaszel jest przewlekły?</BlackTitle>
          <ViewCheckbox margin={'16px 0'}
          >{viewCaseLung.interview.cough.isChronic ? 'tak' : 'nie'}</ViewCheckbox></>}
        {viewCaseLung.interview.cough.isChronic && <>
          <BlackTitle>od kiedy?</BlackTitle>
          <Content>{dateStringify(viewCaseLung.interview.cough.dateFrom).substring(3)}</Content>
          <BlackTitle>Zmiana charakteru kaszlu w ostatnim kwartale</BlackTitle>
          <ViewCheckbox margin={'16px 0'}
          >{viewCaseLung.interview.cough.lastQuarterChange ? 'tak' : 'nie'}</ViewCheckbox></>}
        <Title>Krwioplucie</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.hemoptysis.isActive ? 'tak' : viewCaseLung.interview.hemoptysis.isActive === false ? 'nie' : ''}</ViewCheckbox>
        {viewCaseLung.interview.hemoptysis.isActive && <>
          <BlackTitle>od kiedy?</BlackTitle>
          <Content>{dateStringify(viewCaseLung.interview.hemoptysis.dateFrom).substring(3)}</Content></>}
        <Title>Niezamierzona utrata masy ciała</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.weightLoss.status}</ViewCheckbox>
        {viewCaseLung.interview.weightLoss.status === 'tak' && <FlexContainer>
          <div><BlackTitle>od kiedy?</BlackTitle>
            <Content>{dateStringify(viewCaseLung.interview.weightLoss.dateFrom).substring(3)}</Content></div>
          <div><BlackTitle>ile kg?</BlackTitle>
            <Content>{viewCaseLung.interview.weightLoss.weight} kg</Content></div>
        </FlexContainer>}
        <Title>Chrypka</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.hoarseness.isActive ? 'tak' : viewCaseLung.interview.hoarseness.isActive === false ? 'nie' : ''}</ViewCheckbox>
        {viewCaseLung.interview.hoarseness.isActive && <>
          <BlackTitle>od kiedy?</BlackTitle>
          <Content>{dateStringify(viewCaseLung.interview.hoarseness.dateFrom).substring(3)}</Content></>}
        <Title>Duszność</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.dyspnoea.isActive ? 'tak' : viewCaseLung.interview.dyspnoea.isActive === false ? 'nie' : ''}</ViewCheckbox>
        {viewCaseLung.interview.dyspnoea.isActive && <>
          <BlackTitle>od kiedy?</BlackTitle>
          <Content>{dateStringify(viewCaseLung.interview.dyspnoea.dateFrom).substring(3)}</Content></>}
        <Title>inne objawy o istotnej wartości klinicznej</Title>
        <Content>{viewCaseLung.interview.otherSymptoms || '-'}</Content>
        <Title>Narażenie na czynniki kancerogenne</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.carcinogenicFactors.status}</ViewCheckbox>
        {viewCaseLung.interview.carcinogenicFactors.status === 'tak' &&
          <div><BlackTitle>Jakie?</BlackTitle>
            <Content>{viewCaseLung.interview.carcinogenicFactors.which}</Content></div>
        }
        <Title>Aktualny Stan sprawności w skali ECOG</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.statusECOG}</ViewCheckbox>
        <Title>Typ histologiczny nowotworu</Title>
        <ViewCheckbox margin={'16px 0'}
        >{viewCaseLung.interview.histologicalCancer}</ViewCheckbox>
      </>}</Container>
  );
};