import { useAuthStore, useCasesStore } from '../../../../store/hooks';
import { ReactNode, useEffect } from 'react';
import { useCasesApi } from '../../../../apiHooks';
import { IBreadcrumbsElement } from '../../../../interfaces/user/breadcrumbs';
import { SelectChangeEvent } from '@mui/material';
import { IOption } from '../../../../interfaces/common/form/select';

export const useOtherCases = () => {
  const casesStore = useCasesStore();
  const { getListOfOtherCases } = useCasesApi();
  const {
    currentPage,
    filteredListOfOtherCasesByCurrentPage,
    isListOfOtherCasesLoaded,
    otherPageCount,
    listOfOtherCases,
    selectedAreasForOther
  } = casesStore;
  const { areas } = useAuthStore();

  useEffect(() => {
    if (!isListOfOtherCasesLoaded) {
      if (listOfOtherCases === null) {
        void getListOfOtherCases();
      } else {
        void getListOfOtherCases(false);
      }
    }

  }, [listOfOtherCases, getListOfOtherCases, isListOfOtherCasesLoaded]);

  useEffect(() => {
    return () => {
      casesStore.resetTableConfig();
      casesStore.resetListOfOtherCases();
    };
  }, [casesStore]);

  const options = areas && areas.map(el => ({ id: el.id, title: el.fullName }));

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      name: 'Opisy przypadków innych'
    }];

  const handleChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedAreasForOther.includes(0)) {
        casesStore.setSelectedAreasForOther(selectedValues.filter(value => value !== 0));
      } else {
        if (options) {
          casesStore.setSelectedAreasForOther([...options.map((option: IOption) => option.id), 0]);
        }
      }
    } else {
      if (selectedAreasForOther.includes(0)) {
        casesStore.setSelectedAreasForOther([]);
      } else {
        casesStore.setSelectedAreasForOther(selectedValues);
      }
    }
  };

  return {
    breadcrumbs,
    listOfOtherCases: filteredListOfOtherCasesByCurrentPage,
    currentPage,
    setCurrentPage: casesStore.setCurrentPage,
    otherPageCount: otherPageCount,
    isListOfOtherCasesIsNotEmpty: casesStore.isFilteredOtherCasesListNotEmpty || casesStore.listOfOtherCases === null,
    selectedAreas: selectedAreasForOther, handleChange, options
  };
};