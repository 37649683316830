import {useApiCall} from "./useApiCall";
import {useProfileStore, useReckoningStore} from "../store/hooks";
import {useCallback} from "react";
import {IHistory} from "../interfaces/user/history/history";
import {IProfile} from "../interfaces/admin/user/profile";

export const useProfileApi = () => {
  const {api} = useApiCall();
  const profileStore = useProfileStore();
  const myReckoningStore = useReckoningStore()
  const {profile} = profileStore

  const getProfile = useCallback(
    async (isLoaded?: boolean) => {
      const res: IProfile = await api.apiInstance.get('profile', isLoaded);
      profileStore.setProfile(res);
      myReckoningStore.setContractType(res.isCompanyContract, res.isUserContract)
      return !!res
    },
    [api.apiInstance, myReckoningStore, profileStore]
  );

  const editProfile = useCallback(
    async (data: IProfile) => {
      const res: IHistory = await api.apiInstance.put('profile', data);
      profileStore.setProfile({...profile, ...data});
      return !!res
    },
    [api.apiInstance, profileStore, profile]
  );

  return {
    getProfile,
    editProfile
  }
}