import { UserLayout } from '../../../shared/user/layout/UserLayout';

import { observer } from 'mobx-react-lite';

import React from 'react';

import {
  ButtonsContainer,
  GridCaseCard,
  Header,
  ListContainer,
  ListHeader,
  ListSubHeader,
  MyReckoningContainer,
  NoData,
  NoReckoningInfo,
  SummaryContainer,
  SummaryElement
} from './MyReckoning.styled';
import { Grid } from '@mui/material';
import { acceptedTerms, beTerms, changeDescriptionWord, descriptionTerms, rejectionTerms } from './MyReckoning.helper';
import theme from '../../../../theme/theme';
import { Button } from '../../../shared/button/Button';
import { generateLink, UserLink } from '../../../../utils';
import { RoleCategory } from '../../../../constants/projectUsers';
import { CaseCard } from '../../../shared/user/cards/CaseCard';
import { ReactComponent as ArrowDouble } from '../../../../assets/icon/arrowDouble.svg';
import { CaseStatus } from '../../../../interfaces/user/draftCaseNeuro/draft';
import { useMyReckoning } from './useMyReconing.vm';

export const MyReckoning = observer(() => {

  const {
    buttonText,
    isProcessActive,
    showMore,
    reckoningDashboard,
    acceptedList,
    rejectedList,
    breadcrumbs,
    navigate,
    reckoningSummaryHandler
  } = useMyReckoning();

  return (<UserLayout breadcrumbs={breadcrumbs}>
    <MyReckoningContainer>
      {reckoningDashboard && <Grid container>
        <Grid item xs={12} lg={6}>
          <Header>Twoje<br /> rozliczenia:</Header>
          <SummaryContainer>
            <p>Dodałeś do tej
              pory<SummaryElement>{` ${changeDescriptionWord(reckoningDashboard.all, descriptionTerms)} przypadków,`}</SummaryElement>
            </p>
            <p>z czego <SummaryElement
              color={theme.colors.turquoise2}>{`${changeDescriptionWord(reckoningDashboard.accepted, descriptionTerms)} przypadków `}</SummaryElement>
              {changeDescriptionWord(reckoningDashboard.accepted, acceptedTerms, false)},
              <br />i<SummaryElement
                color={theme.colors.red}>{` ${changeDescriptionWord(reckoningDashboard.rejected, rejectionTerms)}, `}</SummaryElement>
            </p>
            <p><SummaryElement>W trakcie
              akceptacji</SummaryElement> obecnie {changeDescriptionWord(reckoningDashboard.waiting, beTerms, false)}
              <SummaryElement>{` ${changeDescriptionWord(reckoningDashboard.waiting, descriptionTerms)} przypadków. `}</SummaryElement>
            </p>
            <p><SummaryElement>W trakcie
              rozliczenia</SummaryElement> obecnie {changeDescriptionWord(reckoningDashboard.reckoning, beTerms, false)}
              <SummaryElement>{` ${changeDescriptionWord(reckoningDashboard.reckoning, descriptionTerms)} przypadków. `}</SummaryElement>
            </p>
          </SummaryContainer>
          <ButtonsContainer>
            {!isProcessActive && <NoReckoningInfo>
              Nie wybrałeś jeszcze formy rozliczenia.
              Wejdź w dane rozliczenia i uzupełnij dane
              aby móc rozliczać opisy przypadków:
            </NoReckoningInfo>}
            <Button text={buttonText} size={'tiny'} width={'244px'}
                    backgroundColor={theme.colors.dark1}
                    color={'white'}
                    clickHandler={reckoningSummaryHandler} />
            <Button text={'Rozlicz'} size={'tiny'} width={'244px'}
                    backgroundColor={isProcessActive ? theme.colors.red : theme.colors.gray3}
                    color={'white'}
                    disabled={!isProcessActive}
                    clickHandler={() => isProcessActive && navigate(generateLink(RoleCategory.USER, UserLink.MY_ACCEPTED_CASES))} />
          </ButtonsContainer>

        </Grid>
        <Grid item xs={12} lg={6} display={'flex'} gap={4} flexDirection={'column'}>
          <ListContainer>
            <ListHeader>Opisy przypadków do edycji:</ListHeader>
            <ListSubHeader>(które należy poprawić, aby móc się z nich rozliczyć)</ListSubHeader>
            <Grid pt={1} container>
              {rejectedList.length !== 0 ? rejectedList.map(element =>
                  <GridCaseCard item xs={12} md={4} key={element.customId}>
                    <CaseCard key={element.id} id={element.id} status={element.status} customId={element.customId}
                              patient={element.patient} updatedAt={element.updatedAt}
                              title={element.title} />
                  </GridCaseCard>
                )
                : <NoData>Nie masz jeszcze opisów przypadków "do edycji".</NoData>}
            </Grid>
            <Button text={'Zobacz więcej'} width={'190px'} color={'white'} size={'small'}
                    backgroundColor={theme.colors.dark1} icon={<ArrowDouble />}
                    clickHandler={() => showMore(CaseStatus.REJECT)} />
          </ListContainer>
          <ListContainer>
            <ListHeader>Zaakceptowane opisy przypadków:</ListHeader>
            <ListSubHeader>(które nie zostały jeszcze rozliczone)</ListSubHeader>

            <Grid pt={1} container>
              {acceptedList.length !== 0 ? acceptedList.map(element =>
                  <GridCaseCard item xs={12} md={4} key={element.id}>
                    <CaseCard key={element.id} id={element.id} status={element.status} customId={element.customId}
                              patient={element.patient} updatedAt={element.updatedAt}
                              title={element.title} />
                  </GridCaseCard>
                )
                : <NoData>Nie masz jeszcze opisów przypadków "do edycji".</NoData>}
            </Grid>
            <Button text={'Zobacz więcej'} width={'190px'} color={'white'} size={'small'}
                    backgroundColor={theme.colors.dark1} icon={<ArrowDouble />}
                    clickHandler={() => showMore(CaseStatus.ACCEPT)} />
          </ListContainer>
        </Grid>
      </Grid>}
    </MyReckoningContainer>
  </UserLayout>);
});