import styled from 'styled-components';
import theme from '../../../../../theme/theme';

export const ConfirmationTextModal = styled.div`
  margin-top: 5px;
  max-width: 700px;
  text-align: center;

`;

export const Title = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  text-transform: uppercase;
`;

export const Paragraph = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

export const FlexPosition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 16px;
  flex-direction: column;
  height: 120px;

  & button {
    text-transform: unset;
  }
`;

export const MyReckoningConfirmationContainer = styled.div`
  margin-top: 50px;
  font-size: 20px;
  line-height: 28px;

  & p {
    max-width: 100%;
  }

  & button {
    margin: 50px auto 0 auto;
  }
`;
export const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const TextContainer = styled.div`
  margin: 50px 0 45px 0;
`;

export const TextDocumentContainer = styled.div`
  margin: 8px 0;

  & h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: left;
    padding-left: 16px;
  }

  & h6 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin: 16px auto 40px;
`;

export const DataText = styled.p`
  font-size: 14px;
  font-weight: 600;

  & span {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const MoneyContainer = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px auto;
  width: 45px;
  height: 45px;
  background-color: ${theme.colors.turquoise2};
`;