import {CaseStatus} from "../../../../../interfaces/user/draftCaseNeuro/draft";
import theme from "../../../../../theme/theme";
import {ReactComponent as Edit} from "../../../../../assets/icon/edit.svg";
import {ReactComponent as Pending} from "../../../../../assets/icon/pending.svg";
import {ReactComponent as Success} from "../../../../../assets/icon/success.svg";
import {ReactComponent as Close} from "../../../../../assets/icon/close.svg";

interface IStatusElement {
  key: CaseStatus,
  name: string;
  color: string;
  component: JSX.Element;

}

const statusElements: IStatusElement[] = [
  {
    key: CaseStatus.DRAFT,
    name: 'Szkic',
    color: theme.colors.blue1,
    component: <Edit/>
  },
  {
    key: CaseStatus.SENT,
    name: 'Wysłany',
    color: theme.colors.yellow,
    component: <Pending/>
  },
  {
    key: CaseStatus.ACCEPT,
    name: 'Zaakceptowany',
    color: theme.colors.turquoise2,
    component: <Success/>
  },
  {
    key: CaseStatus.REJECT,
    name: 'Do edycji',
    color: theme.colors.red,
    component: <Close/>
  },
  {
    key: CaseStatus.WAIT,
    name: 'Oczekujący',
    color: theme.colors.yellow,
    component: <Edit/>
  }
]
export const mapStatus = (status?: CaseStatus) => {
  return statusElements.find(element => element.key === status)
}

