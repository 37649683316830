import { ChangeEvent, useState } from 'react';

import { defined } from '../../../../utils';
import { getComparator } from '../utils/getComparior';
import { searchByFilter } from '../utils/searchByFilter';
import { stableSort } from '../utils/stableSort';
import { IAction, IHeadCell, ITableConfig, ITableData, TableSortOrder } from '../../../../interfaces/common/table';

const initialTableConfig: ITableConfig = {
  orderBy: '',
  order: TableSortOrder.Ascending,
  page: 0,
  rowsPerPage: 10,
  searchValue: '',
  selected: [],
  selectedToState: () => {
  }
};

export const useTableController = (headCells: IHeadCell[], data: ITableData[], customTableConfig?: ITableConfig, actions?: IAction[], isSummary?: boolean, sortOrderByHandler?: (val: string) => void, sortOrderHandler?: (val: TableSortOrder) => void) => {
  const [order, setOrder] = useState<TableSortOrder>(customTableConfig?.order || defined(initialTableConfig.order));
  const [orderBy, setOrderBy] = useState<string>(customTableConfig?.orderBy || defined(initialTableConfig.orderBy));
  const [page, setPage] = useState<number>(customTableConfig?.page || defined(initialTableConfig.page));
  const [selected, setSelected] = useState<string[]>(customTableConfig?.selected || defined(initialTableConfig.selected));
  const [rowsPerPage, setRowsPerPage] = useState<number>(customTableConfig?.rowsPerPage || defined(initialTableConfig.rowsPerPage));
  const [searchValue, setSearchValue] = useState<string>(customTableConfig?.searchValue || defined(initialTableConfig.searchValue));
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const filterChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const setOrderHandler = (order: TableSortOrder) => {
    setOrder(order);
    sortOrderHandler && sortOrderHandler(order);

  };
  const setOrderByHandler = (orderBy: string) => {
    setOrderBy(orderBy);
    sortOrderByHandler && sortOrderByHandler(orderBy);
  };
  const changePageHandler = (event: unknown, newPage: number) => setPage(newPage);

  const setSelectedHandler = (value: string) => {
    if (selected.includes(value)) {
      setSelected(selected.filter(item => item !== value));
      customTableConfig?.selectedToState?.(selected.filter(item => item !== value));
    } else {
      setSelected([...selected, value]);
      customTableConfig?.selectedToState?.([...selected, value]);
    }
  };

  const allSelectToggler = (allIds: string[]) => {
    if (selected.length === allIds.length) {
      setSelected([]);
      customTableConfig?.selectedToState?.([]);
      setIsAllSelected(false);
    } else {
      setSelected(allIds);
      customTableConfig?.selectedToState?.(allIds);
      setIsAllSelected(true);
    }
  };
  const changeRowPerPageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property: string) => {
    const isAsc = orderBy === property && order === TableSortOrder.Ascending;
    setOrderHandler(isAsc ? TableSortOrder.Descending : TableSortOrder.Ascending);
    setOrderByHandler(property);
  };

  const isSelectedHandler = (id: string) => selected.indexOf(id) !== -1;

  let drawArray = (() => stableSort(data, getComparator(order, orderBy))
    .filter(searchByFilter(searchValue, headCells)))();

  const summary = isSummary && drawArray.length > 0 ? Object.keys(drawArray[0]).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: drawArray.reduce((result, element) => {
        if (typeof element[currentValue] === 'number') {
          return result + +element[currentValue];
        } else {
          return result;
        }
      }, 0)
    }),
    {}
  ) : null;

  const widthActionsHead = actions ? actions.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.width || 0);
  }, 0) : 0;

  return {
    tableConfig: {
      order,
      orderBy,
      page,
      rowsPerPage,
      searchValue
    },
    filterChangeHandler,
    setOrderHandler,
    setOrderByHandler,
    changePageHandler,
    changeRowPerPageHandler,
    setSelectedHandler,
    allSelectToggler,
    isAllSelected,
    isSelectedHandler,
    createSortHandler,
    drawArray,
    widthActionsHead,
    summary
  };
};