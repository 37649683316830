import { observer } from 'mobx-react-lite';
import { Grid } from '@mui/material';

import {AccordionCustom} from './accordion/AccordionCustom';
import { useThirdStage } from './useThirdStage';
import { doctorTypes } from './ThirdStage.helper';
import { ModalAdd } from './modalAdd/ModalAdd';
import { Button } from '../../../../../shared/button/Button';
import {
  DoctorType
} from '../../../../../../interfaces/user/draftCaseLung/thirdStage';
import { Modal } from '../../../../../shared/modal/Modal';
import theme from '../../../../../../theme/theme';
import plus from '../../../../../../assets/icon/beigePlus.svg';
import { ErrorInfo } from './ThirdStage.styles';

export const ThirdStage = observer(() => {
  const {
    type,
    addDoctorTypeHandler,
    onChangeHandler,
    deleteDoctor,
    doctors,
    isModalOpened,
    expandedId,
    closeModal,
    isDoctorElement,
    checkThirdStage
  } = useThirdStage()

  return (
    <div>
      <Grid container gap={1} bgcolor={isDoctorElement === false ? 'rgba(223, 20, 20, 0.1)' : 'transparent'} p={1}>
        {Object.keys(doctorTypes).map(key => 
                  <Grid item key={key}>
                  <Button text={doctorTypes[key as DoctorType]} backgroundColor={theme.colors.dark1} icon={plus} iconOrientation='left' padding={'16px'}
                          color={theme.colors.beige} size={'small'} clickHandler={() => addDoctorTypeHandler(key as DoctorType)} />
                </Grid>
        )}
        
      </Grid>
      {isDoctorElement === false && <ErrorInfo>* Dodaj lekarza na ścieżce pacjenta</ErrorInfo>}
      <Grid container gap={1} mt={4} >
        <Grid item xs={12}>
          {
            doctors.map(doctor => (
              <AccordionCustom key={doctor.id} isModalOpened={isModalOpened}
                               deleteDoctor={deleteDoctor}
                               setExpandedId={onChangeHandler}
                               expandedId={expandedId}
                               expanded={expandedId === +doctor.id!} doctor={doctor}/>
            ))
          }
        </Grid>
      </Grid>
       {isModalOpened && type !== null &&
          <Modal position={'static'} width='60%' maxHeight='700px' clickHandler={closeModal}>
            <ModalAdd type={type} title={doctorTypes[type as DoctorType]} handleCloseDrugModal={closeModal} checkThirdStage={checkThirdStage}/>
          </Modal>
        }       

    </div>
  );
});

