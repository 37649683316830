import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & button {
    justify-content: left;
    padding-left: 14px;
  }

  & svg {
    height: 100%;
    right: 8px;
    margin: 0;
  }

`