import styled from "styled-components";
import theme from "../../../../theme/theme";

interface IIsActive {
  isActive: boolean;
}

export const UserTopContainer = styled.div<IIsActive>`
  position: absolute;
  z-index: 1000;
  right: 0;
  top: -20px;
  padding-top: 20px;
  padding-right: 65px;
  height: 60px;
  width: 300px;
  background: ${props => props.isActive ? theme.colors.turquoise2 : `linear-gradient(269.97deg, ${theme.colors.darkBlue} 5.36%, ${theme.colors.blue4} 99.98%)`};
  border-radius: 31px 0 0 31px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`

export const UserName = styled.span`
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  margin-left: 15px;
`
export const ButtonsContainer = styled.div`
  display: flex;
`

export const ButtonProfile = styled.div<IIsActive>`
  width: 30px;
  margin: auto;
  text-align: center;
  cursor: ${props => props.isActive ? 'pointer' : 'normal'};
`