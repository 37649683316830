import {useRootStore} from '../RootStateContext';

export const useReckoningStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.reckoningStore) {
    throw new Error('reckoningStore store should be defined');
  }

  return rootStore.reckoningStore;
};
