import { ITableData } from '../../../../interfaces/common/table';
import { ICasesStat, IPublishedUsers, IReckonings } from '../../../../interfaces/admin/statistics/statistics';

export const stableSort = <T>(array: ITableData[] | IPublishedUsers[] | IReckonings[] | ICasesStat[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};
