import React from 'react'
import { RegisterLayoutPublish } from '../../layout/registerLayoutPublish/RegisterLayoutPublish'
import { RegisterFormPublishComponent } from "./form/RegisterFormPublishComponent";
import theme from "../../../../../theme/theme";

export const RegisterPublishingUser = () => {
  return <RegisterLayoutPublish>
    <RegisterFormPublishComponent titleColor={theme.colors.beige}
                                  labelColor={theme.colors.beige}
                                  buttonBcgColor={theme.colors.blue2}
                                  buttonTextColor={theme.colors.darkBlue}/>
  </RegisterLayoutPublish>
}
