import React from 'react'

import {Button} from "../../../shared/button/Button";
import chevron from '../../../../assets/icon/chevron.svg'
import logo from "../../../../assets/img/logo.png";
import theme from "../../../../theme/theme";
import {Img, RegisterInfoContainer, Text} from "./RegisterInfo.styled";

interface IProps {
  clickHandler?: () => void
}

export const RegisterInfo: React.FC<IProps> = ({clickHandler}) => {
  return (
    <RegisterInfoContainer>
      <Img src={logo} alt={'logo'}/>
      <Text>
        Celem naszej pracy jest tworzenie systemów bazodanowych wspierających gromadzenie i udostępnianie danych
        dziedzinowych na potrzeby eksploracji i analiz w celach edukacyjnych.
        <br/>
        <br/>
        Serwis RPM przeznaczony tylko dla uprawnionych użytkowników (HCP). RPM umożliwia dodawanie i zbieranie
        zanonimizowanych opisów przypadków medycznych ze wszystkich obszarów terapeutycznych.
        Serwis będzie promowany jako miejsce eksploracji i analizy w obszarze edukacji medycznej dla HCP.
      </Text>
      {clickHandler && <Button
          clickHandler={clickHandler}
          size={'big'} color={theme.colors.beige}
          backgroundColor={theme.colors.darkBlue}
          text={'zarejestruj się '}
          icon={chevron}
          maxWidth={'400px'}
      />}
    </RegisterInfoContainer>
  )
}
