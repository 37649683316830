import {useRootStore} from '../RootStateContext';

export const useProgressBarNeuroStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.progressBarNeuroStore) {
        throw new Error('progressBarNeuroStore store should be defined');
    }

    return rootStore.progressBarNeuroStore;
};
