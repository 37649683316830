import styled from 'styled-components';
import theme from '../../../../../../theme/theme';

export const ContentAfterContainer = styled.div`
  margin-top: 40px;
`;

export const ParagraphFirst = styled.div`
  display: flex;
  gap: 30px;
  align-items: end;
  margin-bottom: 10px;
  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
    align-items: center;
  }
`;