import styled from 'styled-components';
import theme from '../../../../theme/theme';
import { Grid } from '@mui/material';

interface IColor {
  color: string;
}

export const DataMiddle = styled.div`
  font-weight: 800;
  font-size: 10px;
  line-height: 120%;
  padding-top: 16px;

  & span {
    font-weight: 400;
  }
`;

export const DataLarge = styled.div`
  font-weight: 800;
  font-size: 14px;
  line-height: 120%;
  padding-top: 16px;

  & span {
    font-weight: 400;
    height: 50px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: block;
  }
`;

export const StyledStatus = styled.p<IColor>`
  font-weight: 800;
  font-size: 10px;
  line-height: 120%;

  & span {
    color: ${props => props.color};
    text-transform: uppercase;
  }
`;
export const GridCaseCard = styled(Grid)`
  padding: 8px 8px;

  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 8px 0px !important;
  }
`;

interface ICaseCardContainer {
  maxWidth?: string;
}

export const CaseCardContainer = styled.div<ICaseCardContainer>`
  position: relative;
  cursor: pointer;
  padding: 14px 12px 20px;
  width: 100%;
  background: linear-gradient(180deg, #F9FCFF 0%, #D7EBFC 100%);
  border: 1px solid #D8ECFD;
  height: 231px;
  max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};
`;

export const Icon = styled.div<IColor>`
  border-radius: 50%;
  position: absolute;
  background-color: ${props => props.color};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -15px;
  right: 10px;

  & img {
    max-width: 15px;
    max-height: 15px;
  }
`;

export const Date = styled.div`
  text-align: right;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  padding-top: 4px;

`;

export const StyledNext = styled.div`

  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.dark1};

  border-radius: 50%;
  cursor: pointer;

  & svg {
    transform: rotate(180deg);
    width: 15px;
    height: 12px;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  bottom: 14px;
  right: 10px;
  align-items: flex-end;
  color: ${theme.colors.dark1};
  font-weight: 800;
  font-size: 10px;
  line-height: 120%;
  text-transform: uppercase;
  gap: 5px;

`;