import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useStatisticsStore } from '../store/hooks';
import { IExcelFilters, IStatistics } from '../interfaces/admin/statistics/statistics';
import { AxiosRequestConfig } from 'axios';
import { fileDownload } from '../utils/fileDownload';


export const useStatisticsApi = () => {
  const { api } = useApiCall();
  const statisticsStore = useStatisticsStore();

  const getStatistics = useCallback(
    async (isLoaded?: boolean) => {
      const res: IStatistics = await api.apiInstance.get('statistics', isLoaded);
      if (res) {
        statisticsStore.setStatistics(res);
      }
    },
    [api.apiInstance, statisticsStore]
  );

  const getExcel = useCallback(
    async (object: IExcelFilters) => {
      const config: AxiosRequestConfig = { responseType: 'blob' };
      const res = await api.apiInstance.post('statistics/report', object, true, config);
      if (res) {
        fileDownload(res, false, 'xlsx');
      }
    },
    [api.apiInstance]
  );

  return {
    getStatistics,
    getExcel
  };
};