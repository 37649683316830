import { Chip, Stack, Button } from '@mui/material';
import theme from '../../../../../../theme/theme';
import { ReactComponent as Close } from '../../../../../../assets/icon/close.svg';
import React from 'react';
import { IOption } from '../../../../../../interfaces/common/form/select';
import { useStatisticsStore } from '../../../../../../store/hooks';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { properChips } from '../../Statistics.helper';
import { IAllChips, IFilters } from '../../../../../../interfaces/admin/statistics/statistics';

export const ChipsSection = observer(() => {
  const statisticsStore = useStatisticsStore();
  const { chipsFromFilters, amountOfChips } = useStatisticsStore();
  const location = useLocation();
  const { pathname } = location;
  const lastIndex = pathname.lastIndexOf('/');
  const lastPart = pathname.substring(lastIndex + 1);
  return (
    <Stack direction='row' spacing={1} flexWrap={'wrap'} gap={1}>
      {Object.entries(chipsFromFilters).map(([key, value]) => (
        <React.Fragment key={key}>{value?.map((el: IOption) => (
          <Chip key={el.title} disabled={!properChips[lastPart as keyof IAllChips][key as keyof IFilters]}
                label={el.title}
                sx={chipStyle}
                onDelete={() => statisticsStore.deleteChip(el.id, key)}
                deleteIcon={<Close />}
                variant='outlined' />))}</React.Fragment>
      ))}
      {amountOfChips ?
        <Button variant='text' onClick={() => statisticsStore.resetAllChips()}>Usuń filtry</Button> : null}
    </Stack>
  );
});

const chipStyle = {
  fontSize: '16px',
  margin: 0,
  color: theme.colors.dark1,
  backgroundColor: theme.colors.lightBlue,
  '&.Mui-disabled': {
    backgroundColor: theme.colors.gray5,
    opacity: 1
  },
  '&.MuiChip-label': {
    paddingRight: '18px'
  },
  'svg': {
    margin: '0 12px 0 -6px !important'
  },
  'path': {
    fill: theme.colors.dark1
  }
};