import { useRootStore } from '../RootStateContext';

export const useContractStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.contractStore) {
    throw new Error('contractStore store should be defined');
  }

  return rootStore.contractStore;
};
