import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { IAction, IHeadCell } from '../../../../../interfaces/common/table';
import theme from '../../../../../theme/theme';
import { ReactComponent as Download } from '../../../../../assets/icon/download.svg';
import React, { useEffect, useState } from 'react';
import { useContractApi } from '../../../../../apiHooks';
import { useContractStore } from '../../../../../store/hooks';
import { ActionButton } from '../../../../shared/customTables/button/ActionButton';
import { ReactComponent as CheckboxCheckIcon } from '../../../../../assets/icon/checkboxCheck.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icon/edit.svg';
import { StatusText } from '../../../../shared/admin/status/Status.styled';
import { useModal } from '../../../../shared/modal/useModal.vm';
import { ModalType } from '../../../../../constants/modalType';
import { splitData, SplitData } from '../../../../../utils';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { CellTime } from '../../../../shared/customTables/cells/cellTime/CellTime';
import { Checkbox } from '@mui/material';
import { IArea } from '../../../../../interfaces/admin/areas/areas';
import { useContracts } from '../useContracts.vm';

export const useCompanyContracts = () => {
  const contractStore = useContractStore();
  const { companyContracts, isCompanyContractsLoaded, userInfo } = contractStore;
  const [, setActionId] = useState<null | number>(null);
  const { handleModal, isModalOpened, closeModal, id: editId } = useModal();
  const { getCompanyContractZip } = useContractApi();

  const {
    handleModal: handleAcceptModal,
    isModalOpened: isAcceptModalOpened,
    closeModal: closeAcceptModal,
    id: acceptId
  } = useModal();
  const {
    checkCompanyContractFilePdf,
    getCompanyContractFiles,
    acceptCompanyContractFile,
    getUserInfo
  } = useContractApi();

  const {
    filteredContracts,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years
  } = useContracts(companyContracts);

  useEffect(() => {
    if (!isCompanyContractsLoaded) {
      if (companyContracts === null) {
        void getCompanyContractFiles();
      } else {
        void getCompanyContractFiles(false);
      }
    }
  }, [companyContracts, getCompanyContractFiles, isCompanyContractsLoaded]);

  const handleRefresh = async () => {
    void getCompanyContractFiles(false);
  };

  useEffect(() => {
    return () => {
      contractStore.resetCompanyContracts();
    };
  }, [contractStore]);


  const downloadHandler = async (id: number | string, contractId?: number) => {
    const res = await getCompanyContractZip(+id);
    const blob = new Blob([res], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const acceptContractHandler = async (id: number | string) => {
    handleAcceptModal(ModalType.ACCEPT, +id);
  };

  const handleAccept = async () => {
    if (acceptId) {
      const res = await acceptCompanyContractFile(+acceptId);
      if (res) {
        await getCompanyContractFiles(false);
        closeAcceptModal();
      }
    }
  };

  const editContactHandler = async (id: number | string, user?: number, type?: string, customId?: string, userId?: number) => {
    if (userId) {
      await getUserInfo(userId);
    }
    setActionId(id as number);
    handleModal(ModalType.VIEW_MODAL, id);
  };

  const generateCheckedAction = (id: number | string) => {

    if (companyContracts) {
      const element = companyContracts.find((element) => element.id === id);
      if (element) {

        return <ActionButton id={id as number}
                             background={theme.colors.white}
                             border={theme.colors.white}
                             width={52} actionElement={<Checkbox checked={element.checked} onChange={async () => {
          await checkCompanyContractFilePdf(Number(id), !element.checked);
          await getCompanyContractFiles(false);
        }
        } />} />;
      }
    }

    return null;
  };

  const generateStatusActions = (id: number | string, user?: number, type?: string, customId?: string, userId?: number) => {
    if (companyContracts) {
      const element = companyContracts.find((element) => element.id === id);
      if (element) {
        if (element.accepted) {
          return <StatusText color={theme.colors.turquoise2}><span>zaakceptowane</span></StatusText>;
        } else {
          return <React.Fragment>
            <ActionButton id={id as number} actionHandler={acceptContractHandler}
                          background={theme.colors.turquoise2}
                          border={theme.colors.turquoise2}
                          width={52} actionElement={<CheckboxCheckIcon />} />
            <ActionButton id={id as number} actionHandler={editContactHandler} background={theme.colors.red}
                          border={theme.colors.red}
                          width={52}
                          userId={userId}
                          userContract={user}
                          actionElement={<EditIcon />} />
          </React.Fragment>;
        }
      }
    }
    return null;
  };

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: 'checked',
        custom: generateCheckedAction,
        width: 52
      },
      {
        key: 'download',
        actionHandler: downloadHandler,
        background: theme.colors.yellow,
        color: theme.colors.yellow,
        border: theme.colors.yellow,
        actionElement: <Download />,
        width: 52,
        actionId: 'companyContract'
      },
      {
        key: 'status',
        custom: generateStatusActions,
        width: 104
      }
    ];

    return actions;
  };

  const headCells: IHeadCell[] = [
    { id: 'originalName', numeric: false, label: 'Nazwa' },
    { id: 'fullName', numeric: false, label: 'Lekarz' },
    { id: 'createdAt', numeric: false, label: 'Data przesłania' },
    { id: 'areas', numeric: false, label: 'Obszar terapeutyczny' },
    { id: 'userId', numeric: false, label: '' },
    { id: 'companyContract', numeric: false, label: '' }
  ];

  const tableDataRows = createTableRows(filteredContracts ?? [], headCells, false);

  const cellsSwitcher = (keyName: string, value: string | IArea[], onClick?: () => void) => {
    const specialValue: SplitData = keyName === 'createdAt' ? splitData(value.toString()) : ['-', ''];

    switch (keyName) {
      case 'id':
      case 'companyContract':
      case 'userId':
      case 'user':
        return null;
      case 'createdAt':
        return <TableCellData><CellTime specialValue={specialValue} /></TableCellData>;
      case 'originalName':
        return (
          <TableCellData>
            <>
              1. {value}
              <div>2. kwestionariusz osobowy</div>
            </>
          </TableCellData>
        );
      case 'areas':
        return <TableCellData>{value instanceof Array ? value.map(el => el.fullName).join(', ') : value.toString()}</TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  return {
    companyContractsIsNotNull: companyContracts !== null,
    cellsSwitcher,
    generateActions,
    tableDataRows,
    isModalOpened,
    closeModal,
    userInfo,
    headCells,
    isAcceptModalOpened,
    closeAcceptModal,
    handleAccept,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years,
    editId,
    handleRefresh
  };
};