import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { Grid } from '@mui/material';
import { useMyCasesViewModel } from './useMyCases.vm';
import { CaseCard } from '../../../shared/user/cards/CaseCard';
import { observer } from 'mobx-react-lite';
import { FilterButton } from '../../../shared/filterButton/FilterButton';
import { filterButtonData } from '../../../shared/filterButton/filterButtonData';
import { CaseStatus } from '../../../../interfaces/user/draftCaseNeuro/draft';
import { MuiPagination } from '../../../shared/pagination/MuiPagination';
import { ParagraphEmpty } from './MyCases.styled';
import { GridCaseCard } from '../../../shared/user/cards/CaseCard.styled';
import { MultipleSelect } from '../../../shared/form/multipleSelect/MultipleSelect';

export const MyCases = observer(() => {
  const {
    breadcrumbs,
    listOfMyCases,
    switchFilters,
    filters,
    pageCount,
    currentPage,
    setCurrentPage,
    isListOfCasesIsNotEmpty,
    selectedAreas, handleChange, options
  } = useMyCasesViewModel();

  return (<UserLayout breadcrumbs={breadcrumbs}>
      <Grid container
            display={'flex'}
            margin={'0 auto'}
            alignItems={'center'}
            height={'100%'}
            mt={2}
            pt={1}
            pb={1}
      >
        <Grid item xs={12} sm={12} md={4} display='flex' flexDirection={'column'} gap={4} alignItems={'center'}
              margin={{ xs: '20px auto' }}>
          {options && options.length > 1 && <MultipleSelect
            selectedValues={selectedAreas}
            subtitle={'Obszar'}
            elementName={'areaSelect'}
            options={options}
            handleChange={handleChange}
          />}
          {filterButtonData.map(el => (
            <FilterButton
              key={el.id}
              isActive={filters.includes(el.status as CaseStatus)}
              text={el.text}
              status={el.status as CaseStatus}
              switchFilters={switchFilters}
              color={el.color}>{el.icon}
            </FilterButton>
          ))}
        </Grid>
        <Grid item xs={12} sm={12} md={8} minHeight={'560px'}>
          {isListOfCasesIsNotEmpty ? <Grid display={'flex'} flexDirection={'column'}>
            <Grid container minHeight={'500px'}>
              {listOfMyCases && listOfMyCases.map(el => (
                <GridCaseCard key={el.id + el.customId} item xs={12} sm={6} md={4} lg={3}>
                  <CaseCard type={el.type} id={el.id} status={el.status} customId={el.customId} patient={el.patient}
                            title={el.title} updatedAt={el.updatedAt} />
                </GridCaseCard>
              ))}
            </Grid>
            <MuiPagination
              style={{ alignSelf: 'center', marginBottom: '10px' }}
              count={pageCount}
              page={currentPage}
              onChange={(e, value: number) => {
                setCurrentPage(value);
              }} />
          </Grid> : <ParagraphEmpty>Brak danych</ParagraphEmpty>}
        </Grid>
      </Grid>
    </UserLayout>
  );
});