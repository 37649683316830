import styled from 'styled-components';
import theme from '../../../../../theme/theme';
import backgroundPublish from '../../../../../assets/img/backgroundPublish.png';

export const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  @media (max-width: ${theme.breakpoints.large}px) {
    padding: 0 16px;
  }

`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  & > div {
    min-height: calc(100vh - 40px);
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    overflow: auto;
    & > div {
      height: 100%;
    }
  }
`;

export const Image = styled.div`
  background-image: url(${backgroundPublish}), linear-gradient(180deg, #033D71 0%, #87C1F3 58.85%);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: ${theme.breakpoints.small}px) {
    min-height: unset;
    padding: 40px 0
  }
`;