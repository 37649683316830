import { makeAutoObservable } from 'mobx';
import { AreasStore } from './areasStore';
import { AuthStore } from './authStore';
import { PoiStore } from './poiStore';
import { ProgressBarNeuroStore } from './progressBarNeuroStore';
import { ProgressBarLungStore } from './progressBarLungStore';
import { UsersStore } from './usersStore';
import { RolesStore } from './rolesStore';
import { DraftCaseStore } from './draftCaseStore';
import { HomeStore } from './homeStore';
import { CasesStore } from './casesStore';
import { CaseStore } from './caseStore';
import { HistoryStore } from './historyStore';
import { ProfileStore } from './profileStore';
import { ReckoningStore } from './reckoningStore';
import { ContractStore } from './contractStore';
import { SettingsStore } from './settingsStore';
import { StatisticsStore } from './statisticsStore';

export class RootStore {
  public areasStore = new AreasStore(this);
  public authStore = new AuthStore(this);
  public caseStore = new CaseStore(this);
  public casesStore = new CasesStore(this);
  public historyStore = new HistoryStore(this);
  public poiStore = new PoiStore(this);
  public progressBarNeuroStore = new ProgressBarNeuroStore(this);
  public progressBarLungStore = new ProgressBarLungStore(this);
  public rolesStore = new RolesStore(this);
  public usersStore = new UsersStore(this);
  public draftCase = new DraftCaseStore(this);
  public homeStore = new HomeStore(this);
  public profileStore = new ProfileStore(this);
  public reckoningStore = new ReckoningStore(this);
  public statisticsStore = new StatisticsStore(this);
  public contractStore = new ContractStore(this);
  public settingsStore = new SettingsStore(this);

  constructor() {
    makeAutoObservable(this, {
      areasStore: false,
      authStore: false,
      caseStore: false,
      casesStore: false,
      historyStore: false,
      poiStore: false,
      progressBarNeuroStore: false,
      usersStore: false,
      rolesStore: false,
      homeStore: false,
      profileStore: false,
      reckoningStore: false,
      statisticsStore: false,
      contractStore: false,
      settingsStore: false
    });
  }
}
