import React from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFourthStage } from './useFourthStage.vm';
import { Label } from '../../../../../shared/form/label/LabelContainer';
import theme from '../../../../../../theme/theme';
import { CheckboxGroup, RadioForm } from '../firstStage/FirstStage.styled';
import { RadioButton } from '../../../../../shared/form/radioButton/RadioButton';
import { convertBooleanRadio } from '../secondStage/secondStage.helper';
import { Input } from '../../../../../shared/form/input/Input';
import { FormikProvider } from 'formik';
import { Checkbox } from '../../../../../shared/form/checkbox/Checkbox';

export const FourthStage = observer(() => {
  const {
    formik, handleCheckbox, isDisabled, isSquamous
  } = useFourthStage();

  return (
    <Grid container justifyContent={'space-between'} mb={12}>
      {!isSquamous && <><Grid item xs={12}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Czy wykluczono obecność zmian molekularnych?'}
               isRequired={true} />
      </Grid>
        <Grid item xs={12} mt={1}>
          <Label size={'small'} isUpperCase={true} color={theme.colors.dark1} label={'Alk+'}
                 isRequired={true} />
          <RadioForm>
            <RadioButton
              isUppercase={true}
              isError={formik.touched.diagnosis?.molecularChanges?.isAlk && Boolean(formik.errors.diagnosis?.molecularChanges?.isAlk)}
              id={'diagnosis.molecularChanges.isAlk1'}
              name={'diagnosis.molecularChanges.isAlk'}
              value={'tak'}
              checked={convertBooleanRadio(formik.values.diagnosis.molecularChanges.isAlk) === 'tak'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('diagnosis.molecularChanges.isAlk', newValue);
              }} />
            <RadioButton
              isUppercase={true}
              isError={formik.touched.diagnosis?.molecularChanges?.isAlk && Boolean(formik.errors.diagnosis?.molecularChanges?.isAlk)}
              id={'diagnosis.molecularChanges.isAlk2'}
              name={'diagnosis.molecularChanges.isAlk'}
              value={'nie'}
              checked={convertBooleanRadio(formik.values.diagnosis.molecularChanges.isAlk) === 'nie'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('diagnosis.molecularChanges.isAlk', newValue);
              }} />
          </RadioForm>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Label size={'small'} isUpperCase={true} color={theme.colors.dark1} label={'Egfr'}
                 isRequired={true} />
          <RadioForm>
            <RadioButton
              isUppercase={true}
              isError={formik.touched.diagnosis?.molecularChanges?.isEgfr && Boolean(formik.errors.diagnosis?.molecularChanges?.isEgfr)}
              id={'diagnosis.molecularChanges.isEgfr1'}
              name={'diagnosis.molecularChanges.isEgfr'}
              value={'tak'}
              checked={convertBooleanRadio(formik.values.diagnosis.molecularChanges.isEgfr) === 'tak'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('diagnosis.molecularChanges.isEgfr', newValue);
              }} />
            <RadioButton
              isUppercase={true}
              isError={formik.touched.diagnosis?.molecularChanges?.isEgfr && Boolean(formik.errors.diagnosis?.molecularChanges?.isEgfr)}
              id={'diagnosis.molecularChanges.isEgfr2'}
              name={'diagnosis.molecularChanges.isEgfr'}
              value={'nie'}
              checked={convertBooleanRadio(formik.values.diagnosis.molecularChanges.isEgfr) === 'nie'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('diagnosis.molecularChanges.isEgfr', newValue);
              }} />
          </RadioForm>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Label size={'small'} isUpperCase={true} color={theme.colors.dark1} label={'Ros1'}
                 isRequired={true} />
          <RadioForm>
            <RadioButton
              isUppercase={true}
              isError={formik.touched.diagnosis?.molecularChanges?.isRos && Boolean(formik.errors.diagnosis?.molecularChanges?.isRos)}
              id={'diagnosis.molecularChanges.isRos1'}
              name={'diagnosis.molecularChanges.isRos'}
              value={'tak'}
              checked={convertBooleanRadio(formik.values.diagnosis.molecularChanges.isRos) === 'tak'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('diagnosis.molecularChanges.isRos', newValue);
              }} />
            <RadioButton
              isUppercase={true}
              isError={formik.touched.diagnosis?.molecularChanges?.isRos && Boolean(formik.errors.diagnosis?.molecularChanges?.isRos)}
              id={'diagnosis.molecularChanges.isRos2'}
              name={'diagnosis.molecularChanges.isRos'}
              value={'nie'}
              checked={convertBooleanRadio(formik.values.diagnosis.molecularChanges.isRos) === 'nie'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('diagnosis.molecularChanges.isRos', newValue);
              }} />
          </RadioForm>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
                 label={'Czy wykluczenie obecności zmian molekularnych (EGFR, ALK, ROS1) wykonano metodą NGS (new generation sequencing)?'}
                 isRequired={true} />
          <RadioForm>
            <RadioButton isUppercase={true}
                         isError={formik.touched.diagnosis?.ngs && Boolean(formik.errors.diagnosis?.ngs)}
                         id={'diagnosis.ngs1'}
                         name={'diagnosis.ngs'}
                         value={'tak'} checked={formik.values.diagnosis.ngs === 'tak'}
                         onChange={formik.handleChange} />
            <RadioButton isUppercase={true}
                         isError={formik.touched.diagnosis?.ngs && Boolean(formik.errors.diagnosis?.ngs)}
                         id={'diagnosis.ngs2'}
                         name={'diagnosis.ngs'}
                         value={'nie'} checked={formik.values.diagnosis.ngs === 'nie'}
                         onChange={formik.handleChange} />
            <RadioButton isUppercase={true}
                         isError={formik.touched.diagnosis?.ngs && Boolean(formik.errors.diagnosis?.ngs)}
                         id={'diagnosis.ngs3'}
                         name={'diagnosis.ngs'}
                         value={'brak danych'} checked={formik.values.diagnosis.ngs === 'brak danych'}
                         onChange={formik.handleChange} />
          </RadioForm>
        </Grid></>}
      <Grid item xs={12} mt={4}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Poziom ekspresji PD-L1: (wartość w przedziale lub dokładna liczba %)'}
               isRequired={true} />
        <RadioForm $flexDirection={'column'}>
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.expressionLevel?.level && Boolean(formik.errors.diagnosis?.expressionLevel?.level)}
                       id={'diagnosis.expressionLevel.level1'}
                       name={'diagnosis.expressionLevel.level'}
                       value={'podano tylko wynik „ekspresja PD-L1 tj. TPS ≥ 50%”'}
                       checked={formik.values.diagnosis.expressionLevel.level === 'podano tylko wynik „ekspresja PD-L1 tj. TPS ≥ 50%”'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.expressionLevel?.level && Boolean(formik.errors.diagnosis?.expressionLevel?.level)}
                       id={'diagnosis.expressionLevel.level2'}
                       name={'diagnosis.expressionLevel.level'}
                       value={'podano wynik dokładnie oceniając % wybarwionych komórek'}
                       checked={formik.values.diagnosis.expressionLevel.level === 'podano wynik dokładnie oceniając % wybarwionych komórek'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      {formik.values.diagnosis.expressionLevel.level === 'podano wynik dokładnie oceniając % wybarwionych komórek' &&
        <Grid item xs={12} mt={1} pl={2}>
          <Input
            size={'small'}
            color={theme.colors.dark1}
            isUpperCase={true}
            name={'diagnosis.expressionLevel.percent'}
            type={'text'}
            value={(formik.values.diagnosis.expressionLevel.level !== 'podano wynik dokładnie oceniając % wybarwionych komórek') ? '' : formik.values.diagnosis.expressionLevel.percent || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Wpisz...'}
            isError={formik.touched.diagnosis?.expressionLevel?.percent && Boolean(formik.errors.diagnosis?.expressionLevel?.percent)}
          />
        </Grid>}
      <Grid item xs={12} mt={2}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Stopień zaawansowania klinicznego na podstawie TNM w chwili kwalifikacji do immunoterapii'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.tnm && Boolean(formik.errors.diagnosis?.tnm)}
                       id={'diagnosis.tnm1'}
                       name={'diagnosis.tnm'}
                       value={'III'}
                       checked={formik.values.diagnosis.tnm === 'III'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.tnm && Boolean(formik.errors.diagnosis?.tnm)}
                       id={'diagnosis.tnm2'}
                       name={'diagnosis.tnm'}
                       value={'IV'}
                       checked={formik.values.diagnosis.tnm === 'IV'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Przerzuty do ośrodkowego układu nerwowego (OUN)'}
               isRequired={true} />
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
               label={'Obecność przerzutów w OUN?'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.metastases?.status && Boolean(formik.errors.diagnosis?.metastases?.status)}
                       id={'diagnosis.metastases.status1'}
                       name={'diagnosis.metastases.status'}
                       value={'tak'}
                       checked={formik.values.diagnosis.metastases.status === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.metastases?.status && Boolean(formik.errors.diagnosis?.metastases?.status)}
                       id={'diagnosis.metastases.status2'}
                       name={'diagnosis.metastases.status'}
                       value={'nie'}
                       checked={formik.values.diagnosis.metastases.status === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.metastases?.status && Boolean(formik.errors.diagnosis?.metastases?.status)}
                       id={'diagnosis.metastases.status3'}
                       name={'diagnosis.metastases.status'}
                       value={'brak oceny radiologicznej'}
                       checked={formik.values.diagnosis.metastases.status === 'brak oceny radiologicznej'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      {formik.values.diagnosis.metastases.status === 'tak' && <>
        <Grid item xs={12} mt={2} pl={2}>
          <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
                 label={'Czy występują objawy ze strony OUN?'}
                 isRequired={true} />
          <RadioForm>
            <RadioButton isUppercase={true}
                         isError={formik.touched.diagnosis?.metastases?.isCns && Boolean(formik.errors.diagnosis?.metastases?.isCns)}
                         id={'diagnosis.metastases.isCns1'}
                         name={'diagnosis.metastases.isCns'}
                         value={'tak'}
                         checked={convertBooleanRadio(formik.values.diagnosis.metastases.isCns) === 'tak'}
                         onChange={(e) => {
                           const newValue = e.target.value === 'tak';
                           formik.setFieldValue('diagnosis.metastases.isCns', newValue);
                         }} />
            <RadioButton isUppercase={true}
                         isError={formik.touched.diagnosis?.metastases?.isCns && Boolean(formik.errors.diagnosis?.metastases?.isCns)}
                         id={'diagnosis.metastases.isCns2'}
                         name={'diagnosis.metastases.isCns'}
                         value={'nie'}
                         checked={convertBooleanRadio(formik.values.diagnosis.metastases.isCns) === 'nie'}
                         onChange={(e) => {
                           const newValue = e.target.value === 'tak';
                           formik.setFieldValue('diagnosis.metastases.isCns', newValue);
                         }} />
          </RadioForm>
        </Grid>
        {convertBooleanRadio(formik.values.diagnosis.metastases.isCns) === 'tak' && <Grid item xs={12} mt={2} pl={2}>
          <Input
            isRequired={true}
            label={'Jakie?'}
            size={'superSmall'}
            color={theme.colors.dark1}
            isUpperCase={true}
            name={'diagnosis.metastases.which'}
            type={'text'}
            value={formik.values.diagnosis.metastases.which || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Wpisz...'}
            isError={formik.touched.diagnosis?.metastases?.which && Boolean(formik.errors.diagnosis?.metastases?.which)}
          />
        </Grid>}</>}

      <Grid item xs={12} mt={3}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Wymień 3 największe wg Ciebie, problemy na ścieżce diagnostycznej danego pacjenta'} />
        <FormikProvider value={formik}>
          <CheckboxGroup $flexDirection={'column'} $padding={'0 0 0 16px'}>
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('Zbyt długi czas oczekiwania na wynik cytologiczny /  histopatologiczny / radiologiczny')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list1'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'Zbyt długi czas oczekiwania na wynik cytologiczny /  histopatologiczny / radiologiczny'}
              checked={formik.values.diagnosis?.problems?.list?.includes('Zbyt długi czas oczekiwania na wynik cytologiczny /  histopatologiczny / radiologiczny')}
              onChange={() => handleCheckbox('Zbyt długi czas oczekiwania na wynik cytologiczny /  histopatologiczny / radiologiczny')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('Zbyt długi czas oczekiwania na badania radiologiczne')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list2'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'Zbyt długi czas oczekiwania na badania radiologiczne'}
              checked={formik.values.diagnosis?.problems?.list?.includes('Zbyt długi czas oczekiwania na badania radiologiczne')}
              onChange={() => handleCheckbox('Zbyt długi czas oczekiwania na badania radiologiczne')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('słabe wykorzystanie pakietu onkologicznego w POZ na etapie podejrzenia  nowotworu')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list3'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'słabe wykorzystanie pakietu onkologicznego w POZ na etapie podejrzenia  nowotworu'}
              checked={formik.values.diagnosis?.problems?.list?.includes('słabe wykorzystanie pakietu onkologicznego w POZ na etapie podejrzenia  nowotworu')}
              onChange={() => handleCheckbox('słabe wykorzystanie pakietu onkologicznego w POZ na etapie podejrzenia  nowotworu')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('zbyt długie zwlekanie pacjenta ze zgłoszeniem się do lekarza z objawami')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list4'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'zbyt długie zwlekanie pacjenta ze zgłoszeniem się do lekarza z objawami'}
              checked={formik.values.diagnosis?.problems?.list?.includes('zbyt długie zwlekanie pacjenta ze zgłoszeniem się do lekarza z objawami')}
              onChange={() => handleCheckbox('zbyt długie zwlekanie pacjenta ze zgłoszeniem się do lekarza z objawami')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('Nieodpowiednia  jakość materiału do badań histopatologiczny / złe przygotowanie materiału')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list5'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'Nieodpowiednia  jakość materiału do badań histopatologiczny / złe przygotowanie materiału'}
              checked={formik.values.diagnosis?.problems?.list?.includes('Nieodpowiednia  jakość materiału do badań histopatologiczny / złe przygotowanie materiału')}
              onChange={() => handleCheckbox('Nieodpowiednia  jakość materiału do badań histopatologiczny / złe przygotowanie materiału')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas diagnostyki na etapie POZ')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list6'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'zbyt długi czas diagnostyki na etapie POZ'}
              checked={formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas diagnostyki na etapie POZ')}
              onChange={() => handleCheckbox('zbyt długi czas diagnostyki na etapie POZ')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('leczenie w POZ bez diagnostyki radiologicznej')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list7'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'leczenie w POZ bez diagnostyki radiologicznej'}
              checked={formik.values.diagnosis?.problems?.list?.includes('leczenie w POZ bez diagnostyki radiologicznej')}
              onChange={() => handleCheckbox('leczenie w POZ bez diagnostyki radiologicznej')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas oczekiwania na konsultację pulmonologiczną')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list8'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'zbyt długi czas oczekiwania na konsultację pulmonologiczną'}
              checked={formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas oczekiwania na konsultację pulmonologiczną')}
              onChange={() => handleCheckbox('zbyt długi czas oczekiwania na konsultację pulmonologiczną')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas oczekiwania na inną konsultację specjalistyczną')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list9'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'zbyt długi czas oczekiwania na inną konsultację specjalistyczną'}
              checked={formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas oczekiwania na inną konsultację specjalistyczną')}
              onChange={() => handleCheckbox('zbyt długi czas oczekiwania na inną konsultację specjalistyczną')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas do wykonania bronchoskopii/EBUS/EUS')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list10'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'zbyt długi czas do wykonania bronchoskopii/EBUS/EUS'}
              checked={formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas do wykonania bronchoskopii/EBUS/EUS')}
              onChange={() => handleCheckbox('zbyt długi czas do wykonania bronchoskopii/EBUS/EUS')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('Zbyt długi czas do wykonania/opisu badań radiologicznych (TK, PET CT)')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list11'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'Zbyt długi czas do wykonania/opisu badań radiologicznych (TK, PET CT)'}
              checked={formik.values.diagnosis?.problems?.list?.includes('Zbyt długi czas do wykonania/opisu badań radiologicznych (TK, PET CT)')}
              onChange={() => handleCheckbox('Zbyt długi czas do wykonania/opisu badań radiologicznych (TK, PET CT)')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas oczekiwania na opis biopsji')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list12'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'zbyt długi czas oczekiwania na opis biopsji'}
              checked={formik.values.diagnosis?.problems?.list?.includes('zbyt długi czas oczekiwania na opis biopsji')}
              onChange={() => handleCheckbox('zbyt długi czas oczekiwania na opis biopsji')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('niewłaściwy materiał/zbyt mała ilość materiału do wykonania badań molekularnych')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list13'}
              name={'cardiovascularDiseases'}
              textColor={theme.colors.dark1}
              label={'niewłaściwy materiał/zbyt mała ilość materiału do wykonania badań molekularnych'}
              checked={formik.values.diagnosis?.problems?.list?.includes('niewłaściwy materiał/zbyt mała ilość materiału do wykonania badań molekularnych')}
              onChange={() => handleCheckbox('niewłaściwy materiał/zbyt mała ilość materiału do wykonania badań molekularnych')}
            />
            <Checkbox
              disabled={isDisabled && !formik.values.diagnosis?.problems?.list?.includes('niewłaściwy materiał/zbyt mała ilość materiału do oznaczenia ekspresji PD-L1')}
              fontSize={'12px'}
              isUppercase={true}
              isError={formik.touched.diagnosis?.problems?.list && Boolean(formik.errors.diagnosis?.problems?.list)}
              id={'diagnosis.problems.list14'}
              name={'diagnosis.problems.list'}
              textColor={theme.colors.dark1}
              label={'niewłaściwy materiał/zbyt mała ilość materiału do oznaczenia ekspresji PD-L1'}
              checked={formik.values.diagnosis?.problems?.list?.includes('niewłaściwy materiał/zbyt mała ilość materiału do oznaczenia ekspresji PD-L1')}
              onChange={() => handleCheckbox('niewłaściwy materiał/zbyt mała ilość materiału do oznaczenia ekspresji PD-L1')}
            />
          </CheckboxGroup>
        </FormikProvider>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
               label={'Inne'}
        />
        <Input
          disabled={formik.values.diagnosis.problems.list?.length >= 3}
          size={'superSmall'}
          color={theme.colors.dark1}
          isUpperCase={true}
          name={'diagnosis.problems.others'}
          type={'text'}
          value={formik.values.diagnosis.problems?.others?.[0] || ''}
          onChange={(e) => formik.setFieldValue('diagnosis.problems.others[0]', e.target.value)}
          onBlur={() => formik.setFieldTouched('diagnosis.problems.others[0]', true)}
          placeholder={'Wpisz...'}
          isError={formik.touched.diagnosis?.problems?.others && Boolean(formik.errors.diagnosis?.problems?.others)}
        />
        <Input
          disabled={formik.values.diagnosis.problems.list?.length >= 2}
          size={'superSmall'}
          color={theme.colors.dark1}
          isUpperCase={true}
          name={'diagnosis.problems.others'}
          type={'text'}
          value={formik.values.diagnosis.problems?.others?.[1] || ''}
          onChange={(e) => formik.setFieldValue('diagnosis.problems.others[1]', e.target.value)}
          onBlur={() => formik.setFieldTouched('diagnosis.problems.others[1]', true)}
          placeholder={'Wpisz...'}
          isError={formik.touched.diagnosis?.problems?.others && Boolean(formik.errors.diagnosis?.problems?.others)}
        />
        <Input
          disabled={formik.values.diagnosis.problems.list?.length >= 1}
          size={'superSmall'}
          color={theme.colors.dark1}
          isUpperCase={true}
          name={'diagnosis.problems.others'}
          type={'text'}
          value={formik.values.diagnosis.problems?.others?.[2] || ''}
          onChange={(e) => formik.setFieldValue('diagnosis.problems.others[2]', e.target.value)}
          onBlur={() => formik.setFieldTouched('diagnosis.problems.others[2]', true)}
          placeholder={'Wpisz...'}
          isError={formik.touched.diagnosis?.problems?.others && Boolean(formik.errors.diagnosis?.problems?.others)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Karta DILO'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.dilo && Boolean(formik.errors.diagnosis?.dilo)}
                       id={'diagnosis.dilo1'}
                       name={'diagnosis.dilo'}
                       value={'tak'}
                       checked={formik.values.diagnosis.dilo === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.dilo && Boolean(formik.errors.diagnosis?.dilo)}
                       id={'diagnosis.dilo2'}
                       name={'diagnosis.dilo'}
                       value={'nie'}
                       checked={formik.values.diagnosis.dilo === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.diagnosis?.dilo && Boolean(formik.errors.diagnosis?.dilo)}
                       id={'diagnosis.dilo3'}
                       name={'diagnosis.dilo'}
                       value={'brak danych'}
                       checked={formik.values.diagnosis.dilo === 'brak danych'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
    </Grid>
  );
});