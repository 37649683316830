import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAreasStore, useAuthStore} from '../../../../store/hooks';
import {useAreasApi} from '../../../../apiHooks/useAreasApi';
import {IHeadCell} from '../../../../interfaces/common/table';
import {AdminLink, checkPermission, generateLink} from '../../../../utils';
import {RoleCategory, RoleFunction} from '../../../../constants/projectUsers';
import {createTableRows} from '../../../shared/customTables/utils/createTableRows';
import {ModalType} from '../../../../constants/modalType';
import {useModal} from '../../../shared/modal/useModal.vm';
import {ReactComponent as DeleteIcon} from '../../../../assets/icon/delete.svg';
import {ReactComponent as EditIcon} from '../../../../assets/icon/edit.svg';
import theme from '../../../../theme/theme';

export const useAreas = () => {
  const [actionId, setActionId] = useState<number | null>(null);

  const {roleFunction} = useAuthStore();
  const areasStore = useAreasStore();
  const {getAreas, deleteArea} = useAreasApi();
  const {areas, isAreasLoaded} = areasStore;
  const navigate = useNavigate();
  const {handleModal, isModalOpened, closeModal, modalType} = useModal();

  useEffect(() => {
    if (!isAreasLoaded) {
      if (areas === null) {
        void getAreas()
      } else {
        void getAreas(false)
      }
    }
  }, [getAreas, isAreasLoaded, areas])

  useEffect(() => {
    return () => areasStore.resetAreas();
  }, [areasStore])

  const headCells: IHeadCell[] = [
    {id: 'shortName', numeric: false, label: 'Krótka nazwa'},
    {id: 'fullName', numeric: true, label: 'Długa nazwa'},
    {id: 'situationsLimit', numeric: true, label: 'Limit'}
  ]

  const addHandler = () => {
    navigate(generateLink(RoleCategory.ADMIN, AdminLink.ADD_AREA))
  }

  const editHandler = (id: number | string) => {
    navigate(generateLink(RoleCategory.ADMIN, AdminLink.EDIT_AREA, +id))
  }

  const deleteHandler = (id: number | string) => {
    handleModal(ModalType.DELETE_MODAL);
    setActionId(+id);
  }

  const deleteHandlerYes = async () => {
    if (areas && actionId) {
      const isDelete = await deleteArea(actionId);
      if (isDelete) {
        handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
      }
      else{
        handleModal(ModalType.DELETE_ERROR_MODAL);
      }
      setActionId(null);
    }
  }

  const generateActions = () => {
    if (roleFunction && checkPermission(roleFunction, [RoleFunction.ROLE_ADMIN])) {
      return [
        {
          key: 'edit',
          actionHandler: editHandler,
          background: theme.colors.darkBlue,
          actionElement: <EditIcon/>,
          width: 52,
        },
        {
          key: 'delete',
          actionHandler: deleteHandler,
          background: theme.colors.blue4,
          actionElement: <DeleteIcon/>,
          width: 52,
        }
      ]
    }
    return [];
  }

  const tableDataRows = createTableRows(areas ? areas : [], headCells, false)

  return {
    areasIsNotNull: areas !== null,
    tableDataRows,
    headCells,
    generateActions,
    addHandler,
    deleteHandlerYes,
    modalType,
    isModalOpened,
    closeModal
  }
}