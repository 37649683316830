import styled from "styled-components";
import theme from "../../../../theme/theme";

export const Text = styled.div`
  width: 100%;
  max-width: 459px;
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);

  h3 {
    color: ${theme.colors.dark1};
    font-weight: 800;
    font-size: 36px;
    line-height: 43px;

    @media (max-width: ${theme.breakpoints.small}px) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  span {
    color: ${theme.colors.turquoise2};
    text-transform: uppercase;
  }

  p {
    color: ${theme.colors.dark1};
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }
`