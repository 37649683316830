import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

import {
  FieldSetDoctorFormVariantFirst
} from './fieldsetDoctorForm/fieldsetDoctorFormVariantFirst/FieldSetDoctorFormVariantFirst';
import {
  variantFirstConfig, variantFirstConfigForFamily, variantSecondConfig, variantSecondConfigForSquamous
} from './DoctorForm.helper';
import { DateInput } from '../../../../../../shared/form/date/Date';
import { Button } from '../../../../../../shared/button/Button';
import {
  DoctorType,
  IDoctorFieldSets,
  IFieldSetDoctorFormVariantFirst, IFieldSetDoctorFormVariantSecond
} from '../../../../../../../interfaces/user/draftCaseLung/thirdStage';
import {
  FieldSetDoctorFormVariantSecond
} from './fieldsetDoctorForm/fieldsetDoctorFormVariantSecond/FieldSetDoctorFormVariantSecond';
import theme from '../../../../../../../theme/theme';
import { StyledIcon } from '../../../../draftCaseNeuro/stages/thirdStage/accordion/AccordionCustom.styled';
import { ReactComponent as Bin } from '../../../../../../../assets/icon/bin.svg';
import { useDoctorForm } from './useDoctorForm';
import { Modal } from '../../../../../../shared/modal/Modal';
import { ModalType } from '../../../../../../../constants/modalType';
import { DeleteModal } from '../../../../../../shared/modal/sharedModals/deleteModal/DeleteModal';
import { ConfirmationModal } from '../../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';

interface IProps {
  type: DoctorType;
  data?: IDoctorFieldSets;
  onSave: (values: IDoctorFieldSets) => void;
  formContainer?: SxProps<Theme>;
  buttonsContainer?: SxProps<Theme>;
  deleteDoctor?: (id: number) => Promise<boolean>;
  isAccordion?: boolean;
}

export const DoctorForm: FC<IProps> = ({
                                         data,
                                         onSave,
                                         type,
                                         formContainer,
                                         buttonsContainer,
                                         deleteDoctor,
                                         isAccordion
                                       }) => {
  const {
    formik,
    modalType,
    closeModal,
    deleteModalHandler,
    deleteYesHandler,
    isFamilyDoctor,
    isSquamous,
    closeDeleteYesModalHandler,
    submitDoctorForm
  } = useDoctorForm(type, onSave, data, deleteDoctor, isAccordion);

  const variantFirstConfigType = isFamilyDoctor ? variantFirstConfigForFamily : variantFirstConfig;
  const variantSecondConfigType = isSquamous ? variantSecondConfigForSquamous : variantSecondConfig;

  return <><Grid container sx={formContainer}>
    <DateInput
      openTo={'year'}
      color={theme.colors.turquoise2}
      inputFormat={'mm.yyyy'}
      views={['year', 'month']}
      isRequired={true}
      maxWidth={'180px'}
      disableFuture
      label={'Data wizyty'}
      isUpperCase={true}
      name={'date'}
      value={formik.values.date}
      onChange={(value) => formik.setFieldValue('date', value)}
      isError={Boolean(formik.errors.date) && formik.touched.date}
    />
    {variantFirstConfigType.map(element =>
      <FieldSetDoctorFormVariantFirst key={element[0]}
                                      keyFieldSet={element[0]}
                                      title={element[1]}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      data={formik.values[element[0]] as IFieldSetDoctorFormVariantFirst}
                                      errors={formik.errors as IDoctorFieldSets}
                                      submited={formik.submitCount > 0}
                                      setFieldValue={formik.setFieldValue}
                                      setFieldTouched={formik.setFieldTouched}
      />)}
    {!isFamilyDoctor && variantSecondConfigType.map(element =>
      <FieldSetDoctorFormVariantSecond key={element[0]}
                                       keyFieldSet={element[0]}
                                       title={element[1]}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       data={formik.values[element[0]] as IFieldSetDoctorFormVariantSecond}
                                       errors={formik.errors as IDoctorFieldSets}
                                       submited={formik.submitCount > 0}
                                       setFieldValue={formik.setFieldValue}
                                       setFieldTouched={formik.setFieldTouched}
      />)}
  </Grid>
    <Grid container sx={buttonsContainer}>
      <Button text={'Zapisz'} clickHandler={submitDoctorForm} size={'small'} backgroundColor={'black'}
              color={'white'} width={'190px'}></Button>
      {deleteDoctor && data && <StyledIcon onClick={deleteModalHandler}><Bin /></StyledIcon>}
    </Grid>
    {modalType &&
      <Modal clickHandler={modalType === ModalType.DELETE_CONFIRMATION_MODAL ? closeDeleteYesModalHandler : closeModal}>
        {modalType === ModalType.DELETE_MODAL &&
          <DeleteModal handleNo={closeModal} handleYes={deleteYesHandler}
                       textAdded={'tego lekarza ze ścieżki pacjenta'} />
        }
        {modalType === ModalType.DELETE_CONFIRMATION_MODAL &&
          <ConfirmationModal text={<p>Lekarz został usunięty poprawnie ze ścieżki pacjenta.</p>} />
        }
      </Modal>
    }
  </>;
};