
import {Grid} from '@mui/material';
import theme from '../../../../../../../theme/theme';
import {DateInput} from '../../../../../../shared/form/date/Date';
import {Button} from '../../../../../../shared/button/Button';
import {FC} from 'react';
import {useModalAddIncident} from './useModalAddIncident';
import {IIncident} from '../../../../../../../interfaces/user/draftCaseNeuro/draft';
import {Input} from '../../../../../../shared/form/input/Input';
import {Container, ModalTitle, Title} from './ModalAddIncident.styled'
import {observer} from 'mobx-react-lite'

interface IProps {
  idDrug: number;
  incident: IIncident | null;
  handleCloseDrugModal: () => void;
}

export const ModalAddIncident: FC<IProps> = observer(({idDrug, handleCloseDrugModal, incident}) => {

  const {formik, incidentNumber} = useModalAddIncident(idDrug, incident, handleCloseDrugModal);

  return (
    <Container display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Title>Ocena aktywności rzutowej podczas leczenia danym lekiem</Title>
      <ModalTitle>Rzut {incidentNumber}</ModalTitle>
      <form onSubmit={formik.handleSubmit}
            style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
        <div style={{alignSelf: 'center'}}>
          <DateInput
            openTo={'year'}
            inputFormat={'mm.yyyy'}
            views={['year', 'month']}
            label={'Data rzutu'}
            maxWidth={'385px'}
            isUpperCase={true}
            name={'date'}
            size={'small'}
            onBlur={() => formik.setFieldTouched('date', true)}
            color={theme.colors.dark1}
            value={formik.values.date}
            onChange={(e) => formik.setFieldValue('date', e)}
            placeholder={'Wybierz date rzutu'}
            isError={formik.touched.date && Boolean(formik.errors.date)}
          />
        </div>
        <Input
          label={'Opis rzutu (objawy), wdrożone leczenie rzutu, ocena ciężkości rzutu w skali edss przed i po leczeniu rzutu'}
          isUpperCase={true}
          name={'description'}
          type={'textarea'}
          size={'small'}
          color={theme.colors.dark1}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz opis rzutu...'}
          isError={formik.touched.description && Boolean(formik.errors.description)}
        />
        <Grid pt={3} justifyContent={'center'} display={'flex'}>
          <Button text={'zapisz'}
                  backgroundColor={theme.colors.dark1}
                  type={'submit'}
                  color={theme.colors.beige} width={'195px'} size={'small'}/>

        </Grid>
      </form>

    </Container>
  )
})

