import React from "react";
import {Text} from "./TextDrImage.styled";

export const TextDrImage = () => {
  return (
    <Text>
      <h3>Jesteś lekarzem? Dołącz <span>Teraz bezpłatnie</span></h3>
      <p>
        do rezpozytorium i społeczności medycznej. Poszerzaj swoją wiedzę w
        zakresie <strong>MS</strong>.
      </p>
    </Text>
  )
}
