import { useCaseStore } from '../../../../../store/hooks';
import { Container, SectionTitle, Title, Content, BlackTitle } from './Diagnosis.styled';
import React from 'react';
import { ViewCheckbox } from '../sharedComponents/checkbox/ViewCheckbox';

export const Diagnosis = () => {
  const caseStore = useCaseStore();
  const { viewCaseLung } = caseStore;
  const isNotComissioned = viewCaseLung?.doctors.filter(el => el.type !== 'family' && (el.alk?.isCommissioned || el.egfr?.isCommissioned || el.ros?.isCommissioned))?.length;
  const isSquamous = (viewCaseLung && viewCaseLung.interview.histologicalCancer === 'płaskonabłonkowy') || !(!!isNotComissioned && isNotComissioned > 0);
  return (
    <Container>
      {viewCaseLung && viewCaseLung.diagnosis &&
        <>
          <SectionTitle>Rozpoznanie:</SectionTitle>
          {!isSquamous && <>
            <Title>Czy wykluczono obecność zmian molekularnych?</Title>
            <div>
              <BlackTitle>Alk+</BlackTitle>
              <ViewCheckbox margin={'16px 0'}
              >{viewCaseLung.diagnosis.molecularChanges.isAlk ? 'tak' : 'nie'}</ViewCheckbox>
            </div>
            <div><BlackTitle>Egfr</BlackTitle>
              <ViewCheckbox margin={'16px 0'}
              >{viewCaseLung.diagnosis.molecularChanges.isEgfr ? 'tak' : 'nie'}</ViewCheckbox>
            </div>
            <div><BlackTitle>Ros1</BlackTitle>
              <ViewCheckbox margin={'16px 0'}
              >{viewCaseLung.diagnosis.molecularChanges.isRos ? 'tak' : 'nie'}</ViewCheckbox>
            </div>
            <Title>Czy wykluczenie obecności zmian molekularnych (EGFR, ALK, ROS1)
              wykonano metodą NGS (new generation
              sequencing)?</Title>
            <ViewCheckbox margin={'16px 0'}
            >{viewCaseLung.diagnosis.ngs || ''}</ViewCheckbox></>}
          <Title>Poziom ekspresji PD-L1: (wartość w przedziale lub dokładna liczba %)</Title>
          <ViewCheckbox margin={'16px 0'}
          >{viewCaseLung.diagnosis.expressionLevel.level || ''}</ViewCheckbox>
          {viewCaseLung.diagnosis.expressionLevel.percent &&
            <Content>{viewCaseLung.diagnosis.expressionLevel.percent}</Content>}
          <Title>Stopień zaawansowania klinicznego na podstawie TNM w chwili kwalifikacji do immunoterapii</Title>
          <ViewCheckbox margin={'16px 0'}
          >{viewCaseLung.diagnosis.tnm || ''}</ViewCheckbox>
          <Title>Przerzuty do ośrodkowego układu nerwowego (OUN)</Title>
          <BlackTitle>Obecność przerzutów w OUN?</BlackTitle>
          <ViewCheckbox margin={'16px 0'}
          >{viewCaseLung.diagnosis.metastases.status || ''}</ViewCheckbox>
          {viewCaseLung.diagnosis.metastases.status === 'tak' && <>
            <BlackTitle>Czy występują objawy ze strony OUN?</BlackTitle>
            <ViewCheckbox margin={'16px 0'}
            >{viewCaseLung.diagnosis.metastases.isCns ? 'tak' : 'nie'}</ViewCheckbox>
          </>}
          {viewCaseLung.diagnosis.metastases.isCns && <>
            <BlackTitle>Jakie?</BlackTitle>
            <Content>{viewCaseLung.diagnosis.metastases.which || ''}</Content>
          </>}

          <Title>Wymień 3 największe wg Ciebie, problemy na ścieżce diagnostycznej danego pacjenta</Title>
          {viewCaseLung.diagnosis.problems.list?.length > 0 && viewCaseLung.diagnosis.problems.list.map(el =>
            <ViewCheckbox
              key={el}
              margin={'16px 0'}>{el}</ViewCheckbox>)}

          {viewCaseLung.diagnosis.problems.others?.length > 0 && <>
            <BlackTitle>Inne</BlackTitle>

            {viewCaseLung.diagnosis.problems.others?.map(el =>
              <Content key={el}>{el}</Content>)}</>
          }
          <Title>Karta Dilo</Title>
          <ViewCheckbox margin={'16px 0'}
          >{viewCaseLung.diagnosis.dilo || ''}</ViewCheckbox>
        </>
      }
    </Container>
  );
};