import { ReactNode, useEffect, useState } from 'react';
import { useAreasStore, useAuthStore } from '../../../../store/hooks';
import { useAreasApi } from '../../../../apiHooks/useAreasApi';
import { SelectChangeEvent } from '@mui/material';
import { IOption } from '../../../../interfaces/common/form/select';
import { filterByArea, filterByYear } from '../../../../utils/searchHelpers';
import { IContract, IDocuments } from '../../../../interfaces/admin/contract/contract';
import { yearArrayGenerate } from '../../../shared/admin/filter/filter.helper';
import { useRolesApi } from '../../../../apiHooks';
import { useRolesStore } from '../../../../store/hooks/useRolesStore';
import { generateOptionsArea } from '../../../../utils/generateOptionsArea';
import { IPublishingUser } from '../../../../interfaces/admin/user/publishingUser';

export const useContracts = <T extends IContract | IDocuments | IPublishingUser>(contracts: T[] | null, isOwnArea: boolean = false) => {
  const { areas } = useAreasStore();
  const { roles } = useRolesStore();
  const { areas: areasUser } = useAuthStore();
  const { getAreas } = useAreasApi();
  const { getRoles } = useRolesApi();

  const [selectedAreas, setSelectedAreas] = useState<number[]>([]);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  const years = yearArrayGenerate();

  useEffect(() => {
    void getAreas();
  }, [getAreas]);

  useEffect(() => {
    void getRoles();
  }, [getRoles]);

  const selectedAreaTypes = selectedAreas
    .map(selectedId => areas?.find(el => el.id === selectedId))
    .filter(area => area !== undefined)
    .map(area => area!.fullName);

  const optionsAreas = generateOptionsArea(isOwnArea, areas, areasUser);
  const optionsRoles = roles;

  const handleChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedAreas.includes(0)) {
        setSelectedAreas(selectedValues.filter(value => value !== 0));
      } else {
        if (optionsAreas) {
          setSelectedAreas([...optionsAreas.map((option: IOption) => option.id), 0]);
        }
      }
    } else {
      if (selectedAreas.includes(0)) {
        setSelectedAreas([]);
      } else {
        setSelectedAreas(selectedValues);
      }
    }
  };

  const handleChangeYears = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedYears.includes(0)) {
        setSelectedYears(selectedValues.filter(value => value !== 0));
      } else {
        if (years) {
          setSelectedYears([...years.map((year: IOption) => year.id), 0]);
        }
      }
    } else {
      if (selectedYears.includes(0)) {
        setSelectedYears([]);
      } else {
        setSelectedYears(selectedValues);
      }
    }
  };

  const handleChangeRole = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedUsers.includes(0)) {
        setSelectedUsers(selectedValues.filter(value => value !== 0));
      } else {
        if (roles) {
          setSelectedUsers([...roles.map((role: IOption) => role.id), 0]);
        }
      }
    } else {
      if (selectedUsers.includes(0)) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(selectedValues);
      }
    }
  };

  const filteredContracts = contracts && contracts.filter((contract) => {
    if ('createdAt' in contract) {
      return filterByYear(selectedYears)(contract);
    }
    return true;
  }).filter(filterByArea(selectedAreaTypes));

  return {
    filteredContracts,
    optionsAreas,
    selectedAreas,
    optionsRoles,
    handleChange,
    selectedYears,
    selectedUsers,
    handleChangeYears,
    handleChangeRole,
    years
  };
};