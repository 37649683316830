import React, {useEffect} from 'react';
import {IHeadCell} from '../../../../interfaces/common/table';
import {createTableRows} from '../../../shared/customTables/utils/createTableRows';
import {ReactComponent as EditIcon} from '../../../../assets/icon/edit.svg';
import theme from '../../../../theme/theme';

import {AdminLink, generateLink} from '../../../../utils';
import {useNavigate} from 'react-router-dom';

import {RoleCategory, RoleFunction} from '../../../../constants/projectUsers';
import {useRolesApi} from '../../../../apiHooks/useRolesApi';
import {useRolesStore} from '../../../../store/hooks/useRolesStore';
import {checkPermission} from '../../../../utils/checkPermission';
import {useAuthStore} from '../../../../store/hooks';
import {TableCellData} from "../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled";

export const useRolesPermissions = () => {
  const {roleFunction} = useAuthStore();
  const {getRoles} = useRolesApi();
  const rolesStore = useRolesStore();
  const {roles, isRolesLoaded} = rolesStore;

  const navigate = useNavigate()

  useEffect(() => {
    if (!isRolesLoaded) {
      if (roles === null) {
        void getRoles()
      } else {
        void getRoles(false)
      }
    }
  }, [isRolesLoaded, roles, getRoles])

  useEffect(() => {
    return () => rolesStore.clearRoles()
  }, [rolesStore])

  const headCells: IHeadCell[] = [
    {id: 'title', numeric: false, label: 'Nazwa roli'},
    {id: 'usersCount', numeric: true, label: 'Ilość użytkowników'}
  ]

  const editHandler = (id: number | string) => {
    navigate(generateLink(RoleCategory.ADMIN, AdminLink.EDIT_ROLE, +id))
  }


  const generateActions = () => {
    if (roleFunction && checkPermission(roleFunction, [RoleFunction.ROLE_ADMIN])) {
      return [
        {
          key: "edit",
          actionHandler: editHandler,
          background: theme.colors.darkBlue,
          actionElement: <EditIcon/>,
          width: 52,
        }
      ]
    }
    return [];
  }

  const tableDataRows = createTableRows(roles ? roles : [], headCells, false)


  const cellsSwitcher = (keyName: string, value: number[] | string, onClick?: () => void) => {
    switch (keyName) {
      case 'id':
        return null
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  }

  return {
    rolesIsNotNull: roles !== null,
    editHandler,
    headCells,
    generateActions,
    tableDataRows,
    cellsSwitcher
  }
}

