import styled from "styled-components";
import theme from "../../../../../../theme/theme";
import {Grid} from "@mui/material";

export const Annotation = styled.p`
  color: ${theme.colors.gray6};
  font-size: 10px;
  line-height: 14px;
  position: relative;
  top: -15px;
`

export const GridPadding = styled(Grid)`
  padding-left: 20px;

  @media (max-width: ${theme.breakpoints.medium}px) {
    padding-left: 0px;
  }
`
