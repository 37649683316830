import styled from 'styled-components';
import theme from '../../../../../theme/theme';

export const Container = styled.div`
  max-width: 1012px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    width: unset;
  }
`;

export const SectionTitle = styled.h1`
  font-weight: 800;
  font-size: 36px;
  line-height: 120%;
  text-transform: uppercase;
  margin-top: 32px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-weight: 800;
    font-size: 32px;
    line-height: 120%;
  }
`;

export const AccordionContainer = styled.div`
  margin: 32px 0;
`;