import {FC} from 'react';
import {Grid} from '@mui/material';

import {useModalAdd} from './useModalAdd';
import {ModalLayer} from '../modalLayer/ModalLayer';
import {Input} from '../../../../../../shared/form/input/Input';
import {DateInput} from '../../../../../../shared/form/date/Date';
import {Button} from '../../../../../../shared/button/Button';
import theme from '../../../../../../../theme/theme';

interface IProps {
  handleCloseDrugModal: () => void;
}

export const ModalAdd: FC<IProps> = ({handleCloseDrugModal}) => {

  const {formik, numberOfDrugs, minDate} = useModalAdd(handleCloseDrugModal);

  return (
    <>
      <ModalLayer text={`Lek ${numberOfDrugs}`}>
        <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <form onSubmit={formik.handleSubmit}>
            <Input label={'Nazwa leku'}
                   isUpperCase={true}
                   name={'title'}
                   type={'text'}
                   color={theme.colors.turquoise2}
                   value={formik.values.title}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   placeholder={'Nazwa leku...'}
                   isError={formik.touched.title && Boolean(formik.errors.title)}
            />
            <DateInput
              openTo={'year'}
              inputFormat={'mm.yyyy'}
              views={['year', 'month']}
              maxWidth={'385px'}
              label={'Data rozpoczęcia leczenia danym lekiem'}
              isUpperCase={true}
              name={'date'}
              onBlur={() => formik.setFieldTouched('date', true)}
              color={theme.colors.turquoise2}
              value={formik.values.date}
              onChange={(e) => formik.setFieldValue('date', e)}
              placeholder={'Wybierz datę rozpoczęcia leczenia...'}
              isError={formik.touched.date && Boolean(formik.errors.date)}
              minDate={minDate}
            />
            <Grid pt={5} justifyContent={'center'} display={'flex'}>
              <Button text={'zapisz'}
                      backgroundColor={theme.colors.dark1}
                      type={'submit'}
                      color={theme.colors.beige} width={'195px'} size={'small'}/>

            </Grid>
          </form>
        </Grid>
      </ModalLayer>
    </>
  )
}

