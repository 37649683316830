import { BlackTitle, Content, SectionTitle, StyledContainer, Title } from './Classification.styled';
import React, { FC } from 'react';
import { ImageBox } from '../sharedComponents/imageBox/ImageBox';
import { useCaseStore } from '../../../../../store/hooks';
import { observer } from 'mobx-react-lite';
import { GridContainer } from '../sharedComponents/gridContainer/GridContainer';
import { IImages } from '../ViewCase.helper';

export const Classification: FC<IImages> = observer(({ imageHandler }) => {

  const caseStore = useCaseStore();
  const { viewCaseNeuro } = caseStore;
  return (
    <StyledContainer>
      {viewCaseNeuro && <>
        <SectionTitle>kwalifikacja do leczenia <span>okrelizumabem:</span></SectionTitle>
        <Title>ocena MRI:</Title>
        <BlackTitle>Opis MRI na podstawie którego zakwalifikowano pacjenta do leczenia okrelizumabem (np: liczba i
          lokalizacja nowych zmian t1 i/lub nowych i powiększających się zmian t2/flair wzmacniających się po
          kontraście):</BlackTitle>
        <Content>{viewCaseNeuro.assessmentDescription}</Content>
        {viewCaseNeuro.assessmentImages.length !== 0 &&
          <ImageBox imageHandler={imageHandler} images={viewCaseNeuro.assessmentImages} />}

        <Title>ocena stanu pacjenta w skali edss:</Title>
        <GridContainer content={viewCaseNeuro.assessmentAssessment || ''}
                       textData={'Data pierwszego podania okrelizumabu'} date={viewCaseNeuro.assessmentDate!}
                       textContent={'Ocena opisowa stanu pacjenta w skali edss w momencie kwalifikacji do leczenia okrelizumabem'} />
      </>}
    </StyledContainer>
  );
});
