import { styled as styledMui, TableCell } from '@mui/material';
import theme from '../../../../../theme/theme';
import styled from 'styled-components';

interface ITableCellData {
  isbold?: boolean;
}

interface IColorTableCellData {
  color: string;
  width?: string;
  margin?: string;
  isbold?: boolean;
}


export const TableCellData = styledMui(TableCell)<ITableCellData>`
  height: 50px;
  padding: 0 6px 0 6px;
  color: ${theme.colors.darkBlue};
  background: white;
  border: 3px solid ${theme.colors.beige};
  font-weight: ${props => props.isbold ? '700' : '400'};
`;

export const TableStatusCellData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ColorTableCellData = styledMui(TableCell)<IColorTableCellData>`
  height: 50px;
  font-size: 10px;
  text-align: center;
  padding: 1px 6px;
  background: white;
  border: 3px solid ${theme.colors.beige};
  font-weight: ${props => props.isbold ? '700' : '400'};
  color: ${props => props.color};
  outline: 1px solid ${props => props.color};
  width: ${props => props.width ? props.width : '102px '};
  margin: ${props => props.margin ? props.margin : 'auto 1px;'};
  text-transform: uppercase;
`;

export const TableCellDataPointer = styledMui(TableCellData)`
  cursor: pointer;
`;
