import React, { ReactNode } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  InputLabel,
  SelectChangeEvent, checkboxClasses
} from '@mui/material';

import { MultipleSelectStyled } from './MultipleSelect.styled';
import { IOption } from '../../../../interfaces/common/form/select';

interface IProps {
  selectedValues: number[]
  elementName: string;
  title?: string;
  subtitle?: string;
  maxWidth?: string;
  options: IOption[],
  handleChange: (event: SelectChangeEvent<number[]>, child: ReactNode) => void
  subtitleLength?: number
}

export const MultipleSelect: React.FC<IProps> = ({
                                                   maxWidth,
                                                   subtitle,
                                                   selectedValues,
                                                   elementName,
                                                   options,
                                                   handleChange,
                                                   title,
                                                   subtitleLength
                                                 }) => {

  const renderedValue = (selectedValues: number[]) => {
    if (subtitleLength) {
      if (selectedValues.includes(0)) {
        return '';
      } else {
        return ` (${subtitleLength})`;
      }
    } else {
      if (selectedValues.includes(0)) {
        return 'Wszystko';
      } else {
        return selectedValues.map(id => options.find((option: IOption) => option.id === id)?.title).join(', ');
      }
    }
  };

  return (
    <MultipleSelectStyled maxWidth={maxWidth}>
      <FormControl fullWidth={true}>
        {title && <h5>{title}</h5>}
        {selectedValues.length === 0 && <InputLabel sx={{
          '&.MuiFormLabel-root': {
            transform: 'translate(14px, 7px)'
          }
        }} id={elementName}>{subtitle}</InputLabel>}
        <Select
          labelId={elementName}
          id={elementName}
          multiple
          value={selectedValues}
          onChange={handleChange}
          renderValue={(selected: number[]) => (
            <div>
              {subtitleLength && subtitle ? subtitle : subtitle ? subtitle + ': ' : ''}
              {renderedValue(selected)}
            </div>
          )}
          sx={{
            color: 'rgb(51, 51, 51)',
            borderRadius: '28px',
            fontSize: '16px',
            padding: '4px 8px 4px 16px',
            border: '2px solid #e0e0e0'
          }}
        >
          {options.map((option: IOption) => (
            <MenuItem key={option.id} value={option.id}
                      sx={{ padding: '10px 16px', height: '28px', margin: '4px 0' }}>
              <ListItemText primary={option.title} />
              <Checkbox
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: '#333333'
                  }
                }}
                checked={selectedValues.includes(option.id) || selectedValues.includes(0)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MultipleSelectStyled>
  );
};