export const START_YEAR = 2023;

export const generateYear = () => {
  const now = new Date();
  return now.getFullYear();
};

export const months = [
  {
    id: 1,
    title: 'Styczeń'
  },
  {
    id: 2,
    title: 'Luty'
  },
  {
    id: 3,
    title: 'Marzec'
  },
  {
    id: 4,
    title: 'Kwiecień'
  },
  {
    id: 5,
    title: 'Maj'
  },
  {
    id: 6,
    title: 'Czerwiec'
  },
  {
    id: 7,
    title: 'Lipiec'
  },
  {
    id: 8,
    title: 'Sierpień'
  },
  {
    id: 9,
    title: 'Wrzesień'
  },
  {
    id: 10,
    title: 'Październik'
  },
  {
    id: 11,
    title: 'Listopad'
  },
  {
    id: 12,
    title: 'Grudzień'
  }
];

export const shortMonthsArray = ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'];

export const numberConverter = (number: number) => {
  const maxDay = 9;
  const minDay = 0;

  return number <= maxDay && number >= minDay ? `0${number}` : number;
};

export const additionalDay = 1;

export const dateStringify = (date: string, isDay = true) => {
  const convertDate = new Date(date);

  if (isNaN(convertDate.getDate())) {
    return '';
  }

  return convertDate ? `${isDay ? numberConverter(convertDate.getDate()) + '.' : ''}${numberConverter(
    convertDate.getMonth() + additionalDay
  )}.${convertDate.getFullYear()}` : '';
};


export const timeStringify = (date: string) => {
  const convertDate = new Date(date);

  if (isNaN(convertDate.getDate())) {
    return '';
  }

  return convertDate ? convertDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  }) : '';
};
