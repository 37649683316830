import { IBreadcrumbsElement } from "../../../../../../interfaces/user/breadcrumbs";
import { UserLink } from "../../../../../../utils";
import { useEffect } from "react";
import { useContractApi } from "../../../../../../apiHooks";
import { useContractStore } from "../../../../../../store/hooks";

export const useSummaryFormInvoiceVm = () => {
  const {getCompanyContractData} = useContractApi()
  const contractStore = useContractStore()
  const {contractData} = contractStore

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.PROFILE,
      name: "Profil użytkownika"
    },
    {
      name: "Dane rozliczeniowe"
    }
  ];

  useEffect(() => {
    void getCompanyContractData()
  }, [getCompanyContractData])

  return {
    breadcrumbs,
    contractData
  }
}