import styled from "styled-components";
import theme from "../../../../theme/theme";


export const Header = styled.h1`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`
export const AddedInfo = styled.span`
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`

export const CommentArea = styled.div`
  max-height: 200px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  overflow-y: auto;
  margin: 16px 0;
  text-align: center;
`


export const ShowCommentButton = styled.button`
  background: ${theme.colors.dark1};
  height: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-weight: 800;
  font-size: 10px;
  line-height: 120%;
  color: white;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  border: 2px solid ${theme.colors.dark1};
  padding: 2px;
`;

export const WarningIconContainer = styled.div`
  background: ${theme.colors.red};
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;