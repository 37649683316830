import React from 'react';

import { ButtonContainer, StyledLink } from './Button.styled';

interface IProps {
  clickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
  size?: ButtonSize,
  color?: string
  text: string
  backgroundColor?: string
  icon?: JSX.Element | string;
  maxWidth?: string;
  width?: string;
  type?: ButtonType;
  border?: string;
  link?: string
  fontSize?: string
  fontColor?: string;
  margin?: string;
  disabled?: boolean;
  padding?: string;
  iconOrientation?: ButtonIconOrientation
}

export type ButtonSize = 'tiny' | 'small' | 'big'
export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonIconOrientation = 'left' | 'right'

export const Button: React.FC<IProps> = ({
                                           clickHandler,
                                           size,
                                           border,
                                           color,
                                           text,
                                           type,
                                           backgroundColor,
                                           icon,
                                           maxWidth,
                                           width,
                                           link,
                                           fontSize,
                                           margin,
                                           disabled,
                                           padding,
                                           iconOrientation = 'right'
                                         }) => {
  const iconElement = icon && (typeof icon === 'string' ? <img src={icon} alt={text} /> : icon);
  const ButtonLayer = <ButtonContainer isIcon={!!icon} border={border} type={type} size={size} color={color}
                                       padding={padding}
                                       width={width}
                                       onClick={clickHandler && clickHandler}
                                       maxWidth={maxWidth}
                                       fontSize={fontSize}
                                       margin={margin}
                                       disabled={disabled}
                                       backgroundColor={backgroundColor}>{iconOrientation === 'left' && iconElement}{text}{iconOrientation === 'right' && iconElement}</ButtonContainer>;

  return (
    link ? <StyledLink to={link}>{ButtonLayer}</StyledLink> :
      <>{ButtonLayer}</>
  );
};
