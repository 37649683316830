import {IDraftCaseNeuro} from "../interfaces/user/draftCaseNeuro/draft";
import {Gender} from "../constants/gender";
import {IFirstStageNeuro, IFirstStageRequestNeuro} from "../interfaces/user/draftCaseNeuro/firstStage";
import {ISecondStageNeuro} from "../interfaces/user/draftCaseNeuro/secoundStage";
import {IThirdStageNeuro} from "../interfaces/user/draftCaseNeuro/thirdStage";
import {IFourthStageNeuro} from "../interfaces/user/draftCaseNeuro/fouthStage";

export const convertFirstStageToRequest = (firstStage: IFirstStageNeuro): IFirstStageRequestNeuro => {
  return {
    ...firstStage,
    gender: firstStage.gender ? Gender.WOMAN : Gender.MAN,
    yearOfBirth: +firstStage.yearOfBirth
  }
}


export const convertRequestToFirstStage = (firstStage: IFirstStageRequestNeuro): IFirstStageNeuro => {
  return {
    ...firstStage,
    gender: firstStage.gender === Gender.WOMAN,
    yearOfBirth: String(firstStage.yearOfBirth)
  }
}

export const convertResponseToFirstStage = (draftCase: IDraftCaseNeuro): IFirstStageNeuro => {
  return {
    title: draftCase.title,
    initials: draftCase.initials,
    gender: draftCase.gender === Gender.WOMAN,
    yearOfBirth: String(draftCase.yearOfBirth)
  }
}

export const convertResponseToSecondStage = (draftCase: IDraftCaseNeuro): ISecondStageNeuro => {

  return {
    date: draftCase.diagnosisDate ?? "",
    age: draftCase.diagnosisAge ?? "",
    isFluidTesting: draftCase.diagnosisIsFluidTesting,
    projectileActivityDate: draftCase.projectileActivityDate ?? "",
    projectileActivityTreatment: draftCase.projectileActivityTreatment ?? "",
    projectileActivityDescription: draftCase.projectileActivityDescription ?? "",
    projectileActivityAssessment: draftCase.projectileActivityAssessment ?? "",
    resonanceActivityDescription: draftCase.resonanceActivityDescription ?? "",
    diagnosisImages: draftCase.diagnosisImages ?? []
  }
}

export const convertResponseToThirdStage = (draftCase: IDraftCaseNeuro): IThirdStageNeuro => {
  return {
    drugs: draftCase.drugs
  }
}


export const convertResponseToFourthStage = (draftCase: IDraftCaseNeuro): IFourthStageNeuro => {
  return {
    description: draftCase.assessmentDescription ?? '',
    assessment: draftCase.assessmentAssessment ?? '',
    date: draftCase.assessmentDate ?? "",
    images: draftCase.assessmentImages ?? []
  }
}