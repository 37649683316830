import styled from "styled-components";
import Switch from "@mui/material/Switch";
import theme from "../../../../../../../theme/theme";

export const SwitchCustom = styled(Switch)`
  & span {
    padding: 0px;
  }

  & input {
    left: 0px !important;
    height: 36px !important;
    width: 100px !important;
  }

  & .MuiSwitch-track {
    border-radius: 20px;
    background: ${theme.colors.gray6} !important;
  }

  .checkedSwitch {
    transform: translateX(62px) !important;
  }
`