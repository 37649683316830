import * as yup from 'yup';

import {
  DoctorType,
  IDoctorFieldSets,
  IDoctorFieldSetsVariantFirst,
  IDoctorFieldSetsVariantSecond,
  IFieldSetDoctorFormVariantFirst,
  IFieldSetDoctorFormVariantFirstResponse,
  IFieldSetDoctorFormVariantSecond,
  IFieldSetDoctorFormVariantSecondResponse, YesNoNotSpecifiedFormOptions
} from '../../../../../../../interfaces/user/draftCaseLung/thirdStage';

export const validationSchemaVariantFirst = yup.object().shape({
  isCommissioned: yup
    .string()
    .oneOf(['tak', 'nie'])
    .defined()
    .required(),
  isDone: yup
    .string()
    .when(`isCommissioned`, {
      is: 'tak',
      then: (schema) => schema.oneOf(['tak', 'nie']).defined().required()
    }),
  amount: yup
    .number()
    .when('isDone', {
      is: 'tak',
      then: (schema) => schema.required()
    })
});

export const validationSchemaVariantSecond = yup.object().shape({
  isCommissioned: yup
    .string()
    .oneOf(['tak', 'nie'])
    .defined()
    .required(),
  done: yup
    .string()
    .when('isCommissioned', {
      is: 'tak',
      then: (schema) => schema.oneOf(['tak', 'nie', 'nie udało się oznaczyć - konieczne było powtórzenie badania']).defined().required()
    }),
  amount: yup
    .number()
    .when('done', {
      is: 'tak',
      then: (schema) => schema.required()
    })
});

export const validationSchema = yup.object().shape({
  date: yup.string().nonNullable().required(),
  rtgKlp: validationSchemaVariantFirst,
  tkKlp: validationSchemaVariantFirst,
  tkAbdomen: validationSchemaVariantFirst,
  tkPelvis: validationSchemaVariantFirst,
  mri: validationSchemaVariantFirst,
  petCt: validationSchemaVariantFirst,
  biopsy: validationSchemaVariantFirst,
  ebus: validationSchemaVariantFirst,
  histPath: validationSchemaVariantSecond,
  egfr: validationSchemaVariantSecond,
  alk: validationSchemaVariantSecond,
  ros: validationSchemaVariantSecond
});

export const validationSchemaForSquamous = yup.object().shape({
  date: yup.string().nonNullable().required(),
  rtgKlp: validationSchemaVariantFirst,
  tkKlp: validationSchemaVariantFirst,
  tkAbdomen: validationSchemaVariantFirst,
  tkPelvis: validationSchemaVariantFirst,
  mri: validationSchemaVariantFirst,
  petCt: validationSchemaVariantFirst,
  biopsy: validationSchemaVariantFirst,
  ebus: validationSchemaVariantFirst,
  histPath: validationSchemaVariantSecond
});

export const validationSchemaForFamilyDoctor = yup.object().shape({
  date: yup.string().nonNullable().required(),
  rtgKlp: validationSchemaVariantFirst,
  tkKlp: validationSchemaVariantFirst
});

export const dataVariantFirstInitialize: IFieldSetDoctorFormVariantFirst = {
  isCommissioned: '',
  amount: '',
  isDone: ''
};

export const dataVariantSecondInitialize: IFieldSetDoctorFormVariantSecond = {
  isCommissioned: '',
  amount: '',
  done: ''
};

export const initializeFormForFamily: IDoctorFieldSets = {
  date: '',
  rtgKlp: dataVariantFirstInitialize,
  tkKlp: dataVariantFirstInitialize
};

export const initializeForm: IDoctorFieldSets = {
  ...initializeFormForFamily,
  tkAbdomen: dataVariantFirstInitialize,
  tkPelvis: dataVariantFirstInitialize,
  mri: dataVariantFirstInitialize,
  petCt: dataVariantFirstInitialize,
  biopsy: dataVariantFirstInitialize,
  ebus: dataVariantFirstInitialize,
  histPath: dataVariantSecondInitialize,
  egfr: dataVariantSecondInitialize,
  alk: dataVariantSecondInitialize,
  ros: dataVariantSecondInitialize
};


export const variantFirstConfig: [(keyof IDoctorFieldSetsVariantFirst), string][] = [
  ['rtgKlp', 'RTG KLP'], ['tkKlp', 'TK KLP'], ['tkAbdomen', 'TK JAMY BRZUSZNEJ'], ['tkPelvis', 'TK MIEDNICY'], ['mri', 'MRI GŁOWY'], ['petCt', 'PET CT'],
  ['biopsy', 'BRONCHOSKOPIA (BIOPSJA)'], ['ebus', 'EBUS/EUS']
];

export const variantFirstConfigForFamily: [(keyof IDoctorFieldSetsVariantFirst), string][] = [
  ['rtgKlp', 'RTG KLP'], ['tkKlp', 'TK KLP']
];

export const variantSecondConfig: [(keyof IDoctorFieldSetsVariantSecond), string][] = [
  ['histPath', 'BADANIE HIS-PAT'], ['egfr', 'EGFR'], ['alk', 'ALK'], ['ros', 'ROS1']
];

export const variantSecondConfigForSquamous: [(keyof IDoctorFieldSetsVariantSecond), string][] = [
  ['histPath', 'BADANIE HIS-PAT']
];


const fields = {
  yes: {
    toResponse: 'yes', toForm: 'tak'
  },
  no: {
    toResponse: 'no', toForm: 'nie'
  },
  notSpecified: {
    toResponse: 'nie udało się oznaczyć - konieczne było powtórzenie badania',
    toForm: 'nie udało się oznaczyć - konieczne było powtórzenie badania'
  }
};

export const convertYesNoNotSpecifiedOption = (valueForm: string, type: 'toResponse' | 'toForm' = 'toResponse') => {
  const typeReverse = type === 'toResponse' ? 'toForm' : 'toResponse';

  switch (valueForm) {
    case fields.yes[typeReverse]:
      return fields.yes[type];
    case fields.no[typeReverse]:
      return fields.no[type];
    case fields.notSpecified[typeReverse]:
      return fields.notSpecified[type];
    default:
      return '';
  }
};

export const convertResponseToResponseVariantFirst = (data: IFieldSetDoctorFormVariantFirst): IFieldSetDoctorFormVariantFirstResponse => {
  const isCommissioned = data?.isCommissioned === 'tak';
  const isDone = isCommissioned ? data?.isDone === 'tak' : null;
  return {
    isCommissioned,
    amount: isDone ? Number(data.amount) : null,
    isDone: isDone
  };
};

export const convertResponseToResponseVariantSecond = (data: IFieldSetDoctorFormVariantSecond): IFieldSetDoctorFormVariantSecondResponse => {

  const isCommissioned = data?.isCommissioned === 'tak';
  const done = isCommissioned ? (data.done !== '' ? data.done : null) : null;
  return {
    isCommissioned,
    amount: done === 'tak' ? Number(data.amount) : null,
    done: done
  };
};


export const convertResponseToFormVariantFirst = (data: IFieldSetDoctorFormVariantFirstResponse): IFieldSetDoctorFormVariantFirst => {
  const isCommissioned = data?.isCommissioned ? 'tak' : 'nie';
  const isDone = data?.isDone ? 'tak' : 'nie';
  return {
    isCommissioned,
    amount: data.amount ?? '',
    isDone
  };
};

export const convertResponseToFormVariantSecond = (data: IFieldSetDoctorFormVariantSecondResponse): IFieldSetDoctorFormVariantSecond => {
  const isCommissioned = data?.isCommissioned ? 'tak' : data?.isCommissioned === null ? '' : 'nie';
  const done = data?.done ? data.done as YesNoNotSpecifiedFormOptions : '';
  return {
    isCommissioned,
    amount: data.amount ?? '',
    done
  };
};

export const convertFormToResponse = (values: IDoctorFieldSets, type: DoctorType, isSquamous: boolean): IDoctorFieldSets => {
  return {
    type,
    date: values.date,
    rtgKlp: convertResponseToResponseVariantFirst(values.rtgKlp as IFieldSetDoctorFormVariantFirst),
    tkKlp: convertResponseToResponseVariantFirst(values.tkKlp as IFieldSetDoctorFormVariantFirst),
    ...type !== 'family' && {
      tkAbdomen: convertResponseToResponseVariantFirst(values.tkAbdomen as IFieldSetDoctorFormVariantFirst),
      tkPelvis: convertResponseToResponseVariantFirst(values.tkPelvis as IFieldSetDoctorFormVariantFirst),
      mri: convertResponseToResponseVariantFirst(values.mri as IFieldSetDoctorFormVariantFirst),
      petCt: convertResponseToResponseVariantFirst(values.petCt as IFieldSetDoctorFormVariantFirst),
      biopsy: convertResponseToResponseVariantFirst(values.biopsy as IFieldSetDoctorFormVariantFirst),
      ebus: convertResponseToResponseVariantFirst(values.ebus as IFieldSetDoctorFormVariantFirst),
      histPath: convertResponseToResponseVariantSecond(values.histPath as IFieldSetDoctorFormVariantSecond)
    },
    ...!isSquamous && {
      egfr: convertResponseToResponseVariantSecond(values.egfr as IFieldSetDoctorFormVariantSecond),
      alk: convertResponseToResponseVariantSecond(values.alk as IFieldSetDoctorFormVariantSecond),
      ros: convertResponseToResponseVariantSecond(values.ros as IFieldSetDoctorFormVariantSecond)
    }
  };
};

export const convertResponseToForm = (values: IDoctorFieldSets): IDoctorFieldSets => {

  return {
    id: values.id,
    type: values.type,
    date: values.date,
    rtgKlp: convertResponseToFormVariantFirst(values.rtgKlp as IFieldSetDoctorFormVariantFirstResponse),
    tkKlp: convertResponseToFormVariantFirst(values.tkKlp as IFieldSetDoctorFormVariantFirstResponse),
    tkAbdomen: convertResponseToFormVariantFirst(values.tkAbdomen as IFieldSetDoctorFormVariantFirstResponse),
    tkPelvis: convertResponseToFormVariantFirst(values.tkPelvis as IFieldSetDoctorFormVariantFirstResponse),
    mri: convertResponseToFormVariantFirst(values.mri as IFieldSetDoctorFormVariantFirstResponse),
    petCt: convertResponseToFormVariantFirst(values.petCt as IFieldSetDoctorFormVariantFirstResponse),
    biopsy: convertResponseToFormVariantFirst(values.biopsy as IFieldSetDoctorFormVariantFirstResponse),
    ebus: convertResponseToFormVariantFirst(values.ebus as IFieldSetDoctorFormVariantFirstResponse),
    histPath: convertResponseToFormVariantSecond(values.histPath as IFieldSetDoctorFormVariantSecondResponse),
    egfr: convertResponseToFormVariantSecond(values.egfr as IFieldSetDoctorFormVariantSecondResponse),
    alk: convertResponseToFormVariantSecond(values.alk as IFieldSetDoctorFormVariantSecondResponse),
    ros: convertResponseToFormVariantSecond(values.ros as IFieldSetDoctorFormVariantSecondResponse)
  };
};
