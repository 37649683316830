import { ContractContentContainer, File, InfoText } from './ContractContent.styled';
import { ReactComponent as PdfFile } from '../../../../../../../assets/icon/pdfIcon.svg';
import {
  ReactComponent as GreenDownload
} from '../../../../../../../assets/icon/greenDownload.svg';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useContractContents } from './useContractContents';
import { ListContainer, ListElement } from '../../../../profile/form/invoice/InvoiceForm.styled';
import { AreaKey } from '../../../../../../../interfaces/admin/cases/cases';

interface IProps {
  contactName: string;
  ids: number[] | null;
  area: AreaKey;
}

export const ContractContent: FC<IProps> = observer(({ contactName, ids, area }) => {

  const { getDocumentHandler } = useContractContents(ids, area);

  return (
    <ContractContentContainer>
      <h1><b>Nowa umowa o dzieło została utworzona.</b></h1>
      <InfoText>
        <p>Softwebo podpisało umowę o dzieło z dzisiejszą datą. Prosimy o pobranie i przesłanie podpisanej umowy w ciągu
          5
          dni.</p>
        <p>Jeśli nie jest możliwe przesłanie podpisanej umowy w ciągu 5 dni, prosimy o niepobieranie umowy i rozliczenie
          się w dogodnym terminie.</p>
      </InfoText>
      <ListContainer>
        <ListElement>
          <h3><b>1. Pobierz umowę:</b></h3>
          <File onClick={getDocumentHandler}><PdfFile />{contactName}<GreenDownload /></File>
        </ListElement>
        <ListElement>
          <h3><b>2. Sprawdź i podpisz umowę.</b></h3>
          <p>Umowę możesz wydrukować i podpisać tradycyjnie lub w sposób elektroniczny.</p>
        </ListElement>
        <ListElement>
          <h3><b>3. Załącz pełną treść podpisanej umowy:</b></h3>
          <p>W przypadku załączenia niewłaściwego pliku (np. skan tylko ostatniej strony umowy) zostanie on
            odrzucony.</p>
        </ListElement>
      </ListContainer>

    </ContractContentContainer>
  );
});