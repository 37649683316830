import React, { FC } from 'react';
import { Typography } from '@mui/material';

import { DoctorForm } from '../doctorForm/DoctorForm';
import {
  DoctorType,
  IDoctorThirdStageLung
} from '../../../../../../../interfaces/user/draftCaseLung/thirdStage';
import { doctorTypes } from '../ThirdStage.helper';
import { convertResponseToForm } from '../doctorForm/DoctorForm.helper';
import { useAccordionCustom } from './useAccordionCustom';
import {
  AccordionDetailsCustom,
  AccordionStyled,
  AccordionSummaryCustom, StyledSection
} from './AccordionCustom.styled';
import { ReactComponent as ArrowAccordion } from '../../../../../../../assets/icon/arrowAccordion.svg';

interface IProps {
  doctor: IDoctorThirdStageLung;
  deleteDoctor: (idDoctor: number) => Promise<boolean>;
  isModalOpened: boolean;
  expanded: boolean;
  setExpandedId?: (id?: number | null) => void;
  expandedId?: number | null;
}

export const AccordionCustom: FC<IProps> = ({
                                              doctor,
                                              deleteDoctor,
                                              isModalOpened,
                                              expanded,
                                              setExpandedId,
                                              expandedId
                                            }) => {
  const {
    dateFormatted,
    expandedDoctorChange,
    handleOnSave
  } = useAccordionCustom(doctor, isModalOpened, setExpandedId);

  return (
    <AccordionStyled expanded={expanded} onChange={() => expandedDoctorChange()}>
      <AccordionSummaryCustom
        expandIcon={<ArrowAccordion />}
      >
        <Typography>{doctorTypes[doctor.type as DoctorType]}</Typography>
        <Typography variant='body1'>{dateFormatted}</Typography>
      </AccordionSummaryCustom>
      <AccordionDetailsCustom>
        {!isModalOpened && doctor.id === expandedId &&
          <StyledSection>
            <DoctorForm isAccordion={true} data={convertResponseToForm(doctor)} type={doctor.type as DoctorType}
                        onSave={handleOnSave} deleteDoctor={deleteDoctor}
                        formContainer={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}
                        buttonsContainer={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} />
          </StyledSection>
        }
      </AccordionDetailsCustom>
    </AccordionStyled>
  );
};


