import { useModal } from '../../../../shared/modal/useModal.vm';
import { useCaseStore } from '../../../../../store/hooks';
import { AccordionView } from './accordion/AccordionView';
import  { useState } from 'react';
import { SectionTitle, Container, AccordionContainer } from './Path.styled';

export const Path = () => {
  const { isModalOpened } = useModal();
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const caseStore = useCaseStore();
  const { viewCaseLung } = caseStore;

  const onChangeHandler = (id?: number | null) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else if (id) {
      setExpandedId(+id);
    }
  };

  return (
    <Container>
      {viewCaseLung &&
        <>
          <SectionTitle>Ścieżka pacjenta:</SectionTitle>
          <AccordionContainer>{viewCaseLung?.doctors.map(doctor => (
            <AccordionView key={doctor.id}
                           isModalOpened={isModalOpened}
                           setExpandedId={onChangeHandler}
                           expandedId={expandedId}
                           expanded={expandedId === +doctor.id!} doctor={doctor} />
          ))}</AccordionContainer>
        </>
      }
    </Container>
  );
};