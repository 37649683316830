import { Header, InvoiceContentContainer, SummaryElement } from "./InvoiceContent.styled";
import { useReckoningStore } from "../../../../../../../store/hooks";
import {FC} from 'react';

interface IProps {
  ids: number[] | null;
}
export const InvoiceContent : FC<IProps> = ({ids}) => {
  const {companyContractResponse} = useReckoningStore();
  const count = ids ? ids.length : 0;

  return (
    <InvoiceContentContainer>
      <Header>DANE DO FAKTURY ZA OPISY PRZYPADKÓW:</Header>
      {ids && companyContractResponse &&
        <><SummaryElement><b>NAZWA TOWARU LUB USŁUGI:</b><br/>Opracowanie merytoryczne opisów przypadków o numerach: {ids.join(', ')}.</SummaryElement>
        </>}
      <SummaryElement><b>KWOTA NETTO:</b> {count * 1500} PLN</SummaryElement>
      <SummaryElement><b>DANE KONTRAHENTA:</b><br/>
        Softwebo Sp. z o.o.<br/>
        ul. Franciszka Kniaźnina 4A, 01-607 Warszawa<br/>
        NIP: 5272653664<br/></SummaryElement>
    </InvoiceContentContainer>
  )
}