import styled from 'styled-components';
import theme from '../../../../../theme/theme';

export const NavigationContainer = styled.div`
  margin: 16px auto 0 auto;
  text-align: center;
  max-width: 1012px;
  width: 100%;
  gap: 12px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  z-index: 10;

  & a {
    width: 244px;
    flex-shrink: 0;
  }

  button {
    width: 244px;
    padding: 0 16px;
    flex-shrink: 0;
  }

  @media (max-width: ${theme.breakpoints.large}px) {
    & a {
      width: 200px;
      flex-shrink: 0;
    }

    button {
      width: 200px;
      padding: 0 16px;
      flex-shrink: 0;
    }
  }
  @media (max-width: ${theme.breakpoints.medium}px) {
    & a {
      width: auto;
      flex-shrink: 0;
    }

    button {
      width: auto;
      padding: 0 16px;
      flex-shrink: 0;
    }
  }


  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
    align-items: center;
    padding: 26px 0 0;
    gap: 10px;
    & a, button {
      width: 100%;
      max-width: 244px;
    }
  }
`;