import {Modal} from "../../../../../../shared/modal/Modal";

import React, {FC} from "react";
import {
  ButtonContainer,
  ConfirmationTextModal,
  TextContainer
} from "../../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled";
import {ContentModal} from "../../../../../../shared/modal/ContentModal";
import {Button} from "../../../../../../shared/button/Button";
import {Input} from "../../../../../../shared/form/input/Input";
import {DateInput} from "../../../../../../shared/form/date/Date";
import theme from "../../../../../../../theme/theme";
import {useEditModal} from "./useEditModal.vm";

interface IProps {
  closeModal: () => void;
  selectedItem: string
}

export const EditModal: FC<IProps> = ({closeModal, selectedItem}) => {
  const {formik, isInvoice, confirmForm} = useEditModal(selectedItem, closeModal)
  return (<Modal clickHandler={() => closeModal()}>
    <ContentModal
      text={<ConfirmationTextModal>
        <TextContainer>
          <DateInput
            openTo={'year'}
            inputFormat={'dd.mm.yyyy'}
            views={['year', 'month', 'day']}
            isRequired={true}
            maxWidth={'100%'}
            label={'DATA ZAPŁATY'}
            name={"datePay"}
            color={theme.colors.dark1}
            value={formik.values.datePay}
            onChange={(e) => formik.setFieldValue('datePay', e)}
            onBlur={() => formik.setFieldTouched('datePay', true)}
            isError={formik.touched.datePay && Boolean(formik.errors.datePay)}
          />
          {isInvoice && <Input isRequired={true}
                               label={'Numer dokumentu'}
                               isUpperCase={true}
                               name={"numberContract"}
                               type={"text"}
                               value={formik.values.numberContract}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               placeholder={'Wpisz numer dokumentu...'}
                               isError={formik.touched.numberContract && Boolean(formik.errors.numberContract)}
          />}
        </TextContainer>
        <ButtonContainer>
          <Button text={"Zapisz i zamknij"} size={'small'} backgroundColor={'black'}
                  color={'white'} clickHandler={confirmForm}
                  width={'190px'}></Button>
        </ButtonContainer>
      </ConfirmationTextModal>}/>
  </Modal>)
}