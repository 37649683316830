import React from 'react';
import {Grid} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {Input} from '../../../../../shared/form/input/Input';
import theme from '../../../../../../theme/theme';
import {useFourthStage} from './useFourthStage.vm'
import {DateInput} from '../../../../../shared/form/date/Date';
import {MultipleFileUpload} from '../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload';
import {Fieldset} from '../../../../../shared/form/fieldset/FieldsetContainer';
import {Label} from '../../../../../shared/form/label/LabelContainer';

export const FourthStage = observer(() => {
  const {
    formik,
    images,
    addImage,
    removeImage
  } = useFourthStage()

  return (
    <Grid container justifyContent={'space-between'}>
      <Fieldset isUpperCase={true} color={theme.colors.turquoise2} size={'medium'} textAlign={'center'}
                label={'Ocena MRI'}>
        <>
          <Grid item xs={12} mb={2}>
            <Input isRequired={true}
                   label={'Opis MRI na podstawie którego zakwalifikowano pacjenta do leczenia okrelizumabem (np: liczba i lokalizacja nowych zmian t1 i/lub nowych i powiększających się zmian t2/flair wzmacniających się po kontraście)'}
                   isUpperCase={true}
                   size={'small'}
                   name={'description'}
                   height={'500px'}
                   type={'textarea'}
                   color={theme.colors.dark1}
                   value={formik.values.description}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   placeholder={'Opis MRI...'}
                   isError={formik.touched.description && Boolean(formik.errors.description)}
            />
          </Grid>
          <Label isUpperCase={true} color={'black'} isRequired={false} label={'Zdjęcia mri (Opcjonalnie)'}
                 size={'small'}/>
          <Grid item xs={12} mb={2}>
            <MultipleFileUpload images={images} addFile={addImage} removeFile={removeImage}
                                maxFileSize={16}/>
          </Grid>

        </>
      </Fieldset>
      <Fieldset isUpperCase={true} color={theme.colors.turquoise2} size={'medium'}
                label={'Ocena stanu pacjenta w skali EDSS'}>
        <>
          <Grid item xs={12}>
            <Input isRequired={true}
                   label={'Ocena opisowa stanu pacjenta w skali edss w momencie kwalifikacji do leczenia okrelizumabem'}
                   size={'small'}
                   isUpperCase={true}
                   name={'assessment'}
                   type={'textarea'}
                   color={theme.colors.dark1}
                   value={formik.values.assessment}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   placeholder={'Ocena OPISOWA stanu pacjenta...'}
                   isError={formik.touched.assessment && Boolean(formik.errors.assessment)}
            />
          </Grid>
          <Grid mt={2} item xs={12}>
            <DateInput
              openTo={'year'}
              inputFormat={'dd.mm.yyyy'}
              views={['year', 'month', 'day']}
              isRequired={true}
              maxWidth={'385px'}
              label={'Data pierwszego podania okrelizumabu'}
              isUpperCase={true}
              name={'date'}
              color={theme.colors.turquoise2}
              value={formik.values.date}
              onChange={(e) => formik.setFieldValue('date', e)}
              onBlur={() => formik.setFieldTouched('date', true)}
              isError={formik.touched.date && Boolean(formik.errors.date)}
              disableFuture={true}
            />
          </Grid>
        </>
      </Fieldset>
    </Grid>
  )
});