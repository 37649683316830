import {useModal} from '../../../shared/modal/useModal.vm';
import {useNavigate} from 'react-router-dom';
import {GeneralLink, generateLink, UserLink} from '../../../../utils';
import {RoleCategory} from '../../../../constants/projectUsers';
import {ModalType} from '../../../../constants/modalType';
import {IBreadcrumbsElement} from "../../../../interfaces/user/breadcrumbs";
import {useEffect} from "react";
import {useProfileApi} from "../../../../apiHooks/useProfileApi";
import {useAuthStore, useProfileStore, useReckoningStore} from "../../../../store/hooks";

export const useProfile = () => {
  const {getProfile} = useProfileApi();
  const {roleFunction} = useAuthStore();
  const profileStore = useProfileStore()
  const {profile, isProfileLoaded} = profileStore;
  const {contractType} = useReckoningStore();
  const {handleModal, isModalOpened, closeModal} = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isProfileLoaded) {
      if (!profile) {
        void getProfile()
      } else {
        void getProfile(false)
      }

    }
  }, [getProfile, isProfileLoaded, profile])

  useEffect(() => {
    return profileStore.resetProfile();
  }, [profileStore])


  const resetNavigateHandler = () => {
    navigate(generateLink(RoleCategory.GUEST, GeneralLink.RETRIEVE_PASSWORD));
  }

  const reckoningSummaryHandler = () => {
    if (contractType !== 'none') {
      navigate(generateLink(RoleCategory.USER, contractType === 'company' ? UserLink.PROFILE_FORM_INVOICE_SUMMARY : UserLink.PROFILE_FORM_CONTRACT_SUMMARY))
    }
  }

  const navigateForm = () => {
    navigate(generateLink(RoleCategory.USER, UserLink.PROFILE_SELECT_FORM))
  }

  const editModalHandler = () => {
    handleModal(ModalType.EDIT_MODAL);
  }


  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      name: "Profil użytkownika"
    }];


  return {
    roleFunction,
    profile,
    breadcrumbs,
    isModalOpened,
    closeModal,
    resetNavigateHandler,
    reckoningSummaryHandler,
    editModalHandler,
    navigateForm
  }
}