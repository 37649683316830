import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const Header = styled.h1`
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  margin-top: 35px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 20px;
  }
`

export const Subheader = styled.h2`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 20px;
`

export const FieldSetParagraph = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin: -10px auto 5px 16px;
`

export const Paragraph = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  color: ${theme.colors.red};
`

export const ConfirmationTextModal = styled.div`
  margin-top: 5px;
  max-width: 565px;
  text-align: center;
`

export const ContractConfirmationContainer = styled.div`
  font-size: 20px;
  line-height: 28px;

  & p {
    max-width: 100%;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  margin-top: 50px;
  align-items: center;
  gap: 15px;
  justify-content: center;
  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
  }
`

export const ContractTitle = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  text-decoration: none;
  color: ${theme.colors.dark1};


  & span {
    display: flex;
    gap: 8px;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    width: 100%;
  }
`