import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { ICase, ICaseCard } from '../interfaces/admin/cases/cases';
import { CaseStatus } from '../interfaces/user/draftCaseNeuro/draft';
import { filterByCurrentPage, filterByStatus, filterByType } from '../utils/searchHelpers';

export class CasesStore {
  filters: CaseStatus[] = [];

  cases: ICase[] | null = null;
  listOfMyCases: ICaseCard[] | null = null;
  listOfOtherCases: ICaseCard[] | null = null;
  listOfOfLast6Cases: ICaseCard[] | null = null;

  isCasesLoaded: boolean = false;
  isListOfMyCasesLoaded: boolean = false;
  isListOfOtherCasesLoaded: boolean = false;
  listOfOfLast6CasesLoaded: boolean = false;

  cardsPerPage = 8;
  currentPage = 1;
  otherCardsPerPage = 12;

  selectedAreas: number[] = [];
  selectedAreasForOther: number[] = [];
  areaTypes: string[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setCases(cases: ICase[]) {
    this.cases = cases;
    this.isCasesLoaded = true;
  }

  setListOfMyCases(listOfMyCases: ICaseCard[]) {
    this.listOfMyCases = listOfMyCases;
    this.isListOfMyCasesLoaded = true;
  }

  setListOfOtherCases(listOfOtherCases: ICaseCard[]) {
    this.listOfOtherCases = listOfOtherCases;
    this.isListOfOtherCasesLoaded = true;
  }

  setListOfLast6Cases(listOfOfLast6Cases: ICaseCard[]) {
    this.listOfOfLast6Cases = listOfOfLast6Cases;
    this.listOfOfLast6CasesLoaded = true;
  }


  resetListOfOfLast6Cases() {
    this.listOfOfLast6CasesLoaded = false;
  }

  resetCases() {
    this.isCasesLoaded = false;
  }

  resetListOfMyCases() {
    this.isListOfMyCasesLoaded = false;
  }

  resetListOfOtherCases() {
    this.isListOfOtherCasesLoaded = false;
  }

  resetTableConfig() {
    this.cardsPerPage = 8;
    this.currentPage = 1;
    this.otherCardsPerPage = 12;
    this.setFilters([]);
  }

  setFilters = (id: CaseStatus[]) => {
    this.filters = [...id];
    this.resetCurrentPage();
  };

  switchFilters = (status: CaseStatus) => {
    if (this.filters.includes(status)) {
      this.setFilters([...this.filters.filter((el: CaseStatus) => el !== status)]);
    } else {
      this.setFilters([...this.filters, status]);
    }
    this.resetCurrentPage();
  };


  get filteredListOfCases() {
    return this.listOfMyCases
      ?.filter(filterByStatus(this.filters))
      ?.filter(filterByType(this.selectedAreaTypes));
  }

  get filteredListOfCasesByCurrentPage() {
    return this.filteredListOfCases?.filter(filterByCurrentPage(this.currentPage, this.cardsPerPage));
  }

  get isFilteredCasesListNotEmpty() {
    return this.filteredListOfCases && this.filteredListOfCases.length !== 0;
  }

  setCurrentPage = (value: number) => {
    this.currentPage = value;
  };

  setSelectedAreas(values: number[]) {
    this.selectedAreas = values;
    this.resetCurrentPage();
  }

  setSelectedAreasForOther(values: number[]) {
    this.selectedAreasForOther = values;
    this.resetCurrentPage();
  }

  get pageCount() {
    return Math.ceil(
      this.filteredListOfCases && this.isFilteredCasesListNotEmpty ? this.filteredListOfCases?.length / this.cardsPerPage : 1
    );
  }

  get filteredListOfOtherCases() {
    return this.listOfOtherCases
      ?.filter(filterByType(this.selectedAreaTypesForOther));
  }

  get filteredListOfOtherCasesByCurrentPage() {
    return this.filteredListOfOtherCases?.filter(filterByCurrentPage(this.currentPage, this.otherCardsPerPage));
  }

  get isFilteredOtherCasesListNotEmpty() {
    return this.filteredListOfOtherCasesByCurrentPage && this.filteredListOfOtherCasesByCurrentPage.length !== 0;
  }

  get otherPageCount() {
    return Math.ceil(
      this.filteredListOfOtherCases && this.filteredListOfOtherCases.length !== 0 ? this.filteredListOfOtherCases?.length / this.otherCardsPerPage : 1
    );
  }

  get selectedAreaTypes() {
    return this.selectedAreas
      .map(selectedId => this.rootStore.authStore.areas?.find(el => el.id === selectedId))
      .filter(area => area !== undefined)
      .map(area => area!.shortName);
  }

  get selectedAreaTypesForOther() {
    return this.selectedAreasForOther
      .map(selectedId => this.rootStore.authStore.areas?.find(el => el.id === selectedId))
      .filter(area => area !== undefined)
      .map(area => area!.shortName);
  }


  resetCurrentPage = () => (this.currentPage = 1);

}
