import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { ProgressBar } from './progressBar/ProgressBar';
import { useDraftCaseLung } from './useDraftCaseLung';

interface IProps {
  isReset?: boolean;
}

export const DraftCaseLung: FC<IProps> = observer(({ isReset }) => {
  const { breadcrumbs } = useDraftCaseLung();

  return <UserLayout overflowAuto={false} breadcrumbs={breadcrumbs}>
    <ProgressBar />
  </UserLayout>;
});