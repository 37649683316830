import {useParams} from 'react-router-dom';
import {MouseEvent, useEffect} from 'react';
import {useFormik} from 'formik';

import {IAreaCommon} from '../../../../../interfaces/admin/areas/areas';
import {initialValue, validationSchema} from './FormArea.helper';
import {useAreasApi} from '../../../../../apiHooks/useAreasApi';
import {useAreasStore} from '../../../../../store/hooks';
import {useModal} from '../../../../shared/modal/useModal.vm';

export const useFormArea = () => {
  const {postArea, putArea, getArea} = useAreasApi();
  const areasStore = useAreasStore();
  const {area} = areasStore;
  const {handleModal, isModalOpened, closeModal} = useModal();
  const {id} = useParams();
  const isEdit = !!id;

  const formik = useFormik<IAreaCommon>({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let isSuccess= false;
      if(isEdit && id){
        isSuccess = await putArea(+id, values);
      }
      else{
        isSuccess = await postArea(values);
        isSuccess && formik.resetForm();
      }
      isSuccess && handleModal();
    }
  })

  const {setValues} = formik;
  
  useEffect(() => {
    if(area === null && id){
      void getArea(+id)
    }
  }, [id, getArea, area])
  
  useEffect(() => {
    if(area){
     setValues(area);
    }
  }, [area, setValues])

  useEffect(() => {
    return () => areasStore.resetArea();
  }, [areasStore])

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  return {
    isEdit,
    formik,
    confirmForm,
    isModalOpened,
    closeModal,
  }
}