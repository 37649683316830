import styled from 'styled-components';
import theme from '../../../../../../theme/theme';

export interface IStepper {
  color: string;
}

export const StepperContainer = styled.div`
  width: 100px;
  margin-bottom: 50px;
  position: sticky;
  top: 0px;
  height: 570px;

  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`;

export const CurrentStep = styled.div`
  border-radius: 50%;
  width: 95px;
  height: 95px;
  background: ${theme.colors.yellow};
  margin-bottom: 20px;
  color: #F9FCFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
`;
export const PathStepContainer = styled.div`

`;

export const Step = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const StepLabel = styled.div<IStepper>`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  max-height: 40px;
  margin-top: 4px;
  color: ${props => props.color};
`;
export const PathStep = styled.div<IStepper>`
  width: 2px;
  height: 50px;
  background: ${props => props.color};
  margin: 14px auto;
`;