import styled from "styled-components";

export const ParagraphContainer = styled.div`
  text-align: center;
  max-width: 350px;
  margin: auto;
`;
export const ParagraphMain = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: white;
  margin-top: 22px;
`
export const ParagraphAdded = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: #033D71;
  margin-top: 30px;
`
export const RegisterViewingFormContainer = styled.form`
  position: relative;
  padding: 0 32px;
  z-index: 50;
  width: 100%;

  & button {
    margin: 12px 0 0 auto;
  }

  & path {
    fill: white;
  }
;`