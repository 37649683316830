import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useModal } from '../../../../shared/modal/useModal.vm';
import { generateLink, UserLink } from '../../../../../utils';
import { IAction, IHeadCell } from '../../../../../interfaces/common/table';
import { IBreadcrumbsElement } from '../../../../../interfaces/user/breadcrumbs';
import { ModalType } from '../../../../../constants/modalType';
import theme from '../../../../../theme/theme';
import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { TableCellTitle, TitleContainer } from './MyAcceptedCases.styled';
import { ActionButton } from '../../../../shared/customTables/button/ActionButton';
import { RoleCategory } from '../../../../../constants/projectUsers';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { ReactComponent as SearchIcon } from '../../../../../assets/icon/search.svg';
import { useReckoningStore } from '../../../../../store/hooks';
import { useReckoningApi } from '../../../../../apiHooks/useReckoningApi';
import { Checkbox, FormControlLabel } from '@mui/material';
import { TableCellHeader } from '../../../../shared/customTables/customTable/CustomTable.styled';
import { StoreIDs } from '../../../../../config/storeIds';
import { mapIdToKey } from './MyAcceptedCases.helper';

export const useMyAcceptedCases = () => {
  const navigate = useNavigate();

  const { getMyAcceptedCases } = useReckoningApi();
  const reckoningStore = useReckoningStore();

  const {
    reckoningAcceptedCasesFilteredData,
    reckoningAcceptedCases,
    filteredMyReckoningsByCurrentPage,
    pageCount,
    currentPage,
    setCurrentPage,
    selectedValue,
    setSelectedValue,
    contractType,
    isReckoningAcceptedCasesLoaded,
    areasCases
  } = reckoningStore;

  const { handleModal, isModalOpened, closeModal, modalType } = useModal();

  const [selectedBulk, setSelectedBulk] = useState(false);
  const [ids, setIds] = useState<number[] | null>(null);
  const [isSingleMode, setIsSingleMode] = useState<null | boolean>(null);

  const toReckoningButtonActive = ids && ids.length > 0;

  useEffect(() => {
    if (selectedValue) {
      setIsSingleMode(mapIdToKey(selectedValue) === 'lung');
    }
  }, [isSingleMode, selectedValue]);

  useEffect(() => {
    if (!isReckoningAcceptedCasesLoaded) {
      if (reckoningAcceptedCases === null) {
        void getMyAcceptedCases();
      } else {
        void getMyAcceptedCases(false);
      }
    }
  }, [getMyAcceptedCases, reckoningAcceptedCases, isReckoningAcceptedCasesLoaded]);

  useEffect(() => {
    return () => {
      reckoningStore.resetReckoningAcceptedCases();
      reckoningStore.resetSelectedValue();
    };
  }, [reckoningStore]);

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.MY_RECKONING,
      name: 'Moje rozliczenia'
    },
    {
      name: 'Rozlicz'
    }];


  const headCellsModel: IHeadCell[] = [
    { id: 'title', numeric: false, label: 'Tytuł faktury/nazwa opisu przypadku' },
    { id: 'customId', numeric: false, label: 'Id' },
    { id: 'amount', numeric: false, label: 'Kwota' },
    {
      id: 'selected',
      numeric: false,
      header: <TableCellHeader align={'center'} sx={{ width: 110 }}><FormControlLabel
        sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }} checked={selectedBulk}
        onChange={() => casesToReckoning.length > 0 && selectedBulkAction()}
        control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fill: 'white' } }} />}
        label='Zaznacz wszystkie' /></TableCellHeader>
    },
    {
      id: 'action',
      numeric: false,
      label: 'Akcja'
    }
  ];

  let headCells = [];

  if (isSingleMode) {
    headCells = [...headCellsModel.slice(0, 3), headCellsModel[4]];
  } else {
    headCells = headCellsModel.slice(0, 4);
  }

  const changeAreaHandler = (id: number) => {
    setSelectedValue(id);
    setIsSingleMode(id === StoreIDs.AREA_LUNG);
    setIds(null);
  };

  const reckoningHandler = () => {
    if (contractType !== 'none') {
      handleModal(ModalType.MY_RECKONING_SELECTION_MODAL);
    } else {
      handleModal(ModalType.MY_RECKONING_NOT_ACCEPTED_MODAL);
    }
  };

  const documentPlacementHandler = () => {
    handleModal(ModalType.MY_RECKONING_DOCUMENT_PLACEMENT_MODAL);
  };

  const confirmationHandler = () => {
    handleModal(ModalType.MY_RECKONING_CONFIRMATION_MODAL);
  };

  const getTitleText = (id: number) => {
    const findCase = filteredMyReckoningsByCurrentPage && filteredMyReckoningsByCurrentPage.find(el => el.id === id);
    return findCase && findCase.isClosed ? 'rozliczone' : findCase && findCase.inProgress ? 'w trakcie' : 'do rozliczenia';
  };

  const getBackground = (id: number) => {
    const findCase = filteredMyReckoningsByCurrentPage && filteredMyReckoningsByCurrentPage.find(el => el.id === id);
    return findCase && findCase.isClosed ? theme.colors.turquoise2 : findCase && findCase.inProgress ? theme.colors.gray4 : theme.colors.yellow;
  };


  const checkIsDisabled = (id: number) => {
    const findCase = filteredMyReckoningsByCurrentPage && filteredMyReckoningsByCurrentPage.find(el => el.id === id);
    return !Boolean(findCase && (!findCase.isClosed && !findCase.inProgress));
  };

  const selectedById = (id: number) => {
    const findCase = Boolean(ids && ids.find(idSelected => idSelected === id));
    if (findCase) {
      setIds(prevState => {
        const newIds = prevState ? prevState.filter(el => el !== id) : prevState;
        if (newIds === null || newIds.length === 0) {
          setSelectedBulk(false);
        }
        return newIds;
      });
    } else {
      setIds(prevState => {
        const newIds = prevState ? [...prevState, id] : [id];
        if (newIds && casesToReckoning.length === newIds.length) {
          setSelectedBulk(true);
        }
        return newIds;
      });
    }
  };

  const selectedBulkAction = () => {
    setSelectedBulk(prevState => {
        const newValue = !prevState;
        if (newValue) {
          setIds(prevState => filteredMyReckoningsByCurrentPage ? casesToReckoning.map(el => el.id) : prevState);
        } else {
          setIds([]);
        }
        return newValue;
      }
    );
  };


  const cleanReckoning = () => {
    setSelectedBulk(false);
    setIds(null);
  };

  const checkIsSelected = (id: number) => {
    return Boolean(ids && ids.find(idSelected => idSelected === id));
  };

  const actions: IAction[] = [
    {
      key: 'edit',
      background: getBackground,
      actionElement: getTitleText,
      width: 105,
      isDisabled: true
    }
  ];

  const cases = createTableRows(filteredMyReckoningsByCurrentPage ?? [], headCells, true);

  const cellsSwitcher = (keyName: string, value: string, onClick?: () => void, id?: number) => {
    let title = <></>;

    const element = filteredMyReckoningsByCurrentPage && filteredMyReckoningsByCurrentPage.find(element => element.id === id);
    if (keyName === 'title') {

      if (element) {
        title = <><b>{element.createdAt.slice(0, 10)} Opis przypadku: </b>{value}</>;
      }
    }

    if (!id) {
      return null;
    }
    switch (keyName) {
      case 'id':
        return null;
      case 'title':
        return <TableCellTitle><TitleContainer><span>{title}</span><ActionButton id={id}
                                                                                 background={theme.colors.yellow}
                                                                                 actionElement={<SearchIcon />}
                                                                                 width={51}
                                                                                 actionHandler={() => navigate(generateLink(RoleCategory.USER, UserLink.VIEW_CASE_NEURO, id))} /></TitleContainer></TableCellTitle>;
      case 'selected':
        return <TableCellData align={'center'}><Checkbox checked={checkIsSelected(id)} onChange={() => selectedById(id)}
                                                         disabled={checkIsDisabled(id)} /></TableCellData>;
      case 'action':
        return <TableCellData align={'center'}><ActionButton id={id}
                                                             background={'transparent'}
                                                             width={51}
                                                             isDisabled={element?.isClosed || element?.inProgress}
                                                             color={(element?.isClosed || element?.inProgress) ? theme.colors.gray4 : 'black'}
                                                             actionElement={<span>Rozlicz</span>}
                                                             actionHandler={() => {
                                                               if (!(element?.isClosed || element?.inProgress)) {
                                                                 setIds([id]);
                                                                 reckoningHandler();
                                                               }

                                                             }}

        /></TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const selectOptions = areasCases && areasCases.size > 1 ?
    [
      {
        id: StoreIDs.AREA_NEURO,
        name: 'Neurologia'
      },
      {
        id: StoreIDs.AREA_LUNG,
        name: 'Rak płuca'
      }
    ] : null;

  const casesToReckoning = filteredMyReckoningsByCurrentPage ? filteredMyReckoningsByCurrentPage.filter(el => !el.isClosed && !el.inProgress) : [];

  return {
    casesToReckoning,
    isSingleMode,
    reckoningAcceptedCasesIsNotNull: reckoningAcceptedCasesFilteredData !== null,
    ids,
    confirmationHandler,
    contractType,
    documentPlacementHandler,
    isModalOpened,
    closeModal,
    modalType,
    pageCount,
    setCurrentPage,
    currentPage,
    breadcrumbs,
    actions,
    headCells,
    cellsSwitcher,
    cases,
    reckoningHandler,
    cleanReckoning,
    toReckoningButtonActive,
    selectOptions,
    selectedValue,
    changeAreaHandler
  };
};
