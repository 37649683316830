import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useProgressBarNeuroStore} from '../../../../../store/hooks';
import {stages} from './ProgressBar.helper';
import {DraftLayout} from '../layout/draftLayout/DraftLayout';
import {GlobalLoader} from '../../../../shared/appLayer/globalLoader/GlobalLoader';

export const ProgressBar = observer(() => {
  const progressBarStore = useProgressBarNeuroStore();

  const stage = stages.find(stageElement => stageElement.stage === progressBarStore.currentStage);

  useEffect(() => {
    return () => {
      progressBarStore.resetProgressBar()
    }
  }, [progressBarStore])

  if (!stage) {
    return <GlobalLoader/>;
  }

  return (
    <DraftLayout stage={stage} validNext={progressBarStore.maxEditStage > stage.stage} message={stage.message}/>
  );
});
