import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { IViewCaseLung, IViewCaseNeuro } from '../interfaces/user/case';

export class CaseStore {
  viewCaseNeuro: IViewCaseNeuro | null = null;
  viewCaseLung: IViewCaseLung | null = null;


  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setViewCaseNeuro(viewCase: IViewCaseNeuro) {
    this.viewCaseNeuro = viewCase;
  }

  resetCaseNeuro() {
    this.viewCaseNeuro = null;
  }

  setViewCaseLung(viewCase: IViewCaseLung) {
    this.viewCaseLung = viewCase;
  }

  resetCaseLung() {
    this.viewCaseLung = null;
  }
}
