import {useParams} from 'react-router-dom';
import {useEffect} from 'react';

import {IDoctorFieldSets, IDoctorThirdStageLung} from '../../../../../../../interfaces/user/draftCaseLung/thirdStage';
import {useDraftCaseApi} from '../../../../../../../apiHooks/useDraftCaseLungApi';

export const useAccordionCustom = (doctor: IDoctorThirdStageLung, isModalOpened: boolean, setExpandedId?: (id?: number | null) => void) => {
  const {putDoctorThirdStage} = useDraftCaseApi();
  const {id} = useParams();
  const date = new Date(doctor.date as string);

  const formatter = new Intl.DateTimeFormat('pl-PL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  const dateFormatted = formatter.format(date);

  const expandedDoctorChange = () => {
    if(setExpandedId && doctor && doctor.id){
      setExpandedId(doctor.id);
    }
  }

  const handleOnSave = async (values: IDoctorFieldSets) => {
    if(id && doctor.id){
      await putDoctorThirdStage(Number(id), doctor.id, values);
    }
  }

  useEffect(()=> {
    if(setExpandedId && isModalOpened){
      setExpandedId(null)
    }
  }, [isModalOpened, setExpandedId])

  return {
    dateFormatted,
    expandedDoctorChange,
    handleOnSave
  }
}