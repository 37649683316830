import { useApiCall } from "./useApiCall";
import { useCallback } from "react";
import { IDraftCaseNeuro } from "../interfaces/user/draftCaseNeuro/draft";
import { useDraftCaseStore, useProgressBarNeuroStore } from "../store/hooks";
import { IFirstStageRequestNeuro} from "../interfaces/user/draftCaseNeuro/firstStage";
import { ISecondStageNeuro} from "../interfaces/user/draftCaseNeuro/secoundStage";
import { IFourthStageNeuro } from "../interfaces/user/draftCaseNeuro/fouthStage";
import { IImage } from "../interfaces/user/image";
import { useAuthApi } from './useAuthApi';

export const useDraftCaseApi = () => {
  const {api} = useApiCall();
  const draftCaseStore = useDraftCaseStore();
  const progressBarStore = useProgressBarNeuroStore()
  const {checkAuth} = useAuthApi();

  const getDraftCase = useCallback(
    async (id: number | string) => {
      const res: IDraftCaseNeuro = await api.apiInstance.get(`situation/${id}`);
      draftCaseStore.setDraftCaseNeuro(res);
      return !!res
    },
    [api.apiInstance, draftCaseStore]
  );

  const postDraftCase = useCallback(
    async (firstStage: IFirstStageRequestNeuro) => {
      const res = await api.apiInstance.post('situation', firstStage);

      if (res.id) {
        return res.id;
      }
      return false;
    },
    [api.apiInstance]
  );

  const putFirstStageDraftCase = useCallback(
    async (id: number, firstStage: IFirstStageRequestNeuro) => {
      const res = await api.apiInstance.put(`situation/${id}/first`, firstStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const putSecondStageDraftCase = useCallback(
    async (id: number, secondStage: ISecondStageNeuro) => {
      const res = await api.apiInstance.put(`situation/${id}/second`, secondStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const putFourthStageDraftCase = useCallback(
    async (id: number, fourthStage: IFourthStageNeuro) => {
      const res = await api.apiInstance.put(`situation/${id}/fourth`, fourthStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const postImageSecondStage = useCallback(
    async (id: number, image: File) => {
      const form = new FormData();
      form.append('image', image);
      const res: IImage[] = await api.apiInstance.post(`situation/${id}/image/second`, form);
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, diagnosisImages: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const postImageFourthStage = useCallback(
    async (id: number, image: File) => {
      const form = new FormData();
      form.append('image', image);
      const res: IImage[] = await api.apiInstance.post(`situation/${id}/image/fourth`, form);
      if (res) {
        if (progressBarStore.fourthStage) {
          progressBarStore.setFourthStage({...progressBarStore.fourthStage, images: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const deleteImageSecondStage = useCallback(
    async (id: number, idImage: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/image/${idImage}/second`);
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, diagnosisImages: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const deleteImageFourthStage = useCallback(
    async (id: number, idImage: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/image/${idImage}/fourth`);
      if (res) {
        if (progressBarStore.fourthStage) {
          progressBarStore.setFourthStage({...progressBarStore.fourthStage, images: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const publishDraftCase
    = useCallback(
    async (id: number) => {
      const res = await api.apiInstance.post(`situation/${id}/send-to-accept`, {});
      if(res){
        await checkAuth();
      }
      return !!res;
    },
    [api.apiInstance, checkAuth]
  );


  return {
    postImageSecondStage,
    deleteImageSecondStage,
    deleteImageFourthStage,
    getDraftCase,
    postDraftCase,
    putFirstStageDraftCase,
    putSecondStageDraftCase,
    putFourthStageDraftCase,
    postImageFourthStage,
    publishDraftCase
  }
}