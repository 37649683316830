import { useFormik } from "formik";
import { MouseEvent, useEffect } from "react";
import * as yup from "yup";
import { useContractStore } from "../../../../../../../store/hooks";
import { useContractApi } from "../../../../../../../apiHooks";

export const validationSchema = yup.object().shape({
  datePay: yup
    .string()
    .required(),
  numberContract: yup
    .string()
})

export const useEditModal = (selectedItem: string, closeModal: () => void) => {
  const contractStore = useContractStore();
  const {documents} = contractStore;
  const {editContract} = useContractApi()
  const isInvoice = selectedItem.includes('Faktura')
  const itemInfo = selectedItem && documents && documents.find(el => (el.id as string) === selectedItem)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      datePay: '',
      numberContract: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (itemInfo) {
        if (isInvoice && !values.numberContract) {
          formik.setFieldError("numberContract", "Wpisz numer dokumentu");
          return
        }
        const res = await editContract(values.datePay, values.numberContract, isInvoice ? 'company-contract' : 'user-contract', itemInfo.customId)
        if (res) {
          closeModal()
        }
      }
    }
  });

  const {setFieldValue} = formik

  useEffect(() => {
    if (selectedItem && itemInfo && itemInfo.datePay) {
      setFieldValue('datePay', itemInfo.datePay)
      if(isInvoice){
        setFieldValue('numberContract', itemInfo.numberContract)
      }
    }
  }, [setFieldValue, selectedItem, itemInfo, isInvoice])

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  return {
    formik,
    confirmForm,
    isInvoice
  }
}