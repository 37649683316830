import { Item } from './RadioButton.styled';
import React, { ChangeEvent } from 'react';

interface IProps {
  id: string,
  name: string;
  value: string | number
  isUppercase?: boolean
  checked: boolean
  isError?: boolean
  onChange: (e: ChangeEvent<any>) => void
}

export const RadioButton: React.FC<IProps> = ({ id, isError, name, value, checked, onChange, isUppercase }) => {
  return (
    <Item $isError={isError} $isUppercase={isUppercase}>
      <input
        type='radio'
        id={id}
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={id}>{value}</label>
    </Item>
  );
};