import styled from "styled-components";

export const SwitchIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-left: 4px;
`;

export const SwitchIcon = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
`;