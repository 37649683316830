import styled from "styled-components";
import {Button} from "../../button/Button";
import {Grid} from "@mui/material";
import theme from "../../../../theme/theme";
import doctorHospitalBcg from "../../../../assets/img/doctorHospitalBcg.png";
import {TextDrImage} from "../textDrImage/TextDrImage";

export const StyledButton = styled(Button)`
  & button {
    height: 70px !important;
  }
`

export const DoctorImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin: 20px auto 0 !important;
  max-width: 410px;
`

export const StyledText = styled.div`
  & div {
    position: unset;
    transform: unset;
    margin: 0 auto;
  }
`

export const MobileVersion = styled(Grid)`
  & img {
    margin: 35px auto 20px auto;
    display: block
  }

  path {
    fill: ${theme.colors.beige}
  }

  button {
    height: 56px;
    margin-bottom: 30px;
  }
`
export const CirceContainer = styled.div`
  position: relative;
  width: 50%;
  padding-bottom: 50%;
  background: rgba(249, 252, 255, 0.9);
  border-radius: 50%;
`
export const Container = styled.div`
  width: 100%;
  height: 735px;
  display: block;
  position: relative;
  background-image: url(${doctorHospitalBcg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  @media (max-width: ${theme.breakpoints.medium}px) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background-image: none;
    display: flex;
    justify-content: center;
  }
`

export const AdditionalComponent = styled.div`
  position: absolute;
  width: 100%;
  max-height: 317px;
  display: block;
  z-index: 50;
  left: 5%;
  bottom: 10%;
`

export const AdditionalContainer = styled.div`
  position: relative;
`

export const Circle = styled.div`
  max-width: 317px;
  max-height: 317px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  @media (max-width: ${theme.breakpoints.large}px) {
    max-width: 217px;
    max-height: 217px;
  }
  @media (max-width: ${theme.breakpoints.medium}px) {
    max-width: 150px;
    max-height: 150px;
  }
`

export const Img = styled.img`
  margin-bottom: 56px;
  z-index: 50;
  position: relative;
  width: 120px;
`

export const StyledTextDrImage = styled(TextDrImage)`
  @media (max-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`