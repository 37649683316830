import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const SectionTitle = styled.h1`
  font-weight: 800;
  padding: 0 16px;
  font-size: 36px;
  line-height: 120%;
  text-transform: uppercase;
  color: ${theme.colors.dark1};
  margin-bottom: 32px;
  margin-top: 48px;
`
export const Container = styled.div`
  max-width: 1012px;
  margin: 0 auto;
  width: 100%;
`