import { ReactNode, useEffect, useState } from 'react';
import { generateOptionsForSelectMonth, generateOptionsForSelectYear } from './GenerateReportModal.helper';
import { useContractApi } from '../../../../../../../apiHooks';
import { useSearchParams } from 'react-router-dom';
import { useAreasApi } from '../../../../../../../apiHooks/useAreasApi';
import { useAreasStore } from '../../../../../../../store/hooks';
import { SelectChangeEvent } from '@mui/material';

const yearOptions = generateOptionsForSelectYear();

export const useGenerateReportModal = () => {
  let [searchParams] = useSearchParams();

  const {
    getReportYearly,
    getReportMonthly
  } = useContractApi();

  const [yearAnnualReport, setYearAnnualReport] = useState(-1);
  const [yearMonthlyReport, setYearMonthlyReport] = useState(-1);
  const [monthMonthlyReport, setMonthMonthlyReport] = useState(-1);
  const [areaAnnualReport, setAreaAnnualReport] = useState<number[]>([]);
  const [areaMonthlyReport, setAreaMonthlyReport] = useState<number[]>([]);
  const { getAreas } = useAreasApi();
  const { areas } = useAreasStore();
  const monthsOptions = generateOptionsForSelectMonth(String(yearMonthlyReport));
  const areasOptions = areas ? areas.map(el => ({
    id: el.id,
    title: el.fullName
  })) : [];

  useEffect(() => {
    void getAreas();
  }, [getAreas]);

  useEffect(() => {
    if (yearAnnualReport === -1 && yearMonthlyReport === -1 && monthMonthlyReport === -1) {
      const now = new Date();
      let yearPrevMonthlyReport = now.getFullYear();
      let previousMonth = now.getMonth() - 1;

      if (previousMonth < 0) {
        previousMonth = 11;
        yearPrevMonthlyReport -= 1;
      }

      const monthPrevMonthlyReport = new Date(yearPrevMonthlyReport, previousMonth).getMonth() + 1;

      if (searchParams.get('year')) {
        setYearAnnualReport(Number(searchParams.get('year')));
      } else {
        setYearAnnualReport(now.getFullYear());
      }

      const newYearMonthlyReport = yearOptions.find(year => year.id === yearPrevMonthlyReport);
      if (newYearMonthlyReport) {
        setYearMonthlyReport(newYearMonthlyReport.id);
      }

      const newMonthMonthlyReport = monthsOptions.find(month => month.id === monthPrevMonthlyReport);
      if (newMonthMonthlyReport) {
        setMonthMonthlyReport(newMonthMonthlyReport.id);
      }
    }

  }, [searchParams, monthsOptions, monthMonthlyReport, yearAnnualReport, yearMonthlyReport]);


  const handleChangeAreaYearly = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];
    setAreaAnnualReport(selectedValues);
  };


  const handleChangeAreaMonthly = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];
    setAreaMonthlyReport(selectedValues);
  };

  const generateAnnualReport = () => {
    void getReportYearly(yearAnnualReport, areaAnnualReport);
  };

  const generateMonthlyReport = () => {
    void getReportMonthly(monthMonthlyReport, yearMonthlyReport, areaMonthlyReport);
  };


  return {
    yearAnnualReport,
    yearMonthlyReport,
    monthMonthlyReport,
    setYearAnnualReport,
    setYearMonthlyReport,
    setMonthMonthlyReport,
    yearOptions,
    monthsOptions,
    generateAnnualReport,
    generateMonthlyReport,
    areasOptions,
    areaAnnualReport,
    areaMonthlyReport,
    handleChangeAreaYearly,
    handleChangeAreaMonthly
  };
};