import { useCallback } from 'react';

import { useApiCall } from './useApiCall';
import { IDraftCaseLung } from '../interfaces/user/draftCaseLung/draft';
import { useDraftCaseStore } from '../store/hooks';
import { IFirstStageRequestLung } from '../interfaces/user/draftCaseLung/firstStage';
import { ISecondStageLung } from '../interfaces/user/draftCaseLung/secoundStage';
import { IFourthStageLung } from '../interfaces/user/draftCaseLung/fouthStage';
import { IDoctorThirdStageLung } from '../interfaces/user/draftCaseLung/thirdStage';
import { useAuthApi } from './useAuthApi';

export const useDraftCaseApi = () => {
  const { api } = useApiCall();
  const draftCaseStore = useDraftCaseStore();
  const {checkAuth} = useAuthApi();

  const getDraftCase = useCallback(
    async (id: number | string) => {
      const res: IDraftCaseLung = await api.apiInstance.get(`lung/situation/${id}`);
      draftCaseStore.setDraftCaseLung(res);
      return !!res;
    },
    [api.apiInstance, draftCaseStore]
  );

  const postDraftCase = useCallback(
    async (firstStage: IFirstStageRequestLung) => {
      const res = await api.apiInstance.post('lung/situation', firstStage);

      if (res?.id) {
        return res.id;
      }
      return false;
    },
    [api.apiInstance]
  );

  const putFirstStageDraftCase = useCallback(
    async (id: number, firstStage: IFirstStageRequestLung) => {
      const res = await api.apiInstance.put(`lung/situation/${id}/first`, firstStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const putSecondStageDraftCase = useCallback(
    async (id: number, secondStage: ISecondStageLung) => {
      const res = await api.apiInstance.put(`lung/situation/${id}/second`, secondStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const putFourthStageDraftCase = useCallback(
    async (id: number, fourthStage: IFourthStageLung) => {
      const res = await api.apiInstance.put(`lung/situation/${id}/fourth`, fourthStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const publishDraftCase
    = useCallback(
    async (id: number) => {
      const res = await api.apiInstance.post(`lung/situation/${id}/send-to-accept`, {});
      
      if(res){
        await checkAuth();
      }
      return !!res;
    },
    [api.apiInstance, checkAuth]
  );

  const getVoivodeships = useCallback(async () => {
    const res = await api.apiInstance.get('voivodeships');
    if (res) {
      draftCaseStore.setVoivodeships(res.items);
    }

  }, [api.apiInstance, draftCaseStore]);

  const postDoctorThirdStage
    = useCallback(
    async (id: number, data: IDoctorThirdStageLung) => {
      const res = await api.apiInstance.post(`lung/situation/${id}/doctor`, data);

      if (res) {
        return getDraftCase(id);
      }
    },
    [getDraftCase, api.apiInstance]
  );

  const putDoctorThirdStage
    = useCallback(
    async (idSituation: number, id: number, data: IDoctorThirdStageLung) => {
      const res = await api.apiInstance.put(`lung/situation/${idSituation}/doctor/${id}`, data);

      if (res) {
        await getDraftCase(idSituation);
      }
      return !!res;
    },
    [getDraftCase, api.apiInstance]
  );

  const deleteDoctorThirdStage
    = useCallback(
    async (idSituation: number, id: number) => {
      await api.apiInstance.delete(`lung/situation/${idSituation}/doctor/${id}`);
      return true;
    },
    [api.apiInstance]
  );


  return {
    getDraftCase,
    postDraftCase,
    putFirstStageDraftCase,
    putSecondStageDraftCase,
    putFourthStageDraftCase,
    publishDraftCase,
    getVoivodeships,
    postDoctorThirdStage,
    putDoctorThirdStage,
    deleteDoctorThirdStage
  };
};