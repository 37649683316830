import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { GeneralLink, generateLink, UserLink } from "../../../utils";
import { RoleCategory } from "../../../constants/projectUsers";
import { Header, Paragraph } from './Page404.styled'
import { Page404Layer } from "../../shared/layers/page404/Page404Layer";
import doctorImg from './../../../assets/img/doctorHospitalBcg.png'
import { useAuthStore } from "../../../store/hooks";

export const Page404 = observer(() => {
  const {isSessionExpired} = useAuthStore();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(3)

  useEffect(() => {
    if (isSessionExpired) {
      navigate(generateLink(RoleCategory.GUEST, GeneralLink.HOME))
    }
  }, [isSessionExpired, navigate])

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev: number) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      navigate(generateLink(RoleCategory.GUEST, GeneralLink.HOME))
    }
  }, [seconds, navigate]);
  
  return (
    <Page404Layer backgroundImg={doctorImg} handler={() => navigate(generateLink(RoleCategory.USER, UserLink.HOME))}>
      <>
        <Header>Błąd 404</Header>
        <Paragraph>Niestety nie udało nam się znaleźć tej strony.</Paragraph>
      </>
    </Page404Layer>
  )
});
