import { useCaseStore } from '../../../../../store/hooks';
import theme from '../../../../../theme/theme';
import { ReactComponent as Woman } from '../../../../../assets/icon/genderFemale.svg';
import { ReactComponent as Man } from '../../../../../assets/icon/genderMale.svg';
import { ReactComponent as Calendar } from '../../../../../assets/icon/calendar.svg';
import React, { FC } from 'react';
import { Button } from '../../../../shared/button/Button';
import {
  BlackTitle,
  Container,
  Content,
  FlexContainer,
  FlexContainerSecond,
  GenderContainer,
  ImgContainer,
  ObjectContainer,
  SectionTitle,
  SmallerText,
  StyledCalendar,
  Title
} from './MiddlePart.styled';
import { dateStringify } from '../../../../../utils';
import { ImageBox } from '../sharedComponents/imageBox/ImageBox';
import { GridContainer } from '../sharedComponents/gridContainer/GridContainer';
import { IImages } from '../ViewCase.helper';
import { Gender } from '../../../../../constants/gender';


export const MiddlePart: FC<IImages> = ({ imageHandler }) => {
  const caseStore = useCaseStore();
  const { viewCaseNeuro } = caseStore;

  return (
    <Container>
      {viewCaseNeuro && <>
        <Title>Tytuł:</Title>
        <Content>{viewCaseNeuro.title}</Content>
        <FlexContainer>
          <div><Title>Płeć:</Title>
            <GenderContainer><ImgContainer>{viewCaseNeuro.gender === Gender.MAN ? <Man /> :
              <Woman />}</ImgContainer>{viewCaseNeuro.gender === Gender.MAN ? 'Mężczyzna' : 'Kobieta'}</GenderContainer>
          </div>
          <div><Title>Rok urodzenia</Title>
            <SmallerText>{viewCaseNeuro.yearOfBirth}</SmallerText>
          </div>
        </FlexContainer>
        <SectionTitle>Rozpoznanie SM:</SectionTitle>
        <FlexContainerSecond>
          <div><Title>Data rozpoznania:</Title>
            <ObjectContainer><StyledCalendar><Calendar /></StyledCalendar><SmallerText>{dateStringify(viewCaseNeuro.diagnosisDate!)}</SmallerText></ObjectContainer>
          </div>
          <div><Title>Wiek pacjenta w momencie rozpoznania</Title>
            <SmallerText>{viewCaseNeuro.diagnosisAge}</SmallerText>
          </div>
          <div><Title>badanie płynu mózgowo-rdzeniowego</Title>
            <FlexContainerSecond><SmallerText>OBECNOŚĆ PRĄŻKÓW OLIGOKLONALNYCH?</SmallerText>
              <Button size={'small'}
                      backgroundColor={theme.colors.dark1}
                      color={theme.colors.beige}
                      text={viewCaseNeuro.diagnosisIsFluidTesting ? 'TAK' : 'NIE'}
                      width={'95px'} /></FlexContainerSecond>
          </div>
        </FlexContainerSecond>
        <Title>Aktywność rzutowa:</Title>
        <FlexContainer>
          <GridContainer textData={'Data rzutu'} textContent={'Opis rzutu <span>(Objawy)</span>'}
                         content={viewCaseNeuro.projectileActivityDescription!}
                         date={viewCaseNeuro.projectileActivityDate} />
        </FlexContainer>
        <BlackTitle>Wdrożenie leczenia rzutu:</BlackTitle>
        <Content>{viewCaseNeuro.projectileActivityTreatment}</Content>
        <BlackTitle>Ocena ciężkości rzutu w edss przed i po leczeniu rzutu:</BlackTitle>
        <Content>{viewCaseNeuro.projectileActivityAssessment}</Content>
        <Title>Aktywność rezonansowa:</Title>
        <BlackTitle>Opis MRI na podstawie, którego postawiono rozpoznanie (np: liczba i lokalizacja nowych zmian T1
          i/lub nowych i powiększających się zmian T2/FLAIR
          wzmacniających się po kontraście):</BlackTitle>
        <Content>{viewCaseNeuro.resonanceActivityDescription}</Content>
        <ImageBox imageHandler={imageHandler} images={viewCaseNeuro.diagnosisImages} />
      </>}

    </Container>
  );
};
