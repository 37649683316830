
import {RootStore} from './rootStore';
import {makeAutoObservable} from 'mobx';
import {ISettings} from '../interfaces/admin/settings/settings';

export class SettingsStore {

  settings: ISettings | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setSettings(settings: ISettings){
    this.settings = settings;
  }

}
