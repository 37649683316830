import { AdminLayout } from '../../../../shared/admin/layout/AdminLayout';
import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import { HeaderCustomTableContract } from '../headerCustomTable/HeaderCustomTable';
import { useInvoices } from './useInvoices';
import { observer } from 'mobx-react-lite';
import { ChangeDocumentModal } from '../changeDocumentModal/ChangeDocumentModal';
import { PayDocumentModal } from '../payDocumentModal/PayDocumentModal';
import { MultipleSelectContainer } from '../Contract.styled';
import { MultipleSelect } from '../../../../shared/form/multipleSelect/MultipleSelect';

export const Invoices = observer(() => {
  const {
    isModalOpened,
    closeModal,
    tableDataRows,
    generateActions,
    cellsSwitcher,
    companyInvoicesIsNotNull,
    userInfo,
    headCells,
    handlePay,
    isPayModalOpened,
    closePayModal,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years, editId,
    handleRefresh
  } = useInvoices();

  return (<AdminLayout>
    {companyInvoicesIsNotNull &&
      <CustomTable
        actions={generateActions()}
        cellsSwitcher={cellsSwitcher}
        searchBarAddedContent={
          <HeaderCustomTableContract>Faktury</HeaderCustomTableContract>}
        isSearchBar={true}
        tableDataRows={tableDataRows}
        isDataDownloaded={true}
        headCells={headCells}
        contentAfterSearch={<MultipleSelectContainer>
          <MultipleSelect subtitle={'Obszar'} key={'area'} selectedValues={selectedAreas}
                          elementName={'area'}
                          options={optionsAreas ?? []}
                          handleChange={handleChange} />
          <MultipleSelect subtitle={'Rok'} key={'years'} selectedValues={selectedYears}
                          elementName={'years'}
                          options={years}
                          handleChange={handleChangeYears} />
        </MultipleSelectContainer>}
      />}
    {isModalOpened &&
      <ChangeDocumentModal handleRefresh={handleRefresh} id={editId ? +editId : undefined} typeOfContract={'invoice'}
                           userInfo={userInfo}
                           closeModal={closeModal} />}

    {isPayModalOpened &&
      <PayDocumentModal
        closeModal={closePayModal}
        handleYes={handlePay}
        handleNo={closePayModal} />}
  </AdminLayout>);
});