import styled from 'styled-components';
import theme from '../../../../theme/theme';
import checkboxCheck from '../../../../assets/icon/checkboxCheck.svg';


export interface ILabel {
  checked: boolean,
  isError?: boolean,
  textColor?: string
  fontSize?: string
  isUpperCase?: boolean
}

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 16px;
  gap: 20px;
`;

export const Label = styled.label<ILabel>`
  padding-left: 10px;
  font-size: ${props => props.fontSize ? props.fontSize : '16px'};
  position: relative;
  cursor: pointer;
  line-height: 24px;
  padding-right: 16px;
  text-transform: ${props => props.isUpperCase ? 'uppercase' : 'none'};
  color: ${props => props.textColor};


  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: -13px;
    width: 16px;
    height: 16px;
    border: ${props => !props.checked ? `2px solid ${theme.colors.blue2}` : `2px solid ${theme.colors.dark1}`};
    border: ${props => props.isError && !props.checked && `2px solid ${theme.colors.red}`};
  }

  ${props => props.checked && `
   &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: -13px;
    background: #333333;
    width: 16px;
    height: 16px;
    background-image: url(${checkboxCheck});
    background-position: center;
    background-repeat: no-repeat; 
   }
  `}
  & span {
    color: ${theme.colors.red};
    font-weight: 700;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: ${props => props.fontSize ? props.fontSize : '10px'};
    line-height: 16px;
    &:before {
      top: 0;
    }

    ${props => props.checked && `
   &:after {
    top: 0;
    }`}
  }
`;

export const Input = styled.input<{ isError?: boolean }>`
  margin: 0;
  height: 30px;
  padding: 5px 10px;
  display: none;
`;

