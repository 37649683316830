import styled from "styled-components";
import {Grid} from "@mui/material";
import theme from "../../../../../theme/theme";

export const HistoryHeader = styled(Grid)`

`

export const HeaderContainer = styled.div`
  display: flex;
  gap: 10px;

`

export const Header = styled.h1`
  font-size: 20px;
  line-height: 120%;
  font-weight: 800;
  text-transform: uppercase;
`

export const CaseTitle = styled.p`
  color: ${theme.colors.darkBlue};
  font-weight: 700;
  font-size: 10px;
  line-height: 140%;
  padding-top: 5px;
  padding-bottom: 15px;
`

export const SearchIconContainer = styled.div`
  background: ${theme.colors.darkBlue};
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 30px;
    height: 30px;
  }

  & path {
    fill: white;
  }
`

export const InfoContainer = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
  text-transform: uppercase;
`