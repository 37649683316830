import {ArrowButton} from "../arrowButton/ArrowButton";
import {CurrentStep} from "../stepper/Stepper.styled";
import {useArrowsAndStepperMobile} from "./useArrowsAndStepperMobile";
import {FC} from "react";
import {IStageActionsLung} from "../../../../../../interfaces/user/draftCaseLung/progressBar";
import {ArrowsAndStepperMobileContainer} from "./ArrowsAndStepperMobile.styled";

interface IProps {
  isValidNext: boolean;
  prevArrowHandler: () => void;
  nextArrowHandler: () => void;
  prevAction: IStageActionsLung;
  nextAction: IStageActionsLung;
}

export const ArrowsAndStepperMobile: FC<IProps> = ({
                                                     isValidNext,
                                                     prevArrowHandler,
                                                     nextArrowHandler,
                                                     prevAction,
                                                     nextAction
                                                   }) => {
  const {currentStepText} = useArrowsAndStepperMobile();

  return (
    <ArrowsAndStepperMobileContainer>
      {prevAction.access &&
          <ArrowButton isMobile={true} isValid={true} onClick={prevArrowHandler} direction={"left"} text={"Cofnij"}/>}
      <CurrentStep>{currentStepText}</CurrentStep>
      {nextAction.access &&
          <ArrowButton isMobile={true} isValid={isValidNext} onClick={nextArrowHandler} direction={"right"}
                       text={"Następny"}/>}
    </ArrowsAndStepperMobileContainer>
  )
}