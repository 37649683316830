import { useCallback, useMemo } from 'react';

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { useAuthStore, usePoiStore } from '../store/hooks';

export const useApiCall = () => {
  const poiStore = usePoiStore();
  const authStore = useAuthStore();

  const errorHandler = useCallback(
    (status: number, message: string) => {
      poiStore.setGlobalError(status, message);
      if (status === 403) {
        authStore.appLogout();
      }
    },
    [poiStore, authStore]
  );

  const api = useMemo(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    const responseBody = (response: AxiosResponse) => response.data;

    const apiInstance = {
      get: async (url: string, isLoader = true, config?: AxiosRequestConfig) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
        }

        return axios
          .get(url, config)
          .then(responseBody)
          .catch(error => {
            errorHandler(error.response?.status, error.response?.data?.message);
          })
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      },
      post: async (url: string, body: string | unknown, isLoader = true, config?: AxiosRequestConfig) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
        }
        return axios
          .post(url, body, config)
          .then(responseBody)
          .catch(error => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      },
      put: async (url: string, body: string | unknown, isLoader = true) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
        }
        return axios
          .put(url, body)
          .then(responseBody)
          .catch(error => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      },
      delete: async (url: string, isLoader = true) => {
        if (isLoader) {
          poiStore.setGlobalLoader();
        }
        return axios
          .delete(url)
          .then(responseBody)
          .catch(error => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isLoader && poiStore.unsetGlobalLoader());
      }
    };

    return { apiInstance };
  }, [errorHandler, poiStore]);

  return { api };
};
