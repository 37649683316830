import React, { FC } from 'react';
import { Button } from '../../../../shared/button/Button';
import pdfIcon from '../../../../../assets/icon/pdfIcon.svg';

import theme from '../../../../../theme/theme';
import {
  CaseInfo,
  ColorSpan,
  Container,
  DataMiddle,
  IdCase,
  ImgContainer,
  RightSide,
  StatusContainer,
  StyledContainer,
  Title,
} from './HeaderCase.styled';
import { Breakpoint } from '../../../../../hooks/useBreakpoint';
import { dateStringify, timeStringify } from '../../../../../utils';
import { useHeaderCase } from './useHeaderCase';

interface IProps {
  casePdfHandler: () => void;
}

export const HeaderCase: FC<IProps> = ({ casePdfHandler }) => {

  const {
    viewCase,
    breakpoint,
    statusElement
  } = useHeaderCase();

  return (
    <Container>
      {viewCase && statusElement && <StyledContainer>
        <CaseInfo>
          {breakpoint < Breakpoint.SMALL && <IdCase>
            ID opisu przypadku <span>{viewCase.customId}</span>
          </IdCase>}
          <StatusContainer><ImgContainer
            color={statusElement.color}>{statusElement.component}</ImgContainer><DataMiddle>STATUS <ColorSpan
            color={statusElement.color}>{statusElement.name}</ColorSpan></DataMiddle></StatusContainer>
          {viewCase.initials && <DataMiddle>
            Pacjent <span>{viewCase.initials.toUpperCase()}</span>
          </DataMiddle>}
          <DataMiddle>
            <span>{timeStringify(viewCase.createdAt)} {` - `} {dateStringify(viewCase.createdAt)}</span>
          </DataMiddle>
        </CaseInfo>
        <Title>Opis przypadku:

        </Title>
        <RightSide>
          <Button text={'pobierz jako pdf'} size={'small'} icon={pdfIcon} backgroundColor={theme.colors.red}
                  color={theme.colors.beige} width={'206px'} clickHandler={casePdfHandler} />
          {breakpoint >= Breakpoint.SMALL && <IdCase>
            ID opisu <br /> przypadku: <span>{viewCase.customId}</span>
          </IdCase>}
        </RightSide>
      </StyledContainer>}
    </Container>
  );
};
