import {useFormik} from 'formik';
import {useParams} from 'react-router-dom';
import * as yup from "yup";
import {INewDrug} from '../../../../../../../interfaces/user/draftCaseNeuro/thirdStage';
import {useDraftCaseApi, useDrugApi} from '../../../../../../../apiHooks';
import {useDraftCaseStore, useProgressBarNeuroStore} from '../../../../../../../store/hooks';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  date: yup
    .string()
    .required()
})

export const useModalAdd = (handleCloseDrugModal: () => void) => {
  const {id} = useParams();
  const {postDrug} = useDrugApi();
  const {getDraftCase} = useDraftCaseApi();
  const progressBarStore = useProgressBarNeuroStore();
  const {draftCaseNeuro} = useDraftCaseStore();
  const {thirdStage} = progressBarStore
  const numberOfDrugs = thirdStage ? thirdStage.drugs?.length + 1 : ''
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      title: '',
      date: '',
    },
    onSubmit: async (values) => {
      if (id) {
        const newDrug: INewDrug = {
          title: values.title,
          date: values.date
        }
        if (id) {
          handleCloseDrugModal();
          await postDrug(+id, newDrug);
          await getDraftCase(+id);
        }
      }
    }
  })

  const minDate = draftCaseNeuro?.diagnosisDate ? new Date(draftCaseNeuro?.diagnosisDate) : null;

  return {
    minDate,
    formik,
    numberOfDrugs
  }
}