import styled from "styled-components";
import theme from "../../../../../theme/theme";

interface IRegisterViewingFormContainer {
  isButtonActive: boolean;
}

export const RegisterViewingFormContainer = styled.form<IRegisterViewingFormContainer>`
  position: relative;
  z-index: 50;
  padding: 0 32px;

  & button {
    margin: 62px 0 0 auto;
    @media (max-width: ${theme.breakpoints.small}px) {
      max-width: 100% !important;
      margin-top: 34px;
    }
  }

  & path {
    fill: ${(props) => props.isButtonActive ? 'white' : '#033D71'}
  }
;
}
`