export enum ValidationMessages {
  INVALID_LOGIN = 'Dane logowania są nieprawidłowe.',
  INVALID_EMAIL = 'Podany email jest nieprawidłowy.',
  INVALID_USER = 'Użytkownik nie istnieje.',
  INVALID_PWZ = "Nieprawidłowy PWZ.",
  INVALID_EMAIL_IS_USED = 'Taki email nie jest zarejestrowany. Sprawdź poprawność wpisanego emaila.',
  INVALID_FORM = 'Uzupełnij brakujące pola.',
  INVALID_FORMLOGIN = 'Uzupełnij poprawnie dane logowania.',
  INVALID_FORMCHANGE = 'Uzupełnij poprawnie dane do zmiany hasła.',
  EMPTY_FIELD = 'Pole nie może byc puste.',
  INVALID_REGISTERFORM = 'Nieprawidłowo wypełniony formularz.',
  INVALID_PASSWORD = 'Wpisane hasła nie są identyczne.',
  INVALID_CHECKBOX = 'Zaakceptuj Regulamin aby móc sie zarejestrować.',
  INVALID_OR_EMPTY_FORM = 'Dane są nieprawidłowe lub ich brak.',
  INVALID_USER_EXIST = "Użytkownik już istnieje.",
  INVALID_LINK = "Podany link rejestracyjny jest nieaktywny.",
  WEAK_PASSWORD = 'Hasło musi mieć przynajmniej 8 znaków, jedną cyfrę, jedną wielką literę, oraz jeden ze znaków specjalnych _!@#$'
}