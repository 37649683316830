import React, { useState } from 'react';
import { Grid } from '@mui/material';
import {
  DoctorImageComponent
} from '../../../../shared/doctorImageComponent/doctorImageComponent/DoctorImageComponent';
import { RegisterInfo } from '../../registerInfo/RegisterInfo';
import { RegisterLayoutViewing } from '../../layout/registerLayoutViewing/RegisterLayoutViewing';
import theme from '../../../../../theme/theme';
import doctorHospitalBcg from '../../../../../assets/img/doctorHospitalBcg.png';
import { RegisterFormViewingComponent } from './form/RegisterFormViewingComponent';
import { DoctorImg, RegisterContainer, StyledRegisterInfo } from './RegisterViewingUser.styled';

export const RegisterViewingUser = () => {
  const [isRegisterClicked, setIsRegisterClicked] = useState(false);

  return (
    <RegisterLayoutViewing>
      <RegisterContainer>
        <Grid
          container
          alignItems={'center'}
          justifyContent={'center'}
          height={{ xs: '100%' }}
          maxWidth={'1280px'}
          margin={'0 auto'}
          minHeight={'calc(100vh - 40px)'}
        >
          <Grid item xs={12} sm={12} md={7} alignSelf={'flex-end'}>
            <DoctorImageComponent additionalInfo={true} isRegisterClicked={isRegisterClicked}
                                  clickHandler={() => setIsRegisterClicked(true)} />


          </Grid>
          <Grid item sm={12} xs={12} md={5} alignItems={'center'} display={'flex'} flexDirection={'column'}>
            {!isRegisterClicked &&
              <StyledRegisterInfo>
                <RegisterInfo clickHandler={() => setIsRegisterClicked(true)} /></StyledRegisterInfo>
            }
            {isRegisterClicked &&
              <RegisterFormViewingComponent titleColor={theme.colors.dark1} labelColor={theme.colors.dark1}
                                            buttonBcgColor={theme.colors.turquoise2}
                                            buttonTextColor={theme.colors.beige} />

            }

            {isRegisterClicked && <DoctorImg src={doctorHospitalBcg} />}
          </Grid>
        </Grid>
      </RegisterContainer>
    </RegisterLayoutViewing>
  );
};
