import { ICaseCard } from '../interfaces/admin/cases/cases';
import { IAllUsers } from '../interfaces/admin/user/allUsers';
import { IPublishingUser } from '../interfaces/admin/user/publishingUser';

export const sortUsers = (users: IAllUsers[]) => {
  return users.sort((a, b) => +a.active - +b.active || b.id - a.id);
};


export const sortPublishingUsers = (users: IPublishingUser[]) => {
  return users.sort((a, b) => +a.isActive - +b.isActive || b.id - a.id);
};

export const sortCasesByDate = (cases: ICaseCard[]) => {
  return cases.sort((a: ICaseCard, b: ICaseCard) => {
    const c = new Date(a.updatedAt);
    const d = new Date(b.updatedAt);
    return d.getTime() - c.getTime();
  });

};