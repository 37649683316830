import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { Grid } from '@mui/material';
import { CaseCard } from '../../../shared/user/cards/CaseCard';
import { MuiPagination } from '../../../shared/pagination/MuiPagination';
import { useOtherCases } from './useOtherCases.vm';
import { observer } from 'mobx-react-lite';
import { GridCaseCard } from '../../../shared/user/cards/CaseCard.styled';
import { ParagraphEmpty } from '../myCases/MyCases.styled';
import { MultipleSelect } from '../../../shared/form/multipleSelect/MultipleSelect';

export const OtherCases = observer(() => {
  const {
    breadcrumbs,
    otherPageCount,
    currentPage,
    setCurrentPage,
    listOfOtherCases,
    isListOfOtherCasesIsNotEmpty,
    selectedAreas, handleChange, options
  } = useOtherCases();

  return (
    <UserLayout breadcrumbs={breadcrumbs}>
      <Grid container
            display={'flex'}
            margin={'0 auto'}
            height={'100%'}
            alignItems={'flex-start'}
            mt={2}
            pt={1}
      >
        <Grid item xs={12} justifyContent={'flex-end'} display={'flex'}>
          {options && options.length > 1 && <MultipleSelect
            selectedValues={selectedAreas}
            elementName={'areaSelect'}
            options={options}
            handleChange={handleChange}
            subtitle={'Obszar'}
          />}
        </Grid>
        {isListOfOtherCasesIsNotEmpty ?
          <Grid display={'flex'} flexDirection={'column'} width={'100%'} mt={4}>
            <Grid container width={'100%'} minHeight={'500px'}>
              {listOfOtherCases && listOfOtherCases.map(el => (
                <GridCaseCard item xs={12} sm={6} md={3} lg={2} pt={1} pb={1} key={el.customId}>
                  <CaseCard type={el.type} id={el.id} status={el.status} customId={el.customId} patient={el.patient}
                            title={el.title} updatedAt={el.updatedAt} />
                </GridCaseCard>
              ))}
            </Grid>
            <MuiPagination
              style={{ alignSelf: 'center', marginBottom: '10px' }}
              count={otherPageCount}
              page={currentPage}
              onChange={(e, value: number) => {
                setCurrentPage(value);
              }} />
          </Grid> :
          <ParagraphEmpty>Brak danych</ParagraphEmpty>}
      </Grid>
    </UserLayout>
  );
});