import {useFormik} from 'formik';
import {IDrug} from '../../../../../../../interfaces/user/draftCaseNeuro/draft';
import {useParams} from 'react-router-dom';
import {useDraftCaseApi, useDrugApi} from '../../../../../../../apiHooks';


export const useAccordionCustom = (drug: IDrug, isModalOpened: boolean, expandedId?: number, setExpandedId?: (id?: number) => void) => {
  const {id} = useParams();
  const {deleteImageDrug, postImageDrug, putDrug} = useDrugApi();
  const {getDraftCase} = useDraftCaseApi();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: drug ? drug.title : '',
      date: drug ? drug.date : '',
      comments: drug ? drug.comments ?? '' : '',
      dateEnd: drug ? drug.dateEnd : '',
      descriptionReactions: drug ? drug.descriptionReactions ?? '' : '',
      descriptionChanges: drug ? drug.descriptionChanges ?? '' : ''
    },
    onSubmit: async (values) => {

      if (id && drug.id) {
        await putDrug(+id, +drug.id, values);
        setExpandedId && setExpandedId(-1)
      }
    },

  })


  const addImage = async (file: File | null) => {
    if (file && id && expandedId) {
      await postImageDrug(+id, +expandedId, file);
      await getDraftCase(+id);
    }
  }

  const removeImage = async (idFile: number | string) => {
    if (idFile && id && expandedId) {
      await deleteImageDrug(+id, +expandedId, idFile);
      await getDraftCase(+id);
    }
  }

  return {
    formik,
    addImage,
    removeImage,
    images: drug && drug.images ? drug.images : []
  }
}
