import styled from 'styled-components';
import theme from '../../../../../theme/theme';

export const Container = styled.div`
  max-width: 1012px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    width: unset;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.turquoise2};
  margin-top: 32px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-top: 16px;
  }
`;

export const SectionTitle = styled.h1`
  font-weight: 800;
  font-size: 36px;
  line-height: 120%;
  text-transform: uppercase;
  margin-top: 32px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-weight: 800;
    font-size: 32px;
    line-height: 120%;
  }
`;

export const Content = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  color: ${theme.colors.dark1};
`;

export const BlackTitle = styled.h5`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 24px 0 8px 0;
  color: ${theme.colors.dark1};

  & span {
    font-weight: 400;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 16px 0 8px 0;
  }
`;