import { MultipleSelect } from '../../../../../shared/form/multipleSelect/MultipleSelect';
import { IFilters } from '../../../../../../interfaces/admin/statistics/statistics';
import React from 'react';
import { generateOptionsForSelects } from '../../Statistics.helper';
import { useStatisticsStore } from '../../../../../../store/hooks';
import { observer } from 'mobx-react-lite';

export const MultiselectSection = observer(() => {
  const statisticsStore = useStatisticsStore();
  const {
    statFilters,
    preparedAreas,
    prepareReckoningStatus,
    prepareAcceptStatus,
    prepareTypeOfReckonings
  } = statisticsStore;

  const { statSelectArray } = generateOptionsForSelects(preparedAreas, prepareTypeOfReckonings, prepareAcceptStatus, prepareReckoningStatus);

  return (
    <>{statSelectArray && statSelectArray.map(el =>
      <MultipleSelect
        subtitle={el.label} key={el.key}
        selectedValues={(statFilters && statFilters[el.key as keyof IFilters]) || []}
        elementName={el.key}
        subtitleLength={statFilters && statFilters[el.key as keyof IFilters]?.length}
        options={el.optionsArray}
        handleChange={(e) => statisticsStore.handleChangeFilters(e, el.key as keyof IFilters, el.optionsArray)} />)}
    </>
  );
});
