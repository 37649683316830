import { Login } from '../../../../pages/auth/login/Login';
import { RetrievePassword } from '../../../../pages/auth/retrievePassword/RetrievePassword';
import { ChangePassword } from '../../../../pages/auth/changePassword/ChangePassword';
import { Page404 } from '../../../../pages/page404/Page404';
import { RegisterViewingUser } from '../../../../pages/auth/register/registerViewingUser/RegisterViewingUser';
import { RegisterPublishingUser } from '../../../../pages/auth/register/registerPublishUser/RegisterPublishingUser';
import { UnderConstruction } from '../../../../pages/underConstruction/UnderConstruction';

export const generalLinks = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/retrieve-password',
    element: <RetrievePassword />
  },
  {
    path: '/reset-password/:token',
    element: <ChangePassword />
  },
  {
    path: '/register/:token',
    element: <ChangePassword isRegister={true} />
  },
  {
    path: '/register/viewer/:token',
    element: <RegisterViewingUser />
  },
  {
    path: '/register/publish/:token',
    element: <RegisterPublishingUser />
  },
  {
    path: '*',
    element: <Page404 />
  },
  {
    path: '/under-construction',
    element: <UnderConstruction />
  }
];