import doctorHospitalBcg from '../../../../assets/img/doctorHospitalBcg.png'
import theme from '../../../../theme/theme'
import logo from "../../../../assets/img/logo.png";
import React from "react";
import {TextDrImage} from "../textDrImage/TextDrImage";
import {Grid} from '@mui/material'
import {ReactComponent as ArrowRight} from "../../../../assets/icon/arrowRight.svg";
import {
  AdditionalComponent,
  AdditionalContainer,
  CirceContainer,
  Circle,
  Container,
  DoctorImg,
  Img,
  MobileVersion,
  StyledButton,
  StyledText,
  StyledTextDrImage
} from "./DoctorImageComponent.styled";

interface IProps {
  additionalInfo?: boolean
  isRegisterClicked?: boolean
  clickHandler?: () => void
}

export const DoctorImageComponent: React.FC<IProps> = ({additionalInfo, isRegisterClicked, clickHandler}) => {
  return (
    <Container>
      <MobileVersion display={{md: 'none'}}>
        <Img src={logo} alt={'logo'}/>
        <StyledText><TextDrImage/></StyledText>
        {!isRegisterClicked && <DoctorImg src={doctorHospitalBcg}/>}
        {!isRegisterClicked && <StyledButton text={'zarejestruj się'} clickHandler={clickHandler}
                                             backgroundColor={theme.colors.darkBlue}
                                             color={theme.colors.beige}
                                             size={'small'}
                                             type={'button'}
                                             maxWidth={'100%'} icon={<ArrowRight/>}/>
        }
      </MobileVersion>
      <Grid display={{xs: 'none', sm: 'none', md: 'flex'}}>
        {isRegisterClicked && <Img src={logo} alt={'logo'}/>}
        {additionalInfo &&
            <AdditionalComponent>
                <AdditionalContainer>
                    <CirceContainer> <Circle/></CirceContainer>
                    <StyledTextDrImage/>
                </AdditionalContainer>
            </AdditionalComponent>
        }
      </Grid>
    </Container>
  )
}
