import {IFormData} from '../../../../../interfaces/common/form/formData'

type Name = 'login' | 'password' | 'pwz';

export interface IFormDataLogin extends IFormData {
  name: Name;
}

export const formData: IFormDataLogin[] = [
  {
    name: 'pwz',
    label: 'PWZ',
    type: 'number',
    placeholder: 'Wpisz swój PWZ...'
  },
  {
    name: 'login',
    label: 'Login (e-mail)',
    type: 'text',
    placeholder: 'Wpisz swój login...'
  },
  {
    name: 'password',
    label: 'Hasło',
    type: 'password',
    placeholder: 'Wpisz swoje hasło...'
  }
]