import styled from 'styled-components';
import theme from '../../../../../../theme/theme';

export const Container = styled.div`
`;

export const Title = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 120%;
  color: ${theme.colors.turquoise2};
  padding: 0;
  margin-top: 32px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-top: 16px;
  }
`;

export const BlackTitle = styled.h5`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 16px 0 8px 0;
  color: ${theme.colors.dark1};
  padding: 0;

  & span {
    font-weight: 400;
  }
`;

export const Content = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  color: ${theme.colors.dark1};
`;

export const FlexContainerSecond = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${theme.breakpoints.small}px) {
    gap: 20px;
    display: block
  }
`;