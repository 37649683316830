import { usePublishingUser } from './usePublishingUser';
import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { CustomTable } from '../../../shared/customTables/customTable/CustomTable';
import { DeleteModal } from '../../../shared/modal/sharedModals/deleteModal/DeleteModal';
import { Modal } from '../../../shared/modal/Modal';
import React from 'react';
import { ModalType } from '../../../../constants/modalType';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { UserForm } from '../../../shared/admin/userForm/UserForm';
import { observer } from 'mobx-react-lite';
import { AdminLink, generateLink } from '../../../../utils';
import { RoleCategory } from '../../../../constants/projectUsers';
import { Number, TopCounter, TopCounterParagraph } from './PublishingUsers.styled';
import { ReactComponent as PeopleIcon } from '../../../../assets/icon/people.svg';
import { MultipleSelect } from '../../../shared/form/multipleSelect/MultipleSelect';

export const PublishingUsers = observer(() => {
  const {
    publishingUsersListIsNotNull,
    count,
    headCells,
    generateActions,
    tableDataRows,
    modalType,
    isModalOpened,
    editHandler,
    deleteHandlerYes,
    userEdit,
    closeModal,
    optionsAreas,
    selectedAreas,
    handleChange,
    cellsSwitcher
  } = usePublishingUser();

  return (
    <AdminLayout>

      <TopCounter>
        <PeopleIcon />
        <TopCounterParagraph $isBold={true}>Obecnie w serwisie jest: </TopCounterParagraph>
        <Number>{count}</Number>
        <TopCounterParagraph>Zarejestrowanych użytkowników</TopCounterParagraph>
      </TopCounter>
      {publishingUsersListIsNotNull &&
        <CustomTable isSearchBar={true} tableDataRows={tableDataRows} isDataDownloaded={true} headCells={headCells}
                     cellsSwitcher={cellsSwitcher}
                     actions={generateActions()}
                     contentAfterSearch={
                       <>{optionsAreas &&
                         <MultipleSelect subtitle={'Obszar'} key={'area'} selectedValues={selectedAreas}
                                         elementName={'area'}
                                         options={optionsAreas}
                                         handleChange={handleChange} />}</>}

        />}
      {isModalOpened &&
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.DELETE_MODAL &&
            <DeleteModal handleNo={() => closeModal()} handleYes={deleteHandlerYes}
                         textAdded={'tego użytkownika'} />
          }
          {modalType === ModalType.DELETE_CONFIRMATION_MODAL &&
            <ConfirmationModal text={<p>Użytkownik został <b>usunięty poprawnie</b>.</p>} />
          }
          {modalType === ModalType.EDIT_MODAL &&
            <UserForm handleNo={() => closeModal()} isEdit={true}
                      link={generateLink(RoleCategory.ADMIN, AdminLink.PUBLISHING_USERS)}
                      userForm={userEdit} actionHandler={editHandler} />
          }
          {modalType === ModalType.EDIT_CONFIRMATION_MODAL &&
            <ConfirmationModal text={<p>Użytkownik został <b>edytowany poprawnie</b>.</p>} />
          }
        </Modal>
      }
    </AdminLayout>
  );
});