import React from "react";
import {Box, Grid,  Tab} from '@mui/material';
import { observer } from "mobx-react-lite";
import {Header, ImgContainer, Item, Section, TabsCustom} from './Documents.styled'
import { UserLayout } from '../../../../shared/user/layout/UserLayout'
import { ReactComponent as Settlement } from './../../../../../assets/icon/settlement.svg'
import { ReactComponent as Pdf } from './../../../../../assets/icon/redPdf.svg'
import { ReactComponent as Download } from './../../../../../assets/icon/greenDownload.svg'
import {TabName, useDocuments} from './useDocuments.vm';
import { IContractDocumentObject } from "../../../../../interfaces/user/contract/contract";

export const Documents = observer(() => {
  const {tab, handleChangeTab, breadcrumbs, contractDocuments} = useDocuments()

  return (
    <UserLayout breadcrumbs={breadcrumbs}>
      <Grid container display={'flex'} >
        <Grid item xs={12} alignSelf={'flex-start'}>
          <Header><Settlement/>Umowy i faktury:</Header>
        </Grid>

        <Box >
          <TabsCustom value={tab} onChange={(e, value) => handleChangeTab(value as TabName)} aria-label="basic tabs example">
            <Tab label='Umowy o współpracy' value='Umowy o współpracy' />
            <Tab label='Faktury' value='Faktury' />
            <Tab label='Umowy o dzieło' value='Umowy o dzieło'  />
          </TabsCustom>
        </Box>

        <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'}
              margin={'auto'}>
          {tab === 'Umowy o współpracy' && contractDocuments && contractDocuments.companyContractFiles?.length > 0 &&
            <Section>{contractDocuments?.companyContractFiles.map((el: IContractDocumentObject) =>
              <Item key={el.id} href={el.link} target={'__blank'}
                    rel={'noreferrer'}><ImgContainer><Pdf/>{el.originalName}
              </ImgContainer><Download/></Item>)}</Section>
          }
          {tab === 'Faktury' && contractDocuments && contractDocuments.companyContractInvoices?.length > 0 &&
            <Section>{contractDocuments?.companyContractInvoices.map((el: IContractDocumentObject) =>
              <Item key={el.id} href={el.link} target={'__blank'}
                    rel={'noreferrer'}><ImgContainer><Pdf/>{el.originalName}
              </ImgContainer><Download/></Item>)}</Section>
          }
          {tab === 'Umowy o dzieło' && contractDocuments && contractDocuments.userContractInvoices?.length > 0 &&
            <Section>{contractDocuments?.userContractInvoices.map((el: IContractDocumentObject) =>
              <Item key={el.id} href={el.link} target={'__blank'} rel={'noreferrer'}
              ><ImgContainer><Pdf/>{el.originalName}
              </ImgContainer><Download/></Item>)}</Section>
          }
        </Grid>
      </Grid>
    </UserLayout>
  )
})