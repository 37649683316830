import {RootStore} from "./rootStore";
import {makeAutoObservable} from "mobx";
import {IProfile} from "../interfaces/admin/user/profile";

export class ProfileStore {
  profile: IProfile | null = null;
  isProfileLoaded: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setProfile(profile: IProfile) {
    this.profile = profile;
    this.isProfileLoaded = true;
  }

  resetProfile() {
    this.isProfileLoaded = false;
  }
}
