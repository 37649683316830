import { Modal } from '../../../../shared/modal/Modal';
import React, { FC } from 'react';
import {
  ButtonContainer,
  ConfirmationTextModal,
  DataContainer,
  DataText,
  TextDocumentContainer
} from '../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled';
import { ContentModal } from '../../../../shared/modal/ContentModal';
import { EditIcon } from '../../../../shared/modal/modalIcons/EditIcon';
import { Button } from '../../../../shared/button/Button';
import { observer } from 'mobx-react-lite';
import { IUserInfo } from '../../../../../interfaces/admin/contract/contract';
import { Input } from '../../../../shared/form/input/Input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ValidationMessages } from '../../../../../constants/validationMessages';
import { Grid } from '@mui/material';
import { useContractApi } from '../../../../../apiHooks';

interface IProps {
  closeModal: () => void;
  handleRefresh: () => void;
  userInfo: IUserInfo | null;
  typeOfContract: string;
  id?: number;
}

const validationSchema = yup.object().shape({
  message: yup
    .string()
    .required(ValidationMessages.EMPTY_FIELD)
});
export const ChangeDocumentModal: FC<IProps> = observer(({
                                                           closeModal,
                                                           handleRefresh,
                                                           userInfo,
                                                           typeOfContract,
                                                           id
                                                         }) => {
  const { sendEmailToPublish } = useContractApi();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id) {
        const res = await sendEmailToPublish(id, values.message, typeOfContract);
        if (res) {
          closeModal();
          handleRefresh();
        }
      }
    }
  });

  return (<Modal clickHandler={() => closeModal()}>
    <ContentModal
      width={'692px'}
      iconType={<EditIcon />}
      text={<ConfirmationTextModal>
        <TextDocumentContainer>
          <h4>Znalazłeś błędy w przesłanym dokumencie.</h4>
          <h6>Po wysłaniu wiadomości o błędach obecny dokument zostanie usunięty z systemu.</h6>
        </TextDocumentContainer>
        {userInfo && <DataContainer>
          <DataText>Kontrahent: <span>{userInfo.fullName}</span></DataText>
          <DataText>tel: <span>{userInfo.phone}</span></DataText>
          <DataText>email: <span>{userInfo.email}</span></DataText>
        </DataContainer>}
        <TextDocumentContainer>
          <h5>Podaj błędy znalezione w dokumencie:</h5>
        </TextDocumentContainer>
        <Grid width={'100%'}>
          <Input isRequired={true}
                 name={'message'}
                 width={'100%'}
                 type={'textarea'}
                 color={'black'}
                 size={'small'}
                 height={'122px'}
                 value={formik.values.message.toString()}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'Wpisz błędy...'}
                 isError={formik.touched.message && Boolean(formik.errors.message)}
          />
        </Grid>
        <ButtonContainer>
          <Button text={'Wyślij'} clickHandler={formik.submitForm} size={'small'} backgroundColor={'black'}
                  color={'white'}
                  width={'190px'}></Button>
        </ButtonContainer>
      </ConfirmationTextModal>} />
  </Modal>);
});