import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { SummaryDataTable } from '../components/summaryData/SummaryDataTable';
import { SummaryData } from '../components/summaryData/SummaryDataService';
import { SummaryDataStyled, SummaryDataTitle } from '../components/summaryData/SummaryData.styled';
import { useStatisticsStore } from '../../../../../store/hooks';
import { shortMonthsArray } from '../../../../../utils';

export const General = observer(() => {
  const {
    filteredCasesByStatus,
    filteredUsersByRole,
    filteredCasesByPayStatus,
    filteredCasesByAmount,
    filteredCasesByMonth,
    preparedAreasForChart,
    filteredUsersByRoleChart,
    preparedRolesForChart,
    amountOfViewsOfCasesChart
  } = useStatisticsStore();

  const chartSetting = {
    yAxis: [
      {
        label: 'Liczba',
        labelStyle: {
          fontSize: 12
        }
      }
    ],
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)'
      }
    },
    height: 340,
    width: 650
  };
  return (
    <Grid container gap={1} height={'100%'} width={'auto'}>
      <Grid container gap={1} flexWrap={'nowrap'} overflow={'auto'}>
        <Grid item xs={12} sm={8} height={'100%'}>
          <SummaryDataStyled>
            <SummaryDataTitle>Opisy przypadków</SummaryDataTitle>
            <Box sx={{
              alignSelf: 'center',
              width: 750,
              display: 'flex',
              position: 'relative',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            }}>
              <BarChart
                dataset={filteredCasesByMonth}
                xAxis={[{
                  scaleType: 'band', dataKey: 'month'
                }]}
                series={preparedAreasForChart}
                {...chartSetting}
              />
            </Box>
          </SummaryDataStyled>
        </Grid>
        <Grid item xs={12} sm={4} display={'flex'} flexGrow={1} height={'100%'}>
          <SummaryDataTable withIcon headCells={['status', 'liczba']} title={'liczba opisów przypadków według statusu'}
                            data={filteredCasesByStatus} />
        </Grid>
      </Grid>
      <Grid container gap={1} flexWrap={'nowrap'}>
        <Grid item xs={12} sm={4} height={'100%'} display={'flex'} flexGrow={1}>
          <SummaryDataTable headCells={['rola', 'liczba']} title={'Zarejestrowani użytkownicy'}
                            data={filteredUsersByRole} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <SummaryDataStyled>
            <SummaryDataTitle>Nowi użytkownicy</SummaryDataTitle>
            {filteredUsersByRoleChart && <Box sx={{
              alignSelf: 'center',
              width: 750,
              display: 'flex',
              position: 'relative',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            }}>
              <BarChart
                dataset={filteredUsersByRoleChart}
                xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                series={preparedRolesForChart}
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: {
                      vertical: 'top',
                      horizontal: 'right'

                    },
                    padding: 0,


                    labelStyle: {
                      fontSize: 10
                    },
                    itemGap: 64
                  }
                }}
                {...chartSetting}
              />
            </Box>}
          </SummaryDataStyled>
        </Grid>
      </Grid>

      <Grid container gap={1} flexWrap={'nowrap'}>
        <Grid item xs={12} sm={4} height={'100%'} display={'flex'} flexDirection={'column'}
              justifyContent={'space-between'} gap={2}>
          <SummaryDataTable headCells={['status', 'liczba']}
                            title={'Liczba opisów przypadków według statusu opłacenia'}
                            data={filteredCasesByPayStatus} />
          <SummaryData title={'Kwota wypłaconych środków'} data={filteredCasesByAmount?.toLocaleString() + ' zł'} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <SummaryDataStyled>
            <SummaryDataTitle>Wyświetlenia opisów przypadków</SummaryDataTitle>
            <Box sx={{
              alignSelf: 'center',
              height: 340,
              width: 750,
              display: 'flex',
              position: 'relative',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
            >
              <BarChart
                xAxis={[{
                  scaleType: 'band', data: shortMonthsArray
                }]}
                series={[{ data: amountOfViewsOfCasesChart }]}
                slotProps={{
                  legend: {
                    position: {
                      vertical: 'middle',
                      horizontal: 'right'
                    },
                    labelStyle: {
                      fontSize: 10
                    }
                  }
                }}
                {...chartSetting}
              />
            </Box>
          </SummaryDataStyled>
        </Grid>
      </Grid>
    </Grid>
  );
});