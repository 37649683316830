import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const RequiredParagraph = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.red};
  @media (max-width: ${theme.breakpoints.small}px) {
    padding-top: 10px;
  }
`