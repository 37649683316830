import { ReactComponent as ArrowAccordion } from '../../../../../../assets/icon/arrowAccordion.svg';
import { Typography } from '@mui/material';
import { doctorTypes } from '../../../draftCaseLung/stages/thirdStage/ThirdStage.helper';
import { DoctorType, IDoctorThirdStageLung } from '../../../../../../interfaces/user/draftCaseLung/thirdStage';
import React, { FC } from 'react';
import { AccordionDetailsCustom, AccordionStyled, AccordionSummaryCustom, StyledSection } from './AccordionView.styled';
import { AccordionForm } from '../form/AccordionForm';
import { useAccordionView } from './useAccordionView.vm';

interface IProps {
  doctor: IDoctorThirdStageLung;
  isModalOpened: boolean;
  expanded: boolean;
  setExpandedId?: (id?: number | null) => void;
  expandedId?: number | null;
}

export const AccordionView: FC<IProps> = ({
                                            doctor,
                                            isModalOpened,
                                            expanded,
                                            expandedId,
                                            setExpandedId
                                          }) => {
  const {
    dateFormatted,
    expandedDoctorChange
  } = useAccordionView(doctor, isModalOpened, setExpandedId);
  
  return (
    <AccordionStyled expanded={expanded} onChange={() => expandedDoctorChange()}>
      <AccordionSummaryCustom
        expandIcon={<ArrowAccordion />}
      >
        <Typography>{doctorTypes[doctor.type as DoctorType]}</Typography>
        <Typography variant='body1'>{dateFormatted}</Typography>
      </AccordionSummaryCustom>
      <AccordionDetailsCustom>
        {!isModalOpened && doctor.id === expandedId &&
          <StyledSection>
            <AccordionForm doctor={doctor} />
          </StyledSection>
        }
      </AccordionDetailsCustom>
    </AccordionStyled>
  );

};