import { IOption } from '../../../../interfaces/common/form/select';
import { yearArrayGenerate } from '../../../shared/admin/filter/filter.helper';
import { IAllChips, ICaseStatusIcons } from '../../../../interfaces/admin/statistics/statistics';
import { CaseStatus } from '../../../../interfaces/user/draftCaseNeuro/draft';
import { ReactComponent as Draft } from '../../../../assets/icon/draftCaseStatus.svg';
import { ReactComponent as Public } from '../../../../assets/icon/publicCaseStatus.svg';
import { ReactComponent as Unpublic } from '../../../../assets/icon/privateCaseStatus.svg';
import { ReactComponent as Close } from '../../../../assets/icon/rejectCaseStatus.svg';
import { ReactComponent as Pending } from '../../../../assets/icon/sentCaseStatus.svg';
import React from 'react';

export const generateOptionsForSelects = (preparedAreas: IOption[], prepareTypeOfReckonings: IOption[], prepareAcceptStatus: IOption[], prepareReckoningStatus: IOption[]) => {
  const statSelectArray = [
    {
      label: 'Obszar',
      key: 'area',
      optionsArray: preparedAreas ?? []
    }, {
      label: 'Rok',
      key: 'year',
      optionsArray: yearArrayGenerate()
    }, {
      label: 'Rodzaj rozliczenia',
      key: 'reckoningType',
      optionsArray: prepareTypeOfReckonings ?? []
    },
    {
      label: 'Status akceptacji',
      key: 'acceptStatus',
      optionsArray: prepareAcceptStatus ?? []
    },
    {
      label: 'Status rozliczenia',
      key: 'reckoningStatus',
      optionsArray: prepareReckoningStatus ?? []
    }
  ];

  return {
    statSelectArray
  };
};

export const acceptStatusMap = new Map([
  ['Szkic', 'DRAFT'],
  ['Do edycji', 'REJECT'],
  ['Oczekujący', 'SENT'],
  ['Zaakceptowany Publiczny', 'isPublic'],
  ['Zaakceptowany Niepubliczny', 'isUnPublic']
]);

export const properChips: IAllChips = {
  users: {
    area: true,
    acceptStatus: true,
    year: false,
    reckoningType: true,
    reckoningStatus: false
  },
  general: {
    area: true,
    acceptStatus: true,
    year: true,
    reckoningType: true,
    reckoningStatus: true
  },
  cases: {
    area: true,
    acceptStatus: true,
    year: true,
    reckoningType: true,
    reckoningStatus: true
  },
  reckonings: {
    area: true,
    acceptStatus: false,
    year: false,
    reckoningType: true,
    reckoningStatus: false
  }
};

export const statusIcons = {
  [CaseStatus.DRAFT]: <Draft />,
  [CaseStatus.ACCEPT]: {
    isPublic: <Public />,
    isUnPublic: <Unpublic />
  },
  [CaseStatus.REJECT]: <Close />,
  [CaseStatus.SENT]: <Pending />
};

export const statusIconsChart: ICaseStatusIcons = {
  'Szkice': <Draft />,
  'Publiczne': <Public />,
  'Niepubliczne': <Unpublic />,
  'Odrzucone': <Close />,
  'Oczekujące': <Pending />
};