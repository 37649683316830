import { Input } from "../../../../shared/form/input/Input";
import { Button } from "../../../../shared/button/Button";
import { ErrorMessage } from "../../../../shared/form/errorMessage/ErrorMesage";
import React from "react";
import { useLoginForm } from './useLoginForm'
import { formData } from './logindata'
import theme from './../../../../../theme/theme'
import { ReactComponent as ArrowRight } from "../../../../../assets/icon/arrowRight.svg";
import { GeneralLink, generateLink } from "../../../../../utils";
import { RoleCategory } from "../../../../../constants/projectUsers";
import { RegisterViewingFormContainer, StyledLink } from "./LoginForm.styled";

export const LoginForm = () => {
  const {formik, isButtonActive, confirmForm, message} = useLoginForm()

  return (
    <RegisterViewingFormContainer
      onSubmit={formik.handleSubmit}
      buttonTextColor={theme.colors.darkBlue}
      isButtonActive={isButtonActive}>
      <div>
        {formData.map(el =>
          <Input color={theme.colors.beige}
                 key={el.name}
                 label={el.label}
                 name={el.name}
                 type={el.type}
                 value={formik.values[el.name]}
                 onChange={formik.handleChange}
                 placeholder={el.placeholder}
                 isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
                 textError={formik.errors[el.name]}
          />
        )}
        {formik.submitCount > 0 &&
          <ErrorMessage errors={formik.errors} errorServer={true} message={message}/>
        }
      </div>

      <Button type='submit'
              clickHandler={confirmForm}
              text={'zaloguj'}
              backgroundColor={isButtonActive ? theme.colors.dark1 : theme.colors.blue2}
              color={isButtonActive ? theme.colors.beige : theme.colors.darkBlue}
              size={'small'}
              maxWidth={'188px'} icon={<ArrowRight/>}
      />
      <StyledLink to={generateLink(RoleCategory.GUEST, GeneralLink.RETRIEVE_PASSWORD)}>
        Kliknij <strong>tutaj</strong> aby zresetować hasło</StyledLink>
    </RegisterViewingFormContainer>
  )
};
