import styled from "styled-components";
import theme from "../../../../theme/theme";

export const RegisterInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 50;
  max-width: 500px;
  width: 100%;
  margin: 20px auto;
  @media (max-width: ${theme.breakpoints.medium}px) {
    margin: 0 auto;
  }

  & button {
    @media (max-width: ${theme.breakpoints.medium}px) {
      max-width: 100% !important;
      margin-top: 0;
      font-size: 16px;
      height: 56px;

      & img {
        width: 12px;
        height: 19px;
      }
    }

  }
`

export const Text = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 46px;
  max-width: 500px;
  padding: 0 32px;
  text-align: center;
`

export const Img = styled.img`
  margin-bottom: 56px;
  z-index: 50;
  position: relative;
  width: 120px;
`