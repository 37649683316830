import React from "react";
import {observer} from "mobx-react-lite";
import {MenuList} from "../menuList/MenuList";
import logo from "../../../../../../assets/img/logo.png";
import {Navbar, NavbarHeader, RightBelt, RoleBottomContainer} from "./Navbar.styled";
import {useNavBarViewModel} from "./useNavBarViewModel";
import {INavLink} from "../../../../../../interfaces/common/link";

interface IProps {
  links: INavLink[];
}

export const NavBar: React.FC<IProps> = observer(({links}) => {
  const {role} = useNavBarViewModel();

  return (
    <Navbar>
      <NavbarHeader/>
      <img src={logo} alt={'logo'} width={120}/>
      <RightBelt/>
      <MenuList linkList={links}/>
      <RoleBottomContainer>{role}</RoleBottomContainer>
    </Navbar>
  )
})
