import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSecondStage } from './useSecoundStage';
import { Input } from '../../../../../shared/form/input/Input';
import theme from '../../../../../../theme/theme';
import { Label } from '../../../../../shared/form/label/LabelContainer';
import { DateInput } from '../../../../../shared/form/date/Date';
import { MultipleFileUpload } from '../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload';
import { Fieldset } from '../../../../../shared/form/fieldset/FieldsetContainer';
import { RadioForm } from '../../../draftCaseLung/stages/firstStage/FirstStage.styled';
import { RadioButton } from '../../../../../shared/form/radioButton/RadioButton';
import { convertBooleanRadio } from '../../../draftCaseLung/stages/secondStage/secondStage.helper';

export const SecondStage = observer(() => {

  const {
    formik,
    images,
    addImage,
    removeImage
  } = useSecondStage();

  return (<Grid container justifyContent={'space-between'}>
    <Grid item xs={12}>
      <Grid container justifyContent={'space-between'}>
        <Grid item pr={1} xs={12} md={6}>
          <DateInput
            openTo={'year'}
            inputFormat={'mm.yyyy'}
            views={['year', 'month']}
            isRequired={true}
            maxWidth={'385px'}
            label={<>Data<br /> rozpoznania</>}
            isUpperCase={true}
            name={'date'}
            color={theme.colors.turquoise2}
            value={formik.values.date}
            onChange={(e) => formik.setFieldValue('date', e)}
            onBlur={() => formik.setFieldTouched('date', true)}
            isError={formik.touched.date && Boolean(formik.errors.date)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input isRequired={true}
                 maxWidth={'385px'}
                 label={<span>Wiek pacjenta <br />w momencie rozpoznania</span>}
                 isUpperCase={true}
                 name={'age'}
                 type={'number'}
                 color={theme.colors.turquoise2}
                 value={formik.values.age.toString()}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'Wpisz wiek pacjenta...'}
                 isError={formik.touched.age && Boolean(formik.errors.age)}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Fieldset isUpperCase={true} color={theme.colors.turquoise2} size={'medium'}
                label={'Badanie płynu mózgowo-rdzeniowego'}>
        <>
          <Label isUpperCase={true} color={'black'} isRequired={true} label={'Obecnośc prążków oligoklonalnych?'}
                 size={'small'} />
          <RadioForm>
            <RadioButton
              isError={formik.touched.isFluidTesting && Boolean(formik.errors.isFluidTesting)}
              id={'isFluidTesting'}
              name={'isFluidTesting'}
              value={'tak'}
              isUppercase={true}
              checked={convertBooleanRadio(formik.values.isFluidTesting) === 'tak'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('isFluidTesting', newValue);
              }}
            />
            <RadioButton
              isError={formik.touched.isFluidTesting && Boolean(formik.errors.isFluidTesting)}
              id={'isFluidTesting2'}
              name={'isFluidTesting'}
              value={'nie'}
              isUppercase={true}
              checked={convertBooleanRadio(formik.values.isFluidTesting) === 'nie'}
              onChange={(e) => {
                const newValue = e.target.value === 'tak';
                formik.setFieldValue('isFluidTesting', newValue);
              }}
            />
          </RadioForm>

        </>
      </Fieldset>
    </Grid>
    <Grid item xs={12} mt={2}>
      <Fieldset isUpperCase={true} color={theme.colors.turquoise2} size={'medium'} label={'Aktywność rzutowa'}
                extraText={'(Przed postawieniem rozpoznania)'}>
        <>
          <DateInput
            openTo={'year'}
            inputFormat={'mm.yyyy'}
            views={['year', 'month']}
            maxWidth={'385px'}
            label={'Data rzutu'}
            isUpperCase={true}
            name={'projectileActivityDate'}
            color={'black'}
            size={'small'}
            value={formik.values.projectileActivityDate}
            onChange={(e) => formik.setFieldValue('projectileActivityDate', e)}
            onBlur={() => formik.setFieldTouched('projectileActivityDate', true)}
            isError={formik.touched.projectileActivityDate && Boolean(formik.errors.projectileActivityDate)}
          />
          <Input isRequired={true}
                 label={'Opis rzutu (objawy)'}
                 isUpperCase={true}
                 name={'projectileActivityDescription'}
                 type={'textarea'}
                 color={'black'}
                 size={'small'}
                 value={formik.values.projectileActivityDescription.toString()}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'Wpisz opis rzutu...'}
                 isError={formik.touched.projectileActivityDescription && Boolean(formik.errors.projectileActivityDescription)}
          />
          <Input isRequired={true}
                 label={'Wdrożone leczenie rzutu'}
                 isUpperCase={true}
                 name={'projectileActivityTreatment'}
                 type={'textarea'}
                 color={'black'}
                 size={'small'}
                 value={formik.values.projectileActivityTreatment.toString()}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'Opis wdrożonego leczenia...'}
                 isError={formik.touched.projectileActivityTreatment && Boolean(formik.errors.projectileActivityTreatment)}
          />
          <Input isRequired={true}
                 label={'Ocena ciężkości rzutu w edss przed i po leczeniu rzutu'}
                 isUpperCase={true}
                 name={'projectileActivityAssessment'}
                 type={'textarea'}
                 color={'black'}
                 size={'small'}
                 value={formik.values.projectileActivityAssessment.toString()}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'Wpisz opis ciężkości rzutu...'}
                 isError={formik.touched.projectileActivityAssessment && Boolean(formik.errors.projectileActivityAssessment)}
          />
        </>
      </Fieldset>
      <Grid />
      <Grid item xs={12} mt={5}>
        <Fieldset isUpperCase={true} color={theme.colors.turquoise2} size={'medium'} label={'Aktywność rezonansowa'}>
          <>
            <Input isRequired={true}
                   label={'Opis MRI na podstawie, którego postawiono rozpoznanie (np: liczba i lokalizacja nowych zmian T1 i/lub nowych i powiększających się zmian T2/FLAIR wzmacniających się po kontraście)'}
                   isUpperCase={true}
                   name={'resonanceActivityDescription'}
                   type={'textarea'}
                   color={'black'}
                   size={'small'}
                   height={'500px'}
                   value={formik.values.resonanceActivityDescription.toString()}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   placeholder={'Opis MRI...'}
                   isError={formik.touched.resonanceActivityDescription && Boolean(formik.errors.resonanceActivityDescription)}
            />
          </>
        </Fieldset>
        <Label isUpperCase={true} color={'black'} isRequired={false} label={'Zdjęcia MRI (Opcjonalnie)'}
               size={'small'} />
        <Grid item xs={12} mb={4}>
          <MultipleFileUpload images={images} removeFile={removeImage} addFile={addImage} maxFileSize={16} />
        </Grid>
      </Grid>

    </Grid>
  </Grid>);
});


