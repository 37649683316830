import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const DoctorImg = styled.img<{ margin?: string }>`
  display: block;
  width: 100%;
  height: auto;
  max-width: 537px;
  margin: ${props => props.margin};
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 auto;
  }
`

export const Header = styled.h1`
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;

  @media (max-width: ${theme.breakpoints.small}px) {
    margin-bottom: 50px;
    line-height: 32px;


    & span {
      display: inline-block;
      font-size: 28px;
      font-weight: 800;
    }
  }
`

export const Subheader = styled.h3`
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  color: ${theme.colors.dark1};
  margin-bottom: 20px;
  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`

export const Section = styled.div`
  display: flex;
  min-height: 180px;
  min-width: 100%;
  height: 100%;
  margin-right: 10px;
  flex-wrap: wrap;
  justify-content: left;

  @media (max-width: ${theme.breakpoints.small}px) {
    display: none;
  }
`

export const StyledButton = styled.div`
  display: none;
  @media (max-width: ${theme.breakpoints.small}px) {
    display: block;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
  }
`