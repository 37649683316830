import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useReckoningStore } from '../store/hooks';
import { IReckoningAcceptedCases, IReckoningDashboard } from '../interfaces/user/reckoning/reckoning';
import { AxiosRequestConfig } from 'axios';
import { AreaKey } from '../interfaces/admin/cases/cases';

export const useReckoningApi = () => {
  const {api} = useApiCall();
  const reckoningStore = useReckoningStore();

  const getReckoningDashboard = useCallback(
    async (isLoader?: boolean) => {
      const res: IReckoningDashboard = await api.apiInstance.get('reckonings', isLoader);
      reckoningStore.setReckoningDashboard(res);
      return !!res
    },
    [api.apiInstance, reckoningStore]
  );


  const getMyAcceptedCases = useCallback(
    async (isLoader?: boolean) => {
      const res: IReckoningAcceptedCases = await api.apiInstance.get('reckoning/situations', isLoader);
      reckoningStore.setReckoningAcceptedCases(res);
      return !!res
    },
    [api.apiInstance, reckoningStore]
  );

  const getWorkContract = useCallback(
    async (ids: number[], area: AreaKey) => {
      const config: AxiosRequestConfig = {responseType: 'blob'}
      return await api.apiInstance.post(`user-contract/pdf`, {ids, area}, true, config);
    },
    [api.apiInstance]
  );

  const sendDocumentNeuro = useCallback(
    async (ids: number[], file: File) => {
      const form = new FormData();
      form.append('file', file);
      ids.forEach((id, index) => form.append(`ids[${index}]`, String(id)));

      const res = await api.apiInstance.post(`reckoning/situations`, form, true);
      return !!res;
    },
    [api.apiInstance]
  );

  const sendDocumentLung = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append('file', file);
      const res = await api.apiInstance.post(`reckoning/situation/${id}`, form, true);
      return !!res;
    },
    [api.apiInstance]
  );


  return {
    getReckoningDashboard,
    getMyAcceptedCases,
    getWorkContract,
    sendDocumentNeuro,
    sendDocumentLung
  }
}