import { IAreaObject } from '../interfaces/user/case';

export const createEndpoints = (userAreas: string[], areaObject: IAreaObject) => {
  const newArray: any[] = [];
  userAreas.forEach((el) => {
    if (Object.keys(areaObject).includes(el)) {
      newArray.push(el);
    }
  });
  return newArray;
};