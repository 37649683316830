import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const StyledData = styled.div`
  align-self: flex-end;
  margin: 0 0 72px auto;
  max-width: 139px;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    align-self: flex-start;
    margin: 0 auto 0 0;
  }

`
export const FlexBox = styled.div`
  display: flex;
  padding: 0 40px;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 0 16px;
  }
`
export const StyledCalendar = styled.div`
  margin-right: 22px
`


export const SmallerText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  height: 31px;
`
export const ObjectContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledBorder = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.dark1};
  margin-bottom: 6px;
`
export const BlackTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.dark1};
  text-transform: uppercase;

  & span {
    font-weight: 400;
  }
`
export const IncidentBox = styled.div`
  width: 183px;
  height: 39px;
  text-align: center;
  background-color: ${theme.colors.blue3};
  color: ${theme.colors.beige};
  line-height: 39px;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  margin-top: 38px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-top: 30px;
  }
`

export const Container = styled.div`
  width: 100%;
  background: #EBF5FF;
  border-radius: 28px;
  padding: 30px 0 40px;
`

export const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: ${theme.colors.blue3};
  padding: 0 40px;
  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 0 16px;
  }
`

export const Content = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.dark1};
`