import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const ArrowsAndStepperMobileContainer = styled.div`
  display: none;
  @media (max-width: ${theme.breakpoints.small}px) {
    height: 100px;
    display: flex;
    position: relative;
    justify-content: center;
    margin-top: 8px;
  }
`;