import styled from 'styled-components';
import theme from '../../../../../theme/theme';

export const DecorContainer = styled.div`
  overflow: hidden;
`;
export const Container = styled.div`
  background: linear-gradient(180deg, rgba(189, 237, 249, 0) 0%, #BCECFB 100%);
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  @media (max-width: ${theme.breakpoints.medium}px) {
    overflow: auto;
  }
  @media (max-width: ${theme.breakpoints.large}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Decor = styled.div`
  display: block;
  position: absolute;
  background: ${theme.colors.beige};
  opacity: 0.5;
  width: 90vw;
  height: 90vw;
  right: 20%;
  text-align: center;;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`;