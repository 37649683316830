import React from 'react';
import { observer } from 'mobx-react-lite';
import theme from '../../../../../../theme/theme';
import { Button } from '../../../../../shared/button/Button';
import viewLens from './../../../../../../assets/icon/viewLens.svg';
import { ReactComponent as Success } from './../../../../../../assets/icon/success.svg';
import { useFifthStage } from './useFifthStage';
import { ButtonContainer, Container, Paragraph, StyledImage } from './FifthStage.styled';

export const FifthStage = observer(() => {

  const { draftHandler, navigateToView, isBlocked, sentToAcceptHandler } = useFifthStage();

  return (
    <Container>
      <StyledImage><Success /></StyledImage>
      <Paragraph>{
        isBlocked ? 'Limit opisów przypadków dla tego konta został wyczerpany. Prosimy pamiętać o dokonaniu rozliczeń. Serdecznie dziękujemy za udział w projekcie.' :
          <>
            Gratulacje! Opis przypadku został ukończony. <br />
            Kliknij WYŚLIJ DO AKCEPTACJI aby wysłać opis teraz lub ZAPISZ JAKO SZKIC i wyślij do akceptacji w innym
            terminie. Po wysłaniu do akceptacji w
            ciągu 48h opis przypadku zostanie zweryfikowany i po zaakceptowaniu będzie widoczny dla innych użytkowników.
          </>
      }</Paragraph>
      <ButtonContainer>
        <Button text={'wyślij do akceptacji'}
                size={'small'}
                width={'222px'}
                fontSize={'12px'}
                disabled={isBlocked}
                color={theme.colors.white}
                backgroundColor={isBlocked ? theme.colors.gray3 : theme.colors.turquoise2}
                clickHandler={sentToAcceptHandler} />
        <Button text={'zapisz jako szkic'} size={'small'} width={'222px'} fontSize={'12px'} color={theme.colors.white}
                backgroundColor={theme.colors.blue3} clickHandler={draftHandler} />
      </ButtonContainer>
      <Button text={'podgląd opisu'} size={'small'} width={'222px'} fontSize={'12px'} color={theme.colors.white}
              backgroundColor={theme.colors.dark1} icon={viewLens} clickHandler={navigateToView} />

    </Container>);
});
