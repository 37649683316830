import {Grid} from '@mui/material';
import theme from '../../../../../theme/theme';
import {Button} from '../../../../shared/button/Button';
import React, {FC} from 'react';
import {ButtonContainer, GridLeft} from './EditProfile.styled';
import {Input} from '../../../../shared/form/input/Input';

import {useEditProfile} from './useEditProfile';
import {Modal} from '../../../../shared/modal/Modal';

interface IProps {
  closeModal: () => void;
}

export const EditProfile: FC<IProps> = ({closeModal}) => {

  const {formik} = useEditProfile(closeModal);

  return (
    <Modal clickHandler={() => closeModal()}>

      <form onSubmit={formik.handleSubmit}>
        <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Grid item>
            <Grid container display={'flex'} flexDirection={'row'}>
              <GridLeft item xs={12} md={4}>
                <Input color={'black'} label={"Afiliacja"} name={"affiliation"} type={"text"}
                       value={formik.values.affiliation}
                       onChange={formik.handleChange}
                       placeholder={""}
                       isError={formik.touched.affiliation && Boolean(formik.errors.affiliation)}
                />
              </GridLeft>
              <Grid item xs={12} md={8}>
                <Input color={'black'} label={"Imię i nazwisko"} name={"fullName"} type={"text"}
                       value={formik.values.fullName}
                       onChange={formik.handleChange}
                       placeholder={"Wpisz imię i nazwisko..."}
                       isError={formik.touched.fullName && Boolean(formik.errors.fullName)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container display={'flex'} flexDirection={'row'}>
              <GridLeft item xs={12} md={8}>
                <Input color={'black'} label={"Email"} name={"email"} type={"text"}
                       value={formik.values.email}
                       onChange={formik.handleChange}
                       placeholder={"Wpisz email..."}
                       isError={formik.touched.email && Boolean(formik.errors.email)}
                />
              </GridLeft>
              <Grid item xs={12} md={4}>
                <Input color={'black'} label={"Telefon"} name={"phone"} type={"text"}
                       value={formik.values.phone}
                       onChange={formik.handleChange}
                       placeholder={"Wpisz numer kontaktowy..."}
                       isError={formik.touched.phone && Boolean(formik.errors.phone)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container display={'flex'} flexDirection={'row'}>
              <Grid item xs={6} pr={1}>
                <Input color={'black'} label={"Numer PWZ"} name={"pwz"} type={"text"}
                       value={String(formik.values.pwz)}
                       onChange={formik.handleChange}
                       placeholder={"Wpisz numer PWZ..."}
                       isError={formik.touched.pwz && Boolean(formik.errors.pwz)}
                />
              </Grid>
            </Grid>
          </Grid>
          <ButtonContainer>
            <Button text={'zapisz'}
                    backgroundColor={theme.colors.dark1}
                    type={'submit'}
                    color={theme.colors.beige} width={'125px'} size={'small'}/>

          </ButtonContainer>

        </Grid>
      </form>

    </Modal>
  )
}