import { Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { default as palette } from './../../../theme/theme'

export const MuiPagination = styled(Pagination)(({theme}) => ({
  '& .MuiButtonBase-root': {
    color: palette.colors.dark1,
    marginBottom: 10
  },
  '& .MuiPaginationItem-ellipsis': {
    color: palette.colors.beige,
  },
  '& .Mui-selected': {
    backgroundColor: `${palette.colors.turquoise2} !important`,
    color: palette.colors.beige,
    fontWeight: '400',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    lineHeight: '40px'
  },
  '& .MuiPaginationItem-icon': {
    color: palette.colors.dark1
  }
}));
