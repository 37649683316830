import { useAuthStore, useDraftCaseStore, useProgressBarNeuroStore } from '../../../../../../store/hooks';
import { AddCaseActionStatus } from '../../../../../../constants/addCaseStatus';

export const useSixthStage = () => {
  const progressBarStore = useProgressBarNeuroStore();
  const { areas } = useAuthStore();
  const draftCaseStore = useDraftCaseStore();
  const { abbreviationNeuro } = draftCaseStore;
  
  const isDraft = progressBarStore.typeOfCase === 'draft';
  const areaStatus = areas && areas?.find(el => el.shortName === abbreviationNeuro)?.addCaseActionStatus;
  const isBlocked = areaStatus === AddCaseActionStatus.CLOSE_TO_BLOCKED;

  return { isDraft, isBlocked };
};