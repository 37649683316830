import { styled as muiStyled, Table, TableCell, TableContainer, Typography } from '@mui/material';
import theme from '../../../../theme/theme';
import styled from 'styled-components';

export const TableCustom = muiStyled(Table)`
  height: auto;
  min-width: 700px;
  overflow-y: auto;

  th {
    border-bottom: none;
  }
`;

export const TableContainerCustom = muiStyled(TableContainer)`
  width: 100%;
  overflow: auto;
  height: 100%;
`;

export const TableCellHeader = muiStyled(TableCell)`
  background: ${theme.colors.blue4};
  text-transform: uppercase;
  color: white;
  border: 3px solid white;
  border-bottom: none;
  height: 48px;
  padding: 6px;
`;

export const TableCellActions = muiStyled(TableCell)`
  padding: 0px;
  height: 50px;
  width: min-content;
  display: flex;
  border: none;
`;

export interface ITableCellActionsHead {
  width: number;
}

export const TableCellActionsHead = muiStyled(TableCell)<ITableCellActionsHead>`
  width: ${props => props.width + 2}px;
`;

export const ParagraphNoData = muiStyled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`;

export const GenerateReportContainer = styled.div<{ margin?: string, bcgColor?: string, borderColor?: string, svgPath?: string }>`
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  background-color: ${props => props.bcgColor ? props.bcgColor : 'rgba(65, 187, 164, 1)'};
  border: ${props => props.borderColor && `1px solid ${props.borderColor}`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${props => props.margin};

  svg {
    path {
      fill: ${props => props.svgPath}
    }
  }
`;

export const GenerateReportCell = styled.th`
  width: 52px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
`;