import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import {
  IDoctorFieldSets,
  IDoctorFieldSetsVariantSecond,
  IFieldSetDoctorFormVariantSecond
} from '../../../../../../../../../interfaces/user/draftCaseLung/thirdStage';
import { Label } from '../../../../../../../../shared/form/label/LabelContainer';
import { RadioButton } from '../../../../../../../../shared/form/radioButton/RadioButton';
import { Input } from '../../../../../../../../shared/form/input/Input';
import { RadioForm } from '../../../../firstStage/FirstStage.styled';
import theme from '../../../../../../../../../theme/theme';

interface IProps {
  title: string;
  data: IFieldSetDoctorFormVariantSecond;
  keyFieldSet: (keyof IDoctorFieldSetsVariantSecond);
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  errors: IDoctorFieldSets;
  submited: boolean;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const FieldSetDoctorFormVariantSecond: FC<IProps> = ({
                                                              title,
                                                              data,
                                                              keyFieldSet,
                                                              onChange,
                                                              onBlur,
                                                              errors,
                                                              submited,
                                                              setFieldTouched,
                                                              setFieldValue
                                                            }) => {
  const [showFields, setShowFields] = useState<[boolean, boolean] | null>(null);

  const [showIsDone, showAmount] = showFields ?? [false, false];
  const fieldFirst = `${keyFieldSet}.isCommissioned` as keyof IDoctorFieldSets;
  const fieldSecond = `${keyFieldSet}.done` as keyof IDoctorFieldSets;
  const fieldThird = `${keyFieldSet}.amount` as keyof IDoctorFieldSets;

  useEffect(() => {
    if (showFields === null) {
      setShowFields([data?.isCommissioned === 'tak', data?.done === 'tak']);
    }
  }, [data, showFields]);

  useEffect(() => {
    const fieldFirstIsYes = data?.isCommissioned === 'tak';
    const fieldSecondIsYes = data?.done === 'tak';
    setShowFields([fieldFirstIsYes, fieldSecondIsYes]);
  }, [data, fieldFirst, fieldSecond, fieldThird]);

  useEffect(() => {
    if (showFields && !showFields[0]) {
      setFieldValue(fieldSecond, '');
    }
    if (showFields && !showFields[1]) {
      setFieldValue(fieldThird, '');
      setFieldTouched(fieldThird, false);
    }
  }, [showFields, setFieldValue, setFieldTouched, fieldSecond, fieldThird]);

  return (<Grid item xs={12} pr={2} mb={1}>
    <Grid container>
      <Grid item xs={12} mt={1}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={title} />
      </Grid>
      <Grid item xs={12} mt={1}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Czy zostało zlecone?'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={submited && Boolean(errors[keyFieldSet]?.isCommissioned)}
                       id={`${keyFieldSet}-isCommissioned-yes`}
                       name={fieldFirst}
                       value={'tak'} checked={data?.isCommissioned === 'tak'}
                       onChange={onChange} />
          <RadioButton isUppercase={true}
                       isError={submited && Boolean(errors[keyFieldSet]?.isCommissioned)}
                       id={`${keyFieldSet}-isCommissioned-no`}
                       name={fieldFirst}
                       value={'nie'} checked={data?.isCommissioned === 'nie'}
                       onChange={onChange} />
        </RadioForm>
      </Grid>
      {showIsDone && <Grid item xs={12} pl={2} mt={1}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
               label={'Czy zostało wykonane?'} isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={submited && Boolean(errors[keyFieldSet]?.done)}
                       id={`${keyFieldSet}-isDone-yes`}
                       name={fieldSecond}
                       value={'tak'} checked={data?.done === 'tak'}
                       onChange={onChange} />
          <RadioButton isUppercase={true}
                       isError={submited && Boolean(errors[keyFieldSet]?.done)}
                       id={`${keyFieldSet}-isDone-no`}
                       name={fieldSecond}
                       value={'nie'} checked={data?.done === 'nie'}
                       onChange={onChange} />
          <RadioButton isUppercase={true}
                       isError={submited && Boolean(errors[keyFieldSet]?.done)}
                       id={`${keyFieldSet}-done-not_specified`}
                       name={fieldSecond}
                       value={'nie udało się oznaczyć - konieczne było powtórzenie badania'}
                       checked={data?.done === 'nie udało się oznaczyć - konieczne było powtórzenie badania'}
                       onChange={onChange} />
        </RadioForm>
      </Grid>}
      {showAmount && <Grid item xs={12} pl={4} mt={1}>
        <Input
          isRequired={true}
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Liczba dni od zlecenia do otrzymania wyniku'}
          isUpperCase={true}
          name={fieldThird}
          type={'text'}
          value={data.amount as number ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={'Wpisz...'}
          isError={submited && Boolean(errors[keyFieldSet]?.amount)}
        />
      </Grid>}
    </Grid>
  </Grid>);
};