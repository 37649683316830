import { IBreadcrumbsElement } from "../../../../../../interfaces/user/breadcrumbs";
import { UserLink } from "../../../../../../utils";
import { useFormik } from "formik";

import * as yup from "yup"
import { useContractApi } from "../../../../../../apiHooks";
import { MouseEvent, useEffect } from "react";
import { useContractStore } from "../../../../../../store/hooks";
import { IUserFormikContract } from "../../../../../../interfaces/user/contract/contract";
import { useModal } from "../../../../../shared/modal/useModal.vm";

export const validationSchema = yup.object().shape({
  isNotEqualAddresses: yup
    .boolean(),
  fullName: yup
    .string()
    .required(),
  familyName: yup
    .string()
    .required(),
  dateOfBirth: yup
    .string()
    .required(),
  citizenship: yup
    .string()
    .required(),
  motherFirstName: yup
    .string()
    .required(),
  fatherFirstName: yup
    .string()
    .required(),
  pesel: yup
    .string()
    .matches(/[0-9]{11}/)
    .required(),
  bank: yup
    .string()
    .matches(/[0-9]{26}/)
    .required(),
  officeTitle: yup
    .string()
    .required(),
  street: yup
    .string()
    .required(),
  zip: yup
    .string()
    .required()
    .matches(/^[0-9]{2}-[0-9]{3}/),
  city: yup
    .string()
    .required(),
  checkinAddressStreet: yup
    .string()
    .required(),
  checkinAddressZip: yup
    .string()
    .matches(/^[0-9]{2}-[0-9]{3}/)
    .required(),
  checkinAddressCity: yup
    .string()
    .required(),
  checkinAddressCommunity: yup
    .string()
    .required(),
  checkinAddressCounty: yup
    .string()
    .required(),
  checkinAddressVoivodeship: yup
    .string()
    .required(),
  mailingAddressStreet: yup.string().test('isNotEqualAddresses', 'Field is required', function (value) {
    if (this.parent.isNotEqualAddresses === false) {
      return yup.string().required().isValidSync(value);
    }
    return true;
  }),
  mailingAddressZip: yup.string().test('isNotEqualAddresses', 'Field is required', function (value) {
    if (this.parent.isNotEqualAddresses === false) {
      return yup.string().required().isValidSync(value);
    }
    return true;
  }),
  mailingAddressCity: yup.string().test('isNotEqualAddresses', 'Field is required', function (value) {
    if (this.parent.isNotEqualAddresses === false) {
      return yup.string().required().isValidSync(value);
    }
    return true;
  }),
  mailingAddressCommunity: yup.string().test('isNotEqualAddresses', 'Field is required', function (value) {
    if (this.parent.isNotEqualAddresses === false) {
      return yup.string().required().isValidSync(value);
    }
    return true;
  }),
  mailingAddressCounty: yup.string().test('isNotEqualAddresses', 'Field is required', function (value) {
    if (this.parent.isNotEqualAddresses === false) {
      return yup.string().required().isValidSync(value);
    }
    return true;
  }),
  mailingAddressVoivodeship: yup.string().test('isNotEqualAddresses', 'Field is required', function (value) {
    if (this.parent.isNotEqualAddresses === false) {
      return yup.string().required().isValidSync(value);
    }
    return true;
  }),

});

export const useContractFormVm = () => {
  const {getUserContractData, saveUserContractData} = useContractApi()
  const contractStore = useContractStore()
  const {handleModal, isModalOpened, closeModal} = useModal();
  const {userContractData} = contractStore
  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.PROFILE,
      name: "Profil użytkownika"
    },
    {
      link: UserLink.PROFILE_SELECT_FORM,
      name: "Dane rozliczeniowe"
    },
    {
      name: "Forma rozliczenia umowa o dzieło"
    }];

  const formik = useFormik<IUserFormikContract>({
    enableReinitialize: true,
    initialValues: {
      isNotEqualAddresses: false,
      fullName: '',
      familyName: '',
      dateOfBirth: '',
      citizenship: '',
      motherFirstName: '',
      fatherFirstName: '',
      pesel: '',
      bank: '',
      officeTitle: '',
      street: '',
      zip: '',
      city: '',
      checkinAddressStreet: '',
      checkinAddressZip: '',
      checkinAddressCity: '',
      checkinAddressCommunity: '',
      checkinAddressCounty: '',
      checkinAddressVoivodeship: '',
      mailingAddressStreet: '',
      mailingAddressZip: '',
      mailingAddressCity: '',
      mailingAddressCommunity: '',
      mailingAddressCounty: '',
      mailingAddressVoivodeship: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await saveUserContractData({...values, isNotEqualAddresses: !values.isNotEqualAddresses})
      if (res) {
        contractStore.setUserContractData({...values, notEqualAddresses: !values.isNotEqualAddresses})
        handleModal()
      }

    }
  })

  useEffect(() => {
    void getUserContractData()
  }, [getUserContractData])

  const {setFieldValue} = formik

  useEffect(() => {
    if (userContractData) {
      setFieldValue('fullName', userContractData.fullName)
      setFieldValue('familyName', userContractData.familyName)
      setFieldValue('dateOfBirth', userContractData.dateOfBirth)
      setFieldValue('citizenship', userContractData.citizenship)
      setFieldValue('motherFirstName', userContractData.motherFirstName)
      setFieldValue('fatherFirstName', userContractData.fatherFirstName)
      setFieldValue('pesel', userContractData.pesel)
      setFieldValue('bank', userContractData.bank)
      setFieldValue('officeTitle', userContractData.officeTitle)
      setFieldValue('street', userContractData.street)
      setFieldValue('zip', userContractData.zip)
      setFieldValue('city', userContractData.city)
      setFieldValue('checkinAddressStreet', userContractData.checkinAddressStreet)
      setFieldValue('checkinAddressZip', userContractData.checkinAddressZip)
      setFieldValue('checkinAddressCity', userContractData.checkinAddressCity)
      setFieldValue('checkinAddressCommunity', userContractData.checkinAddressCommunity)
      setFieldValue('checkinAddressCounty', userContractData.checkinAddressCounty)
      setFieldValue('checkinAddressVoivodeship', userContractData.checkinAddressVoivodeship)
      setFieldValue('mailingAddressStreet', userContractData.mailingAddressStreet)
      setFieldValue('mailingAddressZip', userContractData.mailingAddressZip)
      setFieldValue('mailingAddressCity', userContractData.mailingAddressCity)
      setFieldValue('mailingAddressCommunity', userContractData.mailingAddressCommunity)
      setFieldValue('mailingAddressCounty', userContractData.mailingAddressCounty)
      setFieldValue('mailingAddressVoivodeship', userContractData.mailingAddressVoivodeship)
      setFieldValue('isNotEqualAddresses', !userContractData.notEqualAddresses)

    }
  }, [setFieldValue, userContractData])

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  const handleCheckboxChange = () => {
    formik.setFieldValue('isNotEqualAddresses', !formik.values.isNotEqualAddresses);
  };
  return {
    breadcrumbs,
    formik,
    confirmForm,
    handleCheckboxChange,
    isModalOpened, closeModal
  }
}