export const convertGender = (gender: string) => {
  switch (gender) {
    case 'mężczyzna':
      return 'M';
    case 'kobieta':
      return 'W';
    case 'inna':
      return 'O';
    case 'M':
      return 'mężczyzna';
    case'W':
      return 'kobieta';
    case 'O':
      return 'inna';
    default:
      return '';
  }
};

export const checkEmptyNumber = (value: number, min: number = 0) => {
  return value < min ? '' : value;
};