import styled from 'styled-components';
import theme from '../../../../../../../theme/theme';

export const ContractContentContainer = styled.div`
  h1 {
    font-size: 20px;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 14px;
    line-height: 19px;
  }
`;
export const File = styled.div`
  gap: 8px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 4px;
  cursor: pointer;

  & svg:first-child {

    & path {
      fill: ${theme.colors.red};
    }
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
`;

export const InfoText = styled.div`
  text-align: left;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${theme.colors.turquoise2};
    padding: 16px 0 0 0;
  }

  p:first-child {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${theme.colors.gray6}
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    p {
      padding: 8px 0 0 0;
    }
  }
`;