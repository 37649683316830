import styled from "styled-components";
import theme from "../../../theme/theme";
import {Grid} from "@mui/material";
import {ReactComponent as Bin} from "../../../assets/icon/bin.svg";

interface IColor {
  color: string;
}

interface ILabel {
  isCenter?: boolean;
}

export const Container = styled(Grid)`
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 28px;
  border: 2px solid ${theme.colors.gray5};
  display: flex;
  justify-content: center;
  background-color: #FFF;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
  }
`

export const ContainerFiles = styled(Grid)`
  position: relative;
`

export const StyledPlusBig = styled.div`
  background: ${theme.colors.dark1};
  width: 31px;
  height: 31px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & svg {
    width: 10px;
    height: 10px;
  }

  & path {
    fill: white
  }
`


export const StyledPlus = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${theme.colors.gray4};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;

  & svg {
    width: 6px;
    height: 6px;
  }

  & path {
    fill: white
  }
`

export const StyledBin = styled(Bin)`
  cursor: pointer;
  margin-right: 10px;

  & svg {
    width: 12px;
    height: 16px;
  }

  path {
    fill: ${theme.colors.red};
  }
`

export const ParagraphImage = styled.p`
  color: ${theme.colors.dark1};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding-right: 10px;
`;

export const Label = styled.label<ILabel>`
  color: ${theme.colors.gray4};
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  ${props => props.isCenter && 'position: absolute; top: calc(50% - 15px); right: 10%;'}
`

export const SpanText = styled.span<IColor>`
  color: ${props => props.color};
  font-size: 12px;
  line-height: 14px;

`