import React, {FC} from 'react';
import {ArrowButton} from '../arrowButton/ArrowButton';
import {StepperCustom} from '../stepper/Stepper';
import {observer} from 'mobx-react-lite';
import {
  ContentComponentContainer,
  ContentContainer,
  ContentContainerHeader,
  ContentInsideContainer,
  DraftLayoutContainer,
  SubHeader,
} from './DraftLayout.styled';
import {IDraftLayout} from './DraftLayout.helper';
import {useDraftLayout} from './useDraftLayout';
import {ArrowsAndStepperMobile} from '../arrowsAndStepperMobile/ArrowsAndStepperMobile';
import {usePoiStore} from '../../../../../../store/hooks';
import { Modal } from '../../../../../shared/modal/Modal';
import { ShowComment } from '../../../showComment/ShowComment';
import { ReactComponent as WarningIcon } from '../../../../../../assets/icon/warning.svg';
import { ShowCommentButton, WarningIconContainer } from '../../../showComment/ShowComment.styled';

export const DraftLayout: FC<IDraftLayout> = observer(({validNext, stage, message}) => {

  const {
    isBottomArrowHiddenMobile,
    isShow,
    prevArrow,
    prevArrowHandler,
    nextArrow,
    nextArrowHandler,
    component,
    name,
    tooltipNext,
    isModalOpened,
    closeModal,
    showComment,
    comment
  } = useDraftLayout({validNext, stage, message})
  const {isGlobalLoader} = usePoiStore();

  return (<DraftLayoutContainer>
    {isModalOpened && comment && <Modal clickHandler={() => closeModal()}>
      <ShowComment comment={comment} />
    </Modal>}
  
    {!isGlobalLoader && !(!prevArrow.access && !nextArrow.access) &&
        <ArrowsAndStepperMobile isValidNext={validNext} nextArrowHandler={nextArrowHandler} nextAction={nextArrow}
                                prevArrowHandler={prevArrowHandler} prevAction={prevArrow}/>}
    {prevArrow.access &&
        <ArrowButton isMobile={false} isValid={true} onClick={prevArrowHandler} direction={'left'} text={'Cofnij'}/>}
    {isShow && <><ContentContainer>
        <ContentInsideContainer>
          {name && <>
              <ContentContainerHeader><span>{name}:</span>
               {comment &&
              <ShowCommentButton
                onClick={showComment}><WarningIconContainer><WarningIcon /></WarningIconContainer>{`SPRAWDŹ KOMENTARZ`}
              </ShowCommentButton>}
              </ContentContainerHeader>
              <SubHeader>{message ?? '* Pola obowiązkowe'}</SubHeader>
          </>}
            <ContentComponentContainer>{component}</ContentComponentContainer>
           
        </ContentInsideContainer>
    </ContentContainer>
        <StepperCustom/></>}
    {nextArrow.access &&
        <ArrowButton tooltipNext={tooltipNext} isMobile={false} isValid={true} onClick={nextArrowHandler}
                     direction={'right'}
                     text={'Następny'}/>}
    {!isGlobalLoader && !(!prevArrow.access && !nextArrow.access) && !isBottomArrowHiddenMobile &&
        <ArrowsAndStepperMobile isValidNext={true} nextArrowHandler={nextArrowHandler} nextAction={nextArrow}
                                prevArrowHandler={prevArrowHandler} prevAction={prevArrow}/>}
  </DraftLayoutContainer>)
});
