import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { MAX_STAGE } from '../components/pages/user/draftCaseLung/progressBar/ProgressBar.helper';

import { IDraftCaseLung } from '../interfaces/user/draftCaseLung/draft';
import { IFirstStageLung } from '../interfaces/user/draftCaseLung/firstStage';
import { ISecondStageLung } from '../interfaces/user/draftCaseLung/secoundStage';
import { IFourthStageLung } from '../interfaces/user/draftCaseLung/fouthStage';
import { IDoctorThirdStageLung, IThirdStageLung } from '../interfaces/user/draftCaseLung/thirdStage';
import {
  convertResponseToFirstStage,
  convertResponseToSecondStage,
  convertResponseToThirdStage
} from '../utils/convertStageObjectsLung';

const INIT_STAGE = 1;

export class ProgressBarLungStore {
  isLoaded: boolean = false;
  firstStage: IFirstStageLung | null = null;
  secondStage: ISecondStageLung | null = null;
  thirdStage: IThirdStageLung | null = null;
  doctorThirdStage: IDoctorThirdStageLung | null = null;
  fourthStage: IFourthStageLung | null = null;
  currentStage = INIT_STAGE;
  maxEditStage = INIT_STAGE;
  typeOfCase: string = '';
  comment: string | null = null;
  isClicked: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setCurrentStage(stage: number) {
    if (stage > 0 && stage <= MAX_STAGE) {
      this.currentStage = stage;
    }
  }

  setTypeOfCase(typeOfCase: string) {
    this.typeOfCase = typeOfCase;
  }

  setMaxEditStage(stage: number) {
    this.maxEditStage = stage;
  }

  resetProgressBar() {
    this.currentStage = INIT_STAGE;
    this.maxEditStage = INIT_STAGE;
    this.comment = null;

    this.setFirstStage(null);
    this.setSecondStage(null);
    this.setThirdStage(null);
    this.setFourthStage(null);

    this.setIsLoaded(false);
  }

  setFirstStage(firstStage: IFirstStageLung | null) {
    this.firstStage = firstStage;
  }

  setSecondStage(secondStage: ISecondStageLung | null) {
    this.secondStage = secondStage;
  }

  setThirdStage(thirdStage: IThirdStageLung | null) {
    this.thirdStage = thirdStage;
  }

  setDoctorThirdStage(doctorThirdStage: IDoctorThirdStageLung | null) {
    this.doctorThirdStage = doctorThirdStage;
  }
  
  setFourthStage(fourthStage: IFourthStageLung | null) {
    this.fourthStage = fourthStage;
  }

  convert(draftCase: IDraftCaseLung, changeStep?: boolean) {
    const step = draftCase.step;

    this.setMaxEditStage(step);
    if (changeStep) {
      this.setCurrentStage(step);
    }

    const firstStage: IFirstStageLung = convertResponseToFirstStage(draftCase);
    this.setFirstStage(firstStage);

    const secondStage: ISecondStageLung = convertResponseToSecondStage(draftCase);
    this.setSecondStage(secondStage);

    const thirdStage: IThirdStageLung = convertResponseToThirdStage(draftCase);
    this.setThirdStage(thirdStage);

    const fourthStage: IFourthStageLung = draftCase;
    this.setFourthStage(fourthStage);

    this.comment = draftCase.comment;
    this.setIsLoaded(true);
  }

  setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
  }

  setClicked(value: boolean) {
    this.isClicked = value;
  }

}
