import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useContractStore } from '../store/hooks';
import { IContractData, IUserFormikContract } from '../interfaces/user/contract/contract';
import { IDocuments } from '../interfaces/admin/contract/contract';
import { convertDate } from '../components/shared/customTables/utils/convertDate';
import { fileDownload } from '../utils/fileDownload';

export const useContractApi = () => {
  const { api } = useApiCall();
  const contractStore = useContractStore();

  const getCompanyContractData = useCallback(async () => {
    const res = await api.apiInstance.get('company-contract', true);
    if (res) {
      contractStore.setContractData(res);
    }
  }, [api.apiInstance, contractStore]);

  const editContractData = useCallback(async (data: IContractData) => {
    const res = await api.apiInstance.put('company-contract', data, true);
    if (res) {
      contractStore.setContractData(data);
    }
  }, [api.apiInstance, contractStore]);

  const checkInvoiceFilePdf = useCallback(async (id: number, check: boolean) => {
    const res = await api.apiInstance.put(`company-contract-invoice/${id}/check`, { isChecked: check }, false);

    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const checkWorkContractPdf = useCallback(async (id: number, check: boolean) => {
    const res = await api.apiInstance.put(`user-contract-invoice/${id}/check`, { isChecked: check }, false);

    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const checkCompanyContractFilePdf = useCallback(async (id: number, check: boolean) => {
    const res = await api.apiInstance.put(`company-contract-file/${id}/check`, { isChecked: check }, false);

    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const getContractPdf = useCallback(async () => {
    const res = await api.apiInstance.get('company-contract/pdf', true, { responseType: 'blob' });
    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const sendContractPdf = useCallback(async (file: File) => {
    const form = new FormData();
    form.append('file', file);
    const res = await api.apiInstance.post('company-contract-file', form, true);

    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const getUserContractData = useCallback(async () => {
    const res = await api.apiInstance.get('user-contract', true);

    if (res) {
      contractStore.setUserContractData(res);
    }
  }, [api.apiInstance, contractStore]);

  const saveUserContractData = useCallback(async (data: IUserFormikContract) => {
    const res = await api.apiInstance.put('user-contract', data, true);

    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const getContractDocuments = useCallback(async () => {
    const res = await api.apiInstance.get('reckoning/history', true);

    if (res) {
      contractStore.setContractDocuments(res);
    }
  }, [api.apiInstance, contractStore]);

  const getCompanyContractFiles = useCallback(async (isLoader?: boolean) => {
    const res = await api.apiInstance.get('company-contract-files', isLoader);

    if (res) {
      contractStore.setCompanyContracts(res.items);
    }
  }, [api.apiInstance, contractStore]);

  const acceptCompanyContractFile = useCallback(async (id: number) => {
    const res = await api.apiInstance.put(`company-contract-file/${id}/accept`, true, true);
    return !!res;
  }, [api.apiInstance]);

  const postFileCompanyContractFile = useCallback(async (id: number, document: File) => {
    const file = new FormData();
    file.append('file', document);
    const res = await api.apiInstance.post(`company-contract-file/${id}`, file);
    return !!res;
  }, [api.apiInstance]);

  const getInvoices = useCallback(async (isLoader?: boolean) => {
    const res = await api.apiInstance.get('company-contract-invoices', isLoader);

    if (res) {
      contractStore.setInvoicesDocuments(res.items);
    }
  }, [api.apiInstance, contractStore]);

  const payInvoiceFile = useCallback(async (id: number) => {
    const res = await api.apiInstance.put(`company-contract-invoice/${id}/pay`, true, true);
    return !!res;
  }, [api.apiInstance]);

  const postFileInvoiceFiles = useCallback(async (id: number, document: File) => {
    const file = new FormData();
    file.append('file', document);
    const res = await api.apiInstance.post(`company-contract-invoice/${id}`, file);
    return !!res;
  }, [api.apiInstance]);

  const getWorkContracts = useCallback(async (isLoader?: boolean) => {
    const res = await api.apiInstance.get('user-contract-invoices', isLoader);

    if (res) {
      contractStore.setWorkContracts(res.items);
    }
  }, [api.apiInstance, contractStore]);

  const acceptWorkContract = useCallback(async (id: number) => {
    const res = await api.apiInstance.put(`user-contract-invoice/${id}/accept`, true, true);
    return !!res;
  }, [api.apiInstance]);

  const payWorkContract = useCallback(async (id: number) => {
    const res = await api.apiInstance.put(`user-contract-invoice/${id}/pay`, true, true);
    return !!res;
  }, [api.apiInstance]);

  const postFileWorkContract = useCallback(async (id: number, document: File) => {
    const file = new FormData();
    file.append('file', document);
    const res = await api.apiInstance.post(`user-contract-invoice/${id}`, file);
    return !!res;
  }, [api.apiInstance]);

  const getQuestionnairePdf = useCallback(async (id: number) => {
    const res = await api.apiInstance.get(`company-contract/questionnaire/${id}`, true, { responseType: 'blob' });
    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const getWorkContactZip = useCallback(async (id: number) => {
    const res = await api.apiInstance.get(`user-contract-invoice/${id}/zip`, true, { responseType: 'blob' });
    if (res) {
      return res;
    }
  }, [api.apiInstance]);


  const getInvoicesZip = useCallback(async (id: number) => {
    const res = await api.apiInstance.get(`company-contract-invoice/${id}/zip`, true, { responseType: 'blob' });
    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const getCompanyContractZip = useCallback(async (id: number) => {
    const res = await api.apiInstance.get(`company-contract-file/${id}/zip`, true, { responseType: 'blob' });
    if (res) {
      return res;
    }
  }, [api.apiInstance]);

  const getDocuments = useCallback(async (isLoader?: boolean) => {
    const res = await api.apiInstance.get('contracts', isLoader);

    if (res) {
      contractStore.setDocuments(res.map((el: IDocuments, index: number) => ({
        ...el,
        customId: el.id,
        id: el.id + ',' + index + el.originalName + '-' + el.type,
        formatedDate: convertDate(el.datePay),
        numberContract: el.numberContract,
        status: el.status === '1' ? 'ACCEPT' : el.status === '0' ? 'WAIT' : el.status,
        isVisible: (el.type === 'Faktura' && el.status !== 'PAY') || (el.type === 'Umowa o dzieło' && el.status !== 'PAY') || el.type === 'Umowa o współpracę'
      })));
    }
  }, [api.apiInstance, contractStore]);

  // const getExcelDocuments = useCallback(async () => {
  //   const res = await api.apiInstance.get('contracts/excel', true, {responseType: 'blob'})
  //   if (res) {
  //     fileDownload(res, false, 'xlsx')
  //   }
  // }, [api.apiInstance])


  const getReportYearly = useCallback(async (year: number, areaIds: number[]) => {
    const res = await api.apiInstance.post('contracts/report/yearly', {
      year,
      areaIds
    }, true, { responseType: 'blob' });
    if (res) {
      fileDownload(res, false, 'xlsx');
    }
  }, [api.apiInstance]);

  const getReportMonthly = useCallback(async (month: number, year: number, areaIds: number[]) => {
    const res = await api.apiInstance.post('contracts/report/monthly', {
      year,
      month,
      areaIds
    }, true, { responseType: 'blob' });
    if (res) {
      fileDownload(res, false, 'pdf');
    }
  }, [api.apiInstance]);

  const editContract = useCallback(async (datePay: string, numberContract: string, typeContract: string, id: number) => {
    const res = await api.apiInstance.put(`contracts/${typeContract}/${id}`, { datePay, numberContract }, true);
    if (res) {
      await getDocuments();
      return !!res;
    }
  }, [api.apiInstance, getDocuments]);

  const getUserInfo = useCallback(async (id: number) => {
    const res = await api.apiInstance.get(`user/${id}`);
    if (res) {
      contractStore.setUserInfo(res);
    }
  }, [api.apiInstance, contractStore]);

  const sendEmailToPublish = useCallback(async (id: number, message: string, type: string) => {
    if (type === 'invoice') {
      return await api.apiInstance.put(`company-contract-invoice/${id}/send-email-to-publishing`, { message }, true);
    }

    if (type === 'workContract') {
      return await api.apiInstance.put(`user-contract-invoice/${id}/send-email-to-publishing`, { message }, true);
    }
    if (type === 'companyContract') {
      return await api.apiInstance.put(`company-contract-files/${id}/send-email-to-publishing`, { message }, true);
    }
  }, [api.apiInstance]);

  return {
    getCompanyContractFiles,
    acceptCompanyContractFile,
    postFileCompanyContractFile,
    getInvoices,
    getWorkContracts,
    acceptWorkContract,
    payWorkContract,
    postFileWorkContract,
    getCompanyContractData,
    editContractData,
    getContractPdf,
    checkInvoiceFilePdf,
    checkWorkContractPdf,
    checkCompanyContractFilePdf,
    sendContractPdf,
    getUserContractData,
    saveUserContractData,
    getContractDocuments,
    payInvoiceFile,
    postFileInvoiceFiles,
    getQuestionnairePdf,
    getDocuments,
    getReportYearly,
    getReportMonthly,
    getWorkContactZip,
    getInvoicesZip,
    getCompanyContractZip,
    editContract,
    getUserInfo,
    sendEmailToPublish
  };
};