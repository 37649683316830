import styled, { css } from 'styled-components';
import theme from '../../../../theme/theme';
import { FormControl } from '@mui/material';
import { ISelect } from '../../../../interfaces/common/form/select';

export const Label = styled.label<{ color?: string }>`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: ${props => props.color ? props.color : theme.colors.dark1};
  margin-left: 16px;
`;

export const SelectContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: ${props => props.width ? props.width : 'auto'}
`;
const selectCommon = css`
  color: rgb(51, 51, 51);
  border-radius: 28px;
  font-size: 16px;
  line-height: 36px;

`;

export const StyledSelect = styled.select<ISelect>`
  ${selectCommon};
  height: ${props => props.height ?? '2.25rem'};
  border: 2px solid ${props => props.isError ? theme.colors.red : theme.colors.gray5};
  padding: 0 22px;
`;


export const FormControlMultiSelect = styled(FormControl)`
  margin-bottom: 15px !important;
  width: 100%;

  & div:first-of-type {
    ${selectCommon};
    background: white;
    padding: 0px 14px;
    cursor: default;
  }

  & fieldset {
    border: 2px solid rgb(224, 224, 224);
  }
`;

export const RedSpan = styled.span`
  color: ${theme.colors.red};
`;

export const ErrorMessage = styled.p`
  color: ${theme.colors.red};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;