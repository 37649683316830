import * as yup from 'yup'
import { useFormik } from 'formik';
import { useAuthApi } from "../../../../../../apiHooks";
import { useRegisterForm } from "../../form/useRegisterForm.vm";
import { useNavigate } from "react-router-dom";
import { GeneralLink, generateLink, RegexRules } from "../../../../../../utils";
import { RoleCategory } from "../../../../../../constants/projectUsers";
import { validatePWZ } from "../../../../../../utils/validatePWZ";
import { IRegisterPublish } from "../../../../../../interfaces/common/register";
import { ValidationMessages } from "../../../../../../constants/validationMessages";

const containLetterE = (string: string) => /^((?!(0))[0-9^e]{7})$/g.test(string)
export const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(ValidationMessages.EMPTY_FIELD),
  affiliation: yup
    .string()
    .required(ValidationMessages.EMPTY_FIELD),
  pwz: yup
    .string()
    .test('withoutE', 'Numer PWZ powinien składać się z 7 cyfr.', (value) => containLetterE(value ?? ''))
    .test('validatePWZ', ValidationMessages.INVALID_PWZ, (value?: string | number) => validatePWZ(value?.toString() ?? ''))
    .required(ValidationMessages.EMPTY_FIELD),
  email: yup
    .string()
    .matches(RegexRules.EMAIL, ValidationMessages.INVALID_EMAIL)
    .required(ValidationMessages.EMPTY_FIELD),
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
  repeatPassword: yup
    .string()
    .test('passwords-match', ValidationMessages.INVALID_PASSWORD, function (value) {
      return this.parent.password === value
    })
    .required(ValidationMessages.EMPTY_FIELD),
  termsAndConditions: yup.boolean().oneOf([true])
})

export const useRegisterViewingForm = () => {
  const {registerViewingUser} = useAuthApi()
  const navigate = useNavigate()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      affiliation: '',
      fullName: '',
      pwz: '',
      email: '',
      password: '',
      repeatPassword: ''
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: Partial<IRegisterPublish> = {};
      if (values.pwz && !validatePWZ(values.pwz.toString())) {
        errors.pwz = ValidationMessages.INVALID_PWZ;
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (!isErrorCheckbox) {
        setMessage('')

        const data = {...values, repeatPassword: undefined, pwz: Number.parseInt(formik.values.pwz)}
        const res = await registerViewingUser(data)
        if (res) {
          navigate(generateLink(RoleCategory.GUEST, GeneralLink.LOGIN))
        }
      }
    }
  })

  const {
    isChecked,
    setChecked,
    isErrorCheckbox,
    message,
    confirmForm,
    isButtonActive,
    setMessage
  } = useRegisterForm(formik)

  return {formik, isChecked, setChecked, isErrorCheckbox, message, confirmForm, isButtonActive}
}
  
  

