import styled from 'styled-components';
import theme from '../../../../../../theme/theme';

export const RadioForm = styled.div<{ $flexDirection?: string }>`
  display: flex;
  margin-top: 12px;
  margin-left: 16px;
  flex-wrap: wrap;
  flex-direction: ${props => props.$flexDirection ? props.$flexDirection : 'row'};
  gap: ${props => props.$flexDirection === 'column' ? '16px' : '24px'};
  //height: 32px;

  @media (max-width: ${theme.breakpoints.large}px) {
    gap: 16px;
  }
`;


export const CheckboxGroup = styled.div<{ $flexDirection?: string, $padding?: string }>`
  display: flex;
  padding: ${props => props.$padding};
  flex-direction: ${props => props.$flexDirection ? props.$flexDirection : 'row'};
  text-transform: uppercase;
  flex-wrap: wrap;
  gap: ${props => props.$flexDirection ? 0 : '16px'};

  & div {
    flex-grow: 1;
  }

  @media (max-width: ${theme.breakpoints.large}px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const TitleHelper = styled.span`
  font-size: 12px;
  color: #000;
  font-weight: 400;
  text-transform: none;
`