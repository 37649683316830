import { useState } from 'react';
import { ModalType } from '../../../constants/modalType';

export const useModal = () => {
  const [isModalOpened, setModalOpened] = useState(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [id, setId] = useState<number | string | null>(null);

  const handleModal = (type?: ModalType, id?: number | string) => {
    setModalOpened(true);
    setModalType(type ?? null);
    if (id) {
      setId(id);
    }
  };

  const closeModal = () => {
    setModalOpened(false);
    setModalType(null);
  };

  const openModal = () => {
    setModalOpened(true);
  };

  return {
    modalType,
    handleModal,
    isModalOpened,
    closeModal,
    openModal,
    id
  };
};
