import { ReactNode, useEffect, useState } from 'react';
import { useAreasStore, useAuthStore } from '../../../../store/hooks';
import { useAreasApi } from '../../../../apiHooks/useAreasApi';
import { SelectChangeEvent } from '@mui/material';
import { IOption } from '../../../../interfaces/common/form/select';
import { filterByArea, filterByRole } from '../../../../utils/searchHelpers';
import { IAllUsers } from '../../../../interfaces/admin/user/allUsers';
import { useRolesStore } from '../../../../store/hooks/useRolesStore';
import { useRolesApi } from '../../../../apiHooks';
import { generateOptionsArea } from '../../../../utils/generateOptionsArea';

export const useUsersFilters = (contracts: IAllUsers[] | null, isOwnArea: boolean = false) => {
  const { areas } = useAreasStore();
  const { roles } = useRolesStore();
  const { areas: areasUser } = useAuthStore();
  const { getAreas } = useAreasApi();
  const { getRoles } = useRolesApi();

  const [selectedAreas, setSelectedAreas] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  useEffect(() => {
    void getAreas();
  }, [getAreas]);

  useEffect(() => {
    void getRoles();
  }, [getRoles]);

  const selectedAreaTypes = selectedAreas
    .map(selectedId => areas?.find(el => el.id === selectedId))
    .filter(area => area !== undefined)
    .map(area => area!.fullName);

  const optionsAreas = generateOptionsArea(isOwnArea, areas, areasUser);
  const optionsRoles = roles;

  const handleChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedAreas.includes(0)) {
        setSelectedAreas(selectedValues.filter(value => value !== 0));
      } else {
        if (optionsAreas) {
          setSelectedAreas([...optionsAreas.map((option: IOption) => option.id), 0]);
        }
      }
    } else {
      if (selectedAreas.includes(0)) {
        setSelectedAreas([]);
      } else {
        setSelectedAreas(selectedValues);
      }
    }
  };


  const handleChangeRole = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedUsers.includes(0)) {
        setSelectedUsers(selectedValues.filter(value => value !== 0));
      } else {
        if (roles) {
          setSelectedUsers([...roles.map((role: IOption) => role.id), 0]);
        }
      }
    } else {
      if (selectedUsers.includes(0)) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(selectedValues);
      }
    }
  };

  const filteredUsers = contracts && contracts.filter(filterByArea(selectedAreaTypes)).filter(filterByRole(selectedUsers));

  const chipsAreas = selectedAreas?.map(selected => areas?.filter(el => el.id === selected).map(obj => obj.fullName)).flat();
  const chipsRoles = selectedUsers?.map(selected => roles?.filter(el => el.id === selected).map(obj => obj.title)).flat();

  const handleReset = () => {
    setSelectedUsers([]);
    setSelectedAreas([]);
  };
  const handleDeleteRole = (value?: string) => {
    if (value) {
      const id = roles?.find(el => el.title === value)?.id;

      if (id) {
        setSelectedUsers(selectedUsers.filter(el => el !== id));
      }
    }
  };

  const handleDeleteArea = (value?: string) => {
    if (value) {
      const id = areas?.find(el => el.fullName === value)?.id;

      if (id) {
        setSelectedAreas(selectedAreas.filter(el => el !== id));
      }
    }
  };
  
  return {
    filteredUsers,
    optionsAreas,
    selectedAreas,
    optionsRoles,
    handleChange,
    selectedUsers,
    handleChangeRole,
    chipsAreas,
    chipsRoles,
    handleReset,
    handleDeleteRole,
    handleDeleteArea
  };
};