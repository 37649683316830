import styled from 'styled-components';
import { Select } from '@mui/material';

export const ParagraphEmpty = styled.p`
  text-align: center;
  width: 100%;
`;


export const StyledSelect = styled(Select)`
`;