import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const StyledBorder = styled.div`
  width: 127px;
  height: 2px;
  background-color: ${theme.colors.dark1};
  margin-bottom: 6px;
`

export const Container = styled.div`
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 16px;
  }
`

export const BlackTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.dark1};
  text-transform: uppercase;

  & span {
    font-weight: 400;
  }
`

export const StyledCalendar = styled.div`
  margin-right: 22px
`

export const SmallerText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  height: 31px;
`

export const Content = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 8px;
  color: ${theme.colors.dark1};

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const ObjectContainer = styled.div`
  display: flex;
  align-items: center;
`