import {ContentModal} from "../../../../../shared/modal/ContentModal";
import {MoneyIcon} from "../../../../../shared/modal/modalIcons/MoneyIcon";
import {
  ConfirmationTextModal
} from "../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled";
import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import {generateLink, UserLink} from "../../../../../../utils";
import {RoleCategory} from "../../../../../../constants/projectUsers";
import {Button} from "../../../../../shared/button/Button";
import theme from "../../../../../../theme/theme";
import {ContentAfterContainer, ParagraphFirst} from "./MyReckoningSelection.styled";
import {ContractType} from "../../../../../../interfaces/admin/contract/contract";


interface IProps {
  documentPlacementHandler: () => void;
  closeModal: () => void;
  type: ContractType;
}

export const MyReckoningSelection: FC<IProps> = ({documentPlacementHandler, closeModal, type}) => {
  const navigate = useNavigate();

  const viewSelectionReckoning = () => {
    const link = 'company' === type ? UserLink.PROFILE_FORM_INVOICE_SUMMARY : UserLink.PROFILE_FORM_CONTRACT_SUMMARY
    navigate(generateLink(RoleCategory.USER, link))
  }

  return <ContentModal
    iconType={<MoneyIcon/>}
    handleYes={documentPlacementHandler}
    handleNo={() => navigate(generateLink(RoleCategory.USER, UserLink.PROFILE))}
    text={<ConfirmationTextModal>
      <ParagraphFirst><p>Masz już wybraną formę rozliczenia:</p>
        <Button width={'165px'} border={theme.colors.turquoise2}
                color={'white'} size={'small'} backgroundColor={theme.colors.turquoise2}
                text={'company' === type ? "Faktura" : "Umowa o dzieło"}
                clickHandler={viewSelectionReckoning}/>
      </ParagraphFirst>
      <p><b>Czy chcesz kontynuwać rozliczenie w tej formie z aktualnymi danymi rozliczeniowymi?</b></p>
    </ConfirmationTextModal>}
    contentAfter={
      <ContentAfterContainer>
        <Button width={'165px'} border={theme.colors.dark1} color={theme.colors.dark1} backgroundColor={'white'}
                size="tiny" text={"Rozliczę się później"} clickHandler={closeModal}/>
      </ContentAfterContainer>
    }
  />
}