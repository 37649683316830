import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonSize } from './Button';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const ButtonContainer = styled.button<{ margin?: string, disabled?: boolean; size?: ButtonSize, border?: string, color?: string, backgroundColor?: string, maxWidth?: string, width?: string, isIcon: boolean, fontSize?: string, padding?: string }>`
  font-size: ${props => props.size === 'small' ? '16px' : (props.size === 'tiny' ? '12px' : '32px')};
  font-size: ${props => props.fontSize};
  min-height: ${props => props.size === 'small' || props.size === 'tiny' ? '36px' : '74px'};
  border-radius: ${props => props.size === 'small' || props.size === 'tiny' ? '28px' : '37px'};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  border: none;
  z-index: 150;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: ${props => props.padding ?? '8px'};
  padding-left ${props => props.padding ?? '8px'};
  max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
  cursor: ${props => props.disabled ? 'normal' : 'pointer'};
  opacity: ${props => props.disabled ? '0.8' : '1'};
  width: ${props => props.width ? props.width : '100%'};
  justify-content: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border: ${props => props.border ? `1px solid ${props.border}` : 'none'};
  margin: ${props => props.margin};

  & svg {
    height: ${props => props.size === 'small' || props.size === 'tiny' ? '12px' : '24px'};
    margin-top: ${props => props.size === 'small' || props.size === 'tiny' ? '3px' : '6px'};
  }
}
`;


