import {useRootStore} from '../RootStateContext';

export const useProgressBarLungStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.progressBarLungStore) {
        throw new Error('progressBarLungStore store should be defined');
    }

    return rootStore.progressBarLungStore;
};
