import styled from "styled-components";
import theme from "../../../../theme/theme";

interface IDatePickerCustom {
  isError: boolean;
  maxWidth?: string;
  textAlign?: string;
}

export const DatePickerCustomContainer = styled.div<IDatePickerCustom>`
  width: 100%;
  text-align: ${props => props.textAlign};
  margin: 0 0 15px 0;

  & div:nth-child(2) > label[data-shrink='true'] {
    display: none;
  }

  & div:nth-child(2) > .MuiOutlinedInput-root:not(label) {
    color: ${theme.colors.dark1};
    height: 2.25rem;
    border-radius: 28px;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 22px;
    background: white;
    border: 2px solid rgb(223, 20, 20);
    color: ${theme.colors.dark1};
    border: 2px solid ${theme.colors.gray5};
    border: ${props => props.isError && '2px solid #DF1414 '};
    ${props => props.maxWidth && `max-width: ${props.maxWidth}`};
  }

  .MuiInputLabel-root.MuiInputLabel-formControl.Mui-error{
    color: black;
    text-transform: none;
    top: -8px;
    left: 10px;
    color: #757575;
    font-weight: 200;
  }

  & fieldset {
    border: none;
  }

  & input {
    padding: 14px 0px;
  }
`
