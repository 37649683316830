import {Grid} from '@mui/material';
import {FC} from 'react';
import {useParams} from 'react-router-dom';

import {DoctorForm} from '../doctorForm/DoctorForm';
import {ModalLayer} from '../../../../draftCaseNeuro/stages/thirdStage/modalLayer/ModalLayer';
import {useDraftCaseApi} from '../../../../../../../apiHooks/useDraftCaseLungApi';
import {DoctorType, IDoctorFieldSets} from '../../../../../../../interfaces/user/draftCaseLung/thirdStage';

interface IProps {
  type: DoctorType;
  title: string;
  handleCloseDrugModal: () => void;
  checkThirdStage: () => void;
}

export const ModalAdd: FC<IProps> = ({type, title, handleCloseDrugModal, checkThirdStage}) => {
  const {id} = useParams();
  const {postDoctorThirdStage} = useDraftCaseApi();

  const handleOnSave = async (values: IDoctorFieldSets) => {
    const isCreated = await postDoctorThirdStage(Number(id), values);

    if(isCreated){
      checkThirdStage();
      handleCloseDrugModal()
    }
  }

  return (
    <>
      <ModalLayer text={title}>
        <Grid container display='flex' alignItems='center' justifyContent='center'>
          <Grid item marginX={4} maxHeight={'550px'}>
            <DoctorForm type={type} onSave={handleOnSave} formContainer={{display: 'flex', alignItems: 'flex-start', marginTop: '110px', overflowY: 'auto', maxHeight: '400px' }} buttonsContainer={{display: 'flex', justifyContent: 'center', marginTop: '8px'}}/>
          </Grid>
        </Grid>
      </ModalLayer>
    </>
  )
};

