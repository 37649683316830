import logo from '../../../../../assets/img/logo.png';
import React from 'react';
import { UserTop } from '../../../appLayer/userTop/UserTop';
import { Img, ImgLayer, NavigationContainer, NavigationContent } from './NavigationComponent.styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkPermission, generateLink, UserLink } from '../../../../../utils';
import { RoleCategory, RoleFunction } from '../../../../../constants/projectUsers';
import { NavigationMenu } from '../navigationMenu/NavigationMenu';
import useBreakpoint, { Breakpoint } from '../../../../../hooks/useBreakpoint';
import { useAuthStore } from '../../../../../store/hooks';
import { NavigationButtons } from '../navigationButton/NavigationButtons';

export const NavigationComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const { roleFunction } = useAuthStore();

  const isActive = roleFunction !== RoleFunction.ROLE_ACCEPTING;

  return (
    <NavigationContainer>
      <NavigationMenu />
      <UserTop isProfile={true} />
      <NavigationContent>
        <ImgLayer isActive={isActive}
                  onClick={() => isActive && navigate(generateLink(RoleCategory.USER, UserLink.HOME))}><Img
          src={logo} />
        </ImgLayer>
      </NavigationContent>


      {checkPermission(roleFunction, [RoleFunction.ROLE_PUBLISHING]) && (location.pathname !== '/' || breakpoint >= Breakpoint.SMALL) &&
        <NavigationButtons />}
    
    </NavigationContainer>
  );
};
