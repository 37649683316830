import * as yup from 'yup';
import { GeneralLink, generateLink, RegexRules } from '../../../../../utils';
import { useFormik } from 'formik';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { ValidationMessages } from '../../../../../constants/validationMessages';
import { useAuthApi } from '../../../../../apiHooks';
import { useAuthStore } from '../../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { validatePWZ } from "../../../../../utils/validatePWZ";

export const validationSchema = yup.object().shape({
  pwz: yup
    .string()
    .test('validatePWZ', ValidationMessages.INVALID_PWZ, (value?: string | number) => value ? validatePWZ(value?.toString()) : true),
  login: yup
    .string()
    .matches(RegexRules.EMAIL, ValidationMessages.INVALID_EMAIL)
    .required(ValidationMessages.EMPTY_FIELD),
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
});
/*
      pwz: '4211016',
      login: 'adrianborkowski1991@gmail.com',
      password: 'X.t&lGit#n\'kZ\'ufTC9G93?vjo@Q3bgE'
 */
export const useLoginForm = () => {
  const navigate = useNavigate()

  const authStore = useAuthStore();
  const authApi = useAuthApi();

  const [isButtonActive, setIsButtonActive] = useState(false);
  const [message, setMessage] = useState('')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pwz: '',
      login: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const convertPWZ = formik.values.pwz === "" ? -1 : Number.parseInt(formik.values.pwz);
      const isLogin = await authApi.loginUser(formik.values.login, formik.values.password, convertPWZ);
      if (isLogin) {
        navigate(generateLink(authStore.roleCategory, GeneralLink.HOME));
      } else {
        setMessage(ValidationMessages.INVALID_LOGIN)
      }
    }
  })


  const createErrorMessage = useCallback(() => {
    if (Object.keys(formik.errors).length > 0) {
      return ValidationMessages.INVALID_FORMLOGIN;
    }

    return ""
  }, [formik.errors])

  useEffect(() => {
    if (formik.submitCount !== 0) {
      setMessage(createErrorMessage());
      const isValid = Object.keys(formik.errors).length === 0;
      setIsButtonActive(isValid);
    }
  }, [createErrorMessage, formik.errors, formik.submitCount])

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  return {formik, isButtonActive, confirmForm, message}
}