import React from 'react'
import { Button, StyledIcon } from './FilterButton.styled'
import { CaseStatus } from "../../../interfaces/user/draftCaseNeuro/draft";

interface IProps {
  isActive: boolean
  text: string;
  color: string;
  status: CaseStatus;
  children: JSX.Element
  switchFilters?: (status: CaseStatus) => void
}


export const FilterButton: React.FC<IProps> = ({isActive, text, children, color, status, switchFilters}) => {
  return (
    <Button isActive={isActive} color={color} onClick={() => switchFilters && switchFilters(status)}>
      {text}
      <StyledIcon color={color}>{children}</StyledIcon>
    </Button>
  )
}

