import * as yup from "yup";
import { useProgressBarNeuroStore } from "../../../../../../store/hooks";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Stages } from "../../progressBar/ProgressBar.helper";
import { IFourthStageNeuro } from "../../../../../../interfaces/user/draftCaseNeuro/fouthStage";
import { useDraftCaseApi } from "../../../../../../apiHooks";
import { useParams } from "react-router-dom";
import { setAllFieldsTouched } from '../../../../../../utils/setAllFieldsTouched';

export const validationSchema = yup.object().shape({
  date: yup
    .string()
    .required(),
  description: yup
    .string()
    .required(),
  assessment: yup
    .string()
    .required()
})

export const useFourthStage = () => {
  const progressBarStore = useProgressBarNeuroStore();
  const {id} = useParams();
  const {postImageFourthStage, deleteImageFourthStage} = useDraftCaseApi()
  const {fourthStage, isClicked} = progressBarStore;
  const formik = useFormik<IFourthStageNeuro>({
    enableReinitialize: true,
    initialValues: {
      date: fourthStage ? fourthStage.date : '',
      description: fourthStage ? fourthStage.description : '',
      assessment: fourthStage ? fourthStage.assessment : '',
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  })

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      progressBarStore.setMaxEditStage(Stages.FOURTH);
    } else {
      const fourthStageConvert: IFourthStageNeuro = {
        description: formik.values.description,
        assessment: formik.values.assessment,
        date: formik.values.date,
        images: fourthStage ? fourthStage.images : []
      }
      progressBarStore.setFourthStage(fourthStageConvert);
      progressBarStore.setMaxEditStage(Stages.FIFTH);
    }
  }, [formik.values.date, formik.errors, fourthStage, progressBarStore, formik.values.description, formik.values.assessment])

  const addImage = async (file: File | null) => {
    if (file && id) {
      await postImageFourthStage(+id, file);
    }
  }

  const removeImage = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteImageFourthStage(+id, idFile);
    }
  }

  const {validateForm, setFieldTouched} = formik;

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
      progressBarStore.setClicked(false);
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values, progressBarStore])

  return {
    formik,
    addImage,
    removeImage,
    images: fourthStage && fourthStage.images ? fourthStage.images : []
  }
}
