import React from 'react';

import {observer} from 'mobx-react-lite';
import {TailSpin} from 'react-loader-spinner';

import {usePoiStore} from '../../../../store/hooks';
import {GlobalLoaderContainer} from "./GlobalLoader.styled";

export const GlobalLoader = observer(() => {
  const poiStore = usePoiStore();
  const {isGlobalLoader} = poiStore;

  return isGlobalLoader ? (
    <GlobalLoaderContainer>
      <TailSpin color="#006AB4" height={80} width={80} radius={1}/>
    </GlobalLoaderContainer>
  ) : null;
});
