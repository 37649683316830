import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const RegisterFormContainer = styled.form<{ buttonTextColor?: string, isButtonActive: boolean }>`
  position: relative;
  z-index: 50;
  padding: 30px 0;

  & button {
    margin: 26px 0 0 auto;
    @media (max-width: ${theme.breakpoints.small}px) {
      max-width: 100% !important;
      margin-top: 34px;
    }

    & path {
      fill: ${(props) => props.isButtonActive ? '#F9FCFF' : props.buttonTextColor}
    }
  }
`

export const Title = styled.h1<{ titleColor: string }>`
  color: ${props => props.titleColor};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 20px;
`