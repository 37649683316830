import {makeAutoObservable} from 'mobx';

import {RootStore} from './rootStore';

export class PoiStore {
    isGlobalLoader = false;
    isGlobalError = false;
    isModalOpen = false;
    errorMessage: string | null = null;
    errorCode: number | null = null;

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    setGlobalLoader = () => (this.isGlobalLoader = true);
    unsetGlobalLoader = () => (this.isGlobalLoader = false);

    setGlobalError = (status: number, message: string) => {
        this.isGlobalError = true;
        this.errorCode = status || 404;
        this.errorMessage = message || 'skontaktuj się z administratorem';
        if (this.errorCode === 401) {
            this.rootStore.authStore.appLogout();
        }
    };

    unsetGlobalError = () => {
        this.isGlobalError = false;
        this.errorMessage = null;
        this.errorCode = null;
    };

    setIsModalOpen() {
        this.isModalOpen = true;
    }

    unsetIsModalOpen() {
        this.isModalOpen = false;
    }
}
