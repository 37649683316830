import {ContentModal} from "../../../../../shared/modal/ContentModal";
import {MoneyIcon} from "../../../../../shared/modal/modalIcons/MoneyIcon";
import {
  ConfirmationTextModal,
  MyReckoningConfirmationContainer
} from "../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled";
import React, {FC} from "react";
import theme from "../../../../../../theme/theme";
import {Button} from "../../../../../shared/button/Button";
import {ContractType} from "../../../../../../interfaces/admin/contract/contract";

interface IProps {
  closeModal: () => void;
  type: ContractType;
}

export const MyReckoningConfirmation: FC<IProps> = ({closeModal, type}) => {
  return <ContentModal
    iconType={<MoneyIcon background={theme.colors.turquoise2}/>}
    text={<ConfirmationTextModal>
      <MyReckoningConfirmationContainer>
        <p><b>Dziękujemy!</b></p>
        <p>Twoja {type === 'company' ? 'faktura' : 'umowa o dzieło'} została poprawnie przekazana do działu rozliczeń.
          <b> W ciągu 30 dni otrzymasz swoje wynagrodzenie za ten opis przypadku na wskazane konto w danych
            rozliczeniowych.</b></p>
        <Button text={"Rozlicz następny"} width={'310px'} backgroundColor={theme.colors.red} size={'tiny'}
                color={'white'} clickHandler={closeModal}/>
      </MyReckoningConfirmationContainer>
    </ConfirmationTextModal>}
  />
}