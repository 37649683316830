import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const ContentContainer = styled.div`
  width: 100%;
  margin: 35px 50px 0 50px;

  @media (max-width: ${theme.breakpoints.extraLarge}px) {
    margin: 35px 0 0 50px;
  }
  @media (max-width: ${theme.breakpoints.large}px) {
    margin: 35px 0 0 0px;
  }
`
export const ContentInsideContainer = styled.div`
  display: block;
  margin: auto;
  overflow: hidden;
  @media (max-width: ${theme.breakpoints.small}px) {
    height: unset;
    overflow: auto;
    padding: 0 16px;
  }

`

export const ContentContainerHeader = styled.h1`
  letter-spacing: -0.01em;
  font-weight: 800;
  font-size: 36px;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SubHeader = styled.h3`
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.red};
  margin-bottom: 38px;
  font-weight: 400;
`

export const ContentComponentContainer = styled.div`
  margin-left: 100px;
  overflow-y: hidden;
  height: calc(100% - 100px);
  padding-right: 20px;

  @media (max-width: ${theme.breakpoints.extraLarge}px) {
    margin-left: 0px;

  }

  @media (max-width: ${theme.breakpoints.small}px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`

export const DraftLayoutContainer = styled.div`
  display: flex;

  @media (max-width: ${theme.breakpoints.extraLarge}px) {
    width: min(100%, 1280px);
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    display: block;
  }
`