import {useUserTop} from "./uerUserTop";
import logout from './../../../../assets/icon/logout.svg'
import userProfile from './../../../../assets/icon/userProfile.svg'
import {ButtonProfile, ButtonsContainer, UserName, UserTopContainer} from "./UserTop.styled";
import {FC} from "react";


interface IProps {
  isProfile: boolean;
}

export const UserTop: FC<IProps> = ({isProfile}) => {

  const {isActiveLink, isActiveProfile, profileHandler, logoutHandler, fullName} = useUserTop(isProfile);

  return (<UserTopContainer isActive={isActiveLink}>
    <UserName>{fullName.slice(0, 35)}{fullName.length >= 35 && '...'}</UserName>
    <ButtonsContainer>
      <ButtonProfile isActive={isActiveProfile} onClick={profileHandler}>
        <img src={userProfile} alt={'user-profile'}/>
      </ButtonProfile>
      <ButtonProfile isActive={true} onClick={logoutHandler}>
        <img src={logout} alt={'logout'}/>
      </ButtonProfile>
    </ButtonsContainer>
  </UserTopContainer>)
}
