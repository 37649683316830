import React, { FC } from 'react';
import { ContentModal } from '../../ContentModal';
import { BinIcon } from '../../modalIcons/BinIcon';


interface IProps {
  handleYes: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  handleNo: () => void;
  textAdded: string;
  additionalText?: string;
  width?: string;
}

export const DeleteModal: FC<IProps> = ({ handleYes, handleNo, textAdded, additionalText, width }) => {
  return <ContentModal
    width={width}
    iconType={<BinIcon />}
    handleYes={handleYes}
    handleNo={handleNo}
    text={<p style={{ textAlign: 'center' }}>Czy jesteś pewny, że chcesz {additionalText} <b> usunąć</b> {textAdded}?
    </p>}
  />;
};