import { INavLink } from '../../../../interfaces/common/link';
import { AdminLink, generateLink } from '../../../../utils';
import { RoleCategory, RoleFunction } from '../../../../constants/projectUsers';

const allLinks = {
  cases: {
    text: 'opisy przypadków',
    link: generateLink(RoleCategory.ADMIN, AdminLink.CASES)
  },
  workContracts: {
    text: 'Umowy o dzieło',
    link: generateLink(RoleCategory.ADMIN, AdminLink.WORK_CONTRACTS)
  },
  companyContracts: {
    text: 'Umowy o współpracy',
    link: generateLink(RoleCategory.ADMIN, AdminLink.COMPANY_CONTRACTS)
  },
  invoices: {
    text: 'Faktury',
    link: generateLink(RoleCategory.ADMIN, AdminLink.INVOICES)
  },
  documents: {
    text: 'Dokumenty',
    link: generateLink(RoleCategory.ADMIN, AdminLink.DOCUMENTS)
  },
  addUser: {
    text: 'dodaj użytkownika',
    link: generateLink(RoleCategory.ADMIN, AdminLink.ADD_USER)
  },
  users: {
    text: 'użytkownicy',
    link: generateLink(RoleCategory.ADMIN, AdminLink.USERS)
  },
  publishingUsers: {
    text: 'użytkownicy publikujący',
    link: generateLink(RoleCategory.ADMIN, AdminLink.PUBLISHING_USERS)
  },
  roles: {
    text: 'role',
    link: generateLink(RoleCategory.ADMIN, AdminLink.ROLES)
  },
  tutorial: {
    text: 'samouczek',
    link: generateLink(RoleCategory.ADMIN, AdminLink.TUTORIAL)
  },
  areas: {
    text: 'obszary terapeutyczne',
    link: generateLink(RoleCategory.ADMIN, AdminLink.AREAS)
  },
  statistics: {
    text: 'statystyka',
    link: generateLink(RoleCategory.ADMIN, AdminLink.STATISTICS) + '/general'
  }
};
export const generateNavbar = (roleFunction: RoleFunction) => {

  let links: INavLink[] = [];

  switch (roleFunction) {
    case RoleFunction.ROLE_ADMIN:
      links = [
        allLinks.addUser, allLinks.users, allLinks.roles, allLinks.areas, allLinks.statistics
      ];
      break;
    case RoleFunction.ROLE_BOOKKEEPER:
      links = [
        allLinks.workContracts, allLinks.companyContracts, allLinks.invoices, allLinks.documents, allLinks.publishingUsers, allLinks.statistics
      ];
      break;
    case RoleFunction.ROLE_ACCEPTING:
      links = [
        allLinks.cases, allLinks.publishingUsers, allLinks.tutorial
      ];
      break;
    case RoleFunction.ROLE_BOOKKEPPING:
      links = [
        allLinks.documents
      ];
      break;
  }

  return links;
};