import doctorImg from "../../../assets/img/manDoctorHospital.png";
import { Paragraph } from "./UnderConstruction.styled";
import { Page404Layer } from "../../shared/layers/page404/Page404Layer";
import React from "react";
import { ReactComponent as UnderConstructionIcon } from './../../../assets/icon/underConstruction.svg'

export const UnderConstruction = () => {
  return (
    <Page404Layer backgroundImg={doctorImg}>
      <>
        <UnderConstructionIcon/>
        <Paragraph>Przepraszamy, trwają prace modernizacyjne. <br/>Zapraszamy później</Paragraph>
      </>
    </Page404Layer>
  )
}