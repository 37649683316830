import { useHistoryItems } from './useHistoryItems';
import { observer } from 'mobx-react-lite';
import { AdminLayout } from '../../../../shared/admin/layout/AdminLayout';
import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import React from 'react';

export const HistoryItems = observer(() => {

  const {
    historyIsNotNull,
    cellsSwitcher,
    tableDataRows,
    headCells,
    addedContent
  } = useHistoryItems();

  return <AdminLayout>
    {historyIsNotNull &&
      <CustomTable searchBarAddedContent={addedContent} cellsSwitcher={cellsSwitcher} isSearchBar={true}
                   tableDataRows={tableDataRows} isDataDownloaded={true}
                   headCells={headCells}
      />}
  </AdminLayout>;
});