import styled from 'styled-components';

interface IActionButton {
  background?: string;
  $rotate?: boolean;
  width: number;
  isDisabled?: boolean;
  border?: string;
  color?: string;
  fill?: string;
  isNotVisible?: boolean;
}

export const ActionButtonStyled = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ActionButtonContainer = styled.div<IActionButton>`
  height: 49px;
  padding: 0;
  min-width: ${props => props.width}px;
  background: ${props => props.background ?? 'white'};
  border: 1px solid ${props => props.border ?? 'white'};
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: ${props => props.color ?? 'white'};
  text-align: center;
  text-transform: uppercase;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  display: ${props => props.isNotVisible ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  transform: ${props => props.$rotate ? `rotate(180deg)` : `()`};
`;