import React from "react";
import {IModalIconProps} from "./ModalIcon.helper";
import {IconContainer} from "./ModalIcon.styled";
import {ReactComponent as Money} from "../../../../assets/icon/money.svg";
import theme from "../../../../theme/theme";

interface IProps extends IModalIconProps {
  background?: string;
}

export const MoneyIcon: React.FC<IProps> = ({clickHandler, background = theme.colors.red}) => {
  return <IconContainer background={background} onClick={clickHandler}><Money/></IconContainer>;
};
