import React from 'react'
import { useRegisterViewingForm } from './useRegisterViewingForm.vm'
import { RegisterForm } from "../../form/RegisterForm";
import { formDataViewing } from "./registerFormData";

interface IProps {
  titleColor: string
  labelColor: string
  buttonBcgColor: string;
  buttonTextColor: string
}

export const RegisterFormViewingComponent: React.FC<IProps> = ({
                                                                 titleColor,
                                                                 labelColor,
                                                                 buttonBcgColor,
                                                                 buttonTextColor
                                                               }) => {
  const {
    formik,
    isChecked,
    setChecked,
    isErrorCheckbox,
    message,
    confirmForm,
    isButtonActive
  } = useRegisterViewingForm()

  return <RegisterForm formik={formik}
                       isChecked={isChecked}
                       isErrorCheckbox={isErrorCheckbox}
                       setChecked={setChecked}
                       confirmForm={confirmForm}
                       isButtonActive={isButtonActive}
                       message={message}
                       titleColor={titleColor}
                       labelColor={labelColor}
                       buttonTextColor={buttonTextColor}
                       buttonBcgColor={buttonBcgColor}
                       formData={formDataViewing}

  />
}
