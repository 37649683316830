import * as yup from 'yup';

export const generateValidationSchemaFourthStage = (value: boolean) => {

  const isSquamousFalse = {
    isAlk: yup
      .boolean()
      .when('isSquamous', {
        is: false,
        then: (schema) => schema.required()
      }),
    isEgfr: yup
      .boolean()
      .when('additionalValues.isSquamous', {
        is: false,
        then: (schema) => schema.required()
      }),
    isRos: yup
      .boolean()
      .when('isSquamous', {
        is: false,
        then: (schema) => schema.required()
      })
  };

  const isSquamousTrue = {
    isAlk: yup
      .boolean()
      .nullable(),
    isEgfr: yup
      .boolean()
      .nullable(),
    isRos: yup
      .boolean()
      .nullable()
  };

  const ngsIsSquamous = yup.string();
  const ngsIsNotSquamous = yup.string().required();

  return yup.object().shape({
    diagnosis: yup.object().shape({
      molecularChanges: yup.object().shape(
        value ? isSquamousTrue : isSquamousFalse
      ),
      ngs: value ? ngsIsSquamous : ngsIsNotSquamous,
      expressionLevel: yup.object().shape({
        level: yup
          .string()
          .required(),
        percent: yup
          .string()
          .when('level', {
            is: 'podano wynik dokładnie oceniając % wybarwionych komórek',
            then: (schema) => schema.required()
          })
      }),
      tnm: yup
        .string()
        .required(),
      metastases: yup.object().shape({
        status: yup
          .string()
          .required(),
        isCns: yup
          .boolean()
          .nullable()
          .when('status', {
            is: 'tak',
            then: (schema) => schema.required()
          }),
        which: yup
          .string()
          .nullable()
          .when('isCns', {
            is: true,
            then: (schema) => schema.required()
          })
      }),
      problems: yup.object().shape({
        list: yup.array().nullable(),
        other: yup.array().nullable()
      }),
      dilo: yup
        .string()
        .required()
    })
  });
};