import {RootStore} from "./rootStore";
import {makeAutoObservable} from "mobx";
import {IRole} from '../interfaces/common/roles';
import {IRoleForm} from '../interfaces/admin/role/roleForm';

export class RolesStore {
  roles: IRole[] | null = null;
  isRolesLoaded: boolean = false;
  roleEdit: IRoleForm | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setRoles(roles: IRole[]) {
    this.roles = roles;
    this.isRolesLoaded = true;
  }

  setRoleEdit(roleEdit: IRoleForm) {
    this.roleEdit = roleEdit;
    this.isRolesLoaded = true;
  }

  clearRoleEdit() {
    this.roleEdit = null;
  }

  clearRoles() {
    this.isRolesLoaded = false;
  }
}
