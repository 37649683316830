import {IBreadcrumbsElement} from "../../../../../../interfaces/user/breadcrumbs";
import {UserLink} from "../../../../../../utils";
import {useEffect} from "react";
import {useContractApi} from "../../../../../../apiHooks";
import {useContractStore} from "../../../../../../store/hooks";

export const useSummaryFormContract = () => {

  const {getUserContractData} = useContractApi()
  const contractStore = useContractStore()
  const {userContractData} = contractStore
  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.PROFILE,
      name: "Profil użytkownika"
    },
    {
      name: "Dane rozliczeniowe"
    }
  ];

  useEffect(() => {
    void getUserContractData()
  }, [getUserContractData])

  return {
    breadcrumbs,
    userContractData
  }
}