import React from 'react';
import { observer } from 'mobx-react-lite';

import { useAreas } from './useAreas';
import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { CustomTable } from '../../../shared/customTables/customTable/CustomTable';
import { Modal } from '../../../shared/modal/Modal';
import { ModalType } from '../../../../constants/modalType';
import { DeleteModal } from '../../../shared/modal/sharedModals/deleteModal/DeleteModal';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { Button } from '../../../shared/button/Button';
import { GridTop } from './Areas.styled';
import theme from '../../../../theme/theme';
import { ReactComponent as Plus } from '../../../../assets/icon/plus.svg';
import { CancellationModal } from '../../../shared/modal/sharedModals/cancellationModal/CancellationModal';

export const Areas = observer(() => {
  const {
    areasIsNotNull,
    tableDataRows,
    headCells,
    generateActions,
    addHandler,
    deleteHandlerYes,
    modalType,
    isModalOpened,
    closeModal
  } = useAreas();

  return (
    <AdminLayout>

      {areasIsNotNull &&
        <CustomTable isSearchBar={true} tableDataRows={tableDataRows}
                     isDataDownloaded={true}
                     headCells={headCells} actions={generateActions()}
                     searchBarAddedContent={
                       <GridTop container display='flex' justifyContent='center'>
                         <Button type='button'
                                 clickHandler={addHandler}
                                 text={'Dodaj obszar terapeutyczny'}
                                 backgroundColor={theme.colors.dark1}
                                 color={theme.colors.white}
                                 size={'small'}
                                 maxWidth={'320px'} icon={<Plus />} />
                       </GridTop>} />}
      {isModalOpened &&
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.DELETE_MODAL &&
            <DeleteModal handleNo={() => closeModal()} handleYes={deleteHandlerYes} width={'565px'}
                         additionalText={'trwale'}
                         textAdded={'ten obszar terapeutyczny'} />
          }
          {modalType === ModalType.DELETE_CONFIRMATION_MODAL &&
            <ConfirmationModal text={<p>Obszar terapeutyczny <b>usunięto poprawnie</b>.</p>} />
          }
          {
            modalType === ModalType.DELETE_ERROR_MODAL &&
            <CancellationModal text={<p>Nie można usunąć obszaru, ponieważ są do niego przypisani użytkownicy</p>} />
          }
        </Modal>
      }
    </AdminLayout>
  );
});