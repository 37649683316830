import React from 'react';
import { Input } from '../../form/input/Input';
import { SearchCustom, TopActionsContainer } from './TopActions.styled';
import { Grid } from '@mui/material';

type SearchEvent = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  isSearchBar?: boolean;
  filterValue: string;
  filterChangeHandler: (event: SearchEvent) => void;
  searchBarAddedContent?: JSX.Element;
  afterSearchFlexRowContent?: JSX.Element;
}

export const TopActions: React.FC<IProps> = ({
                                               isSearchBar,
                                               filterValue,
                                               filterChangeHandler,
                                               searchBarAddedContent,
                                               afterSearchFlexRowContent
                                             }) => {

  return (
    <TopActionsContainer
      className={'search-bar-container'}
      container
      justifyContent='space-between'
      alignItems='center'
      flexWrap='nowrap'
    >
      <Grid item xs={12} display={'flex'} gap={1} flexDirection={'column'}>
        {searchBarAddedContent}
      </Grid>
      {isSearchBar && <Grid sx={{ position: 'relative' }}><Input width={searchBarAddedContent ? 'auto' : '100%'}
                                                                 placeholder={'Wpisz kogo szukasz...'}
                                                                 type='text' name={'search'}
                                                                 value={filterValue}
                                                                 onChange={e => {
                                                                   const event = e as SearchEvent;
                                                                   filterChangeHandler(event);
                                                                 }} />
        <SearchCustom /></Grid>}
      {afterSearchFlexRowContent}
    </TopActionsContainer>
  );
};
