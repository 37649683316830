import styled from "styled-components";
import theme from "../../../../theme/theme";

export const ImageMain = styled.img`
  width: 720px;
  height: auto;
  max-height: 80%;

  @media (max-width: ${theme.breakpoints.small}px) {
    margin: auto 16px;
    width: 100%;
  }
  
`