import { useEffect } from 'react';
import { IDoctorThirdStageLung } from '../../../../../../interfaces/user/draftCaseLung/thirdStage';

export const useAccordionView = (doctor: IDoctorThirdStageLung, isModalOpened: boolean, setExpandedId?: (id?: number | null) => void) => {
  const date = new Date(doctor.date as string);

  const formatter = new Intl.DateTimeFormat('pl-PL', {
    month: '2-digit',
    year: 'numeric'
  });

  const dateFormatted = formatter.format(date);

  const expandedDoctorChange = () => {
    if (setExpandedId && doctor && doctor.id) {
      setExpandedId(doctor.id);
    }
  };


  useEffect(() => {
    if (setExpandedId && isModalOpened) {
      setExpandedId(null);
    }
  }, [isModalOpened, setExpandedId]);

  return {
    dateFormatted,
    expandedDoctorChange
  };
};