type Words = [string, string, string, string];

export const changeDescriptionWord = (length: number, words: Words, isLength: boolean = true) => {
  const [wordFirst, wordSecond, wordThird, wordFourth] = words;
  if (length === 0) {
    return isLength ? `${length} ${wordFirst}` : wordFirst;
  } else if (length === 1) {
    return isLength ? `${length} ${wordSecond}` : wordSecond;
  } else if (length >= 2 && length <= 4) {
    return isLength ? `${length} ${wordThird}` : wordThird;
  } else {
    return isLength ? `${length} ${wordFourth}` : wordFourth;
  }
}
export const beTerms: Words = ['jest', 'jest', 'są', 'są'];

export const acceptedTerms: Words = ['zostało zaakceptowanych', 'został zaakceptowany', 'zostały zaakceptowane', 'zostało zaakceptowanych'];

export const descriptionTerms: Words = ['opisów', 'opis', 'opisy', 'opisów'];

export const rejectionTerms: Words = ['opisów przypadków jest "do edycji"', 'opis przypadku jest "do edycji"', 'opisy przypadków są "do edycji"', 'opisów przypadków są "do edycji"'];
