import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Chip, Grid } from '@mui/material';
import { useAllUsers } from './useAllUsers';
import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { CustomTable } from '../../../shared/customTables/customTable/CustomTable';
import { DeleteModal } from '../../../shared/modal/sharedModals/deleteModal/DeleteModal';
import { ModalType } from '../../../../constants/modalType';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { GenerateReportContainer } from '../../../shared/customTables/customTable/CustomTable.styled';
import { MultipleSelect } from '../../../shared/form/multipleSelect/MultipleSelect';
import { UserForm } from '../../../shared/admin/userForm/UserForm';
import { AdminLink, generateLink } from '../../../../utils';
import { RoleCategory } from '../../../../constants/projectUsers';
import { Modal } from '../../../shared/modal/Modal';
import { Number, TopCounter, TopCounterParagraph } from './AllUsers.styled';
import theme from '../../../../theme/theme';
import { ReactComponent as PeopleIcon } from '../../../../assets/icon/people.svg';
import { ReactComponent as Filters } from '../../../../assets/icon/filters.svg';
import { ReactComponent as Close } from '../../../../assets/icon/close.svg';

export const AllUsers = observer(() => {
  const {
    publishingUsersListIsNotNull,
    count,
    headCells,
    generateActions,
    tableDataRows,
    modalType,
    isModalOpened,
    editHandler,
    deleteHandlerYes,
    userEdit,
    closeModal,
    optionsAreas,
    selectedAreas,
    handleChange,
    cellsSwitcher,
    optionsRoles,
    selectedUsers,
    handleChangeRole,
    chipsAreas,
    chipsRoles,
    openFilters,
    handleOpenFilters,
    amountOfChips,
    handleReset,
    handleDeleteArea,
    handleDeleteRole
  } = useAllUsers();

  return (
    <AdminLayout>
      <TopCounter>
        <PeopleIcon />
        <TopCounterParagraph $isBold={true}>Obecnie w serwisie jest: </TopCounterParagraph>
        <Number>{count}</Number>
        <TopCounterParagraph>Zarejestrowanych użytkowników</TopCounterParagraph>
      </TopCounter>
      {publishingUsersListIsNotNull &&
        <CustomTable
          afterSearchFlexRowContent={<GenerateReportContainer
            margin={'0 0 16px 16px'}
            svgPath={amountOfChips ? theme.colors.white : theme.colors.dark1}
            bcgColor={amountOfChips ? theme.colors.turquoise1 : theme.colors.white}
            borderColor={amountOfChips ? theme.colors.turquoise1 : 'rgb(224, 224, 224)'}
            onClick={() => handleOpenFilters()}
          >
            <Filters />
          </GenerateReportContainer>}
          isSearchBar={true} tableDataRows={tableDataRows} isDataDownloaded={true} headCells={headCells}
          cellsSwitcher={cellsSwitcher}
          actions={generateActions()}
          contentAfterSearch={
            <>{openFilters &&
              <Grid container display={'flex'} flexDirection={'column'}>
                <Grid display={'flex'} gap={2}>{optionsAreas &&
                  <MultipleSelect
                    subtitle={'Obszar'} key={'area'}
                    selectedValues={selectedAreas}
                    elementName={'area'}
                    subtitleLength={selectedAreas.length}
                    options={optionsAreas}
                    handleChange={handleChange} />}
                  {optionsRoles &&
                    <MultipleSelect
                      subtitleLength={selectedUsers.length}
                      subtitle={'Rola'} key={'role'}
                      selectedValues={selectedUsers}
                      elementName={'role'}
                      options={optionsRoles}
                      handleChange={handleChangeRole} />}
                </Grid>
                <Grid display={'flex'} flexWrap={'wrap'} gap={2}>{chipsAreas.map(el => (
                  <Chip key={el}
                        label={el}
                        sx={chipStyle}
                        onDelete={() => handleDeleteArea(el)}
                        deleteIcon={<Close />}
                        variant='outlined' />))}
                  {chipsRoles.map(el => (
                    <Chip key={el}
                          label={el}
                          sx={chipStyle}
                          onDelete={() => handleDeleteRole(el)}
                          deleteIcon={<Close />}
                          variant='outlined' />))}
                  {amountOfChips ?
                    <Button variant='text' onClick={handleReset}>Usuń filtry</Button> : null}
                </Grid>
              </Grid>
            }</>
          } />
      }
      {isModalOpened &&
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.DELETE_MODAL &&
            <DeleteModal handleNo={() => closeModal()} handleYes={deleteHandlerYes}
                         textAdded={'tego użytkownika'} />
          }
          {modalType === ModalType.DELETE_CONFIRMATION_MODAL &&
            <ConfirmationModal text={<p>Użytkownik został <b>usunięty poprawnie</b>.</p>} />
          }
          {modalType === ModalType.EDIT_MODAL &&
            <UserForm handleNo={() => closeModal()} isEdit={true}
                      link={generateLink(RoleCategory.ADMIN, AdminLink.USERS)}
                      userForm={userEdit} actionHandler={editHandler} />
          }
          {modalType === ModalType.EDIT_CONFIRMATION_MODAL &&
            <ConfirmationModal text={<p>Użytkownik został <b>edytowany poprawnie</b>.</p>} />
          }
        </Modal>
      }
    </AdminLayout>
  );
});

const chipStyle = {
  fontSize: '16px',
  color: theme.colors.dark1,
  backgroundColor: theme.colors.lightBlue,
  '&.Mui-disabled': {
    backgroundColor: theme.colors.gray5,
    opacity: 1
  },
  '&.MuiChip-label': {
    paddingRight: '18px'
  },
  'svg': {
    margin: '0 12px 0 -6px !important'
  },
  'path': {
    fill: theme.colors.dark1
  }
};