import { ICasesTable, ISituations } from '../admin/statistics/statistics';

export interface IHeadCell {
  id: string;
  label?: string;
  header?: JSX.Element;
  numeric?: boolean;
  custom?: () => JSX.Element;
  isHidden?: boolean;
  isBold?: boolean;
}

export enum TableSortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface ITableConfig {
  orderBy?: string;
  order?: TableSortOrder;
  page?: number;
  rowsPerPage?: number;
  searchValue?: string;
  selected?: string[];
  selectedToState?: (selectedArray: string[]) => void;
}

export interface ITableData {
  [key: string]: string | number | boolean | JSX.Element | ICasesTable[] | ISituations[];
}


export interface IAction {
  key?: string;
  actionHandler?: (id: number | string, userContract?: number, type?: string, customId?: string, userId?: number) => void;
  background?: ((id: number) => string) | string;
  actionElement?: ((id: number, customId?: string) => JSX.Element | string | null) | JSX.Element | string;
  width: number;
  isDisabled?: ((id: number) => boolean) | boolean;
  custom?: (id: number | string, userContract?: number, type?: string, customId?: string, userId?: number) => JSX.Element | null;
  color?: string;
  border?: string;
  fill?: string;
  isNotVisible?: boolean;
  userContract?: number;
  actionId?: string;
}