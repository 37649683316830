import React, { useEffect, useState } from 'react';
import { IAction, IHeadCell } from '../../../../interfaces/common/table';
import { createTableRows } from '../../../shared/customTables/utils/createTableRows';
import { useModal } from '../../../shared/modal/useModal.vm';
import { useCasesStore } from '../../../../store/hooks';
import { useCasesApi } from '../../../../apiHooks';
import { TableCellData } from '../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { AdminLink, generateAreaLink, generateLink, SplitData, splitData, UserLink } from '../../../../utils';
import { CellTime } from '../../../shared/customTables/cells/cellTime/CellTime';
import { ReactComponent as PublicIcon } from '../../../../assets/icon/public.svg';
import { ReactComponent as UnpublicIcon } from '../../../../assets/icon/unpublic.svg';
import { ReactComponent as HistoryIcon } from '../../../../assets/icon/history.svg';
import { ReactComponent as ViewLensIcon } from '../../../../assets/icon/viewLens.svg';
import { ReactComponent as CheckboxCheckIcon } from '../../../../assets/icon/checkboxCheck.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icon/close.svg';
import { ReactComponent as PaperIcon } from '../../../../assets/icon/paper.svg';
import theme from '../../../../theme/theme';
import { useNavigate } from 'react-router-dom';
import { RoleCategory } from '../../../../constants/projectUsers';
import { CaseStatus } from '../../../../interfaces/user/draftCaseNeuro/draft';
import { ActionButton } from '../../../shared/customTables/button/ActionButton';
import { ButtonContainer } from './Cases.styled';
import { ModalType } from '../../../../constants/modalType';
import { Button } from '../../../shared/button/Button';
import { StatusText } from '../../../shared/admin/status/Status.styled';
import { switchHistoryLink } from '../../../../utils/switchArea';

type SelectedStatus = CaseStatus | 'all';

export const useCases = () => {
  const [actionId, setActionId] = useState<number | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatus>('all');
  const navigate = useNavigate();
  const casesStore = useCasesStore();
  const { cases, isCasesLoaded } = casesStore;
  const [isPublic, setIsPublic] = useState(false);
  const { getCases, acceptCase, rejectCase } = useCasesApi();
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();

  useEffect(() => {
    if (!isCasesLoaded) {
      if (cases === null) {
        void getCases(true);
      } else {
        void getCases(false);
      }
    }
  }, [getCases, cases, isCasesLoaded]);

  useEffect(() => {
    return () => {
      casesStore.resetCases();
    };
  }, [casesStore]);

  const generatePublicationStatusIcon = (id: number, customId?: string) => {
    if (cases && customId) {
      const element = cases.find((element) => element.customId === customId);
      if (element && element.isPublic !== null && element.status !== CaseStatus.SENT) {
        return element.isPublic ? <PublicIcon /> : <UnpublicIcon />;
      }
    }
    return null;
  };

  const generateHistoryBackground = (id: number, customId?: string) => {
    if (cases && customId) {
      const element = cases.find((element) => element.customId === customId);
      if (element) {
        return element.countComments > 0 ? 'white' : 'transparent';
      }
    }
    return 'transparent';
  };

  const generateHistoryIcon = (id: number, customId?: string) => {
    if (cases && customId) {
      const element = cases.find((element) => element.customId === customId);
      if (element) {
        return element.countComments > 0 ? <HistoryIcon /> : null;
      }
    }
    return null;
  };

  const generateHistoryDisabled = (id: number, customId?: string) => {
    if (cases && customId) {
      const element = cases.find((element) => element.customId === customId);
      if (element) {
        return !(element.countComments > 0);
      }
    }
    return false;
  };

  const navigateHistoryHandler = (id: number | string, userContract?: number, type?: string, customId?: string) => {
    if (!generateHistoryDisabled(+id, customId)) {
      if (type) {
        const link = type && switchHistoryLink(type);
        navigate(generateLink(RoleCategory.ADMIN, link as AdminLink, id));
      }
    }
  };


  const viewHandler = (id: number | string, userContract?: number, type?: string, customId?: string) => {
    const element = cases && cases.find((element) => element.customId === customId);

    if (cases) {
      if (element && element.type) {
        navigate(generateLink(RoleCategory.USER, generateAreaLink(element.type, 'view') as UserLink, +id));
      }
    }
  };

  const acceptCaseModalHandler = async (id: number | string, userContract?: number, type?: string, customId?: string) => {
    setActionId(+id);
    const element = cases && cases.find((element) => element.customId === customId);

    if (element && element.type) {
      setType(element.type);
    }
    handleModal(ModalType.ACCEPT_CASE_MODAL);
  };

  const acceptCaseHandler = async (isPublic: boolean) => {
    setIsPublic(isPublic);
    if (actionId !== null && type) {
      await acceptCase(actionId, isPublic, type);
      await getCases(false);
    }
    setActionId(null);
    setType(null);
    handleModal(ModalType.ACCEPT_CASE_CONFIRMATION_MODAL);
  };

  const rejectCaseModalHandler = (id: number | string, userContract?: number, type?: string, customId?: string) => {
    setActionId(+id);
    const element = cases && cases.find((element) => element.customId === customId);


    if (element && element.type) {
      setType(element.type);
    }
    handleModal(ModalType.REJECT_CASE_MODAL);
  };

  const rejectCaseHandler = async (comment: string) => {
    if (actionId !== null && type) {
      await rejectCase(actionId, comment, type);
      await getCases();
    }
    setActionId(null);
    setType(null);
    handleModal(ModalType.REJECT_CASE_CONFIRMATION_MODAL);
  };

  const generateStatusActions = (id: number | string, userContract?: number, type?: string, customId?: string) => {
    if (cases) {
      const element = cases.find((element) => element.customId === customId);
      if (element) {
        switch (element.status) {
          case CaseStatus.REJECT:
            return <StatusText color={theme.colors.red}><span>do edycji</span></StatusText>;
          case CaseStatus.ACCEPT:
            return <StatusText color={theme.colors.turquoise2}><span>zaakceptowany</span></StatusText>;
          case CaseStatus.SENT:
            return (<>
              <ActionButton customId={customId} type={type} key={'accept'} id={id as number}
                            actionHandler={acceptCaseModalHandler}
                            background={theme.colors.turquoise2}
                            width={52} actionElement={<CheckboxCheckIcon />} />
              <ActionButton type={type} key={'reject'} id={id as number} actionHandler={rejectCaseModalHandler}
                            background={theme.colors.red}
                            width={52}
                            customId={customId}
                            actionElement={<CloseIcon />} />
            </>);
        }
      }
    }
    return null;
  };

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: 'send',
        background: 'white',
        actionElement: generatePublicationStatusIcon,
        width: 52,
        isDisabled: true
      },
      {
        key: 'history',
        background: generateHistoryBackground,
        actionElement: generateHistoryIcon,
        actionHandler: navigateHistoryHandler,
        width: 52,
        isDisabled: generateHistoryDisabled
      },
      {
        key: 'view',
        background: theme.colors.darkBlue,
        actionHandler: viewHandler,
        actionElement: <ViewLensIcon />,
        width: 52
      },
      {
        key: 'status',
        custom: generateStatusActions,
        width: 104
      }
    ];
    return actions;
  };

  const headCells: IHeadCell[] = [
    { id: 'title', numeric: false, label: 'Nazwa opisu przypadku' },
    { id: 'customId', numeric: false, label: 'Id opisu przypadku' },
    { id: 'doctor', numeric: false, label: 'Lekarz' },
    { id: 'sentToAcceptAt', numeric: false, label: 'Data przesłania do akceptacji' },
    { id: 'type', numeric: false, label: '' }
  ];

  const cellsSwitcher = (keyName: string, value: string, onClick?: () => void) => {
    const specialValue: SplitData = keyName === 'sentToAcceptAt' ? splitData(value.toString()) : ['-', ''];

    switch (keyName) {
      case 'id':
      case 'type':
        return null;
      case 'sentToAcceptAt':
        return <TableCellData><CellTime specialValue={specialValue} /></TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const changeStatus = (selectedStatus: SelectedStatus) => {
    setSelectedStatus(prevState => {
      if (prevState === selectedStatus) {
        return 'all';
      }
      return selectedStatus;
    });
  };

  const addedContent = <ButtonContainer>
    <Button clickHandler={() => changeStatus(CaseStatus.SENT)}
            text={'do rozpatrzenia'}
            backgroundColor={selectedStatus === CaseStatus.SENT ? theme.colors.gray3 : theme.colors.blue3}
            color={'white'}
            size={'tiny'}
            maxWidth={'200px'}
            icon={<PaperIcon />} />
    <Button clickHandler={() => changeStatus(CaseStatus.ACCEPT)}
            text={'zaakceptowane'}
            backgroundColor={selectedStatus === CaseStatus.ACCEPT ? theme.colors.gray3 : theme.colors.dark1}
            color={'white'}
            size={'tiny'}
            maxWidth={'200px'}
            icon={<CheckboxCheckIcon />} />
    <Button clickHandler={() => changeStatus(CaseStatus.REJECT)}
            text={'do edycji'}
            backgroundColor={selectedStatus === CaseStatus.REJECT ? theme.colors.gray3 : theme.colors.red}
            color={'white'}
            size={'tiny'}
            maxWidth={'200px'}
            icon={<CloseIcon />} />
  </ButtonContainer>;


  const filteredData = cases && selectedStatus !== 'all' ? cases.filter(caseElement => caseElement.status === selectedStatus) : cases;
  const tableDataRows = createTableRows(filteredData ?? [], headCells, false);


  return {
    casesIsNotNull: cases !== null,
    cellsSwitcher,
    generateActions,
    acceptCaseHandler,
    rejectCaseHandler,
    addedContent,
    headCells,
    tableDataRows,
    modalType,
    isModalOpened,
    closeModal,
    isPublic
  };
};
