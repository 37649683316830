import styled from 'styled-components';
import {Grid} from '@mui/material';


export const ParagraphFirst = styled.p`
  margin-bottom: 20px;
`;

export const ButtonContainer = styled(Grid)`
  display: flex;
  width: 100%;
  gap: 16px;
  padding-right: 20px;

  & svg {
    margin-top: 5px;
    width: 20px;
  }
`;