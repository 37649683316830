import {GlobalLoader} from '../../../shared/appLayer/globalLoader/GlobalLoader';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {useAuthStore} from '../../../../store/hooks';

export const Home = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (authStore.navigationLink) {
      navigate(authStore.navigationLink);
      authStore.clearNavigationLink();
    }
  }, [navigate, authStore])

  return <GlobalLoader/>
}