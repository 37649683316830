export enum DoctorType {
  FAMILY = 'family',
  PULMONOLOGY = 'pulmonology',
  INTERNIST = 'internist',
  SURGEON = 'surgeon',
  ONCOLOGIST = 'oncologist',
  MDT = 'mdt'
}

export type IDoctorTypes = {
  [key in DoctorType]: string;
};

export interface IDoctorVisitCommon {
  id?: number;
  date?: string;
  type?: DoctorType;
}

export type YesNoNotSpecifiedOptions = 'yes' | 'no' | 'not_specified';
export type YesNoNotSpecifiedFormOptions =
  'tak'
  | 'nie'
  | 'nie udało się oznaczyć - konieczne było powtórzenie badania';

export interface IDoctorFieldSetsVariantFirst {
  rtgKlp?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
  tkKlp?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
  tkAbdomen?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
  tkPelvis?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
  mri?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
  petCt?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
  biopsy?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
  ebus?: IFieldSetDoctorFormVariantFirstResponse | IFieldSetDoctorFormVariantFirst;
}

export interface IDoctorFieldSetsVariantSecond {
  histPath?: IFieldSetDoctorFormVariantSecondResponse | IFieldSetDoctorFormVariantSecond;
  egfr?: IFieldSetDoctorFormVariantSecondResponse | IFieldSetDoctorFormVariantSecond;
  alk?: IFieldSetDoctorFormVariantSecondResponse | IFieldSetDoctorFormVariantSecond;
  ros?: IFieldSetDoctorFormVariantSecondResponse | IFieldSetDoctorFormVariantSecond;
}

export interface IDoctorFieldSets extends IDoctorFieldSetsVariantFirst, IDoctorFieldSetsVariantSecond, IDoctorVisitCommon {
}

export interface IDoctorThirdStageLung extends IDoctorFieldSets {
  id?: number;
  type?: DoctorType;
}

export interface IThirdStageLung {
  doctors: IDoctorThirdStageLung[];
}

export interface IFieldSetDoctorFormVarianCommon {
  isCommissioned: string | null;
  amount: number | string;
}

export interface IFieldSetDoctorFormVariantFirst extends IFieldSetDoctorFormVarianCommon {
  isDone: string;
}

export interface IFieldSetDoctorFormVariantSecond extends IFieldSetDoctorFormVarianCommon {
  done: YesNoNotSpecifiedFormOptions | '';
}


export interface IFieldSetDoctorFormVarianCommonResponse {
  isCommissioned: boolean | null;
  amount: number | null;
}

export interface IFieldSetDoctorFormVariantFirstResponse extends IFieldSetDoctorFormVarianCommonResponse {
  isDone: boolean | null;
}

export interface IFieldSetDoctorFormVariantSecondResponse extends IFieldSetDoctorFormVarianCommonResponse {
  done: YesNoNotSpecifiedFormOptions | null;
}

