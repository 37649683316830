import {MouseEvent, useCallback, useEffect, useState} from "react";
import {ValidationMessages} from '../../../../../constants/validationMessages'
import {FormikValues} from "formik";

export const useRegisterForm = (formik: FormikValues) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [message, setMessage] = useState('')

  const setChecked = (checked: boolean) => {
    setIsChecked(checked)
  }

  const containsOnlyRepeatPasswordKey = (obj: { [key: string]: string }) => {
    const keys = Object.keys(obj);
    return keys.length === 1 && keys[0] === "repeatPassword";
  }

  const createErrorMessage = useCallback(() => {
    if (containsOnlyRepeatPasswordKey(formik.errors)) {
      return ValidationMessages.INVALID_PASSWORD;
    } else if (Object.keys(formik.errors).length >= 1) {
      return ValidationMessages.INVALID_REGISTERFORM;
    } else if (!isChecked) {
      return ValidationMessages.INVALID_CHECKBOX;
    }
    return ""
  }, [formik.errors, isChecked])

  useEffect(() => {
    if (formik.submitCount !== 0) {
      setMessage(createErrorMessage());
      const isValid = Object.keys(formik.errors).length === 0 && isChecked;
      setIsButtonActive(isValid);
    }
  }, [createErrorMessage, formik.errors, formik.submitCount, isChecked])

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  const isErrorCheckbox = formik.submitCount > 0 && !isChecked
  return {isChecked, setChecked, isErrorCheckbox, message, confirmForm, isButtonActive, setMessage}
}
  
  

