import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { IContractData, IContractDocument, IUserContractData } from '../interfaces/user/contract/contract';
import {
  ICompanyContract,
  IDocuments,
  IInvoice,
  IUserInfo,
  IWorkContract
} from '../interfaces/admin/contract/contract';

export class ContractStore {
  contractData: IContractData | null = null;
  userContractData: IUserContractData | null = null;
  contractDocuments: IContractDocument | null = null;

  //Admin
  companyContracts: ICompanyContract[] | null = null;
  workContracts: IWorkContract[] | null = null;
  invoiceDocuments: IInvoice[] | null = null;
  documents: IDocuments[] | null = null;

  isCompanyContractsLoaded: boolean = false;
  isWorkContractsLoaded: boolean = false;
  isInvoicesLoaded: boolean = false;
  isDocumentsLoaded: boolean = false;
  userInfo: IUserInfo | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setDocuments(documents: IDocuments[]) {
    this.documents = documents;
    this.isDocumentsLoaded = true;
  }

  setUserInfo(user: IUserInfo) {
    this.userInfo = user;
  }

  setContractData(contractData: IContractData) {
    this.contractData = contractData;
  }

  setUserContractData(userContractData: IUserContractData) {
    this.userContractData = userContractData;
  }

  setContractDocuments(contractDocuments: IContractDocument) {
    this.contractDocuments = contractDocuments;
  }

  setInvoicesDocuments(invoiceDocuments: IInvoice[]) {
    this.invoiceDocuments = invoiceDocuments;
    this.isInvoicesLoaded = true;
  }

  setCompanyContracts(companyContracts: ICompanyContract[]) {
    this.companyContracts = companyContracts.sort((a, b) => +a.accepted - +b.accepted);
    this.isCompanyContractsLoaded = true;
  }

  resetCompanyContracts() {
    this.isCompanyContractsLoaded = false;
  }

  setWorkContracts(workContracts: IWorkContract[]) {
    this.workContracts = workContracts.sort((a, b) => +a.accepted - +b.accepted);
    this.isWorkContractsLoaded = true;
  }

  resetWorkContracts() {
    this.isWorkContractsLoaded = false;
  }

  resetDocuments() {
    this.isDocumentsLoaded = false;
  }
}
