import {observer} from 'mobx-react-lite';

import {AccordionCustom} from './accordion/AccordionCustom';
import {Button} from '../../../../../shared/button/Button';
import {ModalType} from '../../../../../../constants/modalType';
import {ModalAddIncident} from './modalAddIncident/ModalAddIncident';
import {useThirdStage} from './useThirdStage';
import {DeleteModal} from '../../../../../shared/modal/sharedModals/deleteModal/DeleteModal';
import {ConfirmationModal} from '../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import {Modal} from '../../../../../shared/modal/Modal';
import { ModalAdd } from './modalAdd/ModalAdd';

import theme from '../../../../../../theme/theme';
import plus from '../../../../../../assets/icon/beigePlus.svg';
import {ButtonContainer, ContainerThirdStage} from './ThirdStage.styled';

export const ThirdStage = observer(() => {
  const {
    drugs,
    isModalOpened,
    closeModal,
    modalType,
    idDrug,
    incident,
    setDrugModalOpened,
    addIncident,
    editIncident,
    deleteHandler,
    deleteHandlerYes,
    expandedId,
    onChangeHandler
  } = useThirdStage();

  return (
    <ContainerThirdStage>
      {
        drugs && drugs.length > 0 && drugs.map((drug, index) => (
          <AccordionCustom key={drug.id} isModalOpened={isModalOpened}
                           deleteDrug={deleteHandler}
                           editIncident={editIncident}
                           addIncident={addIncident}
                           setExpandedId={onChangeHandler}
                           expandedId={+expandedId}
                           expanded={expandedId === +drug.id!}
                           number={index + 1} drug={drug}/>
        ))
      }
      {isModalOpened && <Modal position={'static'} clickHandler={() => closeModal()}>
        {modalType === ModalType.ADD_DRUG && <ModalAdd handleCloseDrugModal={closeModal}/>}
        {(modalType === ModalType.ADD_INCIDENT || modalType === ModalType.EDIT_INCIDENT) && idDrug &&
            <ModalAddIncident idDrug={idDrug} incident={incident} handleCloseDrugModal={closeModal}/>}
        {modalType === ModalType.DELETE_MODAL &&
            <DeleteModal handleNo={() => closeModal()} handleYes={deleteHandlerYes}
                         textAdded={'ten lek w opisie przypadku'}/>
        }
        {modalType === ModalType.DELETE_CONFIRMATION_MODAL &&
            <ConfirmationModal text={<p>Lek w opisie przypadku został <b>usunięty poprawnie</b>.</p>}/>
        }

      </Modal>}
      <ButtonContainer>
        <Button text={'dodaj lek'} backgroundColor={theme.colors.dark1} icon={plus}
                color={theme.colors.beige} width={'190px'} size={'small'}
                clickHandler={setDrugModalOpened}/>
      </ButtonContainer>
    </ContainerThirdStage>
  )
});

