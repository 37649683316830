import { IBreadcrumbsElement } from "../../../../../interfaces/user/breadcrumbs";
import { UserLink } from "../../../../../utils";

export const useSelectFormVm = () => {

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      link: UserLink.PROFILE,
      name: "Profil użytkownika"
    },
    {
      name: "Dane rozliczeniowe"
    }];


  return {
    breadcrumbs
  }

}