import styled from "styled-components";
import {Grid} from "@mui/material";

export const Icon = styled.div<{ bcgColor: string }>`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  background-color: ${props => props.bcgColor};
  width: 52px;
  height: 52px;
  border-radius: 4px;
  top: -26px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
`

export const Container = styled(Grid)`
  border: 1px solid #E0E0E0;
  position: relative;
  border-radius: 11px;
  background: linear-gradient(180deg, #EFEFEF 0%, rgba(249, 252, 255, 0.29) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 53px 28px;
`