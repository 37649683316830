import React from 'react'
import {ModalContainer, ModalTitle} from './ModalLayer.styled';

interface IProps {
  children: JSX.Element;
  text: string
}

export const ModalLayer: React.FC<IProps> = ({children, text}) => {
  return (
    <ModalContainer>
      <ModalTitle>{text}</ModalTitle>
      {children}
    </ModalContainer>)
}

