import React, { ReactNode } from 'react';
import { Label, RedSpan, SelectContainer, StyledSelect, ErrorMessage } from './Select.styled';
import { IOption } from '../../../../interfaces/common/form/select';

interface IProps {
  selectValue: number;
  elementName: string;
  setValue: (value: number) => void;
  optionsArray: IOption[];
  defaultValue?: string;
  isDefaultValue?: boolean;
  label?: string;
  isRequired?: boolean;
  isError?: boolean;
  width?: string;
  color?: string;
  errorMessage?: string;
  children?: ReactNode;
}

export const Select: React.FC<IProps> = ({
                                           label,
                                           isError,
                                           isRequired,
                                           selectValue,
                                           elementName,
                                           defaultValue,
                                           isDefaultValue = true,
                                           setValue,
                                           optionsArray,
                                           width,
                                           color,
                                           errorMessage, children
                                         }) => {
  return (
    <SelectContainer width={width}>
      {label && (
        <Label color={color}>
          {label}
          {isRequired && <RedSpan>*</RedSpan>}
        </Label>
      )}
      <StyledSelect
        name={elementName}
        value={selectValue}
        onChange={(e) => setValue(+e.target.value)}
        id={elementName}
        isError={isError || false}
      >
        {isDefaultValue && <option value={-1}>{defaultValue}</option>}
        {optionsArray?.map((option, index: number) => (
          <option key={index} value={option.id}>
            {option.title || option.name}
          </option>
        ))}
        {children}
      </StyledSelect>
      {isError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </SelectContainer>
  );
};
