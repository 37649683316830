import * as yup from "yup";
import {useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {INewIncident} from '../../../../../../../interfaces/user/draftCaseNeuro/thirdStage';
import {IIncident} from '../../../../../../../interfaces/user/draftCaseNeuro/draft';
import {useIncidentApi} from '../../../../../../../apiHooks/useIncidentApi';
import {useDraftCaseApi} from '../../../../../../../apiHooks';
import {useProgressBarNeuroStore} from '../../../../../../../store/hooks';


export const validationSchema = yup.object().shape({
  date: yup
    .string()
    .required(),
  description: yup
    .string()
    .required()
})

export const useModalAddIncident = (idDrug: number, incident: IIncident | null, handleCloseDrugModal: () => void) => {

  const {id} = useParams();
  const {postIncident, putIncident} = useIncidentApi();
  const {getDraftCase} = useDraftCaseApi();
  const progressBarStore = useProgressBarNeuroStore()
  const {thirdStage} = progressBarStore
  const drugData = thirdStage?.drugs.find(el => +el.id! === idDrug)
  let incidentNumber = drugData?.incidents?.length! + 1
  if (drugData && incident) {
    incidentNumber = drugData.incidents?.findIndex(el => el.id === incident.id!) ?? drugData.incidents?.length! + 1
    incidentNumber += 1
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: incident ? incident.date : '',
      description: incident ? incident.description : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id) {
        let newIncident: INewIncident = {
          description: values.description,
          date: values.date,
        }


        handleCloseDrugModal();
        if (!incident) {
          await postIncident(+id, idDrug, newIncident)
        } else if (incident && incident.id) {
          await putIncident(+id, idDrug, +incident.id, newIncident);
        }
        await getDraftCase(+id);
      }
    }
  })

  return {
    formik,
    incidentNumber
  }

}