import React from 'react'
import { Input } from "../../../../shared/form/input/Input";
import { Grid } from "@mui/material";
import { Button } from '../../../../shared/button/Button'
import { ErrorMessage } from "../../../../shared/form/errorMessage/ErrorMesage";
import { ReactComponent as ArrowRight } from './../../../../../assets/icon/arrowRight.svg'
import { formData } from "./retrievePasswordFormData";
import { useRetrievePasswordViewingForm } from "./useRetrievePasswordViewingForm.vm";
import {
  ParagraphAdded,
  ParagraphContainer,
  ParagraphMain,
  RegisterViewingFormContainer
} from "./RetrievePasswordViewingForm.styled";
import theme from "../../../../../theme/theme";

export const RetrievePasswordViewingForm = () => {
  const {
    formik,
    message,
    confirmForm,
    isButtonActive
  } = useRetrievePasswordViewingForm()

  return <RegisterViewingFormContainer onSubmit={formik.handleSubmit}>
    <Grid container justifyContent={'space-between'}>
      <Input key={formData.name} label={formData.label} name={formData.name} type={formData.type}
             value={formik.values.email}
             color={'white'}
             onChange={formik.handleChange}
             placeholder={formData.placeholder}
             isError={formik.touched.email && Boolean(formik.errors.email)}
             textError={formik.errors.email}
      />
    </Grid>
    {formik.submitCount > 0 && <ErrorMessage errors={formik.errors} errorServer={false}
                                             message={message}/>}
    <ParagraphContainer>
      <ParagraphMain>Na ten adres zostanie wysłany link aktywacyjny dzięki któremu nadasz nowe
        hasło.</ParagraphMain>
      <ParagraphAdded>Jeśli w ciągu 5min nie pojawi się na twojej skrzynce pocztowej sprawdź folder “SPAM”. Jeżeli
        dalej
        nie otrzymałeś emaila z linkiem aktywacyjnym skontaktuj się z naszym supportem.</ParagraphAdded>
    </ParagraphContainer>

    <Button text={'wyślij'} clickHandler={confirmForm}
            backgroundColor={isButtonActive ? theme.colors.dark1 : theme.colors.darkBlue}
            color={'white'}
            size={'small'} type={'submit'}
            maxWidth={'135px'} icon={<ArrowRight/>}/>
  </RegisterViewingFormContainer>
}
