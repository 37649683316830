import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFirstStage } from './useFirstStage.vm';
import { Input } from '../../../../../shared/form/input/Input';
import { CheckboxGroup, RadioForm, TitleHelper } from './FirstStage.styled';
import { Label } from '../../../../../shared/form/label/LabelContainer';
import { RadioButton } from '../../../../../shared/form/radioButton/RadioButton';
import theme from '../../../../../../theme/theme';
import { checkEmptyNumber } from './firstStage.helper';
import { FormikProvider } from 'formik';
import { Checkbox } from '../../../../../shared/form/checkbox/Checkbox';
import { Select } from '../../../../../shared/form/select/Select';

export const FirstStage = observer(() => {
  const { formik, handleCheckbox, voivodeships } = useFirstStage();

  return (
    <Grid container justifyContent={'space-between'} mb={12}>
      <Grid item xs={12}>
        <Input isRequired={true}
               color={theme.colors.turquoise2}
               label={'Tytuł opisu przypadku'}
               isUpperCase={true}
               name={'title'}
               type={'text'}
               value={formik.values.title}
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
               placeholder={'Wpisz tytuł opisu przypadku...'}
               isError={formik.touched.title && Boolean(formik.errors.title)}
               before={<TitleHelper>Tytuł opisu przypadku powinien zawierać charakterystycznę cechę, proszę unikać
                 tytułów
                 "Przypadek 1".</TitleHelper>}
        />
      </Grid>
      <Grid container mt={2} gap={3}>
        <Grid item xs={10} md={4}>
          <Input isRequired={true}
                 color={theme.colors.turquoise2}
                 label={'Rok urodzenia'}
                 isUpperCase={true}
                 name={'yearOfBirth'}
                 maxLength={4}
                 type={'number'}
                 value={checkEmptyNumber(formik.values.yearOfBirth)}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'rrrr'}
                 isError={formik.touched.yearOfBirth && Boolean(formik.errors.yearOfBirth)}
          />
        </Grid>
        <Grid item xs={10} md={6}>
          <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2} label={'płeć'}
                 isRequired={true} />
          <RadioForm>
            <RadioButton isError={formik.touched.gender && Boolean(formik.errors.gender)} id={'gender1'} name={'gender'}
                         value={'kobieta'} checked={formik.values.gender === 'kobieta'}
                         onChange={formik.handleChange} />
            <RadioButton isError={formik.touched.gender && Boolean(formik.errors.gender)} id={'gender2'} name={'gender'}
                         value={'mężczyzna'} checked={formik.values.gender === 'mężczyzna'}
                         onChange={formik.handleChange} />
            <RadioButton isError={formik.touched.gender && Boolean(formik.errors.gender)} id={'gender3'} name={'gender'}
                         value={'inna'} checked={formik.values.gender === 'inna'}
                         onChange={formik.handleChange} />
          </RadioForm>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Miejsce zamieszkania pacjenta'}
        />
      </Grid>
      <Grid container gap={{ xs: 0, md: 3 }} mt={2} justifyItems={'center'} alignItems={'flex-end'}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
        <Grid item md={6} xs={12}>
          <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Miasto'}
                 isRequired={true} />
          <Input
            isUpperCase={true}
            name={'patientCity'}
            type={'text'}
            value={formik.values.patientCity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Wpisz...'}
            isError={formik.touched.patientCity && Boolean(formik.errors.patientCity)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Województwo'}
                 isRequired={true} />
          <Select defaultValue={'Wybierz'} selectValue={formik.values.patientVoivodeshipId}
                  elementName={'patientVoivodeshipId'}
                  color={theme.colors.dark1}
                  setValue={(e) => formik.setFieldValue('patientVoivodeshipId', e)}
                  optionsArray={voivodeships}
                  isError={formik.touched.patientVoivodeshipId && Boolean(formik.errors.patientVoivodeshipId)} />
        </Grid>
      </Grid>


      <Grid container gap={{ xs: 0, md: 3 }} mt={2} justifyItems={'center'} alignItems={'flex-end'}>
        <Grid item md={3} xs={8}>
          <Input isRequired={true}
                 color={theme.colors.turquoise2}
                 label={'Wzrost'}
                 isUpperCase={true}
                 name={'height'}
                 type={'number'}
                 value={checkEmptyNumber(formik.values.height)}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'Wpisz...'}
                 isError={formik.touched.height && Boolean(formik.errors.height)}
          />
        </Grid>
        <Grid item md={5} xs={8}>
          <Input isRequired={true}
                 color={theme.colors.turquoise2}
                 label={'Aktualna masa ciała'}
                 isUpperCase={true}
                 name={'weight'}
                 type={'number'}
                 value={checkEmptyNumber(formik.values.weight)}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 placeholder={'Wpisz...'}
                 isError={formik.touched.weight && Boolean(formik.errors.weight)}
          />
        </Grid>
        <Grid item md={3} xs={8}>
          <Input
            disabled
            color={theme.colors.turquoise2}
            label={'BMI'}
            isUpperCase={true}
            name={'bmi'}
            type={'number'}
            value={checkEmptyNumber(formik.values.bmi)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={{ md: 3, xs: 2 }}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Choroby współistniejące o charakterze przewlekłym'}
        />
      </Grid>
      <Grid item xs={12} mt={3}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
               label={'choroby układu krążenia'} />
        <FormikProvider value={formik}>
          <CheckboxGroup>
            <Checkbox
              fontSize={'12px'}
              isError={formik.touched.chronicDisease?.cardiovascularDiseases && Boolean(formik.errors.chronicDisease?.cardiovascularDiseases)}
              id={'cardiovascularDiseases1'}
              name={'cardiovascularDiseases'}
              textColor={theme.colors.dark1}
              label={'Niewydolność serca'}
              checked={formik.values.chronicDisease.cardiovascularDiseases?.includes('Niewydolność serca')}
              onChange={() => handleCheckbox('Niewydolność serca')}
            />
            <Checkbox
              fontSize={'12px'}
              isError={formik.touched.chronicDisease?.cardiovascularDiseases && Boolean(formik.errors.chronicDisease?.cardiovascularDiseases)}
              id={'cardiovascularDiseases2'}
              name={'cardiovascularDiseases'}
              textColor={theme.colors.dark1}
              label={'Choroba niedokrwienna serca'}
              checked={formik.values.chronicDisease.cardiovascularDiseases?.includes('Choroba niedokrwienna serca')}
              onChange={() => handleCheckbox('Choroba niedokrwienna serca')}
            />
            <Checkbox
              fontSize={'12px'}
              isError={formik.touched.chronicDisease?.cardiovascularDiseases && Boolean(formik.errors.chronicDisease?.cardiovascularDiseases)}
              id={'cardiovascularDiseases3'}
              name={'cardiovascularDiseases'}
              textColor={theme.colors.dark1}
              label={'Nadciśnienie tętnicze'}
              checked={formik.values.chronicDisease.cardiovascularDiseases?.includes('Nadciśnienie tętnicze')}
              onChange={() => handleCheckbox('Nadciśnienie tętnicze')}
            />
            <Checkbox
              fontSize={'12px'}
              isError={formik.touched.chronicDisease?.cardiovascularDiseases && Boolean(formik.errors.chronicDisease?.cardiovascularDiseases)}
              id={'cardiovascularDiseases4'}
              name={'cardiovascularDiseases'}
              textColor={theme.colors.dark1}
              label={'Zaburzenia rytmu serca'}
              checked={formik.values.chronicDisease.cardiovascularDiseases?.includes('Zaburzenia rytmu serca')}
              onChange={() => handleCheckbox('Zaburzenia rytmu serca')}
            />
          </CheckboxGroup>
        </FormikProvider>
      </Grid>
      <Grid item xs={12} flexDirection={'column'}>
        <Checkbox
          fontSize={'12px'}
          isError={formik.touched.chronicDisease?.cardiovascularDiseases && Boolean(formik.errors.chronicDisease?.cardiovascularDiseases)}
          id={'cardiovascularDiseases5'}
          name={'cardiovascularDiseases'}
          textColor={theme.colors.dark1}
          label={'Inne'}
          checked={formik.values.chronicDisease.cardiovascularDiseases?.includes('Inne')}
          onChange={() => handleCheckbox('Inne')}
        />
        {formik.values.chronicDisease.cardiovascularDiseases?.includes('Inne') && <Grid item xs={12} flexGrow={1}>
          <Input
            width={'100%'}
            maxWidth={'100%'}
            name={'chronicDisease.cardiovascularDiseaseOther'}
            type={'text'}
            value={formik.values.chronicDisease.cardiovascularDiseaseOther}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Wpisz...'}
            isError={formik.touched.chronicDisease?.cardiovascularDiseaseOther && Boolean(formik.errors.chronicDisease?.cardiovascularDiseaseOther)}
          />
        </Grid>}
      </Grid>
      <Grid item xs={12} mt={2}>
        <Input
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Choroby układu pokarmowego'}
          isUpperCase={true}
          name={'chronicDisease.digestiveSystemDisease'}
          type={'text'}
          value={formik.values.chronicDisease?.digestiveSystemDisease}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.chronicDisease?.digestiveSystemDisease && Boolean(formik.errors.chronicDisease?.digestiveSystemDisease)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Input
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Choroby układu moczowo-płciowego'}
          isUpperCase={true}
          name={'chronicDisease.urogenitalDisease'}
          type={'text'}
          value={formik.values.chronicDisease?.urogenitalDisease}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.chronicDisease?.urogenitalDisease && Boolean(formik.errors.chronicDisease?.urogenitalDisease)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
               label={'Choroby układu oddechowego'} />
      </Grid>
      <Grid item xs={12} flexDirection={'row'} display={'flex'}>
        <Checkbox
          isError={formik.touched.chronicDisease?.isPochp && Boolean(formik.errors.chronicDisease?.isPochp)}
          id={'isPochp'}
          fontSize={'12px'}
          name={'isPochp'}
          textColor={theme.colors.dark1}
          label={'POChP'}
          checked={Boolean(formik.values.chronicDisease.isPochp)}
          onChange={(e) => formik.setFieldValue('chronicDisease.isPochp', e)}
        />
        <Checkbox
          fontSize={'12px'}
          isError={formik.touched.chronicDisease?.isAstma && Boolean(formik.errors.chronicDisease?.isAstma)}
          id={'isAstma'}
          name={'isAstma'}
          textColor={theme.colors.dark1}
          label={'Astma oskrzelowa'}
          isUppercase={true}
          checked={Boolean(formik.values.chronicDisease.isAstma)}
          onChange={(e) => formik.setFieldValue('chronicDisease.isAstma', e)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Input
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Inne choroby układu oddechowego'}
          isUpperCase={true}
          name={'chronicDisease.respiratorySystemDisease'}
          type={'text'}
          value={formik.values.chronicDisease?.respiratorySystemDisease}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.chronicDisease?.respiratorySystemDisease && Boolean(formik.errors.chronicDisease?.respiratorySystemDisease)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Input
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Choroby tkanki łącznej'}
          isUpperCase={true}
          name={'chronicDisease.connectiveTissueDisease'}
          type={'text'}
          value={formik.values.chronicDisease?.connectiveTissueDisease}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.chronicDisease?.connectiveTissueDisease && Boolean(formik.errors.chronicDisease?.connectiveTissueDisease)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
               label={'Choroby autoimmunologiczne, których obecność nie jest przeciwwskazaniem do immunoterapii:'} />
      </Grid>
      <Grid item xs={12} mt={1}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Cukrzyca typu 1'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.diabetes && Boolean(formik.errors.chronicDisease?.diabetes)}
                       id={'chronicDisease.diabetes1'}
                       name={'chronicDisease.diabetes'}
                       value={'tak'} checked={formik.values.chronicDisease.diabetes === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.diabetes && Boolean(formik.errors.chronicDisease?.diabetes)}
                       id={'chronicDisease.diabetes2'}
                       name={'chronicDisease.diabetes'}
                       value={'nie'} checked={formik.values.chronicDisease.diabetes === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.diabetes && Boolean(formik.errors.chronicDisease?.diabetes)}
                       id={'chronicDisease.diabetes3'}
                       name={'chronicDisease.diabetes'}
                       value={'brak danych'} checked={formik.values.chronicDisease?.diabetes === 'brak danych'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Niedoczynność tarczycy'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.hypothyroidism && Boolean(formik.errors.chronicDisease?.hypothyroidism)}
                       id={'chronicDisease.hypothyroidism1'}
                       name={'chronicDisease.hypothyroidism'}
                       value={'tak'} checked={formik.values.chronicDisease?.hypothyroidism === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.hypothyroidism && Boolean(formik.errors.chronicDisease?.hypothyroidism)}
                       id={'chronicDisease.hypothyroidism2'}
                       name={'chronicDisease.hypothyroidism'}
                       value={'nie'} checked={formik.values.chronicDisease.hypothyroidism === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.hypothyroidism && Boolean(formik.errors.chronicDisease?.hypothyroidism)}
                       id={'chronicDisease.hypothyroidism3'}
                       name={'chronicDisease.hypothyroidism'}
                       value={'brak danych'} checked={formik.values.chronicDisease.hypothyroidism === 'brak danych'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'łuszczyca'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.psoriasis && Boolean(formik.errors.chronicDisease?.psoriasis)}
                       id={'chronicDisease.psoriasis1'}
                       name={'chronicDisease.psoriasis'}
                       value={'tak'} checked={formik.values.chronicDisease?.psoriasis === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.psoriasis && Boolean(formik.errors.chronicDisease?.psoriasis)}
                       id={'chronicDisease.psoriasis2'}
                       name={'chronicDisease.psoriasis'}
                       value={'nie'} checked={formik.values.chronicDisease.psoriasis === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.psoriasis && Boolean(formik.errors.chronicDisease?.psoriasis)}
                       id={'chronicDisease.psoriasis3'}
                       name={'chronicDisease.psoriasis'}
                       value={'brak danych'} checked={formik.values.chronicDisease.psoriasis === 'brak danych'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Bielactwo'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.albinism && Boolean(formik.errors.chronicDisease?.albinism)}
                       id={'chronicDisease.albinism1'}
                       name={'chronicDisease.albinism'}
                       value={'tak'} checked={formik.values.chronicDisease?.albinism === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.albinism && Boolean(formik.errors.chronicDisease?.albinism)}
                       id={'chronicDisease.albinism2'}
                       name={'chronicDisease.albinism'}
                       value={'nie'} checked={formik.values.chronicDisease.albinism === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.albinism && Boolean(formik.errors.chronicDisease?.albinism)}
                       id={'chronicDisease.albinism3'}
                       name={'chronicDisease.albinism'}
                       value={'brak danych'} checked={formik.values.chronicDisease.albinism === 'brak danych'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Input
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Inna choroba nowotworowa obecnie leczona, bez cech progresji (stabilizacja choroby):'}
          isUpperCase={true}
          name={'chronicDisease.cancerDiseaseTreated'}
          type={'text'}
          value={formik.values.chronicDisease?.cancerDiseaseTreated}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.chronicDisease?.cancerDiseaseTreated && Boolean(formik.errors.chronicDisease?.cancerDiseaseTreated)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Input
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Inna choroba nowotworowa, stan po leczeniu radykalnym, bez cech wznowy'}
          isUpperCase={true}
          name={'chronicDisease.cancerDiseaseRadicalTreatment'}
          type={'text'}
          value={formik.values.chronicDisease?.cancerDiseaseRadicalTreatment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.chronicDisease?.cancerDiseaseRadicalTreatment && Boolean(formik.errors.chronicDisease?.cancerDiseaseRadicalTreatment)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
               label={'występowanie raka płuca w rodzinie wśród krewnych I stopnia'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.lungCancer && Boolean(formik.errors.chronicDisease?.lungCancer)}
                       id={'chronicDisease.lungCancer1'}
                       name={'chronicDisease.lungCancer'}
                       value={'tak'} checked={formik.values.chronicDisease?.lungCancer === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.lungCancer && Boolean(formik.errors.chronicDisease?.lungCancer)}
                       id={'chronicDisease.lungCancer2'}
                       name={'chronicDisease.lungCancer'}
                       value={'nie'} checked={formik.values.chronicDisease.lungCancer === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.chronicDisease?.lungCancer && Boolean(formik.errors.chronicDisease?.lungCancer)}
                       id={'chronicDisease.lungCancer3'}
                       name={'chronicDisease.lungCancer'}
                       value={'nie zgłoszono'} checked={formik.values.chronicDisease.lungCancer === 'nie zgłoszono'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Input
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Inne choroby (nie wymienione powyżej o istotnym charakterze klinicznym)'}
          isUpperCase={true}
          name={'chronicDisease.other'}
          type={'text'}
          value={formik.values.chronicDisease?.other}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.chronicDisease?.other && Boolean(formik.errors.chronicDisease?.other)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2} label={'Miejsce leczenia chorego'} />
      </Grid>
      <Grid container gap={2} flexWrap={'nowrap'} mt={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <Grid item md={6} xs={12}>
          <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Miasto'}
                 isRequired={true} />
          <Input
            color={theme.colors.dark1}
            isUpperCase={true}
            name={'city'}
            type={'text'}
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Wpisz...'}
            isError={formik.touched.city && Boolean(formik.errors.city)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Województwo'}
                 isRequired={true} />
          <Select defaultValue={'Wybierz'} selectValue={formik.values.voivodeshipId}
                  elementName={'voivodeshipId'}
                  color={theme.colors.dark1}
                  setValue={(e) => formik.setFieldValue('voivodeshipId', e)}
                  optionsArray={voivodeships}
                  isError={formik.touched.voivodeshipId && Boolean(formik.errors.voivodeshipId)} />
        </Grid>
      </Grid>
    </Grid>);
});



