import { AddUser } from '../../../../pages/admin/addUser/AddUser';
import { Areas } from '../../../../pages/admin/areas/Areas';
import { generalLinks } from './generalLinks';
import { Home } from '../../../../pages/admin/home/Home';
import { RolesPermissions } from '../../../../pages/admin/rolesPermissions/RolesPermissions';
import { EditRole } from '../../../../pages/admin/rolesPermissions/editRole/EditRole';
import { FormArea } from '../../../../pages/admin/areas/formArea/FormArea';
import { Cases } from '../../../../pages/admin/cases/Cases';
import { Tutorial } from '../../../../pages/admin/tutorial/Tutorial';
import { ViewCaseNeuro } from '../../../../pages/user/viewCaseNeuro/ViewCase';
import { ViewCaseLung } from '../../../../pages/user/viewCaseLung/ViewCase';
import { HistoryItems } from '../../../../pages/admin/cases/historyItems/HistoryItems';
import { WorkContracts } from '../../../../pages/admin/contract/workContracts/WorkContracts';
import { CompanyContracts } from '../../../../pages/admin/contract/companyContracts/CompanyContracts';
import { Invoices } from '../../../../pages/admin/contract/invoices/Invoices';
import { Documents } from '../../../../pages/admin/contract/documents/Documents';
import { Statistics } from '../../../../pages/admin/statistics/Statistics';
import { Users } from '../../../../pages/admin/statistics/users/Users';
import { StaticticCases } from '../../../../pages/admin/statistics/cases/Cases';
import { StatReckonings } from '../../../../pages/admin/statistics/reckonings/Reckonings';
import { General } from '../../../../pages/admin/statistics/general/General';
import { AllUsers } from '../../../../pages/admin/allUsers/AllUsers';
import { PublishingUsers } from '../../../../pages/admin/publishingUsers/PublishingUsers';

export const onlyAdminLinks = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/admin/add-user',
    element: <AddUser />
  },
  {
    path: '/admin/users',
    element: <AllUsers />
  },
  {
    path: '/admin/publishing-users',
    element: <PublishingUsers />
  },
  {
    path: '/admin/roles',
    element: <RolesPermissions />
  },
  {
    path: '/admin/roles/:id/edit',
    element: <EditRole />
  },
  {
    path: '/admin/cases',
    element: <Cases />
  },
  {
    path: '/admin/cases/neuro/:id/history',
    element: <HistoryItems />
  },
  {
    path: '/admin/cases/lung/:id/history',
    element: <HistoryItems />
  },
  {
    path: '/admin/tutorial',
    element: <Tutorial />
  },
  {
    path: '/cases/neuro/:id',
    element: <ViewCaseNeuro />
  },
  {
    path: '/cases/lung/:id',
    element: <ViewCaseLung />
  },
  {
    path: '/admin/work-contracts',
    element: <WorkContracts />
  },
  {
    path: '/admin/company-contracts',
    element: <CompanyContracts />
  },
  {
    path: '/admin/invoices',
    element: <Invoices />
  }, {
    path: '/admin/documents',
    element: <Documents />
  },
  {
    path: '/admin/areas',
    element: <Areas />
  },
  {
    path: '/admin/area/:id/edit',
    element: <FormArea />
  },
  {
    path: '/admin/area/new',
    element: <FormArea />
  },
  {
    path: '/admin/statistics',
    element: <Statistics />,
    children: [
      {
        path: '/admin/statistics/general',
        element: <General />
      }, {
        path: '/admin/statistics/users',
        element: <Users />
      }, {
        path: '/admin/statistics/reckonings',
        element: <StatReckonings />
      }, {
        path: '/admin/statistics/cases',
        element: <StaticticCases />
      }
    ]
  }
];

export const adminLinks = onlyAdminLinks.concat(generalLinks);