import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { IViewCaseNeuro, IViewCaseLung } from '../interfaces/user/case';
import { useCaseStore, useHistoryStore } from '../store/hooks';
import { IHistory } from '../interfaces/user/history/history';
import { AxiosRequestConfig } from 'axios';

export const useCaseApi = () => {
  const { api } = useApiCall();
  const caseStore = useCaseStore();
  const historyStore = useHistoryStore();

  const getCaseInfo = useCallback(
    async (id: string) => {
      const res: IViewCaseNeuro = await api.apiInstance.get(`situation/${id}`);
      caseStore.setViewCaseNeuro(res);
      return !!res;
    },
    [api.apiInstance, caseStore]
  );

  const getCaseInfoLung = useCallback(
    async (id: string) => {
      const res: IViewCaseLung = await api.apiInstance.get(`lung/situation/${id}`);
      caseStore.setViewCaseLung(res);
      return !!res;
    },
    [api.apiInstance, caseStore]
  );

  const getCaseComments = useCallback(
    async (id: number, type: string, isLoading: boolean = true) => {
      let res: IHistory | undefined;
      if (type) {

        if (type === 'neuro') {
          res = await api.apiInstance.get(`situation/${id}/comments`, isLoading);
        } else if (type === 'lung') {
          res = await api.apiInstance.get(`lung/situation/${id}/comments`, isLoading);
        }


      }
      if (res) {
        historyStore.setHistory(res);
        return !!res;
      }
    },
    [api.apiInstance, historyStore]
  );

  const getCasePdf = useCallback(
    async (id: number) => {
      const config: AxiosRequestConfig = { responseType: 'blob' };
      return await api.apiInstance.get(`situation/${id}/pdf`, true, config);
    },
    [api.apiInstance]
  );

  const getCasePdfLung = useCallback(
    async (id: number) => {
      const config: AxiosRequestConfig = { responseType: 'blob' };
      return await api.apiInstance.get(`lung/situation/${id}/pdf`, true, config);
    },
    [api.apiInstance]
  );

  return {
    getCaseComments,
    getCaseInfo,
    getCasePdf,
    getCaseInfoLung,
    getCasePdfLung
  };
};