import { TableSortOrder } from '../../../../interfaces/common/table';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    const astring: string = a[orderBy] as string;
    const bstring: string = b[orderBy] as string;
    if (astring.toLowerCase() < bstring.toLowerCase()) return -1;
    if (astring.toLowerCase() > bstring.toLowerCase()) return 1;
    return 0;
  }
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;

  return 0;
};

export const getComparator = <Key extends keyof any>(order: TableSortOrder, orderBy: Key)
  : (a: { [key in Key]: number | string }, b: { [key in Key]: number | string })
  => number =>
  order === TableSortOrder.Descending
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

  
 