import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import { useUsersVm } from './useUsers.vm';
import { observer } from 'mobx-react-lite';
import { MuiPagination } from '../../../../shared/pagination/MuiPagination';
import { Grid } from '@mui/material';

export const Users = observer(() => {
  const {
    cellsSwitcher,
    headCells,
    tableDataRows,
    isStatisticsTableLoaded,
    userPageCount,
    currentUserPage,
    setUserCurrentPage,
    sortOrderByHandler,
    sortOrderHandler
  } = useUsersVm();
  return (
    <Grid justifyContent={'space-between'} display={'flex'} flexDirection={'column'} height={'100%'}>
      <CustomTable
        sortOrderByHandler={sortOrderByHandler} sortOrderHandler={sortOrderHandler}
        cellsSwitcher={cellsSwitcher}
        customTableConfig={{ rowsPerPage: 20 }}
        tableDataRows={tableDataRows}
        isDataDownloaded={isStatisticsTableLoaded}
        headCells={headCells}
      />
      {isStatisticsTableLoaded && <MuiPagination
        style={{ alignSelf: 'center', marginBottom: '10px', marginTop: '16px' }}
        count={userPageCount}
        page={currentUserPage}
        onChange={(e, value: number) => {
          setUserCurrentPage(value);
        }} />}
    </Grid>
  );
});