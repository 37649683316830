import styled from "styled-components";
import {ILabelFieldsetContainer} from "../Form.helper";
import theme from "../../../../theme/theme";

export const FieldsetContainer = styled.fieldset`
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
`

export const FieldsetLegend = styled.legend<ILabelFieldsetContainer>`
  color: ${props => props.color};
  font-size: ${props => props.size === 'small' ? '16px' : '20px'};
  line-height: ${props => props.size === 'small' ? '30px' : '130%'};
  font-weight: ${props => props.size === 'small' ? '400' : '700'};
  margin-bottom: 4px;
  margin-left: ${props => props.textAlign === 'center' ? '0' : '16px'};
  text-align: ${props => props.textAlign};

  ${props => props.isUpperCase && 'text-transform: uppercase;'}
  & div {
    margin-left: 0;
  }

  & p {
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 16px;
  }
`