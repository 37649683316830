import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import React from 'react';
import { useCases } from './useCases';
import { CustomTable } from '../../../shared/customTables/customTable/CustomTable';
import { observer } from 'mobx-react-lite';
import { Modal } from '../../../shared/modal/Modal';
import { ModalType } from '../../../../constants/modalType';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { AcceptCase } from './modal/acceptCase/AcceptCase';
import { RejectCase } from './modal/reject/RejectCase';
import { ParagraphFirst } from './Cases.styled';

export const Cases = observer(() => {

  const {
    casesIsNotNull,
    cellsSwitcher,
    generateActions,
    acceptCaseHandler,
    rejectCaseHandler,
    addedContent,
    headCells,
    tableDataRows,
    modalType,
    isModalOpened,
    closeModal,
    isPublic
  } = useCases();
  return <AdminLayout>
    {casesIsNotNull &&
      <CustomTable searchBarAddedContent={addedContent} actions={generateActions()} cellsSwitcher={cellsSwitcher}
                   isSearchBar={true}
                   tableDataRows={tableDataRows} isDataDownloaded={true}
                   headCells={headCells} />}
    {isModalOpened &&
      <Modal clickHandler={() => closeModal()}>
        {modalType === ModalType.ACCEPT_CASE_MODAL &&
          <AcceptCase handleSave={acceptCaseHandler} />
        }
        {modalType === ModalType.ACCEPT_CASE_CONFIRMATION_MODAL &&
          <ConfirmationModal
            text={<><p>Gratulacje. Przypadek <b>został zaakceptowany</b> poprawnie.</p><p>Przypisałeś
              mu <b>status&nbsp;
                {isPublic ? 'publiczny' : 'niepubliczny'}</b>.</p></>} />
        }
        {modalType === ModalType.REJECT_CASE_MODAL &&
          <RejectCase handleSave={rejectCaseHandler} />
        }
        {modalType === ModalType.REJECT_CASE_CONFIRMATION_MODAL &&
          <ConfirmationModal
            text={<><ParagraphFirst>Opis przypadku został wprowadzony <b>w stan edycji
              poprawnie</b>.</ParagraphFirst>
              <p><b>Przypadek
                ten nie
                będzie publiczny/dostępny</b> dla innych użytkowników serwisu. Wgląd do przypadku ma tylko autor w
                celu
                jego poprawy.</p></>} />
        }
      </Modal>
    }
  </AdminLayout>;

});
