import React, { FC } from 'react';
import { Paragraph, ParagraphBold } from './SideEffects.styled';
import { ContentModal } from '../../../modal/ContentModal';
import { Modal } from '../../../modal/Modal';

interface IProps {
  closeModal: () => void;
}

export const SideEffect: FC<IProps> = ({closeModal}) => {

  return (
    <Modal clickHandler={() => closeModal()}>
      <ContentModal scrolled={true}>
        <ParagraphBold>Uprzejmie informujemy, że poniższy Serwis nie służy do zgłaszania informacji dotyczących
          bezpieczeństwa farmakoterapii.</ParagraphBold>
        <Paragraph>Prosimy o przekazanie informacji dotyczących działań niepożądanych produktu leczniczego firmy Roche
          poprzez formularz zgłoszenia działania niepożądanego dostępny na stronie internetowej <a
            href={'www.roche.pl'}>www.roche.pl</a> lub bezpośrednio na adres e-mail: <a
            href={`mailto:warsaw.drug_safety@roche.com`}>warsaw.drug_safety@roche.com.</a></Paragraph>
        <Paragraph>W przypadku zidentyfikowania w przedstawionym opisie przypadku informacji dotyczących bezpieczeństwa
          farmakoterapii produktu leczniczego firmy Roche, w tym działań/zdarzeń niepożądanych, sytuacji szczególnych,
          wad jakościowych, Roche jako podmiot odpowiedzialny może kontaktować się z osobą, która przygotowała opis
          przypadku, w celu pozyskania dodatkowych informacji dotyczących zgłoszenia.</Paragraph>
        <Paragraph>Informacje dotyczące działań niepożądanych innych produktów leczniczych należy przekazać do
          właściwego podmiotu odpowiedzialnego za dany produkt leczniczy i do Urzędu Rejestracji Produktów Leczniczych,
          Wyrobów Medycznych i Produktów Biobójczych (<a
            href={'https://smz.ezdrowie.gov.pl'}>https://smz.ezdrowie.gov.pl</a>).</Paragraph>
      </ContentModal>
    </Modal>)
};