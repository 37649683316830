import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { IAction, IHeadCell } from '../../../../../interfaces/common/table';
import theme from '../../../../../theme/theme';
import { ReactComponent as Download } from '../../../../../assets/icon/download.svg';
import React, { useEffect, useState } from 'react';
import { useModal } from '../../../../shared/modal/useModal.vm';
import { ModalType } from '../../../../../constants/modalType';
import { useContractApi } from '../../../../../apiHooks';
import { useContractStore } from '../../../../../store/hooks';
import { splitData, SplitData } from '../../../../../utils';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { CellTime } from '../../../../shared/customTables/cells/cellTime/CellTime';
import { StatusText } from '../../../../shared/admin/status/Status.styled';
import { ActionButton } from '../../../../shared/customTables/button/ActionButton';
import { ReactComponent as EditIcon } from '../../../../../assets/icon/edit.svg';
import { Checkbox } from '@mui/material';
import { useContracts } from '../useContracts.vm';

export const useInvoices = () => {
  const { handleModal, isModalOpened, closeModal, id: editId } = useModal();
  const contractStore = useContractStore();
  const { isInvoicesLoaded, invoiceDocuments, userInfo } = contractStore;
  const { getInvoices, payInvoiceFile, getUserInfo, checkInvoiceFilePdf } = useContractApi();
  const { getInvoicesZip } = useContractApi();
  const [actionId, setActionId] = useState<null | number>(null);

  const {
    handleModal: handlePayModal,
    isModalOpened: isPayModalOpened,
    closeModal: closePayModal,
    id: payId
  } = useModal();

  const {
    filteredContracts,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years
  } = useContracts(invoiceDocuments);


  const downloadHandler = async (id: number | string) => {
    const res = await getInvoicesZip(+id);
    const blob = new Blob([res], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  useEffect(() => {
    if (!isInvoicesLoaded) {
      if (invoiceDocuments === null) {
        void getInvoices();
      } else {
        void getInvoices(false);
      }
    }
  }, [isInvoicesLoaded, getInvoices, invoiceDocuments]);

  const handleRefresh = async () => {
    void getInvoices(true);
  };

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: 'checked',
        custom: generateCheckedAction,
        width: 52
      },
      {
        key: 'download',
        actionHandler: downloadHandler,
        background: theme.colors.yellow,
        color: theme.colors.yellow,
        fill: 'white',
        border: theme.colors.yellow,
        actionElement: <Download />,
        width: 52,
        actionId: 'situationId'
      },
      {
        key: 'status',
        custom: generateStatusActions,
        width: 104
      }
    ];

    return actions;
  };

  const cellsSwitcher = (keyName: string, value: string | [], onClick?: () => void) => {
    const specialValue: SplitData = keyName === 'createdAt' ? splitData(value.toString()) : ['-', ''];

    switch (keyName) {
      case 'id':
      case 'user':
      case 'userId':
      case 'situationId':
        return null;
      case 'createdAt':
        return <TableCellData><CellTime specialValue={specialValue} /></TableCellData>;
      case 'areas':
        return <TableCellData>{value instanceof Array ? value.join(', ') : value.toString()}</TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const editContactHandler = async (id: number | string, user?: number, type?: string, customId?: string, userId?: number) => {
    if (userId) {
      await getUserInfo(userId);
    }
    setActionId(+id);
    handleModal(ModalType.VIEW_MODAL, id);
  };

  const acceptContractHandler = async (id: number | string) => {
    handlePayModal(ModalType.PAY, +id);
  };

  const handlePay = async () => {
    if (payId) {
      const res = await payInvoiceFile(+payId);

      if (res) {
        await getInvoices(false);
        closePayModal();
      }
    }
  };

  const generateCheckedAction = (id: number | string) => {

    if (invoiceDocuments) {
      const element = invoiceDocuments.find((element) => element.id === id);
      if (element) {

        return <ActionButton id={id as number}
                             background={theme.colors.white}
                             border={theme.colors.white}
                             width={52} actionElement={<Checkbox checked={element.checked} onChange={async () => {
          await checkInvoiceFilePdf(Number(id), !element.checked);
          await getInvoices(false);
        }
        } />} />;
      }
    }

    return null;
  };

  const generateStatusActions = (id: number | string, user?: number, type?: string, customId?: string, userId?: number) => {
    if (invoiceDocuments) {
      const element = invoiceDocuments.find((element) => element.id === id);
      if (element) {
        if (element.status === 'PAY') {
          return <StatusText color={theme.colors.turquoise2}><span>opłacone</span></StatusText>;
        } else {
          return <React.Fragment>
            <ActionButton id={id as number} actionHandler={acceptContractHandler}
                          background={theme.colors.dark1}
                          border={theme.colors.turquoise2}
                          width={52} actionElement={<>zapłać</>} />
            <ActionButton id={id as number} actionHandler={editContactHandler} background={theme.colors.red}
                          border={theme.colors.red}
                          userContract={user}
                          userId={userId}
                          width={52}
                          actionElement={<EditIcon />} />
          </React.Fragment>;
        }
      }
    }
    return null;
  };
  const headCells: IHeadCell[] = [
    { id: 'title', numeric: false, label: 'Nazwa' },
    { id: 'fullName', numeric: false, label: 'Lekarz' },
    { id: 'createdAt', numeric: false, label: 'Data przesłania' },
    { id: 'areas', numeric: false, label: 'Obszar terapeutyczny' },
    { id: 'user', numeric: false, label: '' },
    { id: 'userId', numeric: false, label: '' },
    { id: 'situationId', numeric: false, label: '' }
  ];

  const tableDataRows = createTableRows(filteredContracts ?? [], headCells, false);

  return {
    isModalOpened,
    actionId,
    closeModal,
    generateActions,
    tableDataRows,
    cellsSwitcher,
    companyInvoicesIsNotNull: invoiceDocuments !== null,
    userInfo,
    headCells,
    handlePay,
    isPayModalOpened,
    closePayModal, optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years,
    editId,
    handleRefresh
  };
};