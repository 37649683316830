import React from 'react'
import { Container, Decor, DecorContainer } from "./RegisterLayoutViewing.styled";
import { Footer } from "../../../../shared/user/footer/Footer";
import { NavigationMenu } from "../../../../shared/user/navigation/navigationMenu/NavigationMenu";

interface IProps {
  children: JSX.Element
}

export const RegisterLayoutViewing: React.FC<IProps> = ({children}) => {
  return (
    <Container>
      <NavigationMenu/>
      <DecorContainer>
        <Decor/>
      </DecorContainer>
      {children}
      <Footer/>
    </Container>
  )
}
