import { IArea } from '../areas/areas';

export interface IContract {
  id: number;
  fullName: string;
  createdAt: string;
  accepted: boolean;
  checked: boolean;
  link: string;
  contract: number;
  areas: IArea[];
  situationIds: number[];
}

export interface ICompanyContract extends IContract {
  originalName: string;

}

export enum ContractStatus {
  WAIT = 'WAIT',
  ACCEPT = 'ACCEPT',
  PAY = 'PAY'
}

export enum DocumentStatus {
  WAIT = 'WAIT',
  ACCEPT = 'ACCEPT',
  PAY = 'PAY',
}

export interface IWorkContract extends IContract {
  title: string;
  status: ContractStatus;
}

export type ContractType = 'company' | 'user' | 'none';

export interface IInvoice extends IContract {
  title: string;
  status: ContractStatus;
}

export interface IDocuments {
  areas: IArea[];
  status: string | number;
  customId: number;
  type: string;
  datePay: string;
  originalName: string;
  numberContract: string;
  formatedDate: string;
  id: string;
  fullName: string;
  createdAt: string;
  isVisible: boolean;
}

export interface IUserInfo {
  email: string;
  fullName: string;
  phone: string;
  roleId: number;
}