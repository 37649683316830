import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  @media (max-width: ${theme.breakpoints.small}px) {
    margin-bottom: 16px;
    flex-direction: column;
  }
`

export const Paragraph = styled.p`
  color: ${theme.colors.dark1};
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  max-width: 565px;
  margin-top: 15px;
  margin-bottom: 46px;
  text-align: center;
`

export const StyledImage = styled.div`
  background: ${theme.colors.yellow};
  width: 94px;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  & svg {
    width: 49px;
    height: 45px;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
`