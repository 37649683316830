import {Grid, Typography} from '@mui/material';
import {ReactComponent as ArrowAccordion} from '../../../../../../../assets/icon/arrowAccordion.svg';
import {
  AccordionDetailsCustom,
  AccordionStyled,
  AccordionSummaryCustom,
  GridButtonContainer,
  StyledIcon,
  StyledSection,
  Title
} from './AccordionCustom.styled';
import {ReactComponent as Bin} from '../../../../../../../assets/icon/bin.svg';
import {ReactComponent as Plus} from '../../../../../../../assets/icon/beigePlus.svg';
import {Button} from '../../../../../../shared/button/Button';
import theme from '../../../../../../../theme/theme';
import {Input} from '../../../../../../shared/form/input/Input';
import {DateInput} from '../../../../../../shared/form/date/Date';
import {useAccordionCustom} from './useAccordionCustom';
import {IDrug, IIncident} from '../../../../../../../interfaces/user/draftCaseNeuro/draft';
import React, {FC} from 'react';
import {
  MultipleFileUpload
} from '../../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload';
import {observer} from 'mobx-react-lite';
import {Label} from '../../../../../../shared/form/label/LabelContainer';


interface IProps {
  drug: IDrug;
  number: number;
  addIncident: (idDrug: number) => void;
  editIncident: (idDrug: number, idIncident: IIncident) => void;
  deleteDrug: (idDrug: number) => void;
  isModalOpened: boolean;
  // validationDrug: (isValid: boolean, id?: string | number) => void;
  expanded: boolean;
  setExpandedId?: (id?: number) => void;
  expandedId?: number
}

export const AccordionCustom: FC<IProps> = observer(({
                                                       drug,
                                                       number,
                                                       addIncident,
                                                       editIncident,
                                                       deleteDrug,
                                                       isModalOpened,
                                                       // validationDrug,
                                                       expanded,
                                                       setExpandedId,
                                                       expandedId
                                                     }) => {

  const {
    formik,
    addImage,
    removeImage,
    images
  } = useAccordionCustom(drug, isModalOpened, expandedId, setExpandedId);

  return (
    <AccordionStyled expanded={expanded} onChange={() => setExpandedId && drug.id && setExpandedId(+drug.id)}>
      <AccordionSummaryCustom
        expandIcon={<ArrowAccordion/>}
      >
        <Typography>Lek {number}</Typography>
      </AccordionSummaryCustom>
      <AccordionDetailsCustom>
        {!isModalOpened && <form onSubmit={formik.handleSubmit}>
          <StyledSection>
            <Input label={'Nazwa leku'}
                   isUpperCase={true}
                   name={'title'}
                   type={'text'}
                   color={theme.colors.turquoise2}
                   value={formik.values.title}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   placeholder={'Nazwa leku...'}
                   isError={formik.touched.title && Boolean(formik.errors.title)}
            />
            <DateInput
              openTo={'year'}
              inputFormat={'mm.yyyy'}
              views={['year', 'month']}
              label={'Data rozpoczęcia leczenia danym lekiem'}
              isUpperCase={true}
              name={'date'}
              onBlur={() => formik.setFieldTouched('date', true)}
              color={theme.colors.turquoise2}
              value={formik.values.date}
              onChange={(e) => formik.setFieldValue('date', e)}
              placeholder={'Wybierz datę rozpoczęcia leczenia...'}
              isError={formik.touched.date && Boolean(formik.errors.date)}
            />
          </StyledSection>
          <StyledSection>
            <Title>Ocena aktywności rzutowej podczas leczenia danym lekiem</Title>
            <Grid container>
              {drug.incidents && drug.incidents.map((incident, index) => (
                <GridButtonContainer key={incident.id} item xs={12} md={4} mb={1}>
                  <Button text={`Rzut ${index + 1}`} backgroundColor={theme.colors.blue3}
                          clickHandler={(e) => drug.id && incident.id && editIncident(+drug.id, incident)}
                          color={theme.colors.beige} size={'small'}/>
                </GridButtonContainer>
              ))}
              <GridButtonContainer item xs={12} md={4} mb={1}>
                <Button width={'100%'} text={'Dodaj rzut'} icon={<Plus/>} backgroundColor={theme.colors.dark1}
                        clickHandler={(e) => drug.id && addIncident(+drug.id)}
                        color={theme.colors.beige} size={'small'}/>
              </GridButtonContainer>
            </Grid>
          </StyledSection>
          <StyledSection>
            <Title>ocena aktywności rezonansowej podczas
              leczenia danym LEKIEM</Title>

            <Grid container display={'flex'} alignItems={'flex-end'}>
              <Grid item xs={12} pr={1} mb={2}>
                <Input
                  label={'Np: liczba i lokalizacja nowych zmian T1 i/lub nowych i powiększających się zmian T2/FLAIR wzmacniających się po kontraście'}
                  isUpperCase={true}
                  name={'descriptionChanges'}
                  type={'textarea'}
                  size={'small'}
                  color={theme.colors.dark1}
                  value={formik.values.descriptionChanges}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={'Opis MRI...'}
                  isError={formik.touched.descriptionChanges && Boolean(formik.errors.descriptionChanges)}
                />
              </Grid>
              <Label isUpperCase={true} color={'black'} isRequired={false} label={'Zdjęcia (Opcjonalnie)'}
                     size={'small'}/>
              <Grid item xs={12} mb={4}>
                <MultipleFileUpload images={images} addFile={addImage} removeFile={removeImage}
                                    maxFileSize={16}/>
              </Grid>

            </Grid>
          </StyledSection>
          <StyledSection>
            <DateInput
              openTo={'year'}
              inputFormat={'mm.yyyy'}
              views={['year', 'month']}
              maxWidth={'385px'}
              label={'Data zakończenia leczenia danym lekiem'}
              isUpperCase={true}
              name={'dateEnd'}
              onBlur={() => formik.setFieldTouched('dateEnd', true)}
              color={theme.colors.turquoise2}
              value={formik.values.dateEnd}
              onChange={(e) => formik.setFieldValue('dateEnd', e)}
              placeholder={'Wybierz datę zakończenia leczenia...'}
              isError={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)}
            />
            <Input label={'Opis działań niepożądanych (opcjonalnie)'}
                   isUpperCase={true}
                   name={'descriptionReactions'}
                   type={'textarea'}
                   color={theme.colors.turquoise2}
                   value={formik.values.descriptionReactions}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   placeholder={'Wpisz opis działań niepożądanych jeśli wystąpiły...'}
                   isError={formik.touched.descriptionReactions && Boolean(formik.errors.descriptionReactions)}
            />
            <Grid item xs={12} mt={2}>
              <Input label={'Inne uwagi (opcjonalnie)'}
                     isUpperCase={true}
                     name={'comments'}
                     type={'textarea'}
                     color={theme.colors.turquoise2}
                     value={formik.values.comments}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     placeholder={'Wpisz dodatkowe uwagi...'}
                     isError={formik.touched.comments && Boolean(formik.errors.comments)}
              />
            </Grid>

          </StyledSection>
          <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Button type={'submit'} text={'zapisz lek'} backgroundColor={theme.colors.dark1}
                    color={theme.colors.beige} width={'190px'} size={'small'}/>
            <StyledIcon onClick={() => drug.id && deleteDrug(+drug.id)}><Bin/></StyledIcon>

          </Grid>
        </form>}
      </AccordionDetailsCustom>
    </AccordionStyled>

  )
});


