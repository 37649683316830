export const stringMethod = (text: string) => {
    const lettersToReplace = ['a', 'i', 'o', 'u', 'w', 'z', 'A', 'I', 'O', 'U', 'W', 'Z'];
    lettersToReplace.forEach(el =>
        text
            .trim()
            .split(' ' + el + ' ')
            .join(' ' + el + '&nbsp;')
    );
    text = text.replace(/&lt;/gi, str => '<');
    text = text.replace(/&gt;/gi, str => '>');

    return text;
};

export const stringInject = (template: string, stringsInjects: string[]) => {
    let newString = template;
    stringsInjects.forEach((text, index) => {
        newString = newString.replace(`{${index}}`, text);
    });
    return newString;
};
