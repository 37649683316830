import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { observer } from 'mobx-react-lite';
import { useViewCase } from './useViewCase.vm';
import { StyledLayout } from './ViewCase.styled';
import { HeaderCase } from '../viewCaseNeuro/headerCase/HeaderCase';
import { MiddlePart } from './middlePart/MiddlePart';
import { Interview } from './interview/Interview';
import { Diagnosis } from './diagnosis/Diagnosis';
import { StyledButton } from '../viewCaseNeuro/ViewCase.styled';
import { Button } from '../../../shared/button/Button';
import pdfIcon from '../../../../assets/icon/pdfIcon.svg';
import theme from '../../../../theme/theme';
import { Path } from './path/Path';

export const ViewCaseLung = observer(() => {
  const { breadcrumbs, caseInfo, casePdfHandler } = useViewCase();
  return (
    <StyledLayout>
      <UserLayout breadcrumbs={breadcrumbs}>
        {caseInfo &&
          <><HeaderCase casePdfHandler={casePdfHandler} />
            <MiddlePart />
            <Interview />
            <Path />
            <Diagnosis />
            <StyledButton>
              <Button
                clickHandler={casePdfHandler}
                text={'pobierz jako pdf'}
                size={'small'}
                icon={pdfIcon}
                backgroundColor={theme.colors.red}
                color={theme.colors.beige} width={'206px'} />
            </StyledButton>
          </>}
      </UserLayout>
    </StyledLayout>
  );
});

