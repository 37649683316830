import {Grid, styled} from "@mui/material";
import theme from "../../../../theme/theme";
import {ReactComponent as Search} from "../../../../assets/icon/search.svg";

export const TopActionsContainer = styled(Grid)`
  margin-top: 30px;
  position: relative;
  display: flex;
  align-content: end;
  height: 60px;

  & input {
    font-size: 12px !important;
    line-height: 16px !important;
    width: 480px;
    display: block;
    margin-right: 0px;
    margin-left: auto;
    background: ${theme.colors.beige};
    color: ${theme.colors.gray4};
  }

  & svg {
    top: 8px;
  }
`

export const SearchCustom = styled(Search)`
  position: absolute;
  right: 12px;
`