import {useState} from 'react';

export const useDateSetter = (addedDays = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + addedDays);
    const [value, setValue] = useState<Date | null | string>(null);
    const [touched, setTouched] = useState<boolean>(false);

    const onChangeDate = (dateValue: string | null, keyboardInputValue?: string) => {
        const changedDate = dateValue === null ? "" : new Date(dateValue);
        setValue(changedDate);
        setTouched(true);
    }

    const dateElements = value && typeof value !== 'string' ? [value.getFullYear(), value.getMonth() + 1, value.getDate()] : [];

    const isError = value === null || typeof value === 'string';

    return {
        value,
        touched,
        isError,
        dateElements,
        onChangeDate
    };
};
