import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { MAX_STAGE } from '../components/pages/user/draftCaseNeuro/progressBar/ProgressBar.helper';

import { IDraftCaseNeuro } from '../interfaces/user/draftCaseNeuro/draft';
import {
  convertResponseToFirstStage,
  convertResponseToFourthStage,
  convertResponseToSecondStage,
  convertResponseToThirdStage
} from '../utils';
import { IFirstStageNeuro } from '../interfaces/user/draftCaseNeuro/firstStage';
import { ISecondStageNeuro } from '../interfaces/user/draftCaseNeuro/secoundStage';
import { IFourthStageNeuro } from '../interfaces/user/draftCaseNeuro/fouthStage';
import { IThirdStageNeuro } from '../interfaces/user/draftCaseNeuro/thirdStage';

const INIT_STAGE = 1;

export class ProgressBarNeuroStore {
  isLoaded: boolean = false;
  firstStage: IFirstStageNeuro | null = null;
  secondStage: ISecondStageNeuro | null = null;
  thirdStage: IThirdStageNeuro | null = null;
  fourthStage: IFourthStageNeuro | null = null;
  currentStage = INIT_STAGE;
  maxEditStage = INIT_STAGE;
  typeOfCase: string = '';
  isClicked: boolean = false;
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setCurrentStage(stage: number) {
    if (stage > 0 && stage <= MAX_STAGE) {
      this.currentStage = stage;
    }
  }

  setTypeOfCase(typeOfCase: string) {
    this.typeOfCase = typeOfCase;
  }

  setMaxEditStage(stage: number) {
    this.maxEditStage = stage;
  }

  resetProgressBar() {
    this.currentStage = INIT_STAGE;
    this.maxEditStage = INIT_STAGE;

    this.setFirstStage(null);
    this.setSecondStage(null);
    this.setThirdStage(null);
    this.setFourthStage(null);

    this.setIsLoaded(false);
  }

  setFirstStage(firstStage: IFirstStageNeuro | null) {
    this.firstStage = firstStage;
  }

  setSecondStage(secondStage: ISecondStageNeuro | null) {
    this.secondStage = secondStage;
  }

  setThirdStage(thirdStage: IThirdStageNeuro | null) {
    this.thirdStage = thirdStage;
  }

  setFourthStage(fourthStage: IFourthStageNeuro | null) {
    this.fourthStage = fourthStage;
  }

  convert(draftCase: IDraftCaseNeuro, changeStep?: boolean) {
    const step = draftCase.step;
    this.setMaxEditStage(step);
    if (changeStep) {
      this.setCurrentStage(step);
    }

    const firstStage: IFirstStageNeuro = convertResponseToFirstStage(draftCase);
    this.setFirstStage(firstStage);

    const secondStage: ISecondStageNeuro = convertResponseToSecondStage(draftCase);
    this.setSecondStage(secondStage);

    const thirdStage: IThirdStageNeuro = convertResponseToThirdStage(draftCase);
    this.setThirdStage(thirdStage);

    const fourthStage: IFourthStageNeuro = convertResponseToFourthStage(draftCase);
    this.setFourthStage(fourthStage);

    this.setIsLoaded(true);
  }

  setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
  }

  setClicked(value: boolean) {
    this.isClicked = value
  }
}
