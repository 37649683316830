import { useEffect, useState } from "react";
import theme from '../theme/theme';

export enum Breakpoint {
  INIT,

  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE
}

const checkBreakpoint = () => {
  if (window.innerWidth > theme.breakpoints.extraLarge) {
    return Breakpoint.EXTRA_LARGE;
  } else if (
    window.innerWidth <= theme.breakpoints.extraLarge &&
    window.innerWidth > theme.breakpoints.large
  ) {
    return Breakpoint.LARGE;
  } else if (
    window.innerWidth <= theme.breakpoints.large &&
    window.innerWidth > theme.breakpoints.medium
  ) {
    return Breakpoint.MEDIUM;
  } else if (
    window.innerWidth <= theme.breakpoints.medium &&
    window.innerWidth > theme.breakpoints.small
  ) {
    return Breakpoint.SMALL;
  } else if (window.innerWidth <= theme.breakpoints.small) {
    return Breakpoint.EXTRA_SMALL;
  }
  return Breakpoint.INIT;
};

export default function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(Breakpoint.INIT);

  useEffect(() => {
    setBreakpoint(checkBreakpoint());
  }, []);

  useEffect(() => {
    if (breakpoint !== Breakpoint.INIT) {
      const resizeFun = () => {
        const newBreakpoint = checkBreakpoint();
        if (breakpoint !== newBreakpoint) {
          setBreakpoint(newBreakpoint);
        }
      };
      window.addEventListener("resize", resizeFun);

      return () => {
        window.removeEventListener("resize", resizeFun);
      };
    }
  }, [breakpoint]);

  return breakpoint;
}
