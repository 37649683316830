import {useRootStore} from '../RootStateContext';

export const useAreasStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.areasStore) {
        throw new Error('areasStore store should be defined');
    }

    return rootStore.areasStore;
};
