import { RootStore } from './rootStore';
import { makeAutoObservable } from 'mobx';
import { IDraftCaseLung } from '../interfaces/user/draftCaseLung/draft';
import { IDraftCaseNeuro } from '../interfaces/user/draftCaseNeuro/draft';
import { IOption } from '../interfaces/common/form/select';

const ABBREVIATION_LUNG = 'Lung';
const ABBREVIATION_NEURO = 'Neuro';

export class DraftCaseStore {
  draftCaseLung: IDraftCaseLung | null = null;
  draftCaseNeuro: IDraftCaseNeuro | null = null;
  voivodeships: IOption[] = [];
  abbreviationLung: string = ABBREVIATION_LUNG;
  abbreviationNeuro: string = ABBREVIATION_NEURO;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setDraftCaseLung(draftCaseLung: IDraftCaseLung) {
    this.draftCaseLung = draftCaseLung;
  }

  resetDraftCaseLung() {
    this.draftCaseLung = null;
  }

  setDraftCaseNeuro(draftCaseNeuro: IDraftCaseNeuro) {
    this.draftCaseNeuro = draftCaseNeuro;
  }

  resetDraftCaseNeuro() {
    this.draftCaseNeuro = null;
  }

  setVoivodeships(voivodeships: IOption[]) {
    this.voivodeships = voivodeships;
  }
}
