import { useStatisticsStore } from '../../../../../store/hooks';
import { IHeadCell, TableSortOrder } from '../../../../../interfaces/common/table';
import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import React from 'react';
import { IArea } from '../../../../../interfaces/admin/areas/areas';

export const useUsersVm = () => {
  const {
    isStatisticsTableLoaded,
    userPageCount,
    currentUserPage,
    publishedUsersDataTableByCurrentPage,
    setUserCurrentPage
  } = useStatisticsStore();
  const statisticsStore = useStatisticsStore();

  const headCells: IHeadCell[] = [
    { id: 'fullName', numeric: false, label: 'Imię i nazwisko' },
    { id: 'keeper', numeric: false, label: 'Opiekun' },
    { id: 'areas', numeric: false, label: 'Obszar terapeutyczny' },
    { id: 'allCases', numeric: false, label: 'Wszystkie' },
    { id: 'sentCases', numeric: false, label: 'Oczekujące' },
    { id: 'draftCases', numeric: false, label: 'Szkice' },
    { id: 'rejectedCases', numeric: false, label: 'Odrzucone' },
    { id: 'publicCases', numeric: false, label: 'Publiczne' },
    { id: 'unpublicCases', numeric: false, label: 'Niepubliczne' }
  ];
  const sortOrderByHandler = (orderBy: string) => {
    statisticsStore.setOrderBy(orderBy);
  };
  const sortOrderHandler = (order: TableSortOrder) => {
    statisticsStore.setOrder(order);
  };

  const tableDataRows = createTableRows(publishedUsersDataTableByCurrentPage ?? [], headCells, false);

  const cellsSwitcher = (keyName: string, value: string | number | IArea[], onClick?: () => void, id?: number, type?: string) => {
    const areas = typeof value === 'object' ? value?.map(el => el.fullName).join(', ') : value === 0 || value === '' ? '-' : value;
    const specialValue = value === 0 || value === '' ? '-' : value;
    switch (keyName) {
      case 'id':
        return null;
      case 'areas':
        return <TableCellData>{areas}</TableCellData>;
      default:
        return <TableCellData>{specialValue?.toString()}</TableCellData>;
    }
  };

  return {
    cellsSwitcher,
    headCells,
    tableDataRows,
    isStatisticsTableLoaded,
    userPageCount,
    currentUserPage,
    setUserCurrentPage,
    sortOrderByHandler,
    sortOrderHandler
  };

};