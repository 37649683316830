import React, {FC} from "react";

import {LabelContainer, LabelInsideContainer, RedSpan} from "./LabelContainer.styled";
import {ILabelFieldsetProps} from "../Form.helper";

export interface IProps extends ILabelFieldsetProps {
  isRequired?: boolean;
  content?: string | JSX.Element;
  before?: JSX.Element;
}

export const Label: FC<IProps> = ({before, isUpperCase, color, label, isRequired, content, size, textAlign}) => {

  return (
    <LabelContainer isUpperCase={isUpperCase} color={color} size={size} textAlign={textAlign}>
      <LabelInsideContainer>
        <div>{label}{isRequired && <RedSpan>*</RedSpan>}</div>
        {before}
      </LabelInsideContainer>
      {content}
    </LabelContainer>
  )
}
