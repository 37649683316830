import {useReckoningApi} from "../../../../../../apiHooks/useReckoningApi";
import {useFileStateUpload} from "../../../../../shared/uploadFiles/fileUpload/useFileStateUpload";
import {useEffect, useState} from "react";
import {useReckoningStore} from "../../../../../../store/hooks";
import { StoreIDs } from '../../../../../../config/storeIds';

export const useMyReckoningDocumentPlacement = (ids: number[] | null, selectedValue: StoreIDs.AREA_LUNG | StoreIDs.AREA_NEURO, confirmationHandler: () => void, cleanAfterReckoningHandler: () => void) => {

  const {sendDocumentNeuro, sendDocumentLung, getMyAcceptedCases} = useReckoningApi();
  const {file, handleFileChange, handleRemoveFile} = useFileStateUpload();
  const [contractTitle, setContractTitle] = useState("");
  const {userContractResponse} = useReckoningStore();

  useEffect(() => {
    if (userContractResponse !== null) {
      const title = `Umowa-o-dzielo-${userContractResponse.fullName.replace(" ", "-")}-${userContractResponse.createdAt?.slice(0, 10)}`;
      setContractTitle(title)
    }
  }, [userContractResponse])

  const sendFileHandler = async () => {
    if (file && ids && selectedValue) {

      const res = selectedValue===  StoreIDs.AREA_LUNG  ? await sendDocumentLung(ids[0], file[0]) : await sendDocumentNeuro(ids, file[0]);
      if (res) {
        await getMyAcceptedCases(false);
        confirmationHandler();
        cleanAfterReckoningHandler();
      }

    }
  }

  return {
    file,
    contractTitle,
    handleRemoveFile,
    handleFileChange,
    sendFileHandler,
  }
}