import { CaseStatus } from '../interfaces/user/draftCaseNeuro/draft';
import { IArea } from '../interfaces/admin/areas/areas';

export const filterByCurrentPage =
  <T>(currentPage: number, cardsPerPage: number) =>
    (_: T, index: number) =>
      index >= (currentPage - 1) * cardsPerPage && index < currentPage * cardsPerPage;

export const filterByStatus = (status: CaseStatus[]) => (element: any) => {
  return status.length === 0 || status.includes(element.status);
};


export const filterByType = (type: string[]) => (element: any) => {
  return type.length === 0 || type.includes(element.type);
};

export const filterByArea = <T extends { areas: IArea[] | string | string[] }>(type?: string[]) => (element: T) => {
  if (typeof element?.areas === 'string') {
    return type && filterByAreaString(type)(element as { areas: string });
  } else if (typeof element?.areas === 'object') {
    return type && filterByAreaArray(type)(element as { areas: IArea[] | string[] });
  }
};

const filterByAreaString = <T extends { areas: string }>(type: string[]) => (element: T) => {
  return type.length === 0 || (element.areas.split(', ')).some(areaType => type.includes(areaType as string));
};

const filterByAreaArray = <T extends { areas: IArea[] | string[] }>(type: string[]) => (element: T) => {
  const found = (element.areas as (IArea | string)[]).map(area => typeof area === 'string' ? area : area.fullName).some(areaType => type.includes(areaType as string));
  return type.length === 0 || found;
};

export const filterByYear = <T extends { createdAt: string | undefined, paidAt?: string | null, updatedAt?: string }>(years: number[], hasPaidAt?: boolean, hasUpdatedAt?: boolean) => (element: T) => {

  if (hasPaidAt) {
    return years.length === 0 || (element.paidAt && years.includes(new Date(element.paidAt).getFullYear()));
  }
  if (hasUpdatedAt) {
    return years.length === 0 || (element.updatedAt && years.includes(new Date(element.updatedAt).getFullYear()));
  } else {
    return years.length === 0 || (element.createdAt && years.includes(new Date(element.createdAt).getFullYear()));
  }

};

export const filterByReckoningType = <T extends { type: string | null }>(type: string[]) => (element: T) => {
  if (element.type !== null && type.length > 0) {
    return element.type.split(', ').some(reckoningType => type.includes(reckoningType as string));
  } else if (element.type === null && type?.length === 0) {
    return true;
  } else {
    return type?.length === 0;
  }

};

interface ConverterStatus {
  isPublic: boolean;
  isUnPublic: boolean;
}

export const filterByAcceptStatus = <T extends { status: string, isPublic: boolean | null }>(type: string[]) => (element: T) => {
  const result = type?.length === 0 || (element.status.split(', ')).some(status => type.includes(status as string));
  if (type.includes('isPublic') || type.includes('isUnPublic')) {
    const converterStatus: ConverterStatus = {
      isPublic: true,
      isUnPublic: false
    };
    // Filtracja elementów na podstawie typu
    return type.some(el => element.isPublic === converterStatus[el as keyof ConverterStatus]) || result;
  } else {
    return result;
  }
};

const switchReckoningStatus = (value: string) => {
  switch (value) {
    case 'Opłacone':
      return true;
    case 'Nieopłacone':
      return false;
  }
};

export const filterByReckoningStatus = <T extends { isPay: boolean | null }>(type: string[]) => (element: T) => {
  return type?.length === 0 || type.some(el => element.isPay === switchReckoningStatus(el));

};

export const filterByRole = <T extends { roleId?: number }>(roles?: number[]) => (element: T) => {
  if (typeof element.roleId === 'number') {
    return roles?.length === 0 || roles?.includes(element.roleId as number);
  }
};