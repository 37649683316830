import { AdminLayout } from '../../../../shared/admin/layout/AdminLayout';
import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import { useWorkContracts } from './useWorkContracts';
import { HeaderCustomTableContract } from '../headerCustomTable/HeaderCustomTable';
import { observer } from 'mobx-react-lite';
import { ChangeDocumentModal } from '../changeDocumentModal/ChangeDocumentModal';
import { AcceptDocumentModal } from '../acceptDocumentModal/AcceptDocumentModal';
import { PayDocumentModal } from '../payDocumentModal/PayDocumentModal';
import { MultipleSelect } from '../../../../shared/form/multipleSelect/MultipleSelect';
import { MultipleSelectContainer } from '../Contract.styled';

export const WorkContracts = observer(() => {

  const {
    workContractsIsNotNull,
    cellsSwitcher,
    generateActions,
    tableDataRows,
    isEditModalOpened,
    closeEditModal,
    isAcceptModalOpened,
    closeAcceptModal,
    userInfo,
    headCells,
    handleAccept,
    handlePay,
    closePayModal,
    isPayModalOpened,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years, editId,
    handleRefresh
  } = useWorkContracts();

  return (<AdminLayout>
    {workContractsIsNotNull &&
      <CustomTable
        actions={generateActions()}
        cellsSwitcher={cellsSwitcher}
        searchBarAddedContent={<HeaderCustomTableContract>Umowy o dzieło</HeaderCustomTableContract>}
        isSearchBar={true}
        tableDataRows={tableDataRows} isDataDownloaded={true}
        headCells={headCells}
        contentAfterSearch={<MultipleSelectContainer>
          <MultipleSelect subtitle={'Obszar'} key={'area'} selectedValues={selectedAreas}
                          elementName={'area'}
                          options={optionsAreas ?? []}
                          handleChange={handleChange} />
          <MultipleSelect subtitle={'Rok'} key={'year'} selectedValues={selectedYears}
                          elementName={'years'}
                          options={years}
                          handleChange={handleChangeYears} />
        </MultipleSelectContainer>}
      />}
    {isEditModalOpened &&
      <ChangeDocumentModal
        id={editId ? +editId : undefined}
        typeOfContract={'workContract'}
        userInfo={userInfo} handleRefresh={handleRefresh} closeModal={closeEditModal} />}
    {isAcceptModalOpened &&
      <AcceptDocumentModal
        text={'umowę o dzieło'}
        closeModal={closeAcceptModal}
        handleYes={handleAccept}
        handleNo={closeAcceptModal} />}

    {isPayModalOpened &&
      <PayDocumentModal
        closeModal={closePayModal}
        handleYes={handlePay}
        handleNo={closePayModal} />}
  </AdminLayout>);
});