export const checkIsActive = <U extends { id: number; active: boolean }>(
  id: number,
  data: U[]
) => {
  const element = data.find((data) => data.id === id);
  return element && !element.active;
};

export const checkIsPublishingActive = <U extends { id: number; isActive: boolean }>(
  id: number,
  data: U[]
) => {
  const element = data.find((data) => data.id === id);
  return element && !element.isActive;
};