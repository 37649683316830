import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const BlackTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.dark1};
  text-transform: uppercase;

  & span {
    font-weight: 400;
  }
`

export const ObjectContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.small}px) {
    align-items: center;
  }
`

export const StyledCalendar = styled.div`
  margin-right: 22px
`

export const SectionTitle = styled.h1`
  font-weight: 800;
  font-size: 36px;
  line-height: 120%;
  text-transform: uppercase;
  margin-top: 48px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-weight: 800;
    font-size: 32px;
    line-height: 120%;
  }

`

export const SmallerText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;

`

export const FlexContainer = styled.div`
  display: flex;
  gap: 90px;
  @media (max-width: ${theme.breakpoints.small}px) {
    gap: 15px;
  }
`

export const FlexContainerSecond = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: ${theme.breakpoints.small}px) {
    gap: 20px;
    display: block
  }
`

export const GenderContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
`

export const ImgContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #646464;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`

export const Container = styled.div`
  max-width: 1012px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 16px;
    width: unset;
  }
`

export const Title = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 120%;
  padding: 6px 0;
  color: ${theme.colors.turquoise2};
  margin-top: 32px;
`

export const Content = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 8px;
  color: ${theme.colors.dark1};

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 16px;
    line-height: 22px;
  }
`