import styled from 'styled-components';
import { Grid } from '@mui/material';
import theme from '../../../../theme/theme';

interface IAdminLayoutContainer {
  $iscenter: boolean;
}

export const AdminLayoutContainer = styled(Grid)`
  background: ${theme.colors.beige};
`;

export const AdminLayoutContentContainer = styled(Grid)<IAdminLayoutContainer>`
  padding: 16px;
  flex-grow: 1;
  height: 100vh;
  max-height: 100vh;
  min-height: 800px;
  display: flex;
  align-items: ${props => props.$iscenter ? 'center' : 'top'};
  justify-content: center;
  position: relative;
  overflow: auto
`;