import React, { memo } from 'react';
import { Container } from "../FileUploadLayout.styled";
import { useDropZone } from "./useDropZone";

// Define interface for component props/api:
export interface IDropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void;
  onDrag?: () => void;
  onDragIn?: () => void;
  onDragOut?: () => void;
  onDrop?: () => void;
  onFileDrop?: (file: File) => void;
  onFilesDrop?: (files: File[]) => void;
  style?: React.CSSProperties;
}

export const DropZone = memo((props: React.PropsWithChildren<IDropZoneProps>) => {

  const {dropZoneRef} = useDropZone(props);

  return (
    <Container
      item
      ref={dropZoneRef}
    >
      {props.children}
    </Container>
  )
});
