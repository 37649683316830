import styled from "styled-components";
import theme from "../../../../../theme/theme";


export const DoctorImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  max-width: 537px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 auto;
  }
`
export const Header = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 13px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 20px;
    line-height: 27px;
    width: 100%;
    margin-bottom: 44px;
  }

  & svg {
    path {
      stroke: ${theme.colors.dark1};
    }
  }
`

export const RedCircle = styled.div`
  width: 45px;
  height: 45px;
  background-color: ${theme.colors.red};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 38px;
`

export const Subheader = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: ${theme.colors.dark1};
  margin-bottom: 20px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-bottom: 44px;
  }
`

export const FlexDiv = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
    gap: 30px;
  }
`