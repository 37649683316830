import styled from 'styled-components';
import { ReactComponent as Search } from '../../../../assets/icon/search.svg';

export const SearchCustomContainer = styled.div`
  position: relative;
`;

export const SearchCustom = styled(Search)`
  position: absolute;
  top: 7px;
  right: 12px;
`;

export const Header = styled.h1`
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
`;