import styled from "styled-components";

export const InvoiceContentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`
export const Header = styled.h1`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
`
export const SummaryElement = styled.p`
  font-size: 10px !important;
  line-height: 140% !important;
  text-align: left !important;
  margin-top: 16px;
`