export const setAllFieldsTouched = (values: object, handleFunction: (field: string, value: boolean) => void) => {
  Object.keys(flattenObject(values))?.forEach(fieldName => {

    if (fieldName) {
      handleFunction(fieldName, true);
    }
  });

}

type AnyObject = {
  [key: string]: any;
};

const flattenObject = (obj: AnyObject, parentKey: string = '', sep: string = '.'): AnyObject => {
  return Object.keys(obj).reduce((acc: AnyObject, key: string) => {
    const newKey = parentKey ? `${parentKey}${sep}${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(acc, flattenObject(obj[key], newKey, sep));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
};
