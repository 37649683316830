import theme from "../../../theme/theme";
import {ReactComponent as Success} from './../../../assets/icon/success.svg'
import {ReactComponent as Close} from './../../../assets/icon/close.svg'
import {ReactComponent as Edit} from './../../../assets/icon/edit.svg'
import {ReactComponent as Pending} from './../../../assets/icon/pending.svg'

export const filterButtonData = [{
  id: 1,
  text: 'zaakceptowany',
  color: theme.colors.turquoise2,
  icon: <Success/>,
  status: 'ACCEPT'
},
  {
    id: 2,
    text: 'do edycji',
    color: theme.colors.red,
    icon: <Close/>,
    status: 'REJECT'
  },
  {
    id: 3,
    text: 'szkic',
    color: theme.colors.blue1,
    icon: <Edit/>,
    status: 'DRAFT'
  },
  {
    id: 4,
    text: 'oczekujący',
    color: theme.colors.yellow,
    icon: <Pending/>,
    status: 'SENT'
  }
]