import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ImageContainer = styled.div`
  margin-top: 53px;
  margin-bottom: 60px;
  flex-flow: wrap;
  flex-grow: 4;
  display: flex;
  gap: 20px;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    flex-grow: 1;
    margin-top: 10px;
    margin-bottom: 30px
  }

`
export const Card = styled.div`
  background: #F2F2F2;
  border: 1px solid ${theme.colors.gray5};
  border-radius: 28px;
  padding: 15px;
  width: 180px;
  @media (max-width: ${theme.breakpoints.small}px) {
    width: 100%;
    display: flex;
    gap: 10px;

    & img {
      min-width: 157px;
    }
  }
`

export const Img = styled.img`
  width: 100%;
  height: 157px;
  cursor: pointer;
  object-fit: cover;
`