import styled from "styled-components";
import theme from "../../../theme/theme";

export const Button = styled.button<{ isActive: boolean, color: string }>`
  width: 100%;
  cursor: pointer;
  max-width: 244px;
  background: ${props => props.isActive ? props.color : theme.colors.dark1};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  height: 36px;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 36px;
  position: relative;
  color: ${theme.colors.beige};
`

export const StyledIcon = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 3px;
  bottom: 3px;
`
