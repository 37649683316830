import React from 'react';
import { createPortal } from 'react-dom';

import { CloseIcon } from "./modalIcons/CloseIcon";
import { ModalContainer, ModalContent, ModalContentInside } from "./Modal.styled";
import { GlobalLoader } from "../appLayer/globalLoader/GlobalLoader";

interface IModal {
  children: React.ReactNode;
  clickHandler: () => void;
  lightbox?: boolean;
  position?: 'relative' | 'static'
  width?: string;
  minHeight?: string;
  maxHeight?: string;
}

export const Modal: React.FC<IModal> = ({children, width, minHeight, maxHeight, clickHandler, lightbox, position = 'relative'}) => {

  return createPortal(
    <ModalContainer>
      <GlobalLoader/>
      <ModalContent lightbox={lightbox} width={width} minHeight={minHeight} maxHeight={maxHeight}>
        <CloseIcon clickHandler={clickHandler}/>
        <ModalContentInside lightbox={lightbox} position={position}>{children}</ModalContentInside>
      </ModalContent>
    </ModalContainer>,
    document.getElementById('modal-root') as HTMLElement
  );
};
