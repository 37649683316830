import styled from 'styled-components';
import theme from '../../../../../../../theme/theme';

export const ReportParametersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;

  h1 {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
  }

  label, h5 {
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: ${theme.colors.dark1};
    margin-left: 16px;
    margin-bottom: 8px;
  }
`;