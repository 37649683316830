import * as yup from 'yup'
import { useFormik } from 'formik';
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { ValidationMessages } from '../../../../../constants/validationMessages'
import { useAuthApi } from "../../../../../apiHooks";
import { useNavigate } from "react-router-dom";
import { GeneralLink, generateLink } from "../../../../../utils";
import { RoleCategory } from "../../../../../constants/projectUsers";

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
  repeatPassword: yup
    .string()
    .test('passwords-match', ValidationMessages.INVALID_PASSWORD, function (value) {
      return this.parent.password === value
    })
    .required(ValidationMessages.EMPTY_FIELD),
  termsAndConditions: yup.boolean().oneOf([true])
})

export const useChangePasswordViewingForm = (isRegister?: boolean) => {
  const {changePassword, register} = useAuthApi()
  const navigate = useNavigate()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      repeatPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await (isRegister ? register(values.password) : changePassword(values.password))

      if (res) {
        setMessage('');
        formik.resetForm()
        navigate(generateLink(RoleCategory.GUEST, GeneralLink.LOGIN))
      } else {
        setMessage(ValidationMessages.INVALID_USER)
      }
    }
  })

  const [message, setMessage] = useState('')
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

  const createErrorMessage = useCallback(() => {
    if (containsOnlyRepeatPasswordKey(formik.errors)) {
      return ValidationMessages.INVALID_PASSWORD
    } else if (Object.keys(formik.errors).length >= 1) {
      return ValidationMessages.INVALID_FORMCHANGE
    }
    return ""
  }, [formik.errors])


  useEffect(() => {
    if (formik.submitCount !== 0) {
      setMessage(createErrorMessage());
      const isValid = Object.keys(formik.errors).length === 0;
      setIsButtonActive(isValid);
    }
  }, [createErrorMessage, formik.errors, formik.submitCount])

  const containsOnlyRepeatPasswordKey = (obj: { [key: string]: string }) => {
    const keys = Object.keys(obj);
    return keys.length === 1 && keys[0] === "repeatPassword";
  }

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  }

  return {formik, message, confirmForm, isButtonActive}
}
  
  

