import { useRootStore } from '../RootStateContext';

export const useCaseStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.caseStore) {
    throw new Error('caseStore store should be defined');
  }

  return rootStore.caseStore;
};
