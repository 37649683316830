import React from 'react'
import { AdminLayout } from "../../../../shared/admin/layout/AdminLayout";
import { useEditRole } from "./useEditRole";
import { RoleForm } from '../roleForm/RoleForm';
import { Modal } from '../../../../shared/modal/Modal';
import { ModalType } from '../../../../../constants/modalType';
import { ConfirmationModal } from '../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { observer } from 'mobx-react-lite';

export const EditRole = observer(() => {
  const {
    isModalOpened,
    closeModal,
    modalType,
    actionHandler,
    roleEdit
  } = useEditRole()

  return (
    <AdminLayout>
      {roleEdit && <RoleForm isEdit={true} roleForm={roleEdit} actionHandler={actionHandler}/>}
      {isModalOpened &&
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.EDIT_CONFIRMATION_MODAL &&
            <ConfirmationModal text={<p>Role <b>wyedytowano poprawnie</b>.</p>}/>
          }
        </Modal>
      }
    </AdminLayout>
  )
});

