import {useProgressBarNeuroStore} from '../../../../../../store/hooks';
import {useParams} from 'react-router-dom';
import {useDraftCaseApi, useDrugApi} from '../../../../../../apiHooks';
import {useModal} from '../../../../../shared/modal/useModal.vm';
import {useEffect, useState} from 'react';
import {IIncident} from '../../../../../../interfaces/user/draftCaseNeuro/draft';
import {ModalType} from '../../../../../../constants/modalType';
import {Stages} from '../../progressBar/ProgressBar.helper';

export const useThirdStage = () => {
  const [actionId, setActionId] = useState<number | null>(null);
  const progressBarStore = useProgressBarNeuroStore();
  const {id} = useParams();
  const {getDraftCase} = useDraftCaseApi();
  const {deleteDrug} = useDrugApi();
  const {thirdStage} = useProgressBarNeuroStore();
  const {handleModal, isModalOpened, closeModal, modalType} = useModal();
  const [idDrug, setIdDrug] = useState<number | null>(null);
  const [incident, setIncident] = useState<IIncident | null>(null);
  const [expandedId, setExpandedId] = useState<number>(-1);

  const onChangeHandler = (id?: number) => {
    if (expandedId === id) {
      setExpandedId(-1);
    } else if (id) {
      setExpandedId(+id);
    }
  }

  const setDrugModalOpened = () => {
    handleModal(ModalType.ADD_DRUG)
  }

  const addIncident = (idDrug: number) => {
    setIdDrug(idDrug);
    setIncident(null);
    handleModal(ModalType.ADD_INCIDENT);
  }

  const editIncident = (idDrug: number, incident: IIncident) => {
    setIdDrug(idDrug);
    setIncident(incident);
    handleModal(ModalType.EDIT_INCIDENT);
  }


  const deleteHandler = (id: number) => {
    handleModal(ModalType.DELETE_MODAL);
    setActionId(id);
  }

  const deleteHandlerYes = async () => {
    if (id && actionId) {
      await deleteDrug(+id, actionId);
      await getDraftCase(+id);
      handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
    }
  }

  useEffect(() => {
    progressBarStore.setThirdStage(thirdStage);
    progressBarStore.setMaxEditStage(Stages.FOURTH);
  }, [progressBarStore, thirdStage])

  return {
    drugs: thirdStage ? thirdStage.drugs : [],
    isModalOpened,
    closeModal,
    modalType,
    idDrug,
    incident,
    setDrugModalOpened,
    addIncident,
    editIncident,
    deleteHandler,
    deleteHandlerYes,
    onChangeHandler,
    expandedId
  }
}