import { useStatisticsStore } from '../../../../../store/hooks';
import { IHeadCell, TableSortOrder } from '../../../../../interfaces/common/table';
import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import {
  ColorTableCellData,
  TableCellData, TableStatusCellData
} from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import React from 'react';
import theme from '../../../../../theme/theme';
import { statusIcons } from '../Statistics.helper';

export const useCasesVm = () => {
  const {
    isStatisticsTableLoaded,
    casesDataTable,
    casesPageCount,
    currentCasesPage,
    casesDataTableByCurrentPage,
    setCasesCurrentPage
  } = useStatisticsStore();
  const statisticsStore = useStatisticsStore();
  const sortOrderByHandler = (orderBy: string) => {
    statisticsStore.setOrderBy(orderBy);
  };
  const sortOrderHandler = (order: TableSortOrder) => {
    statisticsStore.setOrder(order);
  };
  const generateStatus = (value: string, id?: string) => {
    switch (value) {
      case 'ACCEPT':
        const isPublic = casesDataTable?.find(el => el.customId === id)?.isPublic ? 'isPublic' : 'isUnPublic';
        switch (isPublic) {
          case 'isPublic':
            return <TableCellData><TableStatusCellData>{statusIcons[value][isPublic]}<span>Publiczny</span></TableStatusCellData></TableCellData>;
          case 'isUnPublic':
            return <TableCellData><TableStatusCellData>{statusIcons[value][isPublic]}<span>Niepubliczny</span></TableStatusCellData></TableCellData>;
          default:
            return <TableCellData><span>Zaakceptowany</span></TableCellData>;
        }
      case 'DRAFT':
        return <TableCellData><TableStatusCellData>{statusIcons[value]}<span>Szkic</span></TableStatusCellData></TableCellData>;

      case 'SENT':
        return <TableCellData><TableStatusCellData>{statusIcons[value]}<span>Oczekujący</span></TableStatusCellData></TableCellData>;
      case 'REJECT':
        return <TableCellData><TableStatusCellData>{statusIcons[value]} <span>Do edycji</span></TableStatusCellData>
        </TableCellData>;
    }
  };

  const headCells: IHeadCell[] = [
    { id: 'title', numeric: false, label: 'Opis przypadku' },
    { id: 'customId', numeric: false, label: 'Id opisu' },
    { id: 'author', numeric: false, label: 'Publikujący' },
    { id: 'views', numeric: false, label: 'Wyświetlenia' },
    { id: 'areas', numeric: false, label: 'Obszar terapeutyczny' },
    { id: 'status', numeric: false, label: 'Status akceptacji' },
    { id: 'isPay', numeric: false, label: 'Status rozliczenia' }
  ];

  const tableDataRows = createTableRows(casesDataTableByCurrentPage ?? [], headCells, false);

  const cellsSwitcher = (keyName: string, value: string | number | boolean, onClick?: () => void, id?: number, type?: string, customId?: string) => {
    // const areas = typeof value === 'object' ? value?.map(el => el.fullName).join(', ') : null;

    const specialValue = value === 0 || value === '' ? '-' : value;
    switch (keyName) {
      case 'id':
      case 'isPublic':
        return null;
      case 'areas':
        return <TableCellData>{value}</TableCellData>;
      case 'isPay':
        return <ColorTableCellData sx={{ fontWeight: '700' }}
                                   color={typeof value === 'boolean' && value ? theme.colors.turquoise2 : theme.colors.dark1}>{typeof value === 'boolean' && value ? 'Opłacone' : 'Nieopłacone'}</ColorTableCellData>;
      case 'status':
        return <>{generateStatus(value as string, customId)}</>;
      default:
        return <TableCellData>{specialValue?.toString()}</TableCellData>;
    }
  };

  return {
    cellsSwitcher,
    headCells,
    tableDataRows,
    isStatisticsTableLoaded,
    casesPageCount,
    currentCasesPage,
    setCasesCurrentPage,
    sortOrderByHandler,
    sortOrderHandler
  };

};