import styled from "styled-components";
import theme from "../../../../theme/theme";
import {Grid} from "@mui/material";

export const RejectedDiv = styled.div`
  border: 1px solid ${theme.colors.red};
  color: ${theme.colors.red};
  height: 52px;
  width: 106px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-transform: uppercase;
`

export const StyledButton = styled.div`
  & button {
    cursor: auto;

    svg {
      margin: 0 !important;
    }
  }
`

export const RelativeGrid = styled(Grid)`
  position: relative;
`

export const StyledImg = styled.img`
  position: absolute;
  width: auto;
  left: 0;
  bottom: -88px
`

export const AcceptedDiv = styled.div`
  border: 1px solid ${theme.colors.turquoise2};
  color: ${theme.colors.turquoise2};
  height: 52px;
  width: 106px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-transform: uppercase;
`

export const GreenDiv = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  background-color: ${theme.colors.turquoise2};
  align-items: center;
  justify-content: center;
  margin-right: 2px;
`

export const RedDiv = styled.div`
  width: 52px;
  align-items: center;
  justify-content: center;
  height: 52px;
  display: flex;
  background-color: ${theme.colors.red};
`

export const StyledGrid = styled(Grid)`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
`