import styled from "styled-components";
import theme from "../../../../../theme/theme";
import {Link} from "react-router-dom";

interface IRegisterViewingFormContainer {
  buttonTextColor?: string;
  isButtonActive: boolean;
}

export const RegisterViewingFormContainer = styled.form<IRegisterViewingFormContainer>`
  position: relative;
  z-index: 50;
  padding: 0 32px;
  width: 100%;
  display: flex;
  flex-direction: column;

  & button {
    margin: 62px 0 0 auto;
    @media (max-width: ${theme.breakpoints.small}px) {
      max-width: 100% !important;
      margin-top: 34px;
    }

    & path {
      fill: ${(props) => props.isButtonActive ? theme.colors.beige : props.buttonTextColor}
    }
  }
`

export const StyledLink = styled(Link)`
  font-weight: 300;
  font-size: 16px;
  line-height: 190%;
  color: ${theme.colors.darkBlue};
  text-align: center;
  margin-top: 17px;
`