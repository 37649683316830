import {Grid} from '@mui/material'
import React from "react";
import {observer} from 'mobx-react-lite'
import {IDrug} from "../../../../../../interfaces/user/draftCaseNeuro/draft";
import {GridContainer} from "../../sharedComponents/gridContainer/GridContainer";
import {TitleBox} from "./HeaderHistory.styled";
import {
  BlackTitle,
  Container,
  ObjectContainer,
  SmallerText,
  StyledBorder,
  StyledCalendar
} from "../../sharedComponents/gridContainer/GridContainer.styled";
import {ReactComponent as Calendar} from "../../../../../../assets/icon/calendar.svg";
import {dateStringify} from "../../../../../../utils";
import useBreakpoint, {Breakpoint} from "../../../../../../hooks/useBreakpoint";

interface IProps {
  drug: IDrug
  index: number
}

export const HeaderHistory: React.FC<IProps> = observer(({drug, index}) => {
  const breakpoint = useBreakpoint()
  return (
    <Container>
      <Grid container mb={3}>
        <Grid item xs={6} sm={2} pr={1}>
          <TitleBox>Lek {index + 1}</TitleBox>
        </Grid>
        {breakpoint < Breakpoint.SMALL && <Grid item xs={6}>
            <BlackTitle>data rozpoczęcia leczenia danym LEKIEM</BlackTitle>
            <StyledBorder></StyledBorder>
            <ObjectContainer><StyledCalendar><Calendar/></StyledCalendar><SmallerText>{dateStringify(drug.date!)}</SmallerText></ObjectContainer>
        </Grid>}
        <Grid item xs={12} sm={10}>
          <GridContainer textData={'data leczenia danym LEKIEM w 1L'}
                         textContent={'Nazwa leku'} content={drug.title}
                         date={drug.date}/>
        </Grid>
      </Grid>
    </Container>
  )

})
