import { Modal } from '../../../../shared/modal/Modal';

import React, { FC } from 'react';
import {
  ConfirmationTextModal,
  TextDocumentContainer,
  MoneyContainer
} from '../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled';
import { ContentModal } from '../../../../shared/modal/ContentModal';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Money } from './../../../../../assets/icon/money.svg';

interface IProps {
  closeModal: () => void;
  handleNo: () => void;
  handleYes: () => void;
}

export const PayDocumentModal: FC<IProps> = observer(({
                                                        closeModal,
                                                        handleYes,
                                                        handleNo
                                                      }) => {
  return (
    <Modal clickHandler={() => closeModal()} minHeight={'250px'}>
      <ContentModal
        text={<ConfirmationTextModal>
          <MoneyContainer><Money /></MoneyContainer>
          <TextDocumentContainer>
            <p>Czy jesteś pewien, że chcesz oznaczyć dokument jako opłacony?</p>
          </TextDocumentContainer>
        </ConfirmationTextModal>}
        handleYes={() => handleYes()}
        handleNo={() => handleNo()}
      />
    </Modal>);
});