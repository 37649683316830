import {GenderIcon} from "../genderIcon/GenderIcon";
import {ReactComponent as GenderFemaleIcon} from "../../../../../../../assets/icon/genderFemale.svg";
import {ReactComponent as GenderMaleIcon} from "../../../../../../../assets/icon/genderMale.svg";
import React, {FC} from "react";
import {SwitchCustom} from "./SwitchGender.styled";

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value: boolean;
  name: string
}

export const SwitchGender: FC<IProps> = ({onChange, name, value}) => {

  return (
    <SwitchCustom
      name={name}
      classes={{
        checked: 'checkedSwitch'
      }}
      onChange={onChange}
      checked={value}
      sx={{height: 36, width: 100, padding: 0}}
      checkedIcon={
        <GenderIcon><GenderFemaleIcon/></GenderIcon>}
      icon={<GenderIcon><GenderMaleIcon/></GenderIcon>}
    />
  )
}

