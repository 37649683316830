import { useAuthStore, useCaseStore } from '../../../../../store/hooks';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import { mapStatus } from './HeaderCase.helper';
import { RoleFunction } from '../../../../../constants/projectUsers';
import { useLocation } from 'react-router-dom';
import { IObjType } from '../../../../../interfaces/user/case';
import { extractCaseTypeFromUrl } from '../../../../../utils/extractCaseTypeFromUrl';

export const useHeaderCase = () => {
  const { roleFunction } = useAuthStore();
  const location = useLocation();
  const { pathname } = location;

  const caseStore = useCaseStore();
  const { viewCaseNeuro, viewCaseLung } = caseStore;
  const breakpoint = useBreakpoint();

  const viewCaseObj: Record<string, any> = {
    neuro: viewCaseNeuro,
    lung: viewCaseLung
  };

  const statusElement = {
    neuro: mapStatus(caseStore.viewCaseNeuro?.status),
    lung: mapStatus(caseStore.viewCaseLung?.status)
  };

  const isUserAccepting = roleFunction === RoleFunction.ROLE_PUBLISHING;

  const type = extractCaseTypeFromUrl(pathname);

  const viewCase = viewCaseObj[type as keyof IObjType];

  return {
    isUserAccepting,
    viewCase,
    breakpoint,
    statusElement: statusElement[type as keyof IObjType]
  };
};