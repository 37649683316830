import styled from 'styled-components';
import theme from '../../../../theme/theme';
import { Grid } from '@mui/material';

export const ProfileContainer = styled(Grid)`
  margin-bottom: 16px;
  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 0 16px;
  }
`;

export const EditButtonContainer = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-right: 20px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-right: 12px;
    gap: 8px;
    height: 27px;
  }
`;
export const EditButton = styled.div`
  cursor: pointer;
  background: ${theme.colors.blue3};
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakpoints.small}px) {
    height: 21px;
    width: 21px;
    & svg {
      height: 9px;
      width: 9px;
    }
  }
`;

export const UserProfileIconContainer = styled.div`
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 30px;
  margin-top: 25px;
  line-height: 41px;
  margin-bottom: 12px;
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 20px;
    line-height: 27px;
    display: flex;
    width: 100%
  }
`;

export const ProfileElement = styled.p`
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 300;
`;

export const ResetLinkContainer = styled.p`
  text-decoration: underline;
  margin-bottom: 18px;
`;

export const ResetLink = styled.span`
  text-decoration: underline;
  margin-bottom: 18px;
  font-weight: 700;
  cursor: pointer;
`;

export const ButtonsReckoningContainer = styled.div`
  display: flex;
  gap: 28px;
  margin: 10px 0 20px 0px;
  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
`;

export const StyledButton = styled.div`
  @media (max-width: ${theme.breakpoints.small}px) {
    display: flex;
    justify-content: center;

    & button {
      margin-bottom: 0;
    }
  }
`;