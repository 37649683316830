import styled from "styled-components";
import theme from "../../../../../theme/theme";
import {Tabs} from '@mui/material';


export const TabsCustom = styled(Tabs)`

  button{
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    top: 4px;
  }

  button:not(.Mui-selected){
    background: #e0e0e0;
  }
  
  .MuiTabs-indicator { 
    display: none; 
  } 
  
  .Mui-selected{
    border-radius: 4px 4px 0 0;
    background: white;
    box-shadow: 2px -2px 3px 0 #e0e0e0;
  }
  
  button{
    color: #333333 !important;
  }
`
export const Item = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  cursor: pointer;
  text-decoration: none;
  color: ${theme.colors.dark1}
`

export const Header = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  gap: 13px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 20px;
    line-height: 27px;
    width: 100%;
    margin-bottom: 16px;
  }

  & svg {
    flex-shrink: 0;

    path {
      stroke: ${theme.colors.dark1};
    }
  }
`
export const Subheader = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  margin-bottom: 8px;
  color: ${theme.colors.dark1};
`

export const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding-top: 3px;
`

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Section = styled.div`
  height: 100%;
  overflow: auto;
  padding: 32px;
  background: white;
  
  & svg {
    margin-right: 13px;
  }
`