export const fileDownload = (res: BlobPart, isZip: boolean = false, documentType: string) => {
  const blob = new Blob([res], {type: isZip ? 'application/zip' : documentType === 'pdf' ? 'application/pdf' : undefined});
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `download.${isZip ? 'zip' : documentType}`);
  document.body.appendChild(link);
  link.click();
}

export const openFile = async (id: number, request: (id: number) => Promise<ArrayBuffer>) => {
  const res = await request(id);
  const blob = new Blob([res], {type: 'application/pdf'});
  const url = URL.createObjectURL(blob);
  window.open(url);
}