import {DoctorImg, Header, Section, StyledButton, Subheader} from "./viewUserHome.styled";
import React from "react";
import {Grid} from '@mui/material'
import doctorHospitalBcg from "../../../../../assets/img/doctorHospitalBcg.png";
import {CaseCard} from "../../../../shared/user/cards/CaseCard";
import {NoCases} from "../publishUserHome/publishUserHome.styled";
import {useViewUserHome} from "./useViewUserHome.vm";
import {observer} from "mobx-react-lite";
import {Button} from "../../../../shared/button/Button";
import theme from "../../../../../theme/theme";

export const ViewUserHome = observer(() => {
  const {navigateToOthersCases, listOfOfLast6Cases, fullName, affiliation, navigateToCases} = useViewUserHome()

  return (
    <>
      <Grid item xs={12} sm={6} justifyContent={'space-between'} display={'flex'} height={'100%'}
            flexDirection={'column'}>
        <Header>Miło Cię widzieć, <br/>
          <span>{affiliation} {fullName}</span></Header>
        <DoctorImg src={doctorHospitalBcg} margin={'0 auto'}/>
        <StyledButton><Button clickHandler={navigateToCases} color={theme.colors.beige}
                              backgroundColor={theme.colors.dark1}
                              text={'zobacz przypadki'} size={'small'} margin={'-36px auto 0'}
        /></StyledButton>
      </Grid>
      <Grid item xs={12} sm={6} justifyContent={'center'} display={'flex'} flexDirection={'column'}
            alignItems={'center'}>
        <Subheader>Zobacz najnowsze opisy przypadków i aktywności:</Subheader>
        <Section>
          {listOfOfLast6Cases && listOfOfLast6Cases.length !== 0 ?
            <>{listOfOfLast6Cases.map(({
                                         status,
                                         id,
                                         customId,
                                         patient,
                                         title,
                                         updatedAt,
                                         type
                                       }) => (
              <Grid item xs={6} lg={4} p={1} key={id}>
                <CaseCard key={id} id={id} status={status} customId={customId} patient={patient} updatedAt={updatedAt}
                          title={title} type={type}/></Grid>
            ))}
              <Grid item xs={12}>
                <Button color={theme.colors.beige} backgroundColor={theme.colors.dark1}
                        text={'zobacz więcej'} size={'small'} margin={'14px auto 0'}
                        maxWidth={'244px'}
                        clickHandler={navigateToOthersCases}/>
              </Grid>
            </>
            : <NoCases>Nie ma jeszcze żadnych dodanych opisów przypadków.</NoCases>}
        </Section>
      </Grid>
    </>
  )
})