import React, { useState } from 'react';
import openEye from './../../../../assets/icon/openEye.svg';
import closedEye from './../../../../assets/icon/closedEye.svg';
import { Type } from '../../../../interfaces/common/form/formData';
import { Label } from '../label/LabelContainer';
import { InputContainer, InputElement, PasswordIcon, TextareaElement } from './Input.styled';
import { Size } from '../Form.helper';

interface IProps {
  name: string;
  type?: Type;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string | JSX.Element;
  isUpperCase?: boolean;
  color?: string;
  isRequired?: boolean;
  isError?: boolean;
  textError?: string | boolean;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  readonly?: boolean;
  component?: JSX.Element;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  size?: Size;
  maxWidth?: string;
  width?: string;
  height?: string;
  margin?: string;
  after?: JSX.Element;
  before?: JSX.Element;
}

export const Input: React.FC<IProps> = ({
                                          name,
                                          type,
                                          value,
                                          onChange,
                                          label,
                                          isUpperCase = false,
                                          placeholder,
                                          textError,
                                          color = 'black',
                                          isError = false,
                                          isRequired = false,
                                          readonly = false,
                                          maxLength,
                                          disabled = false,
                                          component,
                                          onBlur,
                                          size = 'medium',
                                          maxWidth,
                                          width,
                                          height,
                                          before,
                                          after,
                                          margin
                                        }) => {

  const [passwordIsVisible, setPasswordVisible] = useState(false);
  const textFieldType = type === 'password' ? passwordIsVisible ? 'text' : 'password' : type;

  const content = component ? component :
    type === 'textarea' ?
      <TextareaElement
        isError={isError}
        maxLength={maxLength}
        readOnly={readonly}
        disabled={disabled}
        id={name}
        width={width}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxWidth={maxWidth}
        height={height}
      /> :
      <>
        <InputElement
          margin={margin}
          isError={isError}
          maxLength={maxLength}
          readOnly={readonly}
          disabled={disabled}
          type={textFieldType}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          maxWidth={maxWidth}
          height={height}
        />
        {type === 'password' && <PasswordIcon src={!passwordIsVisible ? openEye : closedEye}
                                              onClick={() => setPasswordVisible(!passwordIsVisible)} />}
      </>;
  return (
    <InputContainer isRequired={isRequired} type={type ?? 'text'} width={width}>

      <>{label ?
        <><Label before={before} isUpperCase={isUpperCase} size={size} content={content} color={color} label={label}
                 isRequired={isRequired} /></>
        : <>{before}{content}</>
      }</>
      {isError && textError && <p>{textError}</p>}
      {after}
    </InputContainer>
  );
};

