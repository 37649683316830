import { makeAutoObservable } from 'mobx';

import { RoleCategory, RoleFunction } from '../constants/projectUsers';
import { RootStore } from './rootStore';
import { checkRoleCategory, generateNavigationLink } from '../utils';
import { IAreas, ISituationsCount, IUserLoginResponse } from '../interfaces/admin/user/userLoginResponse';
import { INavLink } from '../interfaces/common/link';
import { generateNavbar } from '../components/shared/admin/layout/AdminLayout.helper';
import { AddCaseActionStatus } from '../constants/addCaseStatus';

export class AuthStore {
  isUserLoad = false;
  isLoggedIn = false;
  isSessionExpired = false;
  role: string = '';
  fullName: string = '';
  affiliation?: string = '';
  roleCategory: RoleCategory = RoleCategory.GUEST;
  roleFunction: RoleFunction = RoleFunction.ROLE_GUEST;
  navigationLink: string | null = null;
  links: INavLink[] | null = null;
  addCaseActionStatus: AddCaseActionStatus = AddCaseActionStatus.WAITING;
  situations: ISituationsCount | null = null;
  areas: IAreas[] | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  appLogin(response: IUserLoginResponse) {
    this.isLoggedIn = true;
    this.roleFunction = response.roleFunction;
    this.role = response.role;
    this.fullName = response.fullName;
    this.affiliation = response.affiliation;
    this.roleCategory = checkRoleCategory(response.roleFunction);
    this.navigationLink = generateNavigationLink(response.roleFunction, this.roleCategory);
    this.links = generateNavbar(response.roleFunction);

    if (response.areas) {
      this.areas = response.areas.map(el => ({
        ...el,
        shortName: el.shortName.toLowerCase(),
        addCaseActionStatus: AuthStore.checkAddCaseStatus((el.accept + el.sent), el.limit)
      }));
    }

    this.setIsSessionExpired(false);
    this.setIsUserLoad(true);
  }

  appLogout() {
    this.isLoggedIn = false;
    this.role = '';
    this.roleCategory = RoleCategory.GUEST;
    this.roleFunction = RoleFunction.ROLE_GUEST;
    this.clearNavigationLink();
    this.setIsSessionExpired(false);
  }

  userLoad() {
    this.setIsUserLoad(true);
  }

  saveNavigationLink(navigationLink: string) {
    this.navigationLink = navigationLink;
  }

  clearNavigationLink() {
    this.navigationLink = null;
  }

  private setIsUserLoad(isUserLoad: boolean) {
    this.isUserLoad = isUserLoad;
  }

  setIsSessionExpired(isSessionExpired: boolean) {
    this.isSessionExpired = isSessionExpired;
  }

  private static checkAddCaseStatus(situations: number, limit: number) {
    if (situations >= limit) {
      return AddCaseActionStatus.BLOCKED;
    } else if (situations === (limit - 1)) {
      return AddCaseActionStatus.CLOSE_TO_BLOCKED;
    } else {
      return AddCaseActionStatus.OPEN;
    }
  }
}
