import React, {FC} from "react";
import {FieldsetContainer, FieldsetLegend} from "./FieldsetContainer.styled";
import {ILabelFieldsetProps} from "../Form.helper";

export interface IProps extends ILabelFieldsetProps {
  children: JSX.Element;
}

export const Fieldset: FC<IProps> = ({isUpperCase, extraText, color, label, children, size, textAlign}) => {

  return (
    <FieldsetContainer>
      <FieldsetLegend isUpperCase={isUpperCase} color={color} size={size} textAlign={textAlign}>
        {label}
        {extraText && <p>{extraText}</p>}
      </FieldsetLegend>
      {children}
    </FieldsetContainer>
  )
}
