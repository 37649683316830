import { ReactComponent as Checkbox } from './../../../../../../assets/icon/checkboxCheck.svg';
import { Container, ImgContainer, DotContainer } from './ViewCheckbox.styled';
import React from 'react';

interface IProps {
  type?: 'dot' | 'checkbox';
  children: JSX.Element | string;
  margin?: string;
}

export const ViewCheckbox: React.FC<IProps> = ({ type, children, margin }) => {
  return (
    <Container $margin={margin}>
      {type && <>{type === 'checkbox' ? <ImgContainer>
        <Checkbox />
      </ImgContainer> : <DotContainer />}</>}
      <p>{children}</p>
    </Container>
  );
};