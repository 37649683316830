import { AdminLink } from './generateLink';

export const switchHistoryLink = (type: string) => {
  switch (type) {
    case 'neuro':
      return AdminLink.HISTORY_NEURO;
    case 'lung':
      return AdminLink.HISTORY_LUNG;
  }
};

export function getPathType(path: string) {
  const segments = path.split('/').slice(1); // Usunięcie pierwszego pustego elementu
  switch (true) {
    case segments.includes('neuro'):
      return 'neuro';
    case segments.includes('lung'):
      return 'lung';
    default:
      return '';
  }
}