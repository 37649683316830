import styled from "styled-components";
import theme from "../../../../../../../theme/theme";
import {Grid} from "@mui/material";

export const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
  letter-spacing: -0.05em;
  color: ${theme.colors.blue3};
  padding-bottom: 15px;
`

export const Container = styled(Grid)`
  margin: auto 64px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: auto auto;
  }
`

export const Paragraph = styled.p`
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.red};
  align-self: flex-end;
  margin: 0 auto 0 0;

`

export const ModalTitle = styled.div`
  top: 80px;
  width: auto;
  height: 55px;
  padding: 0 20px;
  align-self: flex-start;
  margin-left: -65px;
  border-radius: 0 30px 30px 0;
  line-height: 55px;
  background-color: ${theme.colors.turquoise2};
  font-weight: 800;
  font-size: 20px;
  color: ${theme.colors.beige};
  text-align: center;
  text-transform: uppercase;

  @media (max-width: ${theme.breakpoints.medium}px) {
    margin-left: -81px;
  }
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-left: -16px;
  }
`
