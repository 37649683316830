import React from 'react';
import { useLocation } from 'react-router-dom';
import { AdminLink, generateLink } from '../../../../../../utils';
import { RoleCategory } from '../../../../../../constants/projectUsers';
import { Button } from '../../../../../shared/button/Button';
import { Grid } from '@mui/material';
import theme from '../../../../../../theme/theme';

export const NavigationButtons = () => {
  const location = useLocation();

  const listOfButtons = [{
    text: 'ogólne',
    link: generateLink(RoleCategory.ADMIN, AdminLink.STATISTICS) + '/general'
  }, {
    text: 'publikujący',
    link: generateLink(RoleCategory.ADMIN, AdminLink.STATISTICS) + '/users'
  }, {
    text: 'rozliczenia',
    link: generateLink(RoleCategory.ADMIN, AdminLink.STATISTICS) + '/reckonings'
  }, {
    text: 'opisy przypadków',
    link: generateLink(RoleCategory.ADMIN, AdminLink.STATISTICS) + '/cases'
  }];

  return (
    <Grid container gap={2}>
      {listOfButtons.map(({ text, link }) => (
        <Button text={text}
                link={link}
                key={text}
                padding={'16px'}
                maxWidth={'166px'}
                size={'small'}
                width={'unset'}
                border={location?.pathname === link ? theme.colors.white : theme.colors.blue3}
                color={location?.pathname === link ? theme.colors.white : theme.colors.blue3}
                backgroundColor={location?.pathname === link ? theme.colors.blue3 : theme.colors.white}
                fontSize={'12px'}
        />))}
    </Grid>
  );
};
