import styled from "styled-components";
import theme from "../../../../theme/theme";

export const ButtonContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  margin: 33px 0 15px 0;

  & button:first-child {
    & path {
      fill: ${theme.colors.dark1} !important;
    }
  }
;

  & button:last-child {
    & path {
      fill: ${(props) => props.color}
    }
  }
;
`
export const StyledLink = styled.div`
  display: block;
  text-decoration: none;
  width: 100%
`

export const Paragraph = styled.p`
  color: ${theme.colors.red};
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
`