import { IImage } from '../image';

export type Gender = 'M' | 'W';

export enum CaseStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  WAIT = 'WAIT',
}


export interface IDraftCaseNeuro {
  id: number;
  step: number;
  title: string;
  criteria: string;
  initials: string;
  gender: Gender;
  status: CaseStatus;
  yearOfBirth: number;
  diagnosisDate: string | null;
  diagnosisAge: number | null;
  diagnosisIsFluidTesting: boolean | null;
  projectileActivityDate: string | null;
  projectileActivityDescription: string | null;
  projectileActivityTreatment: string | null;
  projectileActivityAssessment: string | null;
  resonanceActivityDescription: string | null;
  resonanceActivityChanges: string | null;
  resonanceActivityNewChanges: string | null;
  assessmentDate: string | null;
  assessmentAssessment: string | null;
  assessmentDescription: string | null;
  assessmentDescriptionChanges: string | null;
  assessmentNewChanges: string | null;
  diagnosisImages: IImage[];
  assessmentImages: IImage[];
  drugs: IDrug[];
  comment: string | null;
}

export interface IDrug {
  id?: string;
  title: string;
  date: string;
  comments: string | null;
  dateEnd: string | null;
  descriptionChanges: string | null;
  descriptionReactions: string | null;
  images?: IImage[];
  incidents?: IIncident[];
}

export interface IIncident {
  id?: string;
  date: string;
  description: string;
}