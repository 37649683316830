import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const Header = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 13px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 20px;
    line-height: 27px;
    width: 100%;
    margin-bottom: 16px;
  }

  & svg {
    flex-shrink: 0;

    path {
      stroke: ${theme.colors.dark1};
    }
  }
`
export const Subheader = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  margin-bottom: 30px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-bottom: 16px;
    margin-top: 8px;
    font-size: 16px;
    line-height: unset;
    text-align: start;
  }

  & span {
    color: ${theme.colors.turquoise2};
    text-transform: uppercase;
  }

`

export const ImgContainer = styled.div`
  width: 36px;
  height: 36px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.blue3};
  margin-right: 12px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-right: 6px;

  }

  & svg {
    width: 16px;
    height: 16px;
  }
`

export const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding-top: 3px;
`

export const StyledInfo = styled.div`
  font-weight: 400;
  font-size: 10px;
  height: 155px;
  padding: 24px;
  background: #EBF5FF;
  border-radius: 28px;
  color: ${theme.colors.blue3};
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.small}px) {
    align-items: center;
  }

  & h4 {
    font-weight: 700;
    text-transform: uppercase;
  }

`