import { useAuthStore } from '../../../../store/hooks';
import { useAuthApi } from '../../../../apiHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { GeneralLink, generateLink, UserLink } from '../../../../utils';
import { RoleCategory, RoleFunction } from '../../../../constants/projectUsers';

export const useUserTop = (isProfile: boolean) => {
  const location = useLocation();
  const authStore = useAuthStore();
  const { fullName, roleFunction } = authStore;
  const authApi = useAuthApi();
  const navigate = useNavigate();

  const profileHandler = async () => {
    if (roleFunction !== RoleFunction.ROLE_ACCEPTING && isProfile) {
      navigate(generateLink(RoleCategory.USER, UserLink.PROFILE));
    }
  };

  const logoutHandler = async () => {
    const isLogout = await authApi.serverLogoutUser();
    if (isLogout) {
      navigate(generateLink(RoleCategory.GUEST, GeneralLink.LOGIN));
    }
  };

  const isActiveLink = location.pathname === '/profile';
  const isActiveProfile = roleFunction !== RoleFunction.ROLE_ACCEPTING;

  return {
    isActiveLink,
    isActiveProfile,
    profileHandler,
    logoutHandler,
    fullName
  };
};