import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { Grid } from "@mui/material";
import React from "react";
import { observer } from 'mobx-react-lite'
import { PublishUserHome } from "./publishUserHome/PublishUserHome";
import { useAuthStore } from "../../../../store/hooks";
import { RoleFunction } from "../../../../constants/projectUsers";
import { ViewUserHome } from "./viewUserHome/ViewUserHome";

export const Home = observer(() => {
  const authStore = useAuthStore();
  const {roleFunction} = authStore;
  return <UserLayout>
    <Grid container
          display={'flex'}
          margin={'0 auto'}
          alignItems={'center'}
          height={'100%'}
          mt={2}
    >
      {RoleFunction.ROLE_PUBLISHING === roleFunction ? <PublishUserHome/> : <ViewUserHome/>}
    </Grid>
  </UserLayout>
})
