import {useNavigate} from "react-router-dom";
import {IBreadcrumbsElement} from "../../../../interfaces/user/breadcrumbs";
import {useCasesStore, useReckoningStore} from '../../../../store/hooks';
import {CaseStatus} from "../../../../interfaces/user/draftCaseNeuro/draft";
import {generateLink, UserLink} from "../../../../utils";
import {RoleCategory} from "../../../../constants/projectUsers";
import {useReckoningApi} from '../../../../apiHooks/useReckoningApi';
import {useEffect} from 'react';

export const useMyReckoning = () => {
  const navigate = useNavigate();
  const {switchFilters} = useCasesStore()
  const reckoningStore = useReckoningStore();
  const {reckoningDashboard, isReckoningDashboardLoaded, contractType} = reckoningStore;
  const {getReckoningDashboard} = useReckoningApi();

  useEffect(() => {
    if (!isReckoningDashboardLoaded) {
      if (reckoningDashboard === null) {
        void getReckoningDashboard()
      } else {
        void getReckoningDashboard(false)
      }
    }
  }, [isReckoningDashboardLoaded, reckoningDashboard, getReckoningDashboard])

  useEffect(() => {
    return () => {
      reckoningStore.resetReckoningDashboard();
    }
  }, [reckoningStore])

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      name: "Moje rozliczenia"
    }];


  const showMore = (status: CaseStatus) => {
    switchFilters(status);
    navigate(generateLink(RoleCategory.USER, UserLink.MY_CASES))
  }

  const reckoningSummaryHandler = () => {
    navigate(generateLink(RoleCategory.USER, contractType === 'company' ? UserLink.PROFILE_FORM_INVOICE_SUMMARY : contractType === 'user' ? UserLink.PROFILE_FORM_CONTRACT_SUMMARY : UserLink.PROFILE_SELECT_FORM))
  }

  const isProcessActive = contractType !== "none";

  const acceptedList = reckoningDashboard ? [...reckoningDashboard?.acceptedList].splice(0, 3) : []
  const rejectedList = reckoningDashboard ? [...reckoningDashboard?.rejectedList].splice(0, 3) : []

  const buttonText = contractType === 'none' ? 'wybierz formę rozliczenia' : 'twoje dane rozliczeniowe';

  return {
    buttonText,
    isProcessActive,
    showMore,
    reckoningDashboard,
    acceptedList,
    rejectedList,
    breadcrumbs,
    navigate,
    reckoningSummaryHandler
  }
}

