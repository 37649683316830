import { months } from '../../../../../../../utils';
export const START_YEAR = 2023;

export const generateOptionsForSelectYear = (prefix?: string) => {
  const currentYear = new Date().getFullYear();

  const yearOptions = [];

  for (let year = START_YEAR; year <= currentYear; year++) {
    yearOptions.push({id: year, title: prefix ? `${prefix}${year}` : String(year)});
  }

  return yearOptions;
}

export const generateOptionsForSelectMonth = (year: string) => {
  const currentDate = new Date();

  if(currentDate.getFullYear().toString() === year){
    const month = currentDate.getMonth();
    return months.slice(0, month + 1)
  }

  return months;
}