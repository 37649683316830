import styled from 'styled-components';
import theme from '../../../../theme/theme';
import navbarBackground from '../../../../assets/img/navbarBackground.png';
import { Grid } from '@mui/material';

export const StyledUserLayoutContent = styled.div`
  min-height: calc(100vh - 170px);
  margin: 0 auto;
  @media (max-width: ${theme.breakpoints.small}px) {
    height: calc(100vh - 140px);
  }

`;

export const Header = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: ${theme.colors.blue3};
`;
export const Img = styled.img`
  width: 120px;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${theme.colors.dark1};
  padding-bottom: 8px;

  & a {
    color: ${theme.colors.dark1};
    font-weight: 700;
  }
`;

export const ImgLayer = styled.div`
  cursor: pointer;
  margin: 0 auto;
`;

export const DoctorImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  max-width: 537px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 auto;
  }
`;


export const NavigationContent = styled.div`
  max-width: 1280px;
  margin: 82px auto 0 auto;

  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 72px auto 0 auto;
    flex-direction: column;
    display: flex;
  }
`;

export const NavigationContainer = styled.div`
  position: relative;
  background-image: url(${navbarBackground});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  margin-bottom: 10px;
  width: 100%;
  height: 120px;
  max-height: 120px;
  padding: 0 35px;
  @media (max-width: ${theme.breakpoints.small}px) {
    background-size: auto;
    background-position: top left;
    height: unset;
    max-height: unset;
  }
`;

export const UserLayoutContainer = styled(Grid)`
  background: ${theme.colors.beige};
  margin: 0 auto;

  & footer {
    & div:last-child {
      display: none;
    }
  }
`;