import styled from 'styled-components';
import navbarBackground from '../../../../../assets/img/navbarBackground.png';
import theme from '../../../../../theme/theme';


interface IImgLayer {
  isActive: boolean;
}

export const ImgLayer = styled.div<IImgLayer>`
  cursor: ${props => props.isActive ? 'pointer' : 'normal'};
`;

export const NavigationContent = styled.div`
  max-width: 1280px;
  margin: 32px auto 0 auto;
  display: flex;
  height: 58px;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 72px auto 0 auto;
    flex-direction: column;
    justify-content: center;
  }
`;

export const NavigationContainer = styled.div`
  position: relative;
  background-image: url(${navbarBackground});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  width: 100%;
  height: 118px !important;
  max-height: 120px;
  padding: 0 35px;
  overflow: unset !important;
  @media (max-width: ${theme.breakpoints.small}px) {
    background-size: auto;
    background-position: top left;
    height: unset !important;
    max-height: unset !important;
  }
`;

export const Img = styled.img`
  width: 120px;
`;

