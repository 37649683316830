import { UserLayout } from "../../../../shared/user/layout/UserLayout";
import { Grid } from "@mui/material";
import React from "react";
import { FlexDiv, Header, RedCircle, Subheader } from './SelectForm.styled'
import { ReactComponent as Settlement } from './../../../../../assets/icon/settlement.svg'
import { Button } from "../../../../shared/button/Button";
import { RoleCategory } from "../../../../../constants/projectUsers";
import { generateLink, UserLink } from "../../../../../utils";
import theme from "../../../../../theme/theme";
import { useSelectFormVm } from "./useSelectForm.vm";

export const SelectForm = () => {
  const {breadcrumbs} = useSelectFormVm()
  return (
    <UserLayout breadcrumbs={breadcrumbs} isDoctorVisible={true} isProfileContainer={true}>
      <Grid container display={'flex'}>
        <Grid item xs={12} alignSelf={'flex-start'}>
          <Header><Settlement/> Twoje dane rozliczeniowe:</Header>
        </Grid>
        <Grid item xs={12} display={'flex'} flexDirection={'column'} alignSelf={'flex-start'} justifyContent={'center'}
              alignItems={'center'}>
          <RedCircle><Settlement/></RedCircle>
          <Subheader>Wybierz formę rozliczenia:</Subheader>
          <FlexDiv>
            <Button text={'faktura'} size={'small'} width={'165px'} color={theme.colors.beige}
                    backgroundColor={theme.colors.dark1}
                    link={generateLink(RoleCategory.USER, UserLink.PROFILE_FORM_INVOICE)} fontSize={'12px'}/>
            <Button text={'umowa o dzieło'} size={'small'} color={theme.colors.beige}
                    backgroundColor={theme.colors.dark1} width={'165px'}
                    link={generateLink(RoleCategory.USER, UserLink.PROFILE_FORM_CONTRACT)} fontSize={'12px'}/>
          </FlexDiv>
        </Grid>
      </Grid>
    </UserLayout>
  )
}
