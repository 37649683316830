import {useRolesApi} from '../../../../../apiHooks/useRolesApi';

import {IRoleForm} from '../../../../../interfaces/admin/role/roleForm';
import {useModal} from '../../../../shared/modal/useModal.vm';
import {ModalType} from '../../../../../constants/modalType';
import {useNavigate, useParams} from 'react-router-dom';
import {useCallback, useEffect} from 'react';
import {useRolesStore} from '../../../../../store/hooks/useRolesStore';
import {AdminLink, generateLink} from '../../../../../utils';
import {RoleCategory} from '../../../../../constants/projectUsers';

export const useEditRole = () => {
  const {editRole, getRoleEdit} = useRolesApi()
  const rolesStore = useRolesStore();
  const {roleEdit} = useRolesStore();
  const {handleModal, isModalOpened, closeModal, modalType} = useModal();
  const {id} = useParams();
  const navigate = useNavigate();

  const getRole = useCallback(async (id: number) => {
    const isEdit = await getRoleEdit(+id);
    if (!isEdit) {
      navigate(generateLink(RoleCategory.ADMIN, AdminLink.ROLES));
    }
  }, [getRoleEdit, navigate]);
  
  useEffect(() => {
    if (id && roleEdit === null) {
      void getRole(+id);
    }
  }, [id, getRole, roleEdit, rolesStore])

  useEffect(() => {
    return () => rolesStore.clearRoleEdit();
  }, [rolesStore])

  const actionHandler = async (roleForm: IRoleForm) => {
    if (id) {
      await editRole(roleForm, +id);
      await handleModal(ModalType.EDIT_CONFIRMATION_MODAL);
    }
    return null;
  }

  return {
    isModalOpened,
    roleEdit,
    closeModal,
    modalType,
    actionHandler
  }
}