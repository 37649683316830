import { useCaseApi } from '../../../../apiHooks/useCaseApi';
import { useEffect } from 'react';
import { useAuthStore, useCaseStore } from '../../../../store/hooks';
import { useParams } from 'react-router-dom';
import { UserLink } from '../../../../utils';
import { RoleFunction } from '../../../../constants/projectUsers';

export const useViewCase = () => {
  const { id } = useParams();
  const { roleFunction } = useAuthStore();
  const { getCaseInfoLung, getCasePdfLung } = useCaseApi();
  const caseStore = useCaseStore();
  const { viewCaseLung } = caseStore;

  const generateBreadcrumbs = () => {
    switch (roleFunction) {
      case RoleFunction.ROLE_PUBLISHING:
        return [
          {
            link: UserLink.MY_CASES,
            name: 'Moje opisy przypadków'
          },
          {
            name: 'Wyświetl opis przypadku'
          }];
      case RoleFunction.ROLE_PARTICIPANT:
        return [
          {
            link: UserLink.OTHER_CASES,
            name: 'Opisy przypadków innych'
          },
          {
            name: 'Wyświetl opis przypadku'
          }];
      default:
        return null;
    }
  };

  const casePdfHandler = async () => {
    if (id) {
      const res = await getCasePdfLung(+id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  useEffect(() => {
    if (id) {
      void getCaseInfoLung(id);
    }
  }, [id, getCaseInfoLung]);

  useEffect(() => {
    return () => caseStore.resetCaseLung();
  }, [caseStore]);

  const breadcrumbs = generateBreadcrumbs();

  return {
    breadcrumbs,
    caseInfo: viewCaseLung,
    casePdfHandler
  };
};