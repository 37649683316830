import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSecondStage } from './useSecoundStage';
import { Label } from '../../../../../shared/form/label/LabelContainer';
import theme from '../../../../../../theme/theme';
import { RadioForm } from '../firstStage/FirstStage.styled';
import { RadioButton } from '../../../../../shared/form/radioButton/RadioButton';
import { convertBooleanRadio } from './secondStage.helper';
import { Input } from '../../../../../shared/form/input/Input';
import { checkEmptyNumber } from '../firstStage/firstStage.helper';
import { DateInput } from '../../../../../shared/form/date/Date';


export const SecondStage = observer(() => {
  const {
    formik,
    changeSmockingFields
  } = useSecondStage();

  return (<Grid container justifyContent={'space-between'} flexDirection={'column'}>
    <Grid item xs={12} md={6}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2} label={'Palenie tytoniu'}
             isRequired={true} />
      <RadioForm>
        <RadioButton
          isError={formik.touched.interview?.smockingTobacco?.isActive && Boolean(formik.errors.interview?.smockingTobacco?.isActive)}
          id={'interview.smockingTobacco.isActive1'}
          name={'interview.smockingTobacco.isActive'}
          value={'tak'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.smockingTobacco.isActive) === 'tak'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.smockingTobacco.isActive', newValue);
          }}
        />
        <RadioButton
          isError={formik.touched.interview?.smockingTobacco?.isActive && Boolean(formik.errors.interview?.smockingTobacco?.isActive)}
          id={'interview.smockingTobacco.isActive2'}
          name={'interview.smockingTobacco.isActive'}
          value={'nie'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.smockingTobacco.isActive) === 'nie'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.smockingTobacco.isActive', newValue);
          }}
        />
      </RadioForm>
    </Grid>
    {formik.values.interview.smockingTobacco.isActive && <Grid container gap={{ md: '24px', xs: '16px' }} mt={1}>
      <Grid item xs={10} md={3}>
        <Input
          isRequired={true}
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Liczba lat'}
          isUpperCase={true}
          name={'interview.smockingTobacco.years'}
          type={'number'}
          value={checkEmptyNumber(Number(formik.values.interview.smockingTobacco.years), 1)}
          onChange={(e) => changeSmockingFields('years', e.target.value)}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.interview?.smockingTobacco?.years && Boolean(formik.errors.interview?.smockingTobacco?.years)}
        />
      </Grid>
      <Grid item xs={10} md={4}>
        <Input
          isRequired={true}
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Liczba paczek dziennie'}
          isUpperCase={true}
          name={'interview.smockingTobacco.amount'}
          type={'number'}
          value={checkEmptyNumber(Number(formik.values.interview.smockingTobacco.amount ), 1)}
          onChange={(e) => changeSmockingFields('amount', e.target.value)}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.interview?.smockingTobacco?.amount && Boolean(formik.errors.interview?.smockingTobacco?.amount)}
        />
      </Grid>
      <Grid item xs={10} md={4}>
        <Input
          disabled
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Liczba paczkolat'}
          isUpperCase={true}
          name={'interview.smockingTobacco.packYear'}
          type={'number'}
          value={checkEmptyNumber(Number(formik.values.interview.smockingTobacco.packYear))}
        />
      </Grid>
    </Grid>}
    <Grid item xs={12} md={6} mt={2}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2} label={'Kaszel'}
             isRequired={true} />
      <RadioForm>
        <RadioButton
          isError={formik.touched.interview?.cough?.isActive && Boolean(formik.errors.interview?.cough?.isActive)}
          id={'interview.cough.isActive1'}
          name={'interview.cough.isActive'}
          value={'tak'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.cough.isActive) === 'tak'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.cough.isActive', newValue);
          }}
        />
        <RadioButton
          isError={formik.touched.interview?.cough?.isActive && Boolean(formik.errors.interview?.cough?.isActive)}
          id={'interview.cough.isActive2'}
          name={'interview.cough.isActive'}
          value={'nie'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.cough.isActive) === 'nie'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.cough.isActive', newValue);
          }}
        />
      </RadioForm>
    </Grid>
    {formik.values.interview.cough.isActive && <Grid item xs={12} md={6} pl={2} mt={2}>
      <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1} label={'Czy kaszel jest przewlekły'}
             isRequired={true} />
      <RadioForm>
        <RadioButton
          isError={formik.touched.interview?.cough?.isChronic && Boolean(formik.errors.interview?.cough?.isChronic)}
          id={'interview.cough.isChronic1'}
          name={'interview.cough.isChronic'}
          value={'tak'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.cough.isChronic) === 'tak'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.cough.isChronic', newValue);
          }}
        />
        <RadioButton
          isError={formik.touched.interview?.cough?.isChronic && Boolean(formik.errors.interview?.cough?.isChronic)}
          id={'interview.cough.isChronic2'}
          name={'interview.cough.isChronic'}
          value={'nie'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.cough.isChronic) === 'nie'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.cough.isChronic', newValue);
          }}
        />
      </RadioForm>
    </Grid>}
    {formik.values.interview.cough.isChronic && <><Grid item md={4} xs={10} pl={2} mt={2}>
      <DateInput
        size={'superSmall'}
        openTo={'year'}
        inputFormat={'mm.yyyy'}
        views={['year', 'month']}
        isRequired={true}
        maxWidth={'385px'}
        label={'Od kiedy?'}
        isUpperCase={true}
        name={'interview.cough.dateFrom'}
        color={theme.colors.dark1}
        value={formik.values.interview.cough.dateFrom}
        onChange={(e) => formik.setFieldValue('interview.cough.dateFrom', e)}
        onBlur={() => formik.setFieldTouched('interview.cough.dateFrom', true)}
        isError={formik.touched.interview?.cough?.dateFrom && Boolean(formik.errors.interview?.cough?.dateFrom)}
        disableFuture={true}
      />
    </Grid>
      <Grid item xs={12} pl={2}>
        <Label size={'superSmall'} isUpperCase={true} color={theme.colors.dark1}
               label={'Zmiana charakteru kaszlu w ostatnim kwartale'}
               isRequired={true} />
        <RadioForm>
          <RadioButton isUppercase={true}
                       isError={formik.touched.interview?.cough?.lastQuarterChange && Boolean(formik.errors.interview?.cough?.lastQuarterChange)}
                       id={'interview.cough.lastQuarterChange1.diabetes1'}
                       name={'interview.cough.lastQuarterChange'}
                       value={'tak'} checked={formik.values.interview.cough.lastQuarterChange === 'tak'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.interview?.cough?.lastQuarterChange && Boolean(formik.errors.interview?.cough?.lastQuarterChange)}
                       id={'interview.cough.lastQuarterChange2'}
                       name={'interview.cough.lastQuarterChange'}
                       value={'nie'} checked={formik.values.interview.cough.lastQuarterChange === 'nie'}
                       onChange={formik.handleChange} />
          <RadioButton isUppercase={true}
                       isError={formik.touched.interview?.cough?.lastQuarterChange && Boolean(formik.errors.interview?.cough?.lastQuarterChange)}
                       id={'interview.cough.lastQuarterChange3'}
                       name={'interview.cough.lastQuarterChange'}
                       value={'nie zgłoszono'}
                       checked={formik.values.interview.cough.lastQuarterChange === 'nie zgłoszono'}
                       onChange={formik.handleChange} />
        </RadioForm>
      </Grid></>}
    <Grid item xs={6} md={4} mt={3}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2} label={'Krwioplucie'}
             isRequired={true} />
      <RadioForm>
        <RadioButton
          isError={formik.touched.interview?.hemoptysis?.isActive && Boolean(formik.errors.interview?.hemoptysis?.isActive)}
          id={'interview.hemoptysis.isActive1'}
          name={'interview.hemoptysis.isActive'}
          value={'tak'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.hemoptysis.isActive) === 'tak'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.hemoptysis.isActive', newValue);
          }}
        />
        <RadioButton
          isError={formik.touched.interview?.hemoptysis?.isActive && Boolean(formik.errors.interview?.hemoptysis?.isActive)}
          id={'interview.hemoptysis.isActive2'}
          name={'interview.hemoptysis.isActive'}
          value={'nie'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.hemoptysis.isActive) === 'nie'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.hemoptysis.isActive', newValue);
          }}
        />
      </RadioForm>
    </Grid>
    {formik.values.interview.hemoptysis.isActive && <Grid item xs={10} md={4} pl={2} mt={2}>
      <DateInput
        size={'superSmall'}
        openTo={'year'}
        inputFormat={'mm.yyyy'}
        views={['year', 'month']}
        isRequired={true}
        maxWidth={'385px'}
        label={'Od kiedy?'}
        isUpperCase={true}
        name={'interview.hemoptysis.dateFrom'}
        color={theme.colors.dark1}
        value={formik.values.interview.hemoptysis.dateFrom}
        onChange={(e) => formik.setFieldValue('interview.hemoptysis.dateFrom', e)}
        onBlur={() => formik.setFieldTouched('interview.hemoptysis.dateFrom', true)}
        isError={formik.touched.interview?.hemoptysis?.dateFrom && Boolean(formik.errors.interview?.hemoptysis?.dateFrom)}
        disableFuture={true}
      />
    </Grid>}
    <Grid item xs={12} mt={2}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
             label={'Niezamierzona utrata masy ciała'}
             isRequired={true} />
      <RadioForm>
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.weightLoss?.status && Boolean(formik.errors.interview?.weightLoss?.status)}
                     id={'interview.weightLoss.status1'}
                     name={'interview.weightLoss.status'}
                     value={'tak'} checked={formik.values.interview.weightLoss.status === 'tak'}
                     onChange={formik.handleChange} />
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.weightLoss?.status && Boolean(formik.errors.interview?.weightLoss?.status)}
                     id={'interview.weightLoss.status2'}
                     name={'interview.weightLoss.status'}
                     value={'nie'} checked={formik.values.interview.weightLoss.status === 'nie'}
                     onChange={formik.handleChange} />
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.weightLoss?.status && Boolean(formik.errors.interview?.weightLoss?.status)}
                     id={'interview.weightLoss.status3'}
                     name={'interview.weightLoss.status'}
                     value={'nie zgłoszono'}
                     checked={formik.values.interview.weightLoss.status === 'nie zgłoszono'}
                     onChange={formik.handleChange} />
      </RadioForm>
    </Grid>
    {formik.values.interview.weightLoss.status === 'tak' && <Grid container gap={{ md: '16px', xs: 0 }} pl={2} mt={2}>
      <Grid item xs={10} md={5}>
        <DateInput
          size={'superSmall'}
          openTo={'year'}
          inputFormat={'mm.yyyy'}
          views={['year', 'month']}
          isRequired={true}
          maxWidth={'385px'}
          label={'Od kiedy?'}
          isUpperCase={true}
          name={'interview.weightLoss.dateFrom'}
          color={theme.colors.dark1}
          value={formik.values.interview.weightLoss.dateFrom}
          onChange={(e) => formik.setFieldValue('interview.weightLoss.dateFrom', e)}
          onBlur={() => formik.setFieldTouched('interview.weightLoss.dateFrom', true)}
          isError={formik.touched.interview?.weightLoss?.dateFrom && Boolean(formik.errors.interview?.weightLoss?.dateFrom)}
          disableFuture={true}
        />
      </Grid>
      <Grid item xs={10} md={5}>
        <Input
          isRequired={true}
          size={'superSmall'}
          color={theme.colors.dark1}
          label={'Ile kg?'}
          isUpperCase={true}
          name={'interview.weightLoss.weight'}
          type={'number'}
          value={checkEmptyNumber(formik.values.interview.weightLoss.weight as number)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={'Wpisz...'}
          isError={formik.touched.interview?.weightLoss?.weight && Boolean(formik.errors.interview?.weightLoss?.weight)}
        />
      </Grid>
    </Grid>}
    <Grid item xs={12} md={6} mt={2}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2} label={'Chrypka'}
             isRequired={true} />
      <RadioForm>
        <RadioButton
          isError={formik.touched.interview?.hoarseness?.isActive && Boolean(formik.errors.interview?.hoarseness?.isActive)}
          id={'interview.hoarseness.isActive1'}
          name={'interview.hoarseness.isActive'}
          value={'tak'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.hoarseness.isActive) === 'tak'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.hoarseness.isActive', newValue);
          }}
        />
        <RadioButton
          isError={formik.touched.interview?.hoarseness?.isActive && Boolean(formik.errors.interview?.hoarseness?.isActive)}
          id={'interview.hoarseness.isActive2'}
          name={'interview.hoarseness.isActive'}
          value={'nie'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.hoarseness.isActive) === 'nie'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.hoarseness.isActive', newValue);
          }}
        />
      </RadioForm>
    </Grid>
    {formik.values.interview.hoarseness.isActive && <Grid item xs={10} md={4} pl={2} mt={2}>
      <DateInput
        size={'superSmall'}
        openTo={'year'}
        inputFormat={'mm.yyyy'}
        views={['year', 'month']}
        isRequired={true}
        maxWidth={'385px'}
        label={'Od kiedy?'}
        isUpperCase={true}
        name={'interview.hoarseness.dateFrom'}
        color={theme.colors.dark1}
        value={formik.values.interview.hoarseness.dateFrom}
        onChange={(e) => formik.setFieldValue('interview.hoarseness.dateFrom', e)}
        onBlur={() => formik.setFieldTouched('interview.hoarseness.dateFrom', true)}
        isError={formik.touched.interview?.hoarseness?.dateFrom && Boolean(formik.errors.interview?.hoarseness?.dateFrom)}
        disableFuture={true}
      />
    </Grid>}
    <Grid item xs={12} md={6} mt={2}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2} label={'Duszność'}
             isRequired={true} />
      <RadioForm>
        <RadioButton
          isError={formik.touched.interview?.dyspnoea?.isActive && Boolean(formik.errors.interview?.dyspnoea?.isActive)}
          id={'interview.dyspnoea.isActive1'}
          name={'interview.dyspnoea.isActive'}
          value={'tak'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.dyspnoea.isActive) === 'tak'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.dyspnoea.isActive', newValue);
          }}
        />
        <RadioButton
          isError={formik.touched.interview?.dyspnoea?.isActive && Boolean(formik.errors.interview?.dyspnoea?.isActive)}
          id={'interview.dyspnoea.isActive2'}
          name={'interview.dyspnoea.isActive'}
          value={'nie'}
          isUppercase={true}
          checked={convertBooleanRadio(formik.values.interview.dyspnoea.isActive) === 'nie'}
          onChange={(e) => {
            const newValue = e.target.value === 'tak';
            formik.setFieldValue('interview.dyspnoea.isActive', newValue);
          }}
        />
      </RadioForm>
    </Grid>
    {formik.values.interview.dyspnoea.isActive && <Grid item xs={10} md={4} pl={2} mt={2}>
      <DateInput
        size={'superSmall'}
        openTo={'year'}
        inputFormat={'mm.yyyy'}
        views={['year', 'month']}
        isRequired={true}
        maxWidth={'385px'}
        label={'Od kiedy?'}
        isUpperCase={true}
        name={'interview.dyspnoea.dateFrom'}
        color={theme.colors.dark1}
        value={formik.values.interview.dyspnoea.dateFrom}
        onChange={(e) => formik.setFieldValue('interview.dyspnoea.dateFrom', e)}
        onBlur={() => formik.setFieldTouched('interview.dyspnoea.dateFrom', true)}
        isError={formik.touched.interview?.dyspnoea?.dateFrom && Boolean(formik.errors.interview?.dyspnoea?.dateFrom)}
        disableFuture={true}
      />
    </Grid>}
    <Grid item xs={12} mt={2}>
      <Input
        color={theme.colors.turquoise2}
        label={'Inne objawy o istotnej wartości klinicznej'}
        isUpperCase={true}
        name={'interview.otherSymptoms'}
        type={'textarea'}
        value={formik.values.interview.otherSymptoms || ''}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={'Wpisz...'}
        isError={formik.touched.interview?.otherSymptoms && Boolean(formik.errors.interview?.otherSymptoms)}
      />
    </Grid>
    <Grid item xs={12} mt={4}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
             label={'Narażenie na czynniki kancerogenne'}
             isRequired={true} />
      <RadioForm>
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.carcinogenicFactors?.status && Boolean(formik.errors.interview?.carcinogenicFactors?.status)}
                     id={'interview.carcinogenicFactors.status1'}
                     name={'interview.carcinogenicFactors.status'}
                     value={'tak'} checked={formik.values.interview.carcinogenicFactors.status === 'tak'}
                     onChange={formik.handleChange} />
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.carcinogenicFactors?.status && Boolean(formik.errors.interview?.carcinogenicFactors?.status)}
                     id={'interview.carcinogenicFactors.status2'}
                     name={'interview.carcinogenicFactors.status'}
                     value={'nie'} checked={formik.values.interview.carcinogenicFactors.status === 'nie'}
                     onChange={formik.handleChange} />
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.carcinogenicFactors?.status && Boolean(formik.errors.interview?.carcinogenicFactors?.status)}
                     id={'interview.carcinogenicFactors.status3'}
                     name={'interview.carcinogenicFactors.status'}
                     value={'nie zgłoszono'}
                     checked={formik.values.interview.carcinogenicFactors.status === 'nie zgłoszono'}
                     onChange={formik.handleChange} />
      </RadioForm>
    </Grid>
    {formik.values.interview.carcinogenicFactors.status === 'tak' && <Grid item xs={10} md={4} pl={2}>
      <Input
        isRequired={true}
        size={'small'}
        color={theme.colors.dark1}
        label={'Jakie?'}
        isUpperCase={true}
        name={'interview.carcinogenicFactors.which'}
        type={'text'}
        value={formik.values.interview.carcinogenicFactors.which || ''}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={'Wpisz...'}
        isError={formik.touched.interview?.carcinogenicFactors?.which && Boolean(formik.errors.interview?.carcinogenicFactors?.which)}
      />
    </Grid>}
    <Grid item xs={12} mt={2}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
             label={'Aktualny Stan sprawności w skali ECOG'}
             isRequired={true} />
      <RadioForm>
        <RadioButton
          isUppercase={true}
          isError={formik.touched.interview?.statusECOG && Boolean(formik.errors.interview?.statusECOG)}
          id={'interview.statusECOG1'}
          name={'interview.statusECOG'}
          value={'0'} checked={formik.values.interview.statusECOG === '0'}
          onChange={formik.handleChange} />
        <RadioButton
          isUppercase={true}
          isError={formik.touched.interview?.statusECOG && Boolean(formik.errors.interview?.statusECOG)}
          id={'interview.statusECOG2'}
          name={'interview.statusECOG'}
          value={'1'} checked={formik.values.interview.statusECOG === '1'}
          onChange={formik.handleChange} />
      </RadioForm>
    </Grid>
    <Grid item xs={12} mt={4} mb={12}>
      <Label size={'medium'} isUpperCase={true} color={theme.colors.turquoise2}
             label={'Typ histologiczny nowotworu'}
             isRequired={true} />
      <RadioForm $flexDirection={'column'}>
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.histologicalCancer && Boolean(formik.errors.interview?.histologicalCancer)}
                     id={'interview.histologicalCancer1'}
                     name={'interview.histologicalCancer'}
                     value={'płaskonabłonkowy'}
                     checked={formik.values.interview.histologicalCancer === 'płaskonabłonkowy'}
                     onChange={formik.handleChange} />
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.histologicalCancer && Boolean(formik.errors.interview?.histologicalCancer)}
                     id={'interview.histologicalCancer2'}
                     name={'interview.histologicalCancer'}
                     value={'niepłaskonabłonkowy'}
                     checked={formik.values.interview.histologicalCancer === 'niepłaskonabłonkowy'}
                     onChange={formik.handleChange} />
        <RadioButton isUppercase={true}
                     isError={formik.touched.interview?.histologicalCancer && Boolean(formik.errors.interview?.histologicalCancer)}
                     id={'interview.histologicalCancer3'}
                     name={'interview.histologicalCancer'}
                     value={'rak typu nos'}
                     checked={formik.values.interview.histologicalCancer === 'rak typu nos'}
                     onChange={formik.handleChange} />
      </RadioForm>
    </Grid>
  </Grid>);
});


