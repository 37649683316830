import React, {FC} from "react";
import {Grid} from "@mui/material";
import {FileUploadLayout} from "../../FileUploadLayout";
import {IFileUploadProps, IImage} from "../../../../../interfaces/user/image";
import {FileSource} from "../../../../../interfaces/user/profile/profile";

interface IProps extends IFileUploadProps {
  images: IImage[] | File[];
  addFile: (file: File | null) => Promise<void> | void;
  removeFile?: (id: number | string) => Promise<void> | void;
  fileType?: string;
  fileSource?: FileSource;
  size?: 'small' | 'large'
}


export const MultipleFileUpload: FC<IProps> = ({
                                                 images,
                                                 addFile,
                                                 removeFile,
                                                 fileType,
                                                 extensions,
                                                 maxFileSize,
                                                 fileSource,
                                                 size = 'small'
                                               }): JSX.Element => {

  return (
    <Grid item xs={12} position={'relative'} alignSelf='stretch' height={size === 'small' ? '100px' : '75px'}>
      <FileUploadLayout
        fileType={fileType}
        extensions={extensions}
        maxFileSize={maxFileSize}
        images={images}
        addFile={addFile}
        removeFile={removeFile}
        fileSource={fileSource}
      />
    </Grid>
  )
}