import styled from "styled-components";

interface IStatusText {
  color: string;
  width?: string
  margin?: string
}

export const StatusText = styled.div<IStatusText>`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${props => props.color};
  border: 1px solid ${props => props.color};
  width: ${props => props.width ? props.width : '102px '};
  height: 48px;
  margin: ${props => props.margin ? props.margin : 'auto 1px;'};
  text-transform: uppercase;

  & span {
    margin: auto;
  }
`;

export const StatusTextOther = styled.div<IStatusText>`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  background-color: ${props => props.color};
  width: ${props => props.width ? props.width : '102px '};
  height: 48px;
  margin: ${props => props.margin ? props.margin : 'auto 1px;'};
  text-transform: uppercase;

  & span {
    margin: auto;
  }
`;
