import React from 'react'
import {Container, Icon, Paragraph} from './BoxComponent.styled'

interface IProps {
    text: string;
    children: JSX.Element;
    bcgColor: string;
}

export const BoxComponent: React.FC<IProps> = ({text, children, bcgColor}) => {
    return (
        <Container item xs={4}>
            <Icon bcgColor={bcgColor}>{children}</Icon>
            <Paragraph>{text}</Paragraph>
        </Container>
    )
}
