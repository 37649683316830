import {MouseEvent} from 'react';

import * as yup from 'yup';
import {useFormik} from 'formik';
import {IRoleForm} from '../../../../../interfaces/admin/role/roleForm';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required()
});
export const useRoleForm = (actionHandler: (roleForm: IRoleForm) => Promise<string | null>, roleForm?: IRoleForm | null) => {

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: roleForm ? roleForm.title : ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const userFormNew: IRoleForm = {
        title: values.title,
      }
      const message = await actionHandler(userFormNew);

      if (!message) {
        values.title = roleForm ? roleForm.title : '';
      }
    }
  })

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  return {
    formik,
    confirmForm
  }
}