import {useEffect} from "react";
import {useCasesApi} from "../../../../../apiHooks";
import {useAuthStore, useCasesStore} from "../../../../../store/hooks";
import {useNavigate} from "react-router-dom";
import {generateLink, UserLink} from "../../../../../utils";
import {RoleCategory} from "../../../../../constants/projectUsers";

export const useViewUserHome = () => {
  const navigate = useNavigate()
  const {getListOfOtherCases} = useCasesApi();
  const casesStore = useCasesStore()
  const {listOfOfLast6CasesLoaded, listOfOfLast6Cases} = casesStore
  const authStore = useAuthStore();
  const {fullName, affiliation} = authStore;
  useEffect(() => {
    if (!listOfOfLast6CasesLoaded) {
      if (listOfOfLast6Cases === null) {
        void getListOfOtherCases(true, 6)
      } else {
        void getListOfOtherCases(false, 6)
      }
    }

  }, [listOfOfLast6Cases, getListOfOtherCases, listOfOfLast6CasesLoaded])

  useEffect(() => {
    return () => {
      casesStore.resetListOfOfLast6Cases();
    }
  }, [casesStore])

  const navigateToCases = () => {
    navigate(generateLink(RoleCategory.USER, UserLink.OTHER_CASES));
  }

  const navigateToOthersCases = () => {
    navigate(generateLink(RoleCategory.USER, UserLink.OTHER_CASES));
  }

  return {
    navigateToOthersCases,
    navigateToCases,
    listOfOfLast6Cases,
    fullName,
    affiliation
  }
}