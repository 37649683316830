import { makeAutoObservable } from 'mobx';

import { IArea } from "../interfaces/admin/areas/areas";
import { RootStore } from "./rootStore";

export class AreasStore{
  area: IArea | null = null;
  areas: IArea[] | null = null;
  isAreasLoaded: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setAreas(areas: IArea[]){
    this.areas = areas;
    this.isAreasLoaded = true;
  }

  setArea(area: IArea){
    this.area = area;
  }

  resetAreas() {
    this.isAreasLoaded = false;
  }

  resetArea() {
    this.area = null;
  }
}