import styled from "styled-components";
import theme from "../../../../theme/theme";

export const StyledButton = styled.div`
  margin: 30px auto 87px;
  text-align: center;
  max-width: 1012px;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-bottom: 0;
  }

  & button {
    margin: 0 auto;
  }
`

export const StyledLayout = styled.div`
  & > div > div {
    overflow: auto;
    @media (max-width: ${theme.breakpoints.small}px) {
      height: unset;
    }
  }

`