import {ContentModal} from "../../ContentModal";
import React, {FC} from "react";

import {CancellationTextModal} from "./CancellationModal.styled";
import {CancelIcon} from '../../modalIcons/CancelIcon';

interface IProps {
  text: JSX.Element;
}

export const CancellationModal: FC<IProps> = ({text}) => {
  return <ContentModal
    iconType={<CancelIcon/>}
    text={<CancellationTextModal>{text}</CancellationTextModal>}
  />
}
