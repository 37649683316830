import {IChildren} from "../../../../interfaces/common/common";
import {MainContainer} from "./Main.styled";

export interface IMain {
  background?: string;
}

interface IProps extends IChildren, IMain {
}

export const Main: React.FC<IProps> = ({children, background}) => {
  return (<MainContainer background={background}>{children}</MainContainer>)
};
