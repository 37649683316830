import styled, { keyframes } from 'styled-components';
import { Grid } from '@mui/material';
import theme from '../../../../theme/theme';

interface IUserLayoutContent {
  isBreadcrumb?: boolean;
  overflowAuto?: boolean;
}

const displayPage = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ProfileContainer = styled(Grid)`
  margin-bottom: 16px;
  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 0 16px;
  }
`;


export const UserLayoutContent = styled.div<IUserLayoutContent>`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  flex-grow: 1;
  align-content: center;
  height: 100%;
  animation-name: ${displayPage};
  animation-duration: 0.5s;
  padding: 0 35px;
  display: flex;
  min-height: calc(100vh - 224px);
  overflow: ${props => props.overflowAuto ? 'hidden' : 'auto'};
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0;
    padding: 0 16px 40px 16px;
    min-height: unset;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const UserLayoutContainer = styled(Grid)`
  background: ${theme.colors.beige};

  @media (max-width: ${theme.breakpoints.small}px) {
    margin: 0 auto;
  }
`;
