import { IHeadCell } from '../../../../interfaces/common/table';

type TargetKeysType = Array<IHeadCell | string>;

export const searchByFilter = <T>(searchValue: string, targetKeys: TargetKeysType) => (element: T) => {
  if (!searchValue) return true;

  const searchArray = searchValue.trim().toLowerCase().split(' ').filter(el => el.length > 1);

  const targetString = targetKeys.map(key => {
    if (typeof key === 'string') {
      return String(element[key as keyof T]);
    } else {
      return String(element[key.id as keyof T]);
    }
  }).join(' ').toLowerCase();

  return searchArray.every(searchWord => targetString.includes(searchWord));
};