import { AdminLayout } from '../../../../shared/admin/layout/AdminLayout';
import { CustomTable } from '../../../../shared/customTables/customTable/CustomTable';
import { observer } from 'mobx-react-lite';
import { useDocuments } from './useDocuments.vm';
import React from 'react';

import { ModalType } from '../../../../../constants/modalType';
import { EditModal } from './modal/edit/EditModal';
import { GenerateReportModal } from './modal/generateReport/GenerateReportModal';
import { MultipleSelectContainer } from '../Contract.styled';
import { MultipleSelect } from '../../../../shared/form/multipleSelect/MultipleSelect';

export const Documents = observer(() => {
  const {
    companyContractsIsNotNull,
    cellsSwitcher,
    generateActions,
    tableDataRows,
    headCells,
    openModalGenerateReport,
    isModalOpened,
    modalType,
    closeModal,
    selectedItem,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years
  } = useDocuments();

  return (<AdminLayout>
    {companyContractsIsNotNull &&
      <CustomTable
        isDownloadExcel={true}
        hideAction={true}
        actions={generateActions()}
        cellsSwitcher={cellsSwitcher}
        isSearchBar={true}
        customTableConfig={{ rowsPerPage: 99999999 }}
        tableDataRows={tableDataRows} isDataDownloaded={true}
        headCells={headCells}
        generateReportHandler={openModalGenerateReport}
        contentAfterSearch={<MultipleSelectContainer>
          <MultipleSelect subtitle={'Obszar'} key={'area'} selectedValues={selectedAreas}
                          elementName={'area'}
                          options={optionsAreas ?? []}
                          handleChange={handleChange} />
          <MultipleSelect subtitle={'Rok'} key={'years'} selectedValues={selectedYears}
                          elementName={'years'}
                          options={years}
                          handleChange={handleChangeYears} />
        </MultipleSelectContainer>}
      />
    }
    {isModalOpened && <>
      {modalType === ModalType.EDIT_MODAL && <EditModal selectedItem={selectedItem} closeModal={closeModal} />}
      {modalType === ModalType.GENERATE_REPORT && <GenerateReportModal closeModal={closeModal} />}
    </>}
  </AdminLayout>);
});
