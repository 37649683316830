import {useCallback, useState} from "react";

export const useFileUpload = (setFileHandler: (file: File | null) => Promise<void> | void, maxFileSize = 0.5, fileType: string) => {
  const [isDropActive, setIsDropActive] = useState(false);
  const [isFileTooBig, setIsFileTooBig] = useState(false);
  const [isFileNotSupported, setIsFileNotSupported] = useState(false);


  // Create handler for dropzone's onDragStateChange:
  const onDragStateChange = useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);

  // Create handler for dropzone's onFilesDrop:
  const onFileDrop = useCallback((file: File) => {

      if (!file?.type?.includes(fileType)) {
        setIsFileNotSupported(true);
        return;
      } else {
        setIsFileNotSupported(false);
      }

      if ((file.size / (1024 * 1024)) > maxFileSize) {
        setIsFileTooBig(true);
        return;
      } else {
        setIsFileTooBig(false);
      }

      setFileHandler(file);

    },
    [maxFileSize, setFileHandler, fileType]
  );

  return {
    onDragStateChange,
    onFileDrop,
    setIsDropActive,
    isDropActive,
    isFileTooBig,
    isFileNotSupported
  }
}