import {AdminLayout} from '../../../shared/admin/layout/AdminLayout';
import {CustomTable} from '../../../shared/customTables/customTable/CustomTable';
import React from "react";
import {useRolesPermissions} from './useRolesPermissions';
import {observer} from 'mobx-react-lite';

export const RolesPermissions = observer(() => {
  const {
    rolesIsNotNull,
    tableDataRows,
    headCells,
    generateActions,
    cellsSwitcher
  } = useRolesPermissions();

  return (
    <AdminLayout>
      {rolesIsNotNull &&
          <CustomTable isSearchBar={true} cellsSwitcher={cellsSwitcher} tableDataRows={tableDataRows}
                       isDataDownloaded={true}
                       headCells={headCells} actions={generateActions()}/>}
    </AdminLayout>
  )
});