import React, {FC} from 'react';
import {useRoleForm} from './useRoleForm';
import {Input} from '../../../../shared/form/input/Input';

import {observer} from 'mobx-react-lite';
import {FormButtons} from '../../../../shared/form/formButton/FormButtons';
import {AdminLink, generateLink} from '../../../../../utils';
import {RoleCategory} from '../../../../../constants/projectUsers';
import {IRoleForm} from '../../../../../interfaces/admin/role/roleForm';
import {ErrorMessage} from '../../../../shared/form/errorMessage/ErrorMesage';
import {Container, FormButtonsContainer, Header, InputContainer} from "./RoleForm.styled";
import {ValidationMessages} from '../../../../../constants/validationMessages';

interface IProps {
  roleForm?: IRoleForm | null;
  isEdit?: boolean;
  actionHandler: (roleForm: IRoleForm) => Promise<string | null>;
}

export const RoleForm: FC<IProps> = observer(({roleForm, isEdit, actionHandler}) => {
  const {
    formik,
    confirmForm
  } = useRoleForm(actionHandler, roleForm);

  return (
    <Container>
      <Header>{isEdit ? "EDYCJA ROLI:" : "DODAWANIE ROLI:"}</Header>
      <InputContainer>
        <Input
          isRequired={true}
          key={"title"}
          label={"Nazwa roli"}
          name={"title"}
          type={"text"}
          value={formik.values.title}
          onChange={formik.handleChange}
          placeholder={"Wpisz nazwę roli..."}
        />
      </InputContainer>
      {((formik.submitCount > 0 && formik.errors.title)) &&
          <ErrorMessage errors={formik.errors} errorServer={false}
                        message={ValidationMessages.INVALID_OR_EMPTY_FORM}/>
      }
      <FormButtonsContainer>

        <FormButtons isButtonActive={false} confirmForm={confirmForm}
                     link={generateLink(RoleCategory.ADMIN, AdminLink.ROLES)}
                     buttonText={isEdit ? "zapisz" : "zapisz i dodaj"}/>
      </FormButtonsContainer>

    </Container>
  )
});
