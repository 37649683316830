import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  SummaryDataElementVariantFirst,
  SummaryDataStyled,
  SummaryDataTitle
} from './SummaryData.styled';

interface IProps {
  title: string;
  data?: string | number;
}

export const SummaryData: React.FC<IProps> = observer(({ title, data }) => {
  return <SummaryDataStyled>
    <SummaryDataTitle>{title}</SummaryDataTitle>
    <div>
      <SummaryDataElementVariantFirst>
        {data}
      </SummaryDataElementVariantFirst>
    </div>
  </SummaryDataStyled>;
});