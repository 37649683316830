import styled from "styled-components";
import backgroundNavbar from "../../../../../../assets/img/backgroundNavbar.png";
import theme from "../../../../../../theme/theme";

export const Navbar = styled.div`
  background: url(${backgroundNavbar}), linear-gradient(180deg, #87C1F3 0%, #003B6F 100%);
  background-position: bottom right;
  background-size: cover;
  width: 335px;
  position: relative;

  & img {
    height: 53px;
    z-index: 902;
    margin: 85px auto 50px;
    display: block;
  }

  & nav {
    position: relative;
    height: calc(100% - 273px);
  }
}
`;

export const NavbarHeader = styled.div`
  background: linear-gradient(180deg, ${theme.colors.beige} 0%, rgba(249, 252, 255, 0) 100%);
  background-position: top right;
  background-size: cover;
  width: 100%;
  position: absolute;
  z-index: 900;
  height: 100px;
`;

export const RightBelt = styled.div`
  background: linear-gradient(180deg, ${theme.colors.darkBlue} 0%, ${theme.colors.blue2} 100%);
  width: 15px;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 901;
`;

export const RoleBottomContainer = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  color: white;
`;