import React from "react";
import { IChildren } from "../../../../interfaces/common/common";

import { UserTop } from "../../appLayer/userTop/UserTop";
import { observer } from "mobx-react-lite";
import { AdminLayoutContainer, AdminLayoutContentContainer } from "./AdminLayout.styled";
import { useAdminLayout } from "./useAdminLayout";
import { NavBar } from "./navBar/navBar/NavBar";

interface IProps extends IChildren {
  centralize?: boolean;
}

export const AdminLayout: React.FC<IProps> = observer(({children, centralize = false}) => {
  const {links} = useAdminLayout();

  return (
    <AdminLayoutContainer
      container
      direction='row'
      flexWrap='nowrap'>
      <UserTop isProfile={false}/>
      <NavBar links={links ?? []}/>
      <AdminLayoutContentContainer $iscenter={centralize}
                                   item>
        {children}
      </AdminLayoutContentContainer>
    </AdminLayoutContainer>
  )
});
