import styled from 'styled-components';
import theme from '../../../../../../../theme/theme';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Paragraph = styled.p`
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.red};
  align-self: flex-end;
  margin: -40px auto 20px 0;
  @media (max-width: ${theme.breakpoints.small}px) {
    margin: -65px auto 20px 0;
  }
`;

export const ModalTitle = styled.div`
  width: auto;
  padding: 0 32px;
  height: 55px;
  top: 40px;
  left: 0;
  position: absolute;
  border-radius: 0 30px 30px 0;
  line-height: 55px;
  background-color: ${theme.colors.turquoise2};
  font-weight: 800;
  font-size: 20px;
  color: ${theme.colors.beige};
  text-align: center;
  text-transform: uppercase;
`;

export const ModalTitleContainer = styled.div`
  width: 100%;
`;