import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useDraftCaseNeuro } from './useDraftCaseNeuro';
import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { ProgressBar } from './progressBar/ProgressBar';

interface IProps {
  isReset?: boolean;
}

export const DraftCaseNeuro: FC<IProps> = observer(({ isReset }) => {
  const { breadcrumbs } = useDraftCaseNeuro();

  return <UserLayout overflowAuto={false} breadcrumbs={breadcrumbs}><ProgressBar /></UserLayout>;
});