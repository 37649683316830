import { observer } from 'mobx-react-lite';
import { Grid } from '@mui/material';

import {
  SummaryDataElementVariantSecond, SummaryDataHeader,
  SummaryDataStyled,
  SummaryDataTitle
} from './SummaryData.styled';
import React from 'react';
import { statusIconsChart } from '../../Statistics.helper';
import { ICaseStatusIcons } from '../../../../../../interfaces/admin/statistics/statistics';

interface IProps {
  headCells: string[];
  title: string;
  data: { data1?: string | number, data2?: string | number } [];
  withIcon?: boolean;
}

export const SummaryDataTable: React.FC<IProps> = observer(({ title, data, headCells, withIcon }) => {
  return <SummaryDataStyled>
    <SummaryDataTitle>{title}</SummaryDataTitle>
    <Grid container>
      {headCells.map((el) => <Grid item xs={6} key={el}>
        <SummaryDataHeader>{el}</SummaryDataHeader>
      </Grid>)}
      <Grid container>
        {data.map(el => (
          <React.Fragment key={el.data1}><Grid xs={6} item flexDirection={'row'} display={'flex'}>
            <SummaryDataElementVariantSecond>{withIcon && statusIconsChart[el.data1 as keyof ICaseStatusIcons]}{el.data1}</SummaryDataElementVariantSecond></Grid>
            <Grid xs={6} item flexDirection={'row'}
                  display={'flex'}><SummaryDataElementVariantSecond>{el.data2}</SummaryDataElementVariantSecond>
            </Grid>
          </React.Fragment>))}
      </Grid>
    </Grid>
  </SummaryDataStyled>;
});