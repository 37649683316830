import { useReckoningApi } from "../../../../../../../apiHooks/useReckoningApi";
import { AreaKey } from '../../../../../../../interfaces/admin/cases/cases';

export const useContractContents = (ids: number[] | null, area: AreaKey) => {
  const {getWorkContract} = useReckoningApi();

  const getDocumentHandler = async () => {
    if (ids) {
      const res = await getWorkContract(ids, area);
      const blob = new Blob([res], {type: 'application/pdf'});
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  }

  return {
    getDocumentHandler
  }
}
