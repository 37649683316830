import styled from "styled-components";
import {Grid, TableCell} from '@mui/material';
import theme from "../../../../../theme/theme";

export const TableCellTitle = styled(TableCell)`
  background: white;
  padding: 0px !important;
`


export const ToReckoningButtonContainer = styled(Grid)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  background: white;

  & span {
    padding: 4px;
    max-height: 46px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  & path {
    fill: white;
  }
  

`
export const TableCellReckoning = styled(TableCell)`
  height: 50px;
  padding: 0px;
  color: ${theme.colors.darkBlue};
  background: white;
  border: 3px solid ${theme.colors.beige};
  font-weight: 400;
`
