import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { Grid } from '@mui/material';
import React from 'react';
import {
  ButtonsReckoningContainer,
  EditButton,
  EditButtonContainer,
  Header,
  ProfileElement,
  ResetLink,
  ResetLinkContainer,
  UserProfileIconContainer
} from './Profile.styled';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/icon/profile.svg';
import { Button } from '../../../shared/button/Button';
import theme from '../../../../theme/theme';
import { EditProfile } from './editProfile/EditProfile';
import { useProfile } from './useProfile';
import { observer } from 'mobx-react-lite';
import { checkPermission } from '../../../../utils';
import { RoleFunction } from '../../../../constants/projectUsers';

export const Profile = observer(() => {
  const {
    roleFunction,
    profile,
    breadcrumbs,
    isModalOpened,
    closeModal,
    resetNavigateHandler,
    reckoningSummaryHandler,
    editModalHandler,
    navigateForm
  } = useProfile();

  return (
    <UserLayout breadcrumbs={breadcrumbs} isProfileContainer={true} isDoctorVisible={true}>
      <Grid item xs={12} sm={7} display={'flex'} margin={'auto auto auto 0'}>
        <Grid display={{ xs: 'none', sm: 'flex' }}>
          <EditButtonContainer>
            <EditButton onClick={editModalHandler}><EditIcon /></EditButton>
            <UserProfileIconContainer>
              <ProfileIcon />
            </UserProfileIconContainer>
          </EditButtonContainer>
        </Grid>

        {profile && <Grid container flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
          <Grid item flexDirection={'column'}>
            <Header>
              <Grid display={{ xs: 'flex', sm: 'none' }}>
                <EditButtonContainer>
                  <EditButton onClick={editModalHandler}><EditIcon /></EditButton>
                  <UserProfileIconContainer>
                    <ProfileIcon />
                  </UserProfileIconContainer>
                </EditButtonContainer>
              </Grid>
              Profil użytkownika:</Header>
            <Grid item>
              <p><strong>Imię i nazwisko:</strong></p>
              <ProfileElement>{profile.affiliation} {profile.fullName}</ProfileElement>
            </Grid>
            <Grid item>
              <p><strong>E-mail:</strong></p>
              <ProfileElement>{profile.email}</ProfileElement>
            </Grid>
            <Grid item>
              <p><strong>Numer kontaktowy:</strong></p>
              <ProfileElement>{profile.phone ?? '-'}</ProfileElement>
            </Grid>
            <Grid item>
              <p><strong>Numer PWZ:</strong></p>
              <ProfileElement>{profile.pwz ?? '-'}</ProfileElement>
            </Grid>
          </Grid>
          {checkPermission(roleFunction, [RoleFunction.ROLE_PUBLISHING]) && <Grid item flexDirection={'column'}>
            <Grid item>
              <p><strong>Wybrana domyślnie forma rozliczenia:</strong></p>
              <ButtonsReckoningContainer>
                <Button
                  backgroundColor={profile.isCompanyContract || profile.isUserContract ? theme.colors.turquoise2 : theme.colors.gray4}
                  clickHandler={reckoningSummaryHandler}
                  color={'white'}
                  text={profile.isCompanyContract ? 'faktura' : profile.isUserContract ? 'umowa o dzieło' : 'Brak'}
                  size={'tiny'}
                  width={'150px'} />
                <Button
                  backgroundColor={theme.colors.dark1}
                  color={'white'}
                  text={'Wybierz formę rozliczenia'}
                  size={'tiny'}
                  width={'244px'}
                  clickHandler={navigateForm}
                />
              </ButtonsReckoningContainer>
              <ResetLinkContainer>Kliknij <ResetLink onClick={resetNavigateHandler}>tutaj</ResetLink> aby
                zresetować hasło</ResetLinkContainer>
            </Grid>
          </Grid>}
        </Grid>}
      </Grid>
      {isModalOpened && <EditProfile closeModal={closeModal} />}
    </UserLayout>
  );
});