import styled from 'styled-components';
import theme from '../../../../../../theme/theme';

export const Header = styled.h1`
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  margin-top: 35px;
  color: ${theme.colors.dark1};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 20px;
  }
`;

export const Subheader = styled.h2`
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: ${theme.colors.dark1};
  text-transform: uppercase;
  margin-top: 5px;
`;

export const Paragraph = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 170%;
  color: ${theme.colors.red};
  @media (max-width: ${theme.breakpoints.small}px) {
    margin-bottom: 30px;
  }
`;

export const RequiredParagraph = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.red};
  @media (max-width: ${theme.breakpoints.small}px) {
    padding-top: 10px;
  }
`;

export const ContractTitle = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: ${theme.colors.dark1};

  & span {
    display: flex;
    gap: 8px;
  }
`;


export const FlexDiv = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  cursor: pointer;

  & + h3 {
    margin-bottom: 10px;
  }
`;

export const NomalText = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: ${theme.colors.dark1};
`;
export const BoldText = styled.h3`
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  line-height: 27px;
  color: ${theme.colors.dark1};

  & svg {
    vertical-align: middle;
  }
`;
export const ConfirmationTextModal = styled.div`
  margin-top: 5px;
  max-width: 565px;
  text-align: center;
`;

export const ContractConfirmationContainer = styled.div`
  margin-top: 50px;
  font-size: 20px;
  line-height: 28px;

  & p {
    max-width: 100%;
  }

  & button {
    margin: 50px auto 0 auto;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    margin-top: 10px;
    & button {
      margin: 20px auto 0 auto;
    }
  }
`;

export const StyledFileContainer = styled.div`
  background: #EBF5FF;
  margin-top: 50px;
  border-radius: 28px;
  padding: 30px 22px;
  min-height: 428px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ul {
    margin: 0;
    padding: 0
    
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    margin-top: 28px;
  }
`;
export const ListContainer = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  text-align: left;

`;
export const ListElement = styled.li`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  padding: 16px 0;
  border-bottom: 2px solid ${theme.colors.gray5};

  h3 {
    font-size: 16px;
  }

  p {
    margin-top: 4px;
    margin-left: 20px;
    font-size: 12px;
    color: ${theme.colors.gray6};
  }

  &:last-child {
    border: none;
  }
`;

export const RedCircle = styled.div`
  width: 45px;
  height: 45px;
  background-color: ${theme.colors.red};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;
