import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, TextFieldProps } from '@mui/material';
import { dateStringify } from '../../../../utils';
import { FC } from 'react';
import { CalendarPickerView } from '@mui/x-date-pickers/internals/models';
import { Label } from '../label/LabelContainer';
import { DatePickerCustomContainer } from './Date.styled';
import { Size } from '../Form.helper';
import { pl } from 'date-fns/locale';

interface IProps {
  views: CalendarPickerView [];
  openTo: 'year' | 'month';
  value?: string | null;
  name: string;
  inputFormat: string;
  onChange: (dateValue: string | null, keyboardInputValue?: string) => void;
  label?: string | JSX.Element;
  isRequired?: boolean;
  color?: string;
  isError?: boolean;
  textError?: string | boolean;
  placeholder?: string;
  onBlur?: () => void;
  size?: Size;
  maxWidth?: string;
  disabledPast?: boolean;
  disableFuture?: boolean;
  minDate?: Date | null;
  isUpperCase?: boolean;
}

export const DateInput: FC<IProps> = ({
                                        views,
                                        openTo,
                                        value,
                                        inputFormat,
                                        onChange,
                                        isRequired = false,
                                        isError,
                                        label,
                                        textError,
                                        color = 'black',
                                        onBlur,
                                        size = 'medium',
                                        maxWidth,
                                        disabledPast,
                                        disableFuture,
                                        minDate,
                                        isUpperCase
                                      }) => {

  const isDay = views.find(view => view === 'day');

  const onChangeHandler = (value: any) => {
    const date = new Date(value);
    date.setHours(0, 0, 0, 0);
    onChange(date.toDateString());
    onBlur && onBlur();
  };

  const content = <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns}>
    <DatePicker
      inputFormat={inputFormat}
      disableFuture={disableFuture ?? false}
      disablePast={disabledPast ?? false}
      minDate={minDate}
      openTo={openTo}
      views={views}
      value={value ?? ''}
      label='Wybierz...'
      onChange={onChangeHandler}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
        if (params.inputProps) {
          if (value) {
            params.inputProps.value = dateStringify(value, !!isDay);
          }

          return <TextField ref={params.inputRef} {...params} />;
        }

        return <TextField {...params} />;
      }}
    />
  </LocalizationProvider>;

  return (<DatePickerCustomContainer isError={!!isError} maxWidth={maxWidth}>
    <>{label ?
      <Label isUpperCase={isUpperCase} size={size} content={
        content
      } color={color} label={label} isRequired={isRequired} />
      : <>{content}</>
    }</>
    {isError && <p>{textError}</p>}
  </DatePickerCustomContainer>);
};

