import { IAction, IHeadCell } from '../../../../../interfaces/common/table';
import theme from '../../../../../theme/theme';
import React, { useEffect, useState } from 'react';
import { useContractApi } from '../../../../../apiHooks';
import { useContractStore } from '../../../../../store/hooks';
import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { splitData, SplitData } from '../../../../../utils';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { CellTime } from '../../../../shared/customTables/cells/cellTime/CellTime';
import { ActionButton } from '../../../../shared/customTables/button/ActionButton';
import { ReactComponent as CheckboxCheckIcon } from '../../../../../assets/icon/checkboxCheck.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icon/edit.svg';
import { ReactComponent as Download } from '../../../../../assets/icon/download.svg';
import { ContractStatus } from '../../../../../interfaces/admin/contract/contract';
import { ModalType } from '../../../../../constants/modalType';
import { useModal } from '../../../../shared/modal/useModal.vm';
import { StatusText } from '../../../../shared/admin/status/Status.styled';
import { Checkbox } from '@mui/material';
import { useContracts } from '../useContracts.vm';

export const useWorkContracts = () => {
  const contractStore = useContractStore();
  const { workContracts, isWorkContractsLoaded, userInfo } = contractStore;
  const [, setActionId] = useState<null | number>(null);

  const {
    handleModal: handleEditModal,
    isModalOpened: isEditModalOpened,
    closeModal: closeEditModal,
    id: editId
  } = useModal();
  const {
    handleModal: handleAcceptModal,
    isModalOpened: isAcceptModalOpened,
    closeModal: closeAcceptModal,
    id: acceptId
  } = useModal();
  const {
    handleModal: handlePayModal,
    isModalOpened: isPayModalOpened,
    closeModal: closePayModal,
    id: payId
  } = useModal();
  const {
    checkWorkContractPdf,
    getWorkContracts,
    acceptWorkContract,
    payWorkContract,
    getUserInfo,
    getWorkContactZip
  } = useContractApi();

  const {
    filteredContracts,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years
  } = useContracts(workContracts);

  const handleRefresh = async () => {
    void getWorkContracts(false);
  };

  useEffect(() => {
    if (!isWorkContractsLoaded) {
      if (workContracts === null) {
        void getWorkContracts();
      } else {
        void getWorkContracts(false);
      }
    }
  }, [getWorkContracts, isWorkContractsLoaded, workContracts]);

  useEffect(() => {
    return () => {
      contractStore.resetCompanyContracts();
    };
  }, [contractStore]);

  const downloadHandler = async (id: number | string, user?: number) => {
    // const element = workContracts && workContracts.find(contract => contract.id === id);
    // if (element) {
    //   window.open(element.link);
    // }
    //if (user) {
    const res = await getWorkContactZip(+id);
    const blob = new Blob([res], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    window.open(url);

  };

  const acceptContractHandler = async (id: number | string) => {
    handleAcceptModal(ModalType.ACCEPT, +id);
  };

  const handleAccept = async () => {
    if (acceptId) {
      const res = await acceptWorkContract(+acceptId);
      if (res) {
        closeAcceptModal();
        await getWorkContracts(false);
      }
    }
  };

  const editContactHandler = async (id: number | string, user?: number, type?: string, customId?: string, userId?: number) => {
    if (userId) {
      await getUserInfo(userId);
    }
    setActionId(+id);
    await handleEditModal(ModalType.VIEW_MODAL, id);
  };

  const payContractHandler = async (id: number | string) => {
    handlePayModal(ModalType.PAY, +id);
  };

  const handlePay = async () => {
    if (payId) {
      const res = await payWorkContract(+payId);

      if (res) {
        closePayModal();
        await getWorkContracts(false);
      }
    }
  };

  const generateCheckedAction = (id: number | string) => {

    if (workContracts) {
      const element = workContracts.find((element) => element.id === id);
      if (element) {
        return <ActionButton id={id as number}
                             background={theme.colors.white}
                             border={theme.colors.white}
                             width={52} actionElement={<Checkbox checked={element.checked} onChange={async () => {
          await checkWorkContractPdf(Number(id), !element.checked);
          await getWorkContracts(false);
        }
        } />} />;
      }
    }

    return null;
  };

  const generateStatusActions = (id: number | string, user?: number, type?: string, customId?: string, userId?: number) => {
    if (workContracts) {
      const element = workContracts.find((element) => element.id === id);
      if (element) {
        switch (element.status) {
          case ContractStatus.WAIT:
            return (
              <>
                <ActionButton id={id as number} actionHandler={acceptContractHandler}
                              background={theme.colors.turquoise2}
                              border={theme.colors.turquoise2}
                              width={52} actionElement={<CheckboxCheckIcon />} />
                <ActionButton id={id as number} actionHandler={editContactHandler} background={theme.colors.red}
                              border={theme.colors.red}
                              width={52}
                              userId={userId}
                              userContract={user}
                              actionElement={<EditIcon />} />
              </>);
          case ContractStatus.ACCEPT: {
            return <ActionButton id={id as number} actionHandler={payContractHandler} background={theme.colors.dark1}
                                 border={theme.colors.dark1} color={'white'} actionElement={'zapłać'}
                                 width={104} />;
          }
          case ContractStatus.PAY: {
            return <StatusText color={theme.colors.turquoise2}><span>opłacone</span></StatusText>;
          }
        }
      }
    }
    return null;
  };

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: 'checked',
        custom: generateCheckedAction,
        width: 52
      },
      {
        key: 'download',
        actionHandler: downloadHandler,
        background: theme.colors.yellow,
        color: theme.colors.yellow,
        border: theme.colors.yellow,
        actionElement: <Download />,
        width: 52,
        actionId: 'contract'
      },
      {
        key: 'status',
        custom: generateStatusActions,
        width: 104
      }
    ];

    return actions;
  };


  const headCells: IHeadCell[] = [
    { id: 'title', numeric: false, label: 'Nazwa' },
    { id: 'fullName', numeric: false, label: 'Lekarz' },
    { id: 'createdAt', numeric: false, label: 'Data przesłania' },
    { id: 'areas', numeric: false, label: 'Obszar terapeutyczny' },
    { id: 'contract', numeric: false, label: '' },
    { id: 'userId', numeric: false, label: '' }
  ];

  const tableDataRows = createTableRows(filteredContracts ?? [], headCells, false);

  const cellsSwitcher = (keyName: string, value: string | [], onClick?: () => void) => {
    const specialValue: SplitData = keyName === 'createdAt' ? splitData(value.toString()) : ['-', ''];

    switch (keyName) {
      case 'id':
      case 'contract':
      case 'user':
      case 'userId':
        return null;
      case 'createdAt':
        return <TableCellData><CellTime specialValue={specialValue} /></TableCellData>;
      case 'title':
        return (
          <TableCellData>
            1. {value}
            <div>2. kwestionariusz osobowy</div>
          </TableCellData>
        );
      case 'areas':
        return <TableCellData>{value instanceof Array ? value.join(', ') : value.toString()}</TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  return {
    workContractsIsNotNull: workContracts !== null,
    cellsSwitcher,
    generateActions,
    tableDataRows,
    isEditModalOpened,
    closeEditModal,
    isAcceptModalOpened,
    closeAcceptModal,
    userInfo,
    headCells,
    handleAccept,
    isPayModalOpened,
    handlePay,
    closePayModal,
    optionsAreas,
    selectedAreas,
    handleChange,
    selectedYears,
    handleChangeYears,
    years, editId, handleRefresh
  };
};