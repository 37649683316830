import styled from 'styled-components';
import theme from '../../../../theme/theme';

interface ITopCounterParagraph {
  $isBold?: boolean;
}

export const TopCounter = styled.div`
  position: absolute;
  top: 16px;
  left: 20px;
  display: flex;
  align-items: center;
`;

export const TopCounterParagraph = styled.span<ITopCounterParagraph>`
  font-weight: ${props => props.$isBold ? 700 : 400};
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.dark1};
  text-transform: uppercase;
  margin: auto 4px;
`;

export const Number = styled.span`
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: ${theme.colors.turquoise2};
`;