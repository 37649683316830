import React from 'react';
import {Grid} from '@mui/material';
import {observer} from 'mobx-react-lite';

import {Container, FormButtonsContainer, Header} from '../../rolesPermissions/roleForm/RoleForm.styled';
import {Input} from '../../../../shared/form/input/Input';
import {FormButtons} from '../../../../shared/form/formButton/FormButtons';
import {AdminLayout} from '../../../../shared/admin/layout/AdminLayout';
import {AdminLink, generateLink} from '../../../../../utils';
import {RoleCategory} from '../../../../../constants/projectUsers';
import {IAreaCommon} from '../../../../../interfaces/admin/areas/areas';
import {useFormArea} from './useFormArea';
import {formData} from './FormArea.helper';
import theme from '../../../../../theme/theme';
import {Modal} from '../../../../shared/modal/Modal';
import {ConfirmationModal} from '../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import {TextModal} from '../../addUser/AddUser.styled';

export const FormArea = observer(() => {
  const {
    isEdit,
    formik,
    confirmForm,
    isModalOpened,
    closeModal
  } = useFormArea();

  return ( <AdminLayout><Container>
    <Header>{`${isEdit ? 'EDYCJA' : 'DODAWANIE'} OBSZARU TERAPEUTYCZNEGO`}</Header>
    <Grid container >
      {formData.map(el =>
        <Grid item xs={6} pr={2} pb={2} key={el.name}>
          <Input color={theme.colors.dark1}
                 label={el.label}
                 name={el.name}
                 type={el.type}
                 isRequired={el.isRequired}
                 value={formik.values[el.name as keyof IAreaCommon]}
                 onChange={formik.handleChange}
                 placeholder={el.placeholder}
                 isError={formik.touched[el.name  as keyof IAreaCommon] && Boolean(formik.errors[el.name  as keyof IAreaCommon])}
          />
        </Grid>
      )}
    </Grid>
    <FormButtonsContainer>
      <FormButtons isButtonActive={false} confirmForm={confirmForm}
                   link={generateLink(RoleCategory.ADMIN, AdminLink.AREAS)}
                   buttonText={isEdit ? "zapisz" : "zapisz i dodaj"}/>
    </FormButtonsContainer>
    {isModalOpened && <Modal clickHandler={() => closeModal()}>
      <ConfirmationModal
        text={
          <TextModal><p>{`Obszar terapeutyczny ${isEdit ? 'wyedytowano' : 'utworzono'} poprawnie.`}</p>
          </TextModal>}
      /></Modal>}
  </Container> </AdminLayout>)
});