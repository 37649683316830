import {
  BlackTitle,
  Container,
  Content,
  FlexBox,
  IncidentBox,
  ObjectContainer,
  SmallerText,
  StyledBorder,
  StyledCalendar,
  StyledData,
  Title
} from './Incidents.styled'
import React from "react";
import {ReactComponent as Calendar} from './../../../../../../assets/icon/calendar.svg'
import {IIncident} from "../../../../../../interfaces/user/draftCaseNeuro/draft";
import {dateStringify} from "../../../../../../utils";
import {observer} from "mobx-react-lite";

interface IProps {
  incidents?: IIncident[]
}

export const Incidents: React.FC<IProps> = observer(({incidents}) => {
  return (
    incidents && incidents.length !== 0 ? <Container>
        <Title>Ocena aktywności rzutowej podczas leczenia danym lekiem</Title>
        {incidents?.map((el, index) => (
          <div key={el.id}>
            <IncidentBox>rzut {index + 1}</IncidentBox>
            <FlexBox>
              <StyledData>
                <BlackTitle>data rzutu:</BlackTitle>
                <StyledBorder></StyledBorder>
                <ObjectContainer><StyledCalendar><Calendar/></StyledCalendar><SmallerText>{dateStringify(el.date)}</SmallerText></ObjectContainer>
              </StyledData>
              <BlackTitle>OPIS RZUTU <span>(OBJAWY)</span>, WDROŻENIE LECZENIA RZUTU, ocena ciężkości rzutu w
                EDSS
                przed i po leczeniu rzutu:</BlackTitle>
              <Content>{el.description}</Content>
            </FlexBox>
          </div>
        ))
        }
      </Container> :
      <></>
  )
})
