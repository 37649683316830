import styled, {keyframes} from "styled-components";
import theme from "../../../../../theme/theme";

interface IProps {
  isButton: boolean;
}

const displayNavbar = keyframes`
  0% {
    right: -302px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
`
export const StyledMenu = styled.div`
  display: none;

  @media (max-width: ${theme.breakpoints.small}px) {
    position: absolute;
    cursor: pointer;
    top: 37px;
    right: 30px;
    display: block;
  }
`

export const Dropdownmenu = styled.div`
  display: none;

  @media (max-width: ${theme.breakpoints.small}px) {
    animation-name: ${displayNavbar};
    animation-duration: 0.25s;

    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    z-index: 500;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 302px;
    height: 100vh;

    & svg {
      margin: 0 0 0 auto;
      display: block;
    }
  }
`
export const CloseContainer = styled.div`
  cursor: pointer;
`
export const StyledContainer = styled.div`
  background: linear-gradient(180deg, rgba(249, 252, 255, 0.95) 0%, rgba(219, 237, 252, 0.95) 100%);
  padding: 10px 32px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;

  & img {
    width: 126px;
    margin-bottom: 5px;
  }
`

export const StyledNavlink = styled.div`
  & a {
    text-decoration: none;
    font-weight: 800;
    font-size: 16px;
    line-height: 240%;
    color: ${theme.colors.dark1};
  }

  & a.active {
    color: ${theme.colors.turquoise2};
  }
`

export const StyledNavlinkFooter = styled.div`
  a {
    text-decoration: none;
    font-weight: 800;
    font-size: 20px;
    line-height: 240%;
    color: ${theme.colors.dark1};
  }
`

export const Text = styled.p<IProps>`
  font-weight: 800;
  font-size: 13px;
  line-height: 240%;
  text-transform: uppercase;
  cursor: ${props => props.isButton ? 'pointer' : 'default'}
`

export const Logout = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: ${theme.colors.beige};
  letter-spacing: 0.2em;
  padding: 0 32px;
  background: linear-gradient(90deg, #87C1F3 0%, #033D71 100%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  cursor: pointer;

  & svg {
    margin-left: 12px;
  }
`