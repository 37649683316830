import styled from 'styled-components';
import theme from "../../../../../theme/theme";

export const Paragraph = styled.p`
  font-size: 12px !important;
  line-height: 240% !important;
  text-align: center !important;
  width: 508px !important;

  & a {
    font-weight: 700;
    display: inline;
    color: black;
    font-size: 12px;
    line-height: 240%;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    width: auto !important;
  }

`

export const ParagraphBold = styled(Paragraph)`
  font-weight: 700;
`