import styled from 'styled-components';

export const ContainerThirdStage = styled.div`

`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;