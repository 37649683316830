import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useHistoryStore } from '../../../../../store/hooks';
import { useCaseApi } from '../../../../../apiHooks/useCaseApi';
import { IHeadCell } from '../../../../../interfaces/common/table';
import { generateAreaLink, generateLink, splitData, SplitData, UserLink } from '../../../../../utils';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { CellTime } from '../../../../shared/customTables/cells/cellTime/CellTime';
import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { ReactComponent as HistoryListIcon } from '../../../../../assets/icon/historyList.svg';
import { ReactComponent as SearchIcon } from '../../../../../assets/icon/search.svg';
import {
  CaseTitle,
  Header,
  HeaderContainer,
  HistoryHeader,
  InfoContainer,
  SearchIconContainer
} from './HistoryItems.styled';
import { Grid } from '@mui/material';
import { RoleCategory } from '../../../../../constants/projectUsers';
import { getPathType } from '../../../../../utils/switchArea';

export const useHistoryItems = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getCaseComments } = useCaseApi();
  const historyStore = useHistoryStore();
  const { history, isHistoryLoaded } = historyStore;
  const location = useLocation();
  const type = getPathType(location.pathname);

  useEffect(() => {
    if (!isHistoryLoaded && id) {
      if (history === null) {
        void getCaseComments(+id, type);
      } else {
        void getCaseComments(+id, type, false);
      }
    }
  }, [isHistoryLoaded, getCaseComments, history, id, type]);

  useEffect(() => {
    return () => {
      historyStore.resetHistory();
    };
  }, [historyStore]);

  const cellsSwitcher = (keyName: string, value: string, onClick?: () => void) => {
    const specialValue: SplitData = keyName === 'createdAt' ? splitData(value.toString()) : ['-', ''];

    switch (keyName) {
      case 'id':
        return null;
      case 'createdAt':
        return <TableCellData><CellTime specialValue={specialValue} /></TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const headCells: IHeadCell[] = [
    { id: 'createdAt', numeric: false, label: 'data utworzenia' },
    { id: 'comment', numeric: false, label: 'opis' }
  ];

  const tableDataRows = createTableRows(history?.items ?? [], headCells, false);

  const onClickSearchHandler = () => {
    navigate(generateLink(RoleCategory.USER, generateAreaLink(type, 'view') as UserLink, history?.id));
  };

  const addedContent = history ?
    <HistoryHeader container>
      <Grid item xs={7}>
        <HeaderContainer><HistoryListIcon /><Header>Historia edycji opisu przypadku:</Header></HeaderContainer>
        <CaseTitle>{history.title}</CaseTitle>
      </Grid>
      <Grid item xs={5} display={'flex'}>
        <SearchIconContainer onClick={onClickSearchHandler}>
          <SearchIcon />
        </SearchIconContainer>
        <InfoContainer>
          <p>
            <b>Id opisu przypadku: </b>{history.customId}
          </p>
          <p>
            <b>Lekarz: </b>{history.doctor}
          </p>
        </InfoContainer>
      </Grid>
    </HistoryHeader> : <></>;

  return {
    historyIsNotNull: history !== null,
    cellsSwitcher,
    tableDataRows,
    headCells,
    addedContent
  };
};