import { useFormik } from 'formik';

import { DoctorType, IDoctorFieldSets } from '../../../../../../../interfaces/user/draftCaseLung/thirdStage';
import {
  convertFormToResponse,
  initializeForm, initializeFormForFamily,
  validationSchema,
  validationSchemaForFamilyDoctor, validationSchemaForSquamous
} from './DoctorForm.helper';
import { useModal } from '../../../../../../shared/modal/useModal.vm';
import { ModalType } from '../../../../../../../constants/modalType';
import { useProgressBarLungStore } from '../../../../../../../store/hooks';
import { useDraftCaseApi } from '../../../../../../../apiHooks/useDraftCaseLungApi';
import { useParams } from 'react-router-dom';
import { setAllFieldsTouched } from '../../../../../../../utils/setAllFieldsTouched';

export const useDoctorForm = (type: DoctorType, onSave: (values: IDoctorFieldSets) => void, data?: IDoctorFieldSets, deleteDoctor?: (id: number) => Promise<boolean>, isAccordion: boolean = false) => {
  const {id} = useParams();
  
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();
  const progressBarStore = useProgressBarLungStore();
  const {getDraftCase} = useDraftCaseApi();

  const { secondStage } = progressBarStore;
  const isFamilyDoctor = type === 'family';
  const isSquamous = (secondStage && secondStage.interview.histologicalCancer === 'płaskonabłonkowy') ?? false;

  const formik = useFormik<IDoctorFieldSets>({
    enableReinitialize: true,
    initialValues: isAccordion ? data as IDoctorFieldSets : isFamilyDoctor ? initializeFormForFamily : initializeForm,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: isFamilyDoctor ? validationSchemaForFamilyDoctor : isSquamous ? validationSchemaForSquamous : validationSchema,
    onSubmit: async (values) => {
      const isValid = await formik.validateForm();
      if (isValid) {
        void onSave(convertFormToResponse(values, type, isSquamous));
      }
    }
  });

  const deleteModalHandler = () => {
    handleModal(ModalType.DELETE_MODAL);
  };

  const deleteYesHandler = async () => {
    if (data?.id && deleteDoctor) {
      await deleteDoctor(data.id);
      handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
    }
  };

  const closeDeleteYesModalHandler = async () => {
    if(id){
      await getDraftCase(id);
    }
  }

  const {setFieldTouched, values} = formik;

  const submitDoctorForm = () => {
    setAllFieldsTouched(values, setFieldTouched);
    formik.submitForm();
  }

  return {
    formik,
    isModalOpened,
    modalType,
    closeModal,
    deleteModalHandler,
    deleteYesHandler,
    isFamilyDoctor,
    isSquamous,
    closeDeleteYesModalHandler,
    submitDoctorForm
  };
};