import { FC } from 'react';
import { IAction } from '../../../../interfaces/common/table';
import { ActionButtonContainer } from './ActionButton.styled';

interface IProps extends IAction {
  id: number | string;
  type?: string;
  customId?: string;
  userId?: number;
  rotate?: boolean;
}

export const ActionButton: FC<IProps> = ({
                                           customId,
                                           background,
                                           color,
                                           border,
                                           id,
                                           actionHandler,
                                           actionElement,
                                           width,
                                           isDisabled,
                                           isNotVisible,
                                           userContract,
                                           type,
                                           rotate,
                                           userId

                                         }) => {
  return <ActionButtonContainer color={color} border={border} isNotVisible={isNotVisible}
                                isDisabled={typeof isDisabled === 'function' ? isDisabled(Number(id)) : isDisabled}
                                onClick={() => actionHandler && actionHandler(id, userContract, type, customId, userId)}
                                width={width}
                                $rotate={rotate}
                                background={typeof background === 'function' ? background(Number(id)) : background}>
    {typeof actionElement === 'function' ? actionElement(+id, customId) : actionElement}
  </ActionButtonContainer>;
};
