import styled from "styled-components";
import {NavLink} from "react-router-dom";
import theme from "../../../../../../theme/theme";

export const NavLinkCustom = styled(NavLink)`
  width: 300px;
  margin-left: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0 0 10px;
  height: 52px;
  margin-bottom: 42px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.darkBlue};
  padding-right: 30px;

  p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    color: white;
  }

  &.active {
    background: ${theme.colors.blue2};

    p {
      color: ${theme.colors.darkBlue};
    }
  }

`

export const NavLinkBottomCustom = styled(NavLinkCustom)`
  position: absolute;
  margin-bottom: 0px;
  bottom: 0;
`