import {useRootStore} from '../RootStateContext';

export const useDraftCaseStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.draftCase) {
    throw new Error('useDraftCaseStore store should be defined');
  }

  return rootStore.draftCase;
};
