import {Grid} from "@mui/material";
import {Button} from "../../../../../shared/button/Button";
import theme from "../../../../../../theme/theme";
import React, {MouseEvent} from "react";
import {RequiredParagraph} from "./ButtonConfirm.styled";

interface IProps {
  clickHandler: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  text: string
  bcgColor?: string
}

export const ButtonConfirm: React.FC<IProps> = ({clickHandler, text, bcgColor = theme.colors.dark1}) => {
  return (
    <Grid container alignItems={'center'} justifyContent={'center'} flexDirection={'column'} mt={2}>
      <Grid item alignItems={'center'} justifyContent={'center'} margin={'auto'}>
        <Button type={'submit'} text={text} size={'small'} color={theme.colors.beige}
                backgroundColor={bcgColor} width={'101px'} clickHandler={clickHandler}
        />
      </Grid>
      <Grid item alignSelf={'flex-start'}>
        <RequiredParagraph>* Pola obowiązkowe</RequiredParagraph>
      </Grid>
    </Grid>
  )
}
