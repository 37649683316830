import * as yup from 'yup';
import { convertRequestToFirstStage, RegexRules } from '../../../../../../utils';
import { useFormik } from 'formik';

import { useProgressBarNeuroStore } from '../../../../../../store/hooks';
import { useEffect } from 'react';
import { Stages } from '../../progressBar/ProgressBar.helper';
import { IFirstStageRequestNeuro } from '../../../../../../interfaces/user/draftCaseNeuro/firstStage';
import { Gender } from '../../../../../../constants/gender';
import { setAllFieldsTouched } from '../../../../../../utils/setAllFieldsTouched';


export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  initials: yup
    .string()
    .matches(RegexRules.INITIALS)
    .required(),
  yearOfBirth: yup
    .string()
    .test('year-of-birth-match', 'Year of birth is incorrect', function(value) {
      const yearNumber = value ? +value : -1;
      const currentYear = new Date().getFullYear();
      return yearNumber >= 1900 && yearNumber <= currentYear;
    })
    .required(),
  gender: yup.boolean()
});

export const useFirstStage = () => {
  const progressBarStore = useProgressBarNeuroStore();
  const { firstStage, isClicked } = progressBarStore;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: firstStage ? firstStage.title : '',
      initials: firstStage ? firstStage.initials : '',
      yearOfBirth: firstStage ? firstStage.yearOfBirth : '',
      gender: firstStage ? firstStage.gender : false
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      progressBarStore.setMaxEditStage(Stages.FIRST);
    } else {
      progressBarStore.setMaxEditStage(Stages.SECOND);
    }
  }, [firstStage, formik.errors, progressBarStore]);

  useEffect(() => {
    if ((formik.isValid)) {
      const firstStageConvert: IFirstStageRequestNeuro = {
        title: formik.values.title,
        initials: formik.values.initials,
        yearOfBirth: formik.values.yearOfBirth,
        gender: formik.values.gender ? Gender.WOMAN : Gender.MAN
      };
      progressBarStore.setFirstStage(convertRequestToFirstStage(firstStageConvert));
    } else {
      progressBarStore.setMaxEditStage(Stages.FIRST);
    }
  }, [formik.isValid, formik.values.gender, formik.values.initials, formik.values.title, formik.values.yearOfBirth, progressBarStore]);

  const { validateForm, setFieldTouched } = formik;

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched);
      void validateForm();
      progressBarStore.setClicked(false);
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values, progressBarStore]);

  return { formik };
};