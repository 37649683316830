import { onlyAdminLinks } from '../components/shared/appLayer/appRouting/links/adminLinks';
import { generalLinks } from '../components/shared/appLayer/appRouting/links/generalLinks';
import { RoleCategory, RoleFunction } from '../constants/projectUsers';
import { onlyUserLinks } from '../components/shared/appLayer/appRouting/links/userLinks';

export enum GeneralLink {
  HOME,
  LOGIN,
  RETRIEVE_PASSWORD,
  CHANGE_PASSWORD,
  REGISTER,
  REGISTER_VIEWER,
  REGISTER_PUBLISH,
  PAGE_404,
  UNDER_CONSTRUCTION
}

export enum AdminLink {
  HOME,
  ADD_USER,
  USERS,
  PUBLISHING_USERS,
  ROLES,
  EDIT_ROLE,
  CASES,
  HISTORY_NEURO,
  HISTORY_LUNG,
  TUTORIAL,
  VIEW_CASE_NEURO,
  VIEW_CASE_LUNG,
  WORK_CONTRACTS,
  COMPANY_CONTRACTS,
  INVOICES,
  DOCUMENTS,
  AREAS,
  EDIT_AREA,
  ADD_AREA,
  STATISTICS,
}

export enum UserLink {
  HOME,
  ADD_CASE_NEURO,
  EDIT_CASE_NEURO,
  ADD_CASE_LUNG,
  EDIT_CASE_LUNG,
  VIEW_CASE_NEURO,
  VIEW_CASE_LUNG,
  MY_CASES,
  OTHER_CASES,
  PROFILE,
  PROFILE_SELECT_FORM,
  PROFILE_FORM_INVOICE,
  PROFILE_FORM_CONTRACT,
  PROFILE_FORM_INVOICE_SUMMARY,
  PROFILE_FORM_CONTRACT_SUMMARY,
  PROFILE_FORM_DOCUMENTS,
  MY_RECKONING,
  MY_ACCEPTED_CASES
}


export const generateLink = (roleCategory: RoleCategory, link: GeneralLink | AdminLink | UserLink, id?: number | string) => {
  switch (roleCategory) {
    case RoleCategory.GUEST:
      return generalLinks[link as GeneralLink].path.replace(':id', id ? id.toString() : '');
    case RoleCategory.ADMIN:
      return onlyAdminLinks[link as AdminLink].path.replace(':id', id ? id.toString() : '');
    case RoleCategory.USER:
      return onlyUserLinks[link as UserLink].path.replace(':id', id ? id.toString() : '');
    default:
      return '';
  }
};

export const generateNavigationLink = (roleFunction: RoleFunction | null, roleCategory: RoleCategory) => {
  let path = generateLink(RoleCategory.GUEST, GeneralLink.LOGIN);
  if (roleCategory === RoleCategory.ADMIN) {
    if (RoleFunction.ROLE_ADMIN === roleFunction) {
      path = generateLink(RoleCategory.ADMIN, AdminLink.USERS);
    } else if (RoleFunction.ROLE_ACCEPTING === roleFunction) {
      path = generateLink(RoleCategory.ADMIN, AdminLink.CASES);
    } else if (RoleFunction.ROLE_BOOKKEEPER === roleFunction) {
      path = generateLink(RoleCategory.ADMIN, AdminLink.WORK_CONTRACTS);
    } else if (RoleFunction.ROLE_BOOKKEPPING === roleFunction) {
      path = generateLink(RoleCategory.ADMIN, AdminLink.DOCUMENTS);
    }

  } else if (roleCategory === RoleCategory.USER) {
    path = generateLink(RoleCategory.USER, UserLink.HOME);
  }

  return path;
};

export const generateAreaLink = (value: string, operation: string) => {
  const lowerValue = value.toLowerCase();

  switch (lowerValue) {
    case 'lung':
      switch (operation) {
        case 'add':
          return UserLink.ADD_CASE_LUNG;
        case 'edit':
          return UserLink.EDIT_CASE_LUNG;
        case 'view':
          return UserLink.VIEW_CASE_LUNG;
        default:
          return '';
      }
    case 'neuro':
      switch (operation) {
        case 'add':
          return UserLink.ADD_CASE_NEURO;
        case 'edit':
          return UserLink.EDIT_CASE_NEURO;
        case 'view':
          return UserLink.VIEW_CASE_NEURO;
        default:
          return '';
      }
    default:
      return '';
  }
};