import styled from 'styled-components';
import theme from '../../../../../theme/theme';

export const DoctorImg = styled.img`
  width: 100%;
  height: auto;
  margin: 20px auto 0 !important;
  display: none;
  @media (max-width: ${theme.breakpoints.small}px) {
    display: block;
  }
`;

export const RegisterContainer = styled.div`
  overflow: auto;
  padding: 0 30px;
  min-height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: ${theme.breakpoints.small}px) {
    height: unset;
    min-height: 100vh;
    padding: 16px;
    display: block;
  }
`;


export const StyledRegisterInfo = styled.div`
  width: 100%;
  @media (max-width: ${theme.breakpoints.medium}px) {
    p {
      display: none
    }

    img {
      display: none;
    }

    button {
      display: none;
    }
  }
`;