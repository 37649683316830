import { useProgressBarLungStore } from '../../../../../../store/hooks';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Stages } from '../../progressBar/ProgressBar.helper';
import { IFourthStageLung } from '../../../../../../interfaces/user/draftCaseLung/fouthStage';
import { generateValidationSchemaFourthStage } from '../../../../../../utils/generateValidationSchema';
import { setAllFieldsTouched } from '../../../../../../utils/setAllFieldsTouched';

export const useFourthStage = () => {
  const progressBarStore = useProgressBarLungStore();
  const { fourthStage, secondStage, thirdStage, isClicked } = progressBarStore;
  const [isDisabled, setDisabled] = useState(false);
  const [formInjection, setFormInjection] = useState<boolean | null>(null);
  const isNotComissioned = thirdStage?.doctors.filter(el => el.type !== 'family' && (el.alk?.isCommissioned || el.egfr?.isCommissioned || el.ros?.isCommissioned))?.length;
  const isSquamous = (secondStage && secondStage.interview.histologicalCancer === 'płaskonabłonkowy') || !(!!isNotComissioned && isNotComissioned > 0);

  const formik = useFormik<IFourthStageLung>({
    enableReinitialize: true,
    initialValues: {
      diagnosis: {
        molecularChanges: {
          isAlk: fourthStage?.diagnosis.molecularChanges ? fourthStage.diagnosis.molecularChanges.isAlk : null,
          isEgfr: fourthStage?.diagnosis.molecularChanges ? fourthStage.diagnosis.molecularChanges.isEgfr : null,
          isRos: fourthStage?.diagnosis.molecularChanges ? fourthStage.diagnosis.molecularChanges.isRos : null
        },
        ngs: fourthStage?.diagnosis.ngs ? fourthStage.diagnosis.ngs : '',
        expressionLevel: {
          level: fourthStage?.diagnosis.expressionLevel.level ? fourthStage.diagnosis.expressionLevel.level : '',
          percent: fourthStage?.diagnosis.expressionLevel.percent ? fourthStage.diagnosis.expressionLevel.percent : ''
        },
        tnm: fourthStage?.diagnosis.tnm ? fourthStage.diagnosis.tnm : '',
        metastases: {
          status: fourthStage?.diagnosis.metastases.status ? fourthStage.diagnosis.metastases.status : '',
          isCns: fourthStage?.diagnosis.metastases ? fourthStage.diagnosis.metastases.isCns : null,
          which: fourthStage?.diagnosis.metastases.which ? fourthStage.diagnosis.metastases.which : ''
        },
        problems: {
          list: fourthStage?.diagnosis.problems.list ? fourthStage.diagnosis.problems.list : [],
          others: fourthStage?.diagnosis.problems.others ? fourthStage.diagnosis.problems.others : []
        },
        dilo: fourthStage?.diagnosis.dilo ? fourthStage.diagnosis.dilo : ''
      }
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: generateValidationSchemaFourthStage(isSquamous ?? false),
    onSubmit: async (values) => {
    }
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      progressBarStore.setMaxEditStage(Stages.FOURTH);
    } else {
      progressBarStore.setMaxEditStage(Stages.FIFTH);
    }
  }, [formik.values, formik.errors, fourthStage, progressBarStore]);

  const { setFieldValue, errors } = formik;

  useEffect(() => {
    if ((formik.isValid)) {
      setFormInjection(true);
    } else {
      setFormInjection(false);
      progressBarStore.setMaxEditStage(Stages.FOURTH);
    }
  }, [formik, progressBarStore]);

  useEffect(() => {
    if (formInjection) {
      if (Object.keys(errors).length === 0) {
        progressBarStore.setFourthStage({
          diagnosis: { ...formik.values.diagnosis }
        });
        progressBarStore.setMaxEditStage(Stages.FIFTH);
      } else if (Object.keys(formik.touched).length !== 0 && Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.FOURTH);
      }
    }
  }, [errors, formInjection, progressBarStore, formik.touched, formik.values]);

  const handleCheckbox = (value: string) => {
    const newValues = [...formik.values.diagnosis.problems.list];
    const newOperation = newValues.filter(el => el !== value);
    if (value) {
      if (formik.values.diagnosis?.problems?.list?.includes(value)) {
        setFieldValue('diagnosis.problems.list', newOperation);
      } else if (!formik.values.diagnosis?.problems?.list?.includes(value)) {
        setFieldValue('diagnosis.problems.list', [...newValues, value]);
      }
    }
  };

  useEffect(() => {
    const newLength = formik.values.diagnosis.problems.others?.filter(el => el !== '').concat(formik.values.diagnosis.problems.list)?.length;
    if (newLength >= 3) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formik.values, setFieldValue]);

  useEffect(() => {
    if (formik.values.diagnosis.expressionLevel.level !== 'podano wynik dokładnie oceniając % wybarwionych komórek') {
      setFieldValue('diagnosis.expressionLevel.percent', '');
    }

    if (formik.values.diagnosis.metastases.status !== 'tak') {
      setFieldValue('diagnosis.metastases.isCns', null);
      setFieldValue('diagnosis.metastases.which', '');
    }

    if (formik.values.diagnosis.metastases.isCns === false) {
      setFieldValue('diagnosis.metastases.which', '');
    }

    if (isSquamous) {
      setFieldValue('diagnosis.molecularChanges.isAlk', null);
      setFieldValue('diagnosis.molecularChanges.isEgfr', null);
      setFieldValue('diagnosis.molecularChanges.isRos', null);
      setFieldValue('diagnosis.ngs', '');
    }
  }, [setFieldValue, formik.values.diagnosis.expressionLevel.level, isSquamous, formik.values.diagnosis.metastases.status, formik.values.diagnosis.metastases.isCns]);

  const {validateForm, setFieldTouched} = formik;

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm();
      progressBarStore.setClicked(false);
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values, progressBarStore])

  return {
    formik,
    handleCheckbox,
    isDisabled,
    isSquamous
  };
};
