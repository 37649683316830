import React, { FC, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@mui/material';
import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { useStatisticsApi } from '../../../../apiHooks/useStatisticsApi';
import { useStatisticsStore } from '../../../../store/hooks';
import { GenerateReportContainer } from '../../../shared/customTables/customTable/CustomTable.styled';
import { ReactComponent as DownloadExcel } from './../../../../assets/icon/getExcel.svg';
import { ReactComponent as Filters } from './../../../../assets/icon/filters.svg';
import { Input } from '../../../shared/form/input/Input';
import { SearchCustomContainer, SearchCustom, Header } from './Statistics.styled';
import { useAreasApi } from '../../../../apiHooks/useAreasApi';
import { Outlet } from 'react-router-dom';
import { ChipsSection } from './components/chips/ChipsSection';
import { MultiselectSection } from './components/multiselectSection/MultiselectSection';
import { NavigationButtons } from './components/navigationButtons/NavigationButtons';
import theme from '../../../../theme/theme';

type SearchEvent = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  children?: ReactNode;
}

export const Statistics: FC<IProps> = observer(({ children }) => {

  const { getStatistics, getExcel } = useStatisticsApi();
  const statisticsStore = useStatisticsStore();
  const {
    statistics,
    isStatisticsTableLoaded,
    searchValue,
    isFiltersOpen,
    amountOfChips,
    filtersToExcel
  } = statisticsStore;
  const { getAreas } = useAreasApi();

  useEffect(() => {
    if (!isStatisticsTableLoaded) {
      if (statistics === null) {
        void getStatistics();
      } else {
        void getStatistics(false);
      }
    }
  }, [statistics, getStatistics, isStatisticsTableLoaded]);

  useEffect(() => {
    void getAreas();
  }, [getAreas]);

  useEffect(() => {
    return () => statisticsStore.resetStatisticsTable();
  }, [statisticsStore]);

  const downloadExcel = async () => {
    await getExcel(filtersToExcel);
  };

  return (<AdminLayout>
    <Grid container display='flex' flexDirection='column' mt={4} flexWrap={'nowrap'}>
      <Grid item display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Header>Statystyka</Header>
        <Grid item alignItems={'center'} gap={2} display={'flex'}>
          <SearchCustomContainer>
            <Input
              margin={'0'}
              width={'287px'}
              placeholder={'Wpisz czego szukasz...'}
              type='text'
              name={'search'}
              value={searchValue}
              onChange={e => {
                const event = e as SearchEvent;
                statisticsStore.setSearchValue(event.target.value);
              }} />
            <SearchCustom />
          </SearchCustomContainer>
          <GenerateReportContainer
            svgPath={amountOfChips ? theme.colors.white : theme.colors.dark1}
            bcgColor={amountOfChips ? theme.colors.turquoise1 : theme.colors.white}
            borderColor={amountOfChips ? theme.colors.turquoise1 : 'rgb(224, 224, 224)'}
            onClick={() => statisticsStore.setFilterOpen(!isFiltersOpen)}
          >
            <Filters />
          </GenerateReportContainer>
          <GenerateReportContainer><DownloadExcel onClick={downloadExcel} /></GenerateReportContainer>
        </Grid>
      </Grid>
      {isFiltersOpen && <>
        <Grid item display='flex' gap={1} mt={1} sx={{ borderBottom: `1px solid ${theme.colors.gray5}` }}>
          <MultiselectSection />
        </Grid>
        {!!amountOfChips && <Grid item py={'18px'} sx={{ borderBottom: `1px solid ${theme.colors.gray5}` }}>
          <ChipsSection />
        </Grid>}
      </>}
      <Grid item mt={2}>
        <NavigationButtons />
      </Grid>
      <Grid container flexDirection={'column'} mt={2} height={'100%'} minWidth={'1050px'}>
        <Outlet />
      </Grid>
    </Grid>
  </AdminLayout>);
});