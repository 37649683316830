import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import styled from "styled-components";
import theme from "../../../../../../../theme/theme";

export const AccordionSummaryCustom = styled(AccordionSummary)`
  background: linear-gradient(180deg, rgba(243, 250, 247, 0) 0%, rgba(49, 159, 169, 0.2) 100%);
  color: ${theme.colors.turquoise2} !important;
  border-radius: 36px !important;

  font-size: 16px;
  line-height: 120%;
  height: 60px;
  position: relative;
  padding: 25px 16px 25px 0 !important;
  transition: min-height 10ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 10ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  z-index: 50;

  
  & div:first-of-type {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  & p:first-of-type {
    font-weight: 700 !important;
    margin-left: 16px;
  }

  & p:last-of-type {
    margin-right: 16px;
  }

  & path {
    fill: ${theme.colors.turquoise2};
  }


`;

export const AccordionDetailsCustom = styled(AccordionDetails)`
  padding: 0 !important;
`


export const AccordionStyled = styled(Accordion)`
  margin-bottom: 10px;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  ::after, ::before {
    background-color: transparent !important;
  }

  & > div:nth-child(2) {
    position: relative;
    top: -30px;
  }

  & > div {
    transition-duration: 0s !important;
  }

  & > .Mui-expanded {
    background-color: ${theme.colors.turquoise2} !important;
    color: white !important;
    padding-left: 16px;

    & path {
      fill: white !important;
    }
  }
`


export const Title = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: ${theme.colors.turquoise2};
  text-transform: uppercase;
  padding-bottom: 20px;
`

export const StyledSection = styled.div`
  background: linear-gradient(180deg, rgba(29, 150, 161, 0.1) 0%, rgba(223, 239, 232, 0) 100%);
  padding: 40px 40px;

  &:first-child {
    padding-top: 52px;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 40px 8px;
  }
`