import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDraftCaseStore, useProgressBarLungStore } from '../../../../../../store/hooks';
import { useEffect, useState } from 'react';
import { Stages } from '../../progressBar/ProgressBar.helper';
import { IFirstStageLung } from '../../../../../../interfaces/user/draftCaseLung/firstStage';
import { convertGender } from './firstStage.helper';
import { useDraftCaseApi } from '../../../../../../apiHooks/useDraftCaseLungApi';
import { setAllFieldsTouched } from '../../../../../../utils/setAllFieldsTouched';

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  yearOfBirth: yup
    .string()
    .test('year-of-birth-match', 'Year of birth is incorrect', function(value) {
      const yearNumber = value ? +value : -1;
      const currentYear = new Date().getFullYear();
      return yearNumber >= 1900 && yearNumber <= currentYear;
    })
    .required(),
  gender: yup
    .string()
    .required(),
  patientCity: yup
    .string()
    .required(),
  patientVoivodeshipId: yup
    .number()
    .min(1)
    .required(),
  weight: yup
    .number()
    .min(1)
    .required(),
  height: yup
    .number()
    .min(1) 
    .required(),
  chronicDisease: yup.object().shape({
    cardiovascularDiseases: yup
      .array(),
    cardiovascularDiseaseOther: yup
      .string()
      .when('cardiovascularDiseases', {
        is: (value: string[]) => value.includes('Inne'),
        then: (schema) => schema.required()
      }),
    digestiveSystemDisease: yup
      .string(),
    urogenitalDisease: yup
      .string(),
    isPochp: yup
      .boolean(),
    isAstma: yup
      .boolean(),
    respiratorySystemDisease: yup
      .string(),
    connectiveTissueDisease: yup
      .string(),
    diabetes: yup
      .string()
      .required(),
    hypothyroidism: yup
      .string()
      .required(),
    psoriasis: yup
      .string()
      .required(),
    albinism: yup
      .string()
      .required(),
    cancerDiseaseTreated: yup
      .string(),
    cancerDiseaseRadicalTreatment: yup
      .string(),
    lungCancer: yup
      .string()
      .required(),
    other: yup
      .string()
  }),
  city: yup
    .string()
    .required(),
  voivodeshipId: yup
    .number()
    .min(1)
    .required()
});

export const useFirstStage = () => {
  const progressBarStore = useProgressBarLungStore();
  const { getVoivodeships } = useDraftCaseApi();
  const { firstStage, isClicked } = progressBarStore;
  const { voivodeships } = useDraftCaseStore();
  const [formInjection, setFormInjection] = useState<boolean | null>(null);

  const formik = useFormik<IFirstStageLung>({
    enableReinitialize: true,
    initialValues: {
      title: firstStage ? firstStage.title : '',
      yearOfBirth: firstStage ? firstStage.yearOfBirth : -1,
      gender: firstStage ? convertGender(firstStage.gender) : '',
      patientCity: firstStage ? firstStage.patientCity : '',
      patientVoivodeshipId: firstStage ? firstStage.patientVoivodeshipId : -1,
      height: firstStage ? firstStage.height : -1,
      weight: firstStage ? firstStage.weight : -1,
      bmi: firstStage ? firstStage.bmi : -1,
      chronicDisease: {
        cardiovascularDiseases: firstStage ? firstStage.chronicDisease.cardiovascularDiseases : [],
        cardiovascularDiseaseOther: firstStage ? firstStage.chronicDisease.cardiovascularDiseaseOther : '',
        digestiveSystemDisease: firstStage ? firstStage.chronicDisease.digestiveSystemDisease : '',
        urogenitalDisease: firstStage ? firstStage.chronicDisease.urogenitalDisease : '',
        isPochp: firstStage ? firstStage.chronicDisease.isPochp : false,
        isAstma: firstStage ? firstStage.chronicDisease.isAstma : false,
        respiratorySystemDisease: firstStage ? firstStage.chronicDisease.respiratorySystemDisease : '',
        connectiveTissueDisease: firstStage ? firstStage.chronicDisease.connectiveTissueDisease : '',
        diabetes: firstStage ? firstStage.chronicDisease.diabetes : '',
        hypothyroidism: firstStage ? firstStage.chronicDisease.hypothyroidism : '',
        psoriasis: firstStage ? firstStage.chronicDisease.psoriasis : '',
        albinism: firstStage ? firstStage.chronicDisease.albinism : '',
        cancerDiseaseTreated: firstStage ? firstStage.chronicDisease.cancerDiseaseTreated : '',
        cancerDiseaseRadicalTreatment: firstStage ? firstStage.chronicDisease.cancerDiseaseRadicalTreatment : '',
        lungCancer: firstStage ? firstStage.chronicDisease.lungCancer : '',
        other: firstStage ? firstStage.chronicDisease.other : ''
      },
      city: firstStage ? firstStage.city : '',
      voivodeshipId: firstStage ? firstStage.voivodeshipId : -1
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  });

  const { setFieldValue, errors } = formik;

  useEffect(() => {
    if (Number(formik.values.weight) > 0 && Number(formik.values.height) > 0) {
      const countBMI = Math.round(Number(formik.values.weight) / Math.pow(formik.values.height / 100, 2));
      const bmiValue = Number.isNaN(countBMI) ? '' : countBMI;
      const bmi = (Number.isNaN(bmiValue) && Number(bmiValue) < 0) ? '' : bmiValue;
      setFieldValue('bmi', bmi);
    } else {
      setFieldValue('bmi', -1);
    }
  }, [formik.values.height, formik.values.weight, setFieldValue]);

  const handleCheckbox = (value: string) => {
    const newValues = [...formik.values.chronicDisease.cardiovascularDiseases];
    const newOperation = newValues.filter(el => el !== value);
    if (value) {
      if (formik.values.chronicDisease.cardiovascularDiseases?.includes(value)) {
        setFieldValue('chronicDisease.cardiovascularDiseases', newOperation);
      } else if (!formik.values.chronicDisease.cardiovascularDiseases?.includes(value)) {
        setFieldValue('chronicDisease.cardiovascularDiseases', [...newValues, value]);
      }
    }
  };

  useEffect(() => {
    if (formInjection) {
      if (Object.keys(errors).length === 0) {
        progressBarStore.setFirstStage({ ...formik.values, gender: convertGender(formik.values.gender) });
        progressBarStore.setMaxEditStage(Stages.SECOND);
      } else if (Object.keys(formik.touched).length !== 0 && Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.FIRST);
      }
    }
  }, [errors, formInjection, progressBarStore, formik.touched, formik.values]);

  useEffect(() => {
    if (!formik.values.chronicDisease.cardiovascularDiseases?.includes('Inne')) {
      setFieldValue('chronicDisease.cardiovascularDiseaseOther', '');
    }
  }, [formik.values.chronicDisease.cardiovascularDiseases, setFieldValue]);

  useEffect(() => {
    if (voivodeships.length === 0) void getVoivodeships();
  }, [getVoivodeships, voivodeships.length]);

  useEffect(() => {
    if ((formik.isValid)) {
      setFormInjection(true);
    } else {
      setFormInjection(false);
      progressBarStore.setMaxEditStage(Stages.FIRST);
    }
  }, [formik, progressBarStore]);

  const {validateForm, setFieldTouched} = formik;

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
      progressBarStore.setClicked(false)
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values, progressBarStore])

  return { formik, handleCheckbox, voivodeships };
};