import {AdminLayout} from "../../../shared/admin/layout/AdminLayout";
import {Grid} from "@mui/material";
import {
  AcceptedDiv,
  GreenDiv,
  RedDiv,
  RejectedDiv,
  RelativeGrid,
  StyledButton,
  StyledGrid,
  StyledImg
} from './Tutorial.styled'
import {Button} from "../../../shared/button/Button";
import theme from "../../../../theme/theme";
import arrowRight from './../../../../assets/img/arrowRight.png'
import curlyArrow from './../../../../assets/img/curlyArrow.png'
import {ReactComponent as Success} from './../../../../assets/icon/success.svg'
import {ReactComponent as Close} from './../../../../assets/icon/close.svg'
import {ReactComponent as Public} from './../../../../assets/icon/public.svg'
import {ReactComponent as Unpublic} from './../../../../assets/icon/unpublic.svg'
import {ReactComponent as Examine} from './../../../../assets/icon/examine.svg'
import {ReactComponent as Lens} from './../../../../assets/icon/viewLens.svg'
import {ReactComponent as Time} from './../../../../assets/icon/time.svg'
import {BoxComponent} from "./boxComponent/BoxComponent";

export const Tutorial = () => {
  return <AdminLayout>
    <Grid maxWidth={'780px'} display={'flex'} margin={'auto'} flexDirection={'column'}>
      <Grid container>
        <Grid item xs={12} display={'flex'} mb={3}>
          <StyledGrid item xs={4}>Filtrowanie</StyledGrid>
          <StyledGrid item xs={2}></StyledGrid>
          <StyledGrid item xs={2}>Akcja / Status</StyledGrid>
          <StyledGrid item xs={2}></StyledGrid>
          <StyledGrid item xs={2}>Status</StyledGrid>
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          <Grid item xs={4} pr={3}>
            <StyledButton>
              <Button
                fontSize={'12px'}
                width={'100%'}
                size='small'
                text={'do rozpatrzenia'}
                color={theme.colors.beige}
                backgroundColor={theme.colors.blue3} icon={<Examine/>}/>
            </StyledButton></Grid>
          <Grid item xs={2} pr={1}><img alt={'arrow'} src={arrowRight}/></Grid>
          <Grid item xs={2} display={'flex'}>
            <GreenDiv><Success/></GreenDiv>
            <RedDiv><Close/></RedDiv>

          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mt={7}>
          <Grid item xs={4} pr={3}>
            <StyledButton>
              <Button fontSize={'12px'}
                      width={'100%'}
                      size='small'
                      text={'zaakceptowane'}
                      color={theme.colors.beige}
                      backgroundColor={theme.colors.turquoise2} icon={<Success/>}/></StyledButton>
          </Grid>
          <Grid item xs={2} pr={1} position={'relative'}><img alt={'arrow'} src={arrowRight}
          /></Grid>
          <Grid item xs={2}>
            <AcceptedDiv>zaakceptowany</AcceptedDiv>
          </Grid>
          <RelativeGrid item xs={2} position={'relative'}>
            <img alt={'arrow'} src={arrowRight}/>
            <StyledImg alt={'arrow'} src={curlyArrow}/>
          </RelativeGrid>
          <Grid item xs={2}><Public/></Grid>
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mt={7}>
          <Grid item xs={4} pr={3}>
            <StyledButton>
              <Button
                fontSize={'12px'}
                width={'100%'}
                size='small'
                text={'do edycji'}
                color={theme.colors.beige}
                backgroundColor={theme.colors.red} icon={<Close/>}/></StyledButton>
          </Grid>
          <Grid item xs={2} pr={1} position={'relative'}><img alt={'arrow'} src={arrowRight}
          /></Grid>
          <Grid item xs={2}>
            <RejectedDiv>do edycji</RejectedDiv>
          </Grid>
          <RelativeGrid item xs={2} position={'relative'}>
            <img alt={'arrow'} src={arrowRight}/>
          </RelativeGrid>
          <Grid item xs={2}><Unpublic/></Grid>
        </Grid>
      </Grid>
      <Grid container display={'flex'} justifyContent={'space-between'} mt={10}>
        <BoxComponent
          text={'Kliknięcie w tytuł przypadku lub lupkę pokazuje widok przypadku tak jak widzi go użytkownik ogladający.'}
          bcgColor={theme.colors.darkBlue}><Lens/></BoxComponent>
        <BoxComponent
          text={'Klikniecie w zegarek pokazuje historię edycji opisu przypadku gdzie możesz podejrzeć powody poprzednich edycji.'}
          bcgColor={theme.colors.white}><Time/></BoxComponent>
      </Grid>
    </Grid>
  </AdminLayout>
}
