import {Grid} from "@mui/material";
import {dateStringify, stringMethod} from "../../../../../../utils";
import React from "react";
import {ReactComponent as Calendar} from './../../../../../../assets/icon/calendar.svg'
import {BlackTitle, Content, ObjectContainer, SmallerText, StyledBorder, StyledCalendar} from './GridContainer.styled'
import useBreakpoint, {Breakpoint} from "../../../../../../hooks/useBreakpoint";

interface IProps {
  textData: string;
  textContent: string;
  content: string;
  date?: string | null;
}

export const GridContainer: React.FC<IProps> = ({textData, textContent, content, date}) => {
  const breakpoint = useBreakpoint()
  return (
    <Grid container>
      {date && breakpoint >= Breakpoint.SMALL && <Grid item xs={5} sm={2} pr={2} display={'flex'}
                                                       flexDirection={'column'}>
          <BlackTitle>{textData}</BlackTitle>
          <StyledBorder></StyledBorder>
          <ObjectContainer><StyledCalendar><Calendar/></StyledCalendar><SmallerText>{dateStringify(date!)}</SmallerText></ObjectContainer>
      </Grid>}
      <Grid item xs={12} sm={10}>
        <BlackTitle dangerouslySetInnerHTML={{__html: stringMethod(textContent)}}/>
        <Content>{content}</Content>
      </Grid>
    </Grid>
  )
}

