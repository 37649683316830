import { IDraftCaseLung } from '../interfaces/user/draftCaseLung/draft';
import { ISecondStageLung } from '../interfaces/user/draftCaseLung/secoundStage';
import { IThirdStageLung } from '../interfaces/user/draftCaseLung/thirdStage';
import { IFourthStageLung } from '../interfaces/user/draftCaseLung/fouthStage';
import { IFirstStageLung } from '../interfaces/user/draftCaseLung/firstStage';


export const convertResponseToFirstStage = (draftCase: IDraftCaseLung): IFirstStageLung => {
  return {
    ...draftCase,
    voivodeshipId: draftCase.voivodeship.id,
    patientVoivodeshipId: draftCase.patientVoivodeship.id
  };
};

export const convertResponseToSecondStage = (draftCase: IDraftCaseLung): ISecondStageLung => {
  return {
    interview: {
      smockingTobacco: {
        isActive: draftCase.interview.smockingTobacco.isActive,
        years: draftCase.interview.smockingTobacco.years || -1,
        amount: draftCase.interview.smockingTobacco.amount || -1,
        packYear: draftCase.interview.smockingTobacco.years && draftCase.interview.smockingTobacco.amount ? draftCase.interview.smockingTobacco.years * draftCase.interview.smockingTobacco.amount : -1
      },
      cough: {
        isActive: draftCase.interview.cough.isActive,
        isChronic: draftCase.interview.cough.isChronic,
        dateFrom: draftCase.interview.cough.dateFrom || '',
        lastQuarterChange: draftCase.interview.cough.lastQuarterChange || ''
      },
      hemoptysis: {
        isActive: draftCase.interview.hemoptysis.isActive,
        dateFrom: draftCase.interview.hemoptysis.dateFrom || ''
      },
      weightLoss: {
        status: draftCase.interview.weightLoss.status || '',
        dateFrom: draftCase.interview.weightLoss.dateFrom || '',
        weight: draftCase.interview.weightLoss.weight || -1
      },
      hoarseness: {
        isActive: draftCase.interview.hoarseness.isActive,
        dateFrom: draftCase.interview.hoarseness.dateFrom || ''
      },
      dyspnoea: {
        isActive: draftCase.interview.dyspnoea.isActive,
        dateFrom: draftCase.interview.dyspnoea.dateFrom || ''
      },
      otherSymptoms: draftCase.interview.otherSymptoms || '',
      carcinogenicFactors: {
        status: draftCase.interview.carcinogenicFactors.status || '',
        which: draftCase.interview.carcinogenicFactors.which || ''
      },
      statusECOG: String(draftCase.interview.statusECOG) || '',
      histologicalCancer: draftCase.interview.histologicalCancer || ''
    }
  };
};
export const convertSecondStageToRequest = (draftCase: ISecondStageLung): ISecondStageLung => {
  return {
    interview: {
      smockingTobacco: {
        isActive: draftCase.interview.smockingTobacco.isActive,
        years: Number(draftCase?.interview?.smockingTobacco?.years) > 0 ? Number(draftCase.interview.smockingTobacco.years) : null,
        amount: Number(draftCase.interview.smockingTobacco.amount) > 0 ? Number(draftCase.interview.smockingTobacco.amount) : null,
        packYear: Number(draftCase.interview.smockingTobacco.packYear) > 0 ? Number(draftCase.interview.smockingTobacco.packYear) : null
      },
      cough: {
        isActive: draftCase.interview.cough.isActive,
        isChronic: draftCase.interview.cough.isChronic,
        dateFrom: draftCase.interview.cough.dateFrom || null,
        lastQuarterChange: draftCase.interview.cough.lastQuarterChange || null
      },
      hemoptysis: {
        isActive: draftCase.interview.hemoptysis.isActive,
        dateFrom: draftCase.interview.hemoptysis.dateFrom || null
      },
      weightLoss: {
        status: draftCase.interview.weightLoss.status || null,
        dateFrom: draftCase.interview.weightLoss.dateFrom || null,
        weight: Number(draftCase.interview.weightLoss.weight) > 0 ? draftCase.interview.weightLoss.weight : null
      },
      hoarseness: {
        isActive: draftCase.interview.hoarseness.isActive,
        dateFrom: draftCase.interview.hoarseness.dateFrom || null
      },
      dyspnoea: {
        isActive: draftCase.interview.dyspnoea.isActive,
        dateFrom: draftCase.interview.dyspnoea.dateFrom || null
      },
      otherSymptoms: draftCase.interview.otherSymptoms || null,
      carcinogenicFactors: {
        status: draftCase.interview.carcinogenicFactors.status || null,
        which: draftCase.interview.carcinogenicFactors.which || null
      },
      statusECOG: Number(draftCase.interview.statusECOG),
      histologicalCancer: draftCase.interview.histologicalCancer || null
    }
  };
};

export const convertResponseToThirdStage = (draftCase: IThirdStageLung): IThirdStageLung => {
  return {
    doctors: draftCase.doctors
  };
};


export const convertFourthStageToRequest = (draftCase: IFourthStageLung): IFourthStageLung => {
  return {
    diagnosis: {
      molecularChanges: {
        ...draftCase.diagnosis.molecularChanges
      },
      ngs: draftCase.diagnosis.ngs || null,
      expressionLevel: {
        level: draftCase.diagnosis.expressionLevel.level || null,
        percent: draftCase.diagnosis.expressionLevel.level === 'podano tylko wynik „ekspresja PD-L1 tj. TPS ≥ 50%”' ? null : draftCase.diagnosis.expressionLevel.percent || null
      },
      tnm: draftCase.diagnosis.tnm || null,
      metastases: {
        status: draftCase.diagnosis.metastases.status || null,
        isCns: draftCase.diagnosis.metastases.isCns,
        which: draftCase.diagnosis.metastases.which || null
      },
      problems: {
        list: draftCase.diagnosis.problems.list || [],
        others: draftCase.diagnosis.problems.others.filter(el => el !== '') || []
      },
      dilo: draftCase.diagnosis.dilo || null
    }
  };
};