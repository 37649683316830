import theme from '../../../../../../theme/theme';
import styled from 'styled-components';

export const ImgContainer = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${theme.colors.dark1};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const DotContainer = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${theme.colors.dark1};
  border-radius: 50%;
  margin-right: 8px;
`;

export const Container = styled.div<{ $margin?: string }>`
  display: flex;
  margin: ${props => props.$margin && props.$margin};

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
`;