import { useApiCall } from './useApiCall';
import { useCallback } from 'react';
import { useAuthStore, useHomeStore } from '../store/hooks';
import { IAreaObject } from '../interfaces/user/case';
import { ICaseCard } from '../interfaces/admin/cases/cases';
import { sortCasesByDate } from '../utils';
import { createEndpoints } from '../utils/createEndpoints';

export const useHomeApi = () => {
  const { api } = useApiCall();
  const homeStore = useHomeStore();
  const { areas } = useAuthStore();

  const getListOfLastCases = useCallback(
    async (isLoading: boolean = true) => {

      const userAreas = areas ? areas.map(el => el.shortName) : [];

      const areaObject: IAreaObject = {
        lung: 'lung/situations/last',
        neuro: 'situations/last'
      };

      const createdEndpoints = createEndpoints(userAreas, areaObject);

      const areasEndpoints = createdEndpoints
        .map(el => api.apiInstance.get(areaObject[el as keyof IAreaObject], isLoading));

      await Promise.all(areasEndpoints).then(dataArray => {
        const allCases: ICaseCard[] = [];

        if (dataArray) {
          dataArray.forEach((data, index) => {
            if (data) {
              const areaCases = data?.items.map((el: ICaseCard) => ({
                ...el,
                type: userAreas[index]
              }));
              allCases.push(...areaCases);
            }

          });
          const newAllCases = sortCasesByDate(allCases).filter((el: ICaseCard, index: number) => index < 3);
          homeStore.setListOfLastCases(newAllCases);
        }

      });

    },
    [api.apiInstance, homeStore, areas]
  );


  return {
    getListOfLastCases
  };

};