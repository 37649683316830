import React, {FC} from "react";
import {ConfirmationTextModal} from "../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled";
import {ContentModal} from "../../../shared/modal/ContentModal";
import {ReactComponent as CloseIcon} from "../../../../assets/icon/close.svg";
import {AddedInfo, CommentArea, Header, WarningIconContainer} from "./ShowComment.styled";

interface IShowComment {
  comment?: string | null;
}

export const ShowComment: FC<IShowComment> = ({comment}) => {

  return (
    <ContentModal
      iconType={<WarningIconContainer><CloseIcon/></WarningIconContainer>}
      text={<ConfirmationTextModal><Header>KOMENTARZ UZASADNIAJĄCY POPRAWĘ TEGO OPISU PRZYPADKU</Header>
        <AddedInfo>Popraw opis przypadku według wskazówek z komentarza i wyślij go do akceptacji ponownie.</AddedInfo>
        <CommentArea>{comment}</CommentArea>
      </ConfirmationTextModal>}>
    </ContentModal>
  )
}