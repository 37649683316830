import { useCaseStore } from '../../../../../store/hooks';
import React from 'react';

import {
  BlackTitle,
  Container,
  Content,
  FlexContainer,
  SectionTitle,
  Title,
  List,
  ListItem
} from './MiddlePart.styled';

import { GenderOther } from '../../../../../constants/gender';
import { ViewCheckbox } from '../sharedComponents/checkbox/ViewCheckbox';

export const MiddlePart = () => {
  const caseStore = useCaseStore();
  const { viewCaseLung } = caseStore;

  return (
    <Container>
      {viewCaseLung && <>
        <SectionTitle>Tytuł i dane pacjenta:</SectionTitle>
        <Title>Tytuł opisu przypadku</Title>
        <Content>{viewCaseLung.title}</Content>
        <FlexContainer>
          <div><Title>Rok urodzenia</Title>
            {viewCaseLung.yearOfBirth}
          </div>
          <div><Title>Płeć:</Title>
            {viewCaseLung.gender === GenderOther.MAN ? 'Mężczyzna' : viewCaseLung.gender === GenderOther.WOMAN ? 'Kobieta' : 'Inna'}
          </div>
        </FlexContainer>
        <Title>Miejsce zamieszkania pacjenta</Title>
        <FlexContainer>
          <div>
            <BlackTitle>Miasto</BlackTitle>
            <Content>{viewCaseLung.patientCity}</Content>
          </div>
          <div>
            <BlackTitle>Województwo</BlackTitle>
            <Content>{viewCaseLung.patientVoivodeship?.name}</Content>
          </div>
        </FlexContainer>
        <FlexContainer>
          <div><Title>Wzrost</Title>
            {viewCaseLung.height} cm
          </div>
          <div><Title>Aktualna masa ciała</Title>
            {viewCaseLung.weight} kg
          </div>
          <div><Title>bmi</Title>
            {Math.round(Number(viewCaseLung.weight) / Math.pow(viewCaseLung.height / 100, 2))}
          </div>
        </FlexContainer>
        {viewCaseLung.chronicDisease && <>
          <Title>Choroby współistniejące o charakterze przewlekłym</Title>
          <div><BlackTitle>Choroby układu krążenia</BlackTitle>
            <List>{viewCaseLung.chronicDisease.cardiovascularDiseases.map(el => <ListItem
              key={el}>{el}</ListItem>)}</List>
            <Content>{viewCaseLung.chronicDisease.cardiovascularDiseaseOther}</Content>
          </div>
          <div><BlackTitle>Choroby układu pokarmowego</BlackTitle>
            <Content>{viewCaseLung.chronicDisease.digestiveSystemDisease}</Content>
          </div>
          <div><BlackTitle>Choroby układu moczowo-płciowego</BlackTitle>
            <Content>{viewCaseLung.chronicDisease.urogenitalDisease}</Content>
          </div>
          <div>
            <BlackTitle>Choroby układu oddechowego</BlackTitle></div>
          {viewCaseLung.chronicDisease.isPochp &&
            <div><ViewCheckbox margin={'32px 0'}>pochp</ViewCheckbox></div>}
          {viewCaseLung.chronicDisease.isAstma &&
            <div><ViewCheckbox margin={'32px 0'}>astma oskrzelowa</ViewCheckbox></div>}
          <div><BlackTitle>inne choroby układu oddechowego</BlackTitle>
            <Content>{viewCaseLung.chronicDisease.respiratorySystemDisease}</Content>
          </div>
          <div><BlackTitle>choroby tkanki łącznej</BlackTitle>
            <Content>{viewCaseLung.chronicDisease.connectiveTissueDisease}</Content>
          </div>
          <Title>Choroby autoimmunologiczne, których obecność nie jest przeciwwskazaniem do immunoterapii:</Title>
          <div><BlackTitle>Cukrzyca typu 1</BlackTitle>
            <ViewCheckbox margin={'16px 0'}>{viewCaseLung.chronicDisease.diabetes}</ViewCheckbox>
          </div>
          <div><BlackTitle>Niedoczynność tarczycy</BlackTitle>
            <ViewCheckbox margin={'16px 0'}>{viewCaseLung.chronicDisease.hypothyroidism}</ViewCheckbox>
          </div>
          <div><BlackTitle>Łuszczyca</BlackTitle>
            <ViewCheckbox margin={'16px 0'}>{viewCaseLung.chronicDisease.psoriasis}</ViewCheckbox>
          </div>
          <div><BlackTitle>Bielactwo</BlackTitle>
            <ViewCheckbox margin={'16px 0'}>{viewCaseLung.chronicDisease.albinism}</ViewCheckbox>
          </div>
          <div><BlackTitle>Inna choroba nowotworowa obecnie leczona, bez cech progresji (stabilizacja
            choroby)</BlackTitle>
            <Content>{viewCaseLung.chronicDisease.cancerDiseaseTreated}</Content>
          </div>
          <div><BlackTitle>Inna choroba nowotworowa, stan po leczeniu radykalnym, bez cech wznowy</BlackTitle>
            <Content>{viewCaseLung.chronicDisease.cancerDiseaseRadicalTreatment}</Content>
          </div>
          <div><BlackTitle>występowanie raka płuca w rodzinie wśród krewnych I stopnia</BlackTitle>
            <ViewCheckbox margin={'16px 0'}>{viewCaseLung.chronicDisease.lungCancer}</ViewCheckbox>
          </div>
          <div><BlackTitle>Inne choroby (nie wymienione powyżej o istotnym charakterze klinicznym)</BlackTitle>
            <Content>{viewCaseLung.chronicDisease.other}</Content>
          </div>
        </>}
        <Title>Miejsce leczenia chorego</Title>
        <FlexContainer>
          <div>
            <BlackTitle>Miasto</BlackTitle>
            <Content>{viewCaseLung.city}</Content>
          </div>
          <div>
            <BlackTitle>Województwo</BlackTitle>
            <Content>{viewCaseLung.voivodeship.name}</Content>
          </div>
        </FlexContainer>
      </>}
    </Container>
  );
};
