import React from 'react';
import { IChildren } from '../../../../interfaces/common/common';
import { observer } from 'mobx-react-lite';
import { NavigationComponent } from '../navigation/navigationComponent/NavigationComponent';
import { Footer } from '../footer/Footer';
import { ProfileContainer, UserLayoutContainer, UserLayoutContent } from './UserLayout.styled';
import { IBreadcrumbsElement } from '../../../../interfaces/user/breadcrumbs';
import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';
import { Grid } from '@mui/material';
import { DoctorImg } from '../../../pages/user/profile/selectForm/SelectForm.styled';
import doctorHospitalBcg from '../../../../assets/img/doctorHospitalBcg.png';

interface IProps extends IChildren {
  isDoctorVisible?: boolean;
  isProfileContainer?: boolean;
  breadcrumbs?: IBreadcrumbsElement[] | null;
  overflowAuto?: boolean;
}

export const UserLayout: React.FC<IProps> = observer(({
                                                        isProfileContainer,
                                                        isDoctorVisible,
                                                        children,
                                                        breadcrumbs,
                                                        overflowAuto
                                                      }) => {
  return (
    <UserLayoutContainer
      container
      direction='row'
      flexWrap='wrap'>
      <NavigationComponent />
      <Grid
        sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto'
        }}
        display={{ xs: 'none', sm: 'flex' }}
        minHeight={{ xs: 0, sm: '66px' }}>{<Breadcrumbs
        breadcrumbs={breadcrumbs} />}</Grid>
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: 'calc(100vh - 184px)'
      }}>
        <UserLayoutContent overflowAuto={overflowAuto}>
          <>
            {isProfileContainer ?
              <ProfileContainer container
                                display={'flex'}
                                margin={'0 auto'}
                                height={'100%'}
                                overflow={'auto'}
                                minHeight={'calc(100vh - 224px)'}
              >
                <Grid item xs={12} sm={7} display={'flex'} minHeight={'calc(100vh - 224px)'}>
                  {children}
                </Grid>
                {isDoctorVisible && <Grid item display={{ xs: 'none', sm: 'flex' }} sm={5} alignSelf={'flex-end'}>
                  <DoctorImg src={doctorHospitalBcg} />
                </Grid>}
              </ProfileContainer> :
              children
            }
          </>
        </UserLayoutContent>
        <Footer />
      </div>
    </UserLayoutContainer>
  );
});
