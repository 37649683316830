import styled from 'styled-components';
import { Grid } from '@mui/material';
import theme from '../../../../../theme/theme';


export const ButtonContainer = styled.div`
  width: 100%;
  & button{
    margin: 0px 0px 0px auto;
  }
`

export const GridLeft = styled(Grid)`
  padding-right: 8px;
  @media (max-width: ${theme.breakpoints.medium}px) {
    padding-right: 0px;
  }

`