import styled, { css } from 'styled-components';
import { Grid } from '@mui/material';

import theme from '../../../../../../theme/theme';

export const SummaryDataStyled = styled(Grid)`
  display: flex;
  flex-direction: column !important;
  padding: 16px;
  width: unset;
  flex-grow: 2;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 9.7px 0px rgba(0, 0, 0, 0.25);
`;

export const SummaryDataTitle = styled.h2`
  color: #333;
  font-size: 10px;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const summaryDataElementCommon = css`
  background: #F9FCFF;
  font-size: 12px;
  font-weight: 400;
`;

export const SummaryDataElementVariantFirst = styled.div`
  ${summaryDataElementCommon};
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

export const SummaryDataElementVariantSecond = styled.div`
  ${summaryDataElementCommon};
  margin: 2px;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  border: 1px solid white;
  width: 100%;

  svg {
    flex-shrink: 0
  }
`;


export const SummaryDataHeader = styled.div`
  ${summaryDataElementCommon};
  background: ${theme.colors.blue4};
  text-transform: uppercase;
  color: white;
  height: 50px;
  font-weight: 700;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border: 1px solid white;
`;