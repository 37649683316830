import * as yup from 'yup';
import { useProgressBarLungStore } from '../../../../../../store/hooks';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Stages } from '../../progressBar/ProgressBar.helper';
import { ISecondStageLung } from '../../../../../../interfaces/user/draftCaseLung/secoundStage';
import { setAllFieldsTouched } from '../../../../../../utils/setAllFieldsTouched';

export const validationSchema = yup.object().shape({
  interview: yup.object().shape({
    smockingTobacco: yup.object().shape({
      isActive: yup
        .boolean()
        .required(),
      years: yup
        .string()
        .when('isActive', {
          is: true,
          then: (schema) => schema.notOneOf(['-1']).required()
        }),
      amount: yup
        .string()
        .when('isActive', {
          is: true,
          then: (schema) => schema.notOneOf(['-1']).required()
        })
    }),
    cough: yup.object().shape({
      isActive: yup
        .boolean()
        .required(),
      isChronic: yup
        .boolean()
        .nullable()
        .when('isActive', {
          is: true,
          then: (schema) => schema.required()
        }),
      dateFrom: yup
        .string()
        .when('isChronic', {
          is: true,
          then: (schema) => schema.required()
        }),
      lastQuarterChange: yup
        .string()
        .when('isChronic', {
          is: true,
          then: (schema) => schema.required()
        })
    }),
    hemoptysis: yup.object().shape({
      isActive: yup
        .boolean()
        .required(),
      dateFrom: yup
        .string()
        .when('isActive', {
          is: true,
          then: (schema) => schema.required()
        })
    }),
    weightLoss: yup.object().shape({
      status: yup
        .string()
        .required(),
      dateFrom: yup
        .string()
        .when('status', {
          is: 'tak',
          then: (schema) => schema.required()
        }),
      weight: yup
        .string()
        .nullable()
        .when('status', {
          is: 'tak',
          then: (schema) => schema.notOneOf(['-1']).required()
        })
    }),
    hoarseness: yup.object().shape({
      isActive: yup
        .boolean()
        .required(),
      dateFrom: yup
        .string()
        .when('isActive', {
          is: true,
          then: (schema) => schema.required()
        })
    }),
    dyspnoea: yup.object().shape({
      isActive: yup
        .boolean()
        .required(),
      dateFrom: yup
        .string()
        .when('isActive', {
          is: true,
          then: (schema) => schema.required()
        })
    }),
    otherSymptoms: yup.string(),
    carcinogenicFactors: yup.object().shape({
      status: yup
        .string()
        .required(),
      which: yup
        .string()
        .when('status', {
          is: 'tak',
          then: (schema) => schema.required()
        })
    }),
    statusECOG: yup.number().required(),
    histologicalCancer: yup.string().required()
  })
});

export const useSecondStage = () => {
  const progressBarStore = useProgressBarLungStore();
  const { secondStage, isClicked } = progressBarStore;
  const [formInjection, setFormInjection] = useState<boolean | null>(null);
  const formik = useFormik<ISecondStageLung>({
    enableReinitialize: true,
    initialValues: {
      interview: {
        smockingTobacco: {
          isActive: secondStage?.interview.smockingTobacco ? secondStage.interview.smockingTobacco.isActive : null,
          years: secondStage?.interview.smockingTobacco ? secondStage.interview.smockingTobacco.years : -1,
          amount: secondStage?.interview.smockingTobacco ? secondStage.interview.smockingTobacco.amount : -1,
          packYear: secondStage?.interview.smockingTobacco ? secondStage.interview.smockingTobacco.packYear : -1
        },
        cough: {
          isActive: secondStage ? secondStage.interview.cough.isActive : null,
          isChronic: secondStage ? secondStage.interview.cough.isChronic : null,
          dateFrom: secondStage ? secondStage.interview.cough.dateFrom : '',
          lastQuarterChange: secondStage ? secondStage.interview.cough.lastQuarterChange : ''
        },
        hemoptysis: {
          isActive: secondStage ? secondStage.interview.hemoptysis.isActive : null,
          dateFrom: secondStage ? secondStage.interview.hemoptysis.dateFrom : ''
        },
        weightLoss: {
          status: secondStage ? secondStage.interview.weightLoss.status : '',
          dateFrom: secondStage ? secondStage.interview.weightLoss.dateFrom : '',
          weight: secondStage ? secondStage.interview.weightLoss.weight : -1
        },
        hoarseness: {
          isActive: secondStage ? secondStage.interview.hoarseness.isActive : null,
          dateFrom: secondStage ? secondStage.interview.hoarseness.dateFrom : ''
        },
        dyspnoea: {
          isActive: secondStage ? secondStage.interview.dyspnoea.isActive : null,
          dateFrom: secondStage ? secondStage.interview.dyspnoea.dateFrom : ''
        },
        otherSymptoms: secondStage ? secondStage.interview.otherSymptoms : '',
        carcinogenicFactors: {
          status: secondStage ? secondStage.interview.carcinogenicFactors.status : '',
          which: secondStage ? secondStage.interview.carcinogenicFactors.which : ''
        },
        statusECOG: secondStage ? secondStage.interview.statusECOG : '',
        histologicalCancer: secondStage ? secondStage.interview.histologicalCancer : ''
      }
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  });

  const { values: { interview }, setFieldValue, errors } = formik;

  useEffect(() => {
    if (!interview.smockingTobacco.isActive) {
      setFieldValue('interview.smockingTobacco.years', -1);
      setFieldValue('interview.smockingTobacco.amount', -1);
    }
    if (!interview.cough.isActive) {
      setFieldValue('interview.cough.isChronic', null);
    }
    if (!interview.cough.isChronic) {
      setFieldValue('interview.cough.dateFrom', '');
      setFieldValue('interview.cough.lastQuarterChange', '');
    }
    if (!interview.hemoptysis.isActive) {
      setFieldValue('interview.hemoptysis.dateFrom', '');
    }
    if (!(interview.weightLoss.status === 'tak')) {
      setFieldValue('interview.weightLoss.dateFrom', '');
      setFieldValue('interview.weightLoss.weight', -1);
    }
    if (!interview.hoarseness.isActive) {
      setFieldValue('interview.hoarseness.dateFrom', '');
    }
    if (!interview.dyspnoea.isActive) {
      setFieldValue('interview.dyspnoea.dateFrom', '');
    }
    if (!(interview.carcinogenicFactors.status === 'tak'))
      setFieldValue('interview.carcinogenicFactors.which', '');

  }, [setFieldValue, interview.smockingTobacco.isActive, interview.cough.isChronic, interview.cough.isActive,
    interview.hemoptysis.isActive, interview.weightLoss.status, interview.carcinogenicFactors.status,
    interview.dyspnoea.isActive, interview.hoarseness.isActive]);

  useEffect(() => {
    if ((formik.isValid)) {
      setFormInjection(true);
    } else {
      setFormInjection(false);
      progressBarStore.setMaxEditStage(Stages.SECOND);
    }
  }, [formik, progressBarStore]);

  useEffect(() => {
    if (formInjection) {
      if (Object.keys(errors).length === 0) {
        progressBarStore.setSecondStage({
          ...formik.values
        });
        progressBarStore.setMaxEditStage(Stages.THIRD);
      } else if (Object.keys(formik.touched).length !== 0 && Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.SECOND);
      }
    }
  }, [errors, formInjection, progressBarStore, formik.touched, formik.values]);

  const changeSmockingFields = (fieldName: 'years' | 'amount', value: string) => {
    setFieldValue(`interview.smockingTobacco.${fieldName}`, value);
    const secondValue = Number(fieldName === 'years' ? formik.values.interview.smockingTobacco.amount : formik.values.interview.smockingTobacco.years);
    const firstConvertValue = Number(value);

    setPackYear(firstConvertValue, secondValue)
   }

   const setPackYear = (firstValue: number, secondValue: number) => {
    if(firstValue > 0 && secondValue > 0){
      setFieldValue('interview.smockingTobacco.packYear', firstValue * secondValue);
    }
    else{
      setFieldValue('interview.smockingTobacco.packYear', -1);
    }
   }

   const {validateForm, setFieldTouched} = formik;

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
      progressBarStore.setClicked(false)
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values, progressBarStore])


  return {
    formik,
    changeSmockingFields
  };
};
