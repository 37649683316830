import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {DoctorType} from '../../../../../../interfaces/user/draftCaseLung/thirdStage';
import {useProgressBarLungStore} from '../../../../../../store/hooks';
import {useDraftCaseApi} from '../../../../../../apiHooks/useDraftCaseLungApi';
import {ModalType} from '../../../../../../constants/modalType';
import {Stages} from '../../../draftCaseNeuro/progressBar/ProgressBar.helper';
import {useModal} from '../../../../../shared/modal/useModal.vm';

export const useThirdStage = () => {
  const {id} = useParams();
  const [isDoctorElement, setIsDoctorElement] = useState<null | boolean>(null);
  const { handleModal, isModalOpened, closeModal } = useModal();
  const [type, setType] = useState<DoctorType | null>(null)
  const [expandedId, setExpandedId] = useState<number | null>(null);

  const progressBarLungStore= useProgressBarLungStore();
  const {thirdStage, isClicked} = progressBarLungStore;
  const {deleteDoctorThirdStage} = useDraftCaseApi()

  const addDoctorTypeHandler = (key: DoctorType) => {
    handleModal(ModalType.ADD_MODAL, String(key));
    setType(key);
  }

  const onChangeHandler = (id?: number | null) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else if (id) {
      setExpandedId(+id);
    }
  }

  const deleteDoctor = async (idDoctor: number) => {
    if(id){
      const deleteResult = await deleteDoctorThirdStage(Number(id), idDoctor);
      checkThirdStage();
      return deleteResult;
    }
    return false;
  }

  const doctors = progressBarLungStore.thirdStage ? progressBarLungStore.thirdStage.doctors : [];
  
  const checkThirdStage = useCallback(() => {
    setIsDoctorElement((progressBarLungStore.thirdStage ? progressBarLungStore.thirdStage.doctors : []).length>0)
  }, [progressBarLungStore.thirdStage]);

  useEffect(() => {
    if (thirdStage && thirdStage.doctors.length > 0) {
      progressBarLungStore.setMaxEditStage(Stages.FOURTH);
    } else {
      progressBarLungStore.setMaxEditStage(Stages.THIRD);
    }
    if(isDoctorElement !== null) {
      checkThirdStage();
    }

  }, [progressBarLungStore, thirdStage,isDoctorElement,checkThirdStage])


  useEffect(() => {
    if (isClicked) {
      checkThirdStage();
      progressBarLungStore.setClicked(false)
    }
  }, [isClicked, progressBarLungStore, checkThirdStage])

  return {
    type,
    addDoctorTypeHandler,
    onChangeHandler,
    deleteDoctor,
    doctors,
    isModalOpened,
    expandedId,
    handleModal,
    closeModal,
    modalTitle: id as string,
    isDoctorElement,
    checkThirdStage
  };
};