import styled from 'styled-components';
import { Direction } from './ArrrowButton.helper';
import theme from '../../../../../../theme/theme';

interface IArrowButtonContainer {
  direction: Direction;
  isValid: boolean;
  isMobile?: boolean;
}

export const ArrowButtonContainer = styled.button<IArrowButtonContainer>`
  top: 50%;
  right: ${props => props.direction === 'right' ? '-60px' : 'auto'};
  left: ${props => props.direction === 'left' ? '-60px' : 'auto'};
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${props => props.isValid ? 'pointer' : 'default'};
  opacity: ${props => props.isValid ? 1 : 0.5};
  border: none;
  background: none;

  @media (max-width: ${theme.breakpoints.small}px) {
    display: ${props => props.isMobile ? 'flex' : 'none'};
    top: 0;
    position: absolute;
    right: ${props => props.direction === 'right' ? '-75px' : 'auto'};
    left: ${props => props.direction === 'left' ? '-75px' : 'auto'};
  }
`;


export const ArrowIconContainer = styled.div<IArrowButtonContainer>`
  width: 94px;
  height: 94px;
  background: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


  & svg {
    margin-left: ${props => props.direction === 'right' ? '15px' : 'auto'};
    margin-right: ${props => props.direction === 'left' ? '15px;' : 'auto'};
    transform: rotate(${props => props.direction === 'left' ? '0deg' : '180deg'});
  }

  & path {
    fill: white;
  }
`;

export const ArrowText = styled.span`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;

  @media (max-width: ${theme.breakpoints.extraLarge}px) {
    display: none;
  }
`;