import theme from "../../../../theme/theme";
import {Button} from "../../button/Button";
import {ReactComponent as Close} from "../../../../assets/icon/close.svg";
import {ReactComponent as Plus} from "../../../../assets/icon/plus.svg";
import React, {MouseEvent} from "react";
import {useNavigate} from 'react-router-dom';
import {ButtonContainer, Paragraph, StyledLink} from "./FormButton.styled";

interface IProps {
  isButtonActive: boolean;
  confirmForm?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  link?: string,
  onClickCancel?: () => void;
  buttonText: string
}

export const FormButtons: React.FC<IProps> = ({isButtonActive, confirmForm, link, onClickCancel, buttonText}) => {
  const navigate = useNavigate();

  const linkHandler = () => {
    if (link) {
      navigate(link);
    }
  }

  return (
    <>
      <ButtonContainer color={isButtonActive ? theme.colors.beige : theme.colors.darkBlue}>
        <StyledLink onClick={(e) => onClickCancel ? onClickCancel() : linkHandler()}>
          <Button
            type={'button'}
            text={'anuluj'}
            backgroundColor={theme.colors.beige}
            color={theme.colors.dark1}
            size={'small'}
            border={theme.colors.dark1}
            maxWidth={'188px'} icon={<Close/>}
          />
        </StyledLink>
        <Button type='submit'
                clickHandler={confirmForm}
                text={buttonText}
                backgroundColor={isButtonActive ? theme.colors.dark1 : theme.colors.blue2}
                color={isButtonActive ? theme.colors.beige : theme.colors.darkBlue}
                size={'small'}
                maxWidth={'188px'} icon={buttonText.includes('dodaj') ? <Plus/> : undefined}
        />
      </ButtonContainer>
      <Paragraph>*Pola obowiązkowe</Paragraph>
    </>
  )
}
