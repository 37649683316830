import React, { FC } from 'react';
import { Box, Grid, TableBody, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { CellsSwitcher } from '../cells/cellsSwitcher/CellsSwitcher';
import { useTableController } from '../hook/useTableController';
import { TopActions } from '../topActions/TopActions';
import { GlobalLoader } from '../../appLayer/globalLoader/GlobalLoader';
import { IAction, IHeadCell, ITableConfig, ITableData, TableSortOrder } from '../../../../interfaces/common/table';
import {
  GenerateReportCell,
  GenerateReportContainer,
  ParagraphNoData,
  TableCellActions,
  TableCellActionsHead,
  TableCellHeader,
  TableContainerCustom,
  TableCustom
} from './CustomTable.styled';
import { ActionButton } from '../button/ActionButton';
import { ReactComponent as DownloadExcel } from './../../../../assets/icon/getExcel.svg';
import { TableCellData } from '../cells/cellsSwitcher/CellsSwitcher.styled';

interface IProps<Value> {
  // fetched list we pass to component
  tableDataRows: ITableData[];
  // boolean value checking if data was fetched
  isDataDownloaded: boolean;
  // list of names of column headerCustomTable
  headCells: IHeadCell[];
  // object specifying areas for table like order, rows per page etc.
  customTableConfig?: ITableConfig;
  //list of actions
  actions?: IAction[];
  // show/hidden search bar
  isSearchBar?: boolean;
  //added content for search bar section
  searchBarAddedContent?: JSX.Element,
  contentAfterSearch?: JSX.Element,
  afterSearchFlexRowContent?: JSX.Element,
  // custom cell switcher if data are more complex
  cellsSwitcher?: (keyName: string, value: Value, onClick?: () => void, id?: number) => null | JSX.Element;
  minHeight?: string;
  isDownloadExcel?: boolean;
  hideAction?: boolean
  generateReportHandler?: () => void;
  sortOrderByHandler?: (val: string) => void;
  sortOrderHandler?: (val: TableSortOrder) => void;
  isSummary?: boolean;
  isAccordion?: boolean;
}

export const CustomTable: FC<IProps<any>> = ({
                                               hideAction,
                                               tableDataRows,
                                               isDataDownloaded,
                                               headCells,
                                               customTableConfig,
                                               actions,
                                               isSearchBar,
                                               searchBarAddedContent,
                                               contentAfterSearch,
                                               afterSearchFlexRowContent,
                                               cellsSwitcher,
                                               minHeight,
                                               isDownloadExcel,
                                               generateReportHandler,
                                               isSummary,
                                               isAccordion,
                                               sortOrderByHandler, sortOrderHandler
                                             }): JSX.Element => {

  const {
    tableConfig,
    filterChangeHandler,
    createSortHandler,
    drawArray,
    widthActionsHead,
    summary
  } = useTableController(headCells, tableDataRows, customTableConfig, actions, isSummary, sortOrderByHandler, sortOrderHandler);

  return isDataDownloaded ? (
    <Grid
      container
      flexDirection={'column'}
      flexWrap={'nowrap'}
      minHeight={minHeight}
    >
      <Grid display={'flex'}>
        {(isSearchBar || searchBarAddedContent) && <TopActions
          isSearchBar={isSearchBar}
          filterChangeHandler={filterChangeHandler}
          filterValue={tableConfig.searchValue}
          searchBarAddedContent={searchBarAddedContent}
          afterSearchFlexRowContent={afterSearchFlexRowContent}
        />}
      </Grid>
      {contentAfterSearch}
      <TableContainerCustom>
        {drawArray.length > 0 ? <TableCustom>
          <TableHead>
            <TableRow>
              {
                headCells && headCells.length > 0 && headCells.map((headCell, index) =>
                  <React.Fragment key={headCell.id}>{headCell.label ? <TableCellHeader
                    key={headCell.id}
                    align='left'
                    sortDirection={tableConfig.order === headCell.id ? tableConfig.order : false}
                  >

                    <TableSortLabel
                      active={tableConfig.orderBy === headCell.id}
                      direction={tableConfig.orderBy === headCell.id ? tableConfig.order : TableSortOrder.Ascending}
                      onClick={() => createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {
                        tableConfig.orderBy === headCell.id ? (
                            <Box component='span' sx={visuallyHidden}>
                              {tableConfig.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          )
                          : null
                      }
                    </TableSortLabel>
                  </TableCellHeader> : null}
                    {headCell.header}
                  </React.Fragment>)
              }

              {isDownloadExcel ? <GenerateReportCell>
                  <GenerateReportContainer margin={'auto'}
                                           onClick={generateReportHandler}><DownloadExcel /></GenerateReportContainer>
                </GenerateReportCell> :

                actions && actions.length > 0 ? <TableCellActionsHead width={widthActionsHead} /> : null
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              drawArray && drawArray.length > 0 && drawArray.map((row, index) => (
                <React.Fragment key={row.customId || row.id}><TableRow key={row.customId || row.id}>
                  {
                    Object.keys(row).filter(row => row !== 'id').map((key, index) =>
                      <CellsSwitcher
                        id={+row.id}
                        customId={row.customId as string}
                        key={row[key] + index.toString()}
                        keyName={key?.toString()}
                        value={row[key] ?? ''}
                        type={row.type as string}
                        cellsSwitcher={cellsSwitcher}
                      />
                    )
                  }
                  <TableCellActions> {
                    actions && actions.length > 0 && (
                      <>{actions.map(action => <React.Fragment key={row.key + '-' + action.key}>{action.custom ?
                        <React.Fragment>{action.custom(row.id, +row.user, row.type ? row.type as string : undefined, row.customId ? row.customId as string : undefined, +row.userId || undefined)}</React.Fragment> :
                        <ActionButton isNotVisible={hideAction && !!row.isVisible} key={action.key}
                                      id={row.id} customId={row.customId ? row.customId as string : undefined}
                                      type={row.type ? row.type as string : undefined}
                                      userContract={+row[action.actionId as keyof IAction] ?? undefined} {...action} />}</React.Fragment>)}</>)
                  } </TableCellActions>
                </TableRow>
                  {isAccordion && <TableRow>
                    <>{tableDataRows[index]?.cases}</>
                  </TableRow>}
                </React.Fragment>
              ))
            }

            {summary && <TableRow>
              <CellsSwitcher
                id={0}
                keyName={'total'}
                value={'SUMA'}
                type={'summary'}
                cellsSwitcher={(keyName: string, value: string) =>
                  <TableCellData><strong>{value}</strong></TableCellData>} />
              {<>{Object.keys(summary).slice(1, Object.keys(summary).length - 1).map((key, index) =>
                <CellsSwitcher
                  id={index}
                  key={key + index.toString() + '-summary'}
                  keyName={key?.toString()}
                  value={summary[key as keyof typeof summary]}
                  type={'summary'}
                  cellsSwitcher={(keyName: string, value: string) =>
                    <TableCellData><strong>{value}</strong></TableCellData>} />)}
              </>}
            </TableRow>}
          </TableBody>
        </TableCustom> : <ParagraphNoData>Brak danych</ParagraphNoData>}
      </TableContainerCustom>

    </Grid>
  ) : <GlobalLoader />;
};
