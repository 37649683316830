import { ReactNode, useEffect } from 'react';
import { useCasesApi } from '../../../../apiHooks';
import { useAuthStore, useCasesStore } from '../../../../store/hooks';
import { IBreadcrumbsElement } from '../../../../interfaces/user/breadcrumbs';
import { scrollToTop } from '../../../../utils/scrollToTop';
import { SelectChangeEvent } from '@mui/material';
import { IOption } from '../../../../interfaces/common/form/select';

export const useMyCasesViewModel = () => {
  const { getListOfMyCases } = useCasesApi();
  const casesStore = useCasesStore();
  const {
    filters,
    pageCount,
    currentPage,
    listOfMyCases,
    isListOfMyCasesLoaded,
    filteredListOfCasesByCurrentPage,
    selectedAreas
  } = casesStore;
  const { areas } = useAuthStore();

  useEffect(() => {
    if (!isListOfMyCasesLoaded) {
      if (listOfMyCases === null) {
        void getListOfMyCases();
      } else {
        void getListOfMyCases(false);
      }
    }

  }, [listOfMyCases, getListOfMyCases, isListOfMyCasesLoaded]);

  useEffect(() => {
    return () => {
      casesStore.resetTableConfig();
      casesStore.resetListOfMyCases();
    };
  }, [casesStore]);

  const breadcrumbs: IBreadcrumbsElement[] = [
    {
      name: 'Moje opisy przypadków'
    }];

  const setCurrentPage = (value: number) => {
    casesStore.setCurrentPage(value);
    scrollToTop();
  };

  const options = areas && areas.map(el => ({ id: el.id, title: el.fullName }));

  const handleChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedAreas.includes(0)) {
        casesStore.setSelectedAreas(selectedValues.filter(value => value !== 0));
      } else {
        if (options) {
          casesStore.setSelectedAreas([...options.map((option: IOption) => option.id), 0]);
        }
      }
    } else {
      if (selectedAreas.includes(0)) {
        casesStore.setSelectedAreas([]);
      } else {
        casesStore.setSelectedAreas(selectedValues);
      }
    }
  };

  return {
    breadcrumbs,
    listOfMyCases: filteredListOfCasesByCurrentPage,
    switchFilters: casesStore.switchFilters,
    filters,
    pageCount,
    currentPage,
    setCurrentPage,
    isListOfCasesIsNotEmpty: casesStore.isFilteredCasesListNotEmpty || casesStore.listOfMyCases === null,
    selectedAreas, handleChange, options: options || []
  };
};